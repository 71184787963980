import {Component} from '../../../../component';

class ImageStyle extends Component {
    code() {
        return {
            fit: [this.width, this.height],
            alignment: 'left',
            margin: [0, 5, 5, 5],
        };
    }

    get height() {
        return 842/2.5;
    }

    get width() {
        return 595/2.5;
    }
}

export default ImageStyle;
