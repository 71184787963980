var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"subscription-container"},[_c('div',{staticClass:"col-icon text-center"},[_c('v-icon',{staticClass:"icon-success",class:{'icon-style': _vm.$vuetify.breakpoint.mdAndUp,
                         'icon-style-mobile': !_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" mdi-check-circle-outline ")])],1),_c('div',{staticClass:"col-rest",class:{'h2-18':!_vm.$vuetify.breakpoint.mdAndUp}},[_c('h2',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t("Subscription.activeSubscroption"))+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("Subscription.cancelSubscriptionMessage"))+" "),_c('strong',[_vm._v("roni@formup.fi")])])])]),_c('v-card',{staticClass:"my-profile"},[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"px-7 pt-3 pb-2"},[_c('h1',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("Subscription.myProfile")))])]),_c('v-row',{staticClass:"px-7"},[_c('p',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(_vm.$t("Subscription.visibleForOrganisationOnly"))+" ")])]),_c('v-row',{staticClass:"px-7"},[_c('h2',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("Subscription.profilePicture")))])]),_c('v-row',{staticClass:"px-7 pt-3"},[_c('v-avatar',{attrs:{"color":_vm.avatarColor.bg,"size":"60"}},[(_vm.surname !== '' && _vm.surname !== undefined)?_c('span',{staticClass:"text-h5",style:('color: ' + _vm.avatarColor.text)},[_vm._v(_vm._s(_vm.firstname[0].toUpperCase() + _vm.surname[0].toUpperCase()))]):_c('span',{staticClass:"text-h5",style:('color: ' + _vm.avatarColor.text)},[_vm._v(_vm._s(_vm.firstname[0].toUpperCase() + _vm.firstname[1].toUpperCase()))])])],1),_c('v-row',{staticClass:"px-7 pt-5"},[_c('v-col',{class:{'pr-12 pl-0 py-0': _vm.$vuetify.breakpoint.mdAndUp,
                             'pa-0': !_vm.$vuetify.breakpoint.mdAndUp},attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '6' : '12'}},[_c('h2',{staticClass:"text-primary pb-3"},[_vm._v(" "+_vm._s(_vm.$t("Subscription.firstName"))+" ")]),_c('v-text-field',{class:{ 'text-field-100': !_vm.$vuetify.breakpoint.mdAndUp },attrs:{"outlined":"","rules":_vm.firstNameRules},on:{"keydown":_vm.addChangesMade},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1),_c('v-col',{class:{'pr-12 pl-0 py-0': _vm.$vuetify.breakpoint.mdAndUp,
                             'pa-0': !_vm.$vuetify.breakpoint.mdAndUp},attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '6' : '12'}},[_c('h2',{staticClass:"text-primary pb-3"},[_vm._v(_vm._s(_vm.$t("Subscription.lastName")))]),_c('v-text-field',{class:{ 'text-field-100': !_vm.$vuetify.breakpoint.mdAndUp },attrs:{"outlined":"","rules":_vm.surnameRules},on:{"keydown":_vm.addChangesMade},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1)],1),_c('v-row',{staticClass:"px-7"},[_c('h2',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("Subscription.phoneNumber")))])]),_c('v-row',{staticClass:"px-7 pt-3"},[_c('v-text-field',{class:{ 'text-field-100': !_vm.$vuetify.breakpoint.mdAndUp },attrs:{"outlined":"","rules":_vm.phoneRules},on:{"keydown":_vm.addChangesMade},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-row',{staticClass:"px-7"},[_c('h2',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("Subscription.email")))])]),_c('v-row',{staticClass:"px-7 pt-3"},[_c('v-text-field',{class:{ 'text-field-100': !_vm.$vuetify.breakpoint.mdAndUp },attrs:{"outlined":""},on:{"keydown":_vm.addChangesMade},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-row',{staticClass:"px-7"},[_c('h2',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("Subscription.organisation")))])]),_c('v-row',{staticClass:"px-7 pt-3"},[_c('v-text-field',{class:{ 'text-field-100': !_vm.$vuetify.breakpoint.mdAndUp },attrs:{"outlined":"","disabled":""},on:{"keydown":_vm.addChangesMade},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1),_c('v-row',{staticClass:"pb-4 ma-0",class:{'pb-5 w-100 px-4': !_vm.$vuetify.breakpoint.mdAndUp,
                         'justify-end px-7': _vm.$vuetify.breakpoint.mdAndUp}},[(_vm.changesMade)?_c('div',{class:{'text-right': _vm.$vuetify.breakpoint.mdAndUp,
                             'col-align w-100': !_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-btn',{class:{'secondary-button w-100 py-4 mb-2': !_vm.$vuetify.breakpoint.mdAndUp,
                                 'outline-primary mr-3': _vm.$vuetify.breakpoint.mdAndUp},attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.cancelChanges()}}},[_vm._v(" "+_vm._s(_vm.$t("Subscription.cancel"))+" ")]),_c('v-btn',{staticClass:"primary-button",class:{ 'primary-button w-100 py-4 mb-2': !_vm.$vuetify.breakpoint.mdAndUp,
                                  'disabled-button w-100 py-4 mb-4': _vm.isBtnValid },attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.saveChanges()}}},[_vm._v(" "+_vm._s(_vm.$t("Subscription.save"))+" ")])],1):_vm._e()])],1)],1),_c('v-card',{staticClass:"my-profile"},[_c('v-row',{staticClass:"px-7 pt-3 pb-2"},[_c('h1',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("OwnProfile.settings")))])]),_c('v-row',{staticClass:"h2-18 mb-4 mt-4 px-7"},[_c('h2',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("OwnProfile.language")))])]),_c('v-row',{staticClass:"px-7 mb-7"},[_c('LocaleSwitcher')],1),_c('v-row',{staticClass:"px-7 mb-7"},[_c('v-btn',{staticClass:"button-alert-primary",class:{'w-100 py-3': !_vm.$vuetify.breakpoint.mdAndUp},attrs:{"data-cy":"signOut"},on:{"click":function($event){return _vm.signOut()}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v(" mdi-logout ")]),_vm._v(" "+_vm._s(_vm.$t("Accountmanagement.logOut"))+" ")],1)],1)],1),_c('GeneralSnackbar',{ref:"GeneralSnackbar",attrs:{"snackbarText":_vm.snackbarText}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }