<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("CreateFolder.header") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-form ref="form">
                <v-container fluid :class="{ 'pb-8 px-5 pt-header': !$vuetify.breakpoint.mdAndUp }">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols='10'>
                            <h1>{{ $t("CreateFolder.header") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                class="close-button"
                                @click="closeDialog()"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 pl-1" :class="{'px-3': !$vuetify.breakpoint.mdAndUp}">
                        <v-text-field
                            data-cy="addFolderName"
                            :label="$t('CreateFolder.Form.folderName')"
                            v-model="foldername"
                            :rules="nameRules"
                            @keydown.enter.prevent="createFolder"
                            counter="50"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row
                        class="mb-0"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp,
                                 'mt-12': $vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="cancel"
                                data-cy="closeCreateReportDialog"
                            >
                                {{ $t("CreateFolder.cancel") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                @click="createFolder"
                                data-cy="createFolder"
                            >
                                {{ $t("CreateFolder.createFolderBtn") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <BaseLoadingDialog
            :sendingData="loading"
            :msg="$t('Baseloading.loadingMessage')"
        />
    </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import BaseLoadingDialog from './GeneralizedComponents/BaseLoadingDialog.vue';
import _ from 'lodash';
import { folderTemplate } from '../datamodels/datamodels.js';

export default {
    props: ['shared'],
    components: {
        BaseLoadingDialog,
    },
    data() {
        return {
            chosenType: '',
            dialog: false,
            foldername: '',
            loading: false,
            nameRules: [
                (v) => (v && v.length > 0) || this.$t('CreateFolder.Rules.giveName'),
                (v) =>
                    (v && v.length < 51) || this.$t('CreateFolder.Rules.nameTooLong'),
            ],
            initiallyOpen: ['public'],
            activeItems: [],
        };
    },
    methods: {
        openDialog() {
            this.activeItems = [];
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        createFolder() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                // Folder
                var folderData = _.cloneDeep(folderTemplate);
                folderData['name'] = this.foldername;
                folderData['createdAt'] = Math.round(new Date().getTime() / 1000);
                folderData['updatedAt'] = Math.round(new Date().getTime() / 1000);

                // parentFolder
                let parentFolder = this.currentFolder;
                parentFolder['updatedAt'] = Math.round(new Date().getTime() / 1000);

                folderData['participants'] = parentFolder.participants;

                this.$store
                    .dispatch('addFolder', {
                        folderData: folderData,
                        parentFolderID: parentFolder.id,
                        shared: this.shared,
                    })
                    .then(() => {
                        this.$forceUpdate();
                        this.foldername = '';
                        this.loading = false;
                        this.closeDialog();
                    });
                this.$store.dispatch('updateFolder', parentFolder.id);
                this.$emit('folderCreated');
            }
        },
        changeSelectedTemplate(obj) {
            this.selectedTemplate = obj;
        },
        navigateBack() {
            this.closeDialog();
        },
        chooseFolder() {
            this.chosenType = 'folder';
        },
        chooseAutoFolder() {
            this.chosenType = 'autoFolder';
        },
        cancel() {
            this.dialog = false;
        },
    },
    computed: {
        ...mapGetters(['getCurrentFolderData', 'getCurrentSharedFolderData']),
        ...mapState(['folders']),
        currentFolder() {
            return this.$route.name === 'dashboard'
                ? this.getCurrentFolderData
                : this.getCurrentSharedFolderData;
        },
    },
};
</script>

<style scoped>
.pick-hover:hover {
  cursor: pointer;
}
.alert :hover {
  cursor: pointer;
}
</style>
