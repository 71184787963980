<template>
    <div class="wrapper">
        <v-container class="pa-0">
            <v-layout class="ma-0 pa-0">
                <v-flex>
                    <v-row class="mx-0 my-3">
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'" class="pa-0">
                            <v-text-field
                                class="search"
                                v-model="search"
                                :label="$t('MessageGroupTable.searchMessageGroup')"
                                single-line
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                                clearable
                                filled
                                flat
                                solo
                            >
                            </v-text-field>
                        </v-col>

                        <!-- CREATE NEW -BUTTON (TODO: Into own component) -->

                        <v-col align="end" v-if="$vuetify.breakpoint.mdAndUp">
                            <v-btn
                                class="primary-button mt-2"
                                data-cy="createNewItem"
                                @click="openMessageGroup"
                            >
                                <v-icon class="icon-plus">mdi-plus-circle-outline</v-icon>
                                {{ $t("MessageGroupTable.newMessageGroup") }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :loading="loading"
                        :search="search"
                        :headers="headers"
                        :items="getMessageGroups || []"
                        hide-default-footer
                        :page.sync="page"
                        :sort-by.sync="sortBy"
                        :sort-desc="true"
                        :items-per-page="itemsPerPage"
                        @page-count="pageCount = $event"
                        class="rounded-lg row-pointer mt-5"
                        @click:row="openItem"
                        mobile-breakpoint="0"
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                class="secondary-button"
                                @click.stop="deleteItem(item)"
                                :class="{ 'my-2': !$vuetify.breakpoint.mdAndUp, 'my-3': $vuetify.breakpoint.mdAndUp }"
                            >
                                <v-icon data-cy="deleteProcess" class="mr-2"> mdi-delete </v-icon>
                                {{ $vuetify.breakpoint.mdAndUp ? $t("MessageGroupTable.deleteMessageGroup") : '' }}
                            </v-btn>
                        </template>

                        <template v-slot:item.type="{ item }">
                            <!--FOR ERROR TO GO AWAY-->
                            <div v-if="!item"></div>
                            <v-icon class="text-primary"> mdi-message-reply-text-outline </v-icon>
                        </template>

                        <template v-slot:item.createdAt="{ item }">
                            {{ formatTime(item.createdAt) }}
                        </template>

                        <template v-slot:item.owner="{ item }">
                            <v-avatar
                                v-if="getOwner(item.owner) !== null"
                                :color="getAvatarBg(item.owner)"
                                size="40"
                                class="my-3"
                            >
                                <span
                                    v-if="getLastName(item.owner) !== '' && getLastName(item.owner) !== undefined"
                                    class="font-weight-regular"
                                    :style="
                                        'color: ' + getAvatarText(item.owner)
                                    "
                                >{{
                                    getFirstName(item.owner)[0].toUpperCase() +
                                        getLastName(item.owner)[0].toUpperCase()
                                }}</span
                                >
                                <span v-else
                                      class="font-weight-regular"
                                      :style="
                                          'color: ' + getAvatarText(item.owner)
                                      ">
                                    {{
                                        getFirstName(item.owner)[0].toUpperCase() +
                                            getFirstName(item.owner)[1].toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                            <span
                                v-if="getFirstName(item.owner) !== undefined && getLastName(item.owner) !== undefined"
                                class="pl-2"
                            >
                                {{ getFirstName(item.owner) + " " + getLastName(item.owner) }}
                            </span>
                        </template>
                    </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination
                            :class="{ 'mb-9 pb-9': !$vuetify.breakpoint.mdAndUp }"
                            v-model="page"
                            :length="pageCount"
                            v-if="pageCount > 1"
                            :total-visible="7"
                        >
                        </v-pagination>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>

        <!-- CREATE NEW -BUTTON ON MOBILE DEVICE (TODO: Into own component) -->

        <v-btn
            class="mobile-add"
            v-on="on"
            v-bind="attrs"
            v-if="!$vuetify.breakpoint.mdAndUp"
            @click="openMessageGroup"
        >
            <v-icon class="icon-plus mr-0">mdi-plus</v-icon>
        </v-btn>

        <OkCancelDialog ref="OkCancelDialog" />

        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('MessageGroupTable.deletingMessage')"
        >
        </BaseLoadingDialog>
    </div>
</template>
<script>
import OkCancelDialog from '../OkCancelDialog';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import { messageGroups } from '../../fbInitialize';

export default {
    components: {
        OkCancelDialog,
        BaseLoadingDialog,
    },
    data() {
        return {
            getMessageGroups: [],
            loading: false,
            sendingData: false,
            inspectDialog: false,
            sortDesc: false,
            search: '',
            item: {},
            page: 1,
            sortBy: 'createdAt',
            pageCount: 0,
            itemsPerPage: 50,
        };
    },
    methods: {
        openMessageGroup() {
            this.$router.push({ name: 'messagegroup', params: { edit: false }});
        },
        openItem(item) {
            this.$router.push({ name: 'messagegroup' , params: { messageGroup:item, edit: true}});
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        async deleteItem(item) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('MessageGroupTable.deleteMessageGroupProcessTitle'),
                this.$t('MessageGroupTable.deleteMessageGroupMessage')
            );
            if (deleteApproved) {
                this.$store.dispatch('deleteMessageGroup', item).then(() => {
                    this.$forceUpdate;
                    this.getMessageGroups = this.getMessageGroups.filter((x) => x.id !== item.id);
                });
            }
        },
        formatTime(epochTime) {
            var d = new Date(epochTime * 1000);
            return d.toLocaleString();
        },
        getFirstName(ownerId) {
            let user = this.$store.state.organizationUsers.find(
                (x) => x.id === ownerId
            );
            if (user !== undefined) {
                return (user.firstname);
            }
        },
        getLastName(ownerId) {
            let user = this.$store.state.organizationUsers.find(
                (x) => x.id === ownerId
            );
            if (user !== undefined) {
                return (user.surname);
            }
        },
        getAvatarBg(ownerId) {
            let user = this.$store.state.organizationUsers.find(
                (x) => x.id === ownerId
            );
            if (user !== undefined && user.avatarColor !== undefined) {
                return (user.avatarColor.bg);
            }
        },
        getAvatarText(ownerId) {
            let user = this.$store.state.organizationUsers.find(
                (x) => x.id === ownerId
            );
            if (user !== undefined && user.avatarColor !== undefined) {
                return (user.avatarColor.text);
            }
        },
        getAvatar(ownerId) {
            let user = this.$store.state.organizationUsers.find(
                (x) => x.id === ownerId
            );
            if (user !== undefined && user.avatarColor !== undefined) {
                return (user.avatarColor);
            }
            else return null;

        },
        getOwner(ownerId) {
            let user = this.$store.state.organizationUsers.find(
                (x) => x.id === ownerId
            );
            if (user !== undefined) {
                return user;
            }
            else return null;

        }
    },
    created() {
        messageGroups
            .where('organizationId', '==', this.$store.state.userProfile.organization)
            .where('firebaseDeleted', '==', false)
            .get().then((qs) => {
                const data = qs.docs.map((doc) => {
                    let result = doc.data();
                    result.id = doc.id;
                    return result;
                });
                this.getMessageGroups = data;
            });
    },
    computed: {
        headers() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return [
                    {
                        text: '',
                        value: 'type',
                        sortable: false,
                        width: '5%',
                    },
                    {
                        text: this.$t('MessageGroupTable.tableName'),
                        align: 'start',
                        value: 'name',
                        width: '30%',
                    },
                    {
                        text: this.$t('MessageGroupTable.created'),
                        value: 'createdAt',
                        width: '30%',
                    },
                    {
                        text: this.$t('MessageGroupTable.owner'),
                        value: 'owner',
                        width: '25%',
                    },
                    {
                        text: this.$t('MessageGroupTable.tableActions'),
                        value: 'actions',
                        sortable: false,
                        align: 'start',
                        width: '10%',
                    },
                ];
            } else {
                return [
                    {
                        text: '',
                        value: 'type',
                        sortable: false,
                        width: '5%',
                    },
                    {
                        text: this.$t('MessageGroupTable.tableName'),
                        align: 'start',
                        value: 'name',
                        width: '75%',
                    },
                    {
                        text: '',
                        value: 'actions',
                        sortable: false,
                        align: 'end',
                        width: '20%',
                    },
                ];
            }
        }
    }
};
</script>

<style scoped>
.row-pointer :deep(tbody tr :hover) {
  cursor: pointer;
}
</style>
