<template>
    <div class="wrapper">
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :routeName="'processes'"
            :pageHeader="this.$t('Processes.createMessageGroup')"
            :edit="true"
        />
        <v-container
            class="px-0 pt-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-row class="mx-0 mb-7" v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                    x-large
                    circle
                    icon
                    @click="navigateToProcesses"
                    class="rounded-button mr-7"
                >
                    <v-icon color="primary">mdi-arrow-left</v-icon>
                </v-btn>
                <h1>{{ $t("Processes.createMessageGroup") }} </h1>
            </v-row>
            <v-row class="mb-0 px-4 mx-0" v-else>
                <v-btn class="secondary-button pl-0" @click="navigateToProcesses">
                    <v-icon color="primary" class="icon-plus"> mdi-chevron-left </v-icon>
                    {{ $t("Process.navigateBack") }}
                </v-btn>
            </v-row>
            <v-row class="h1-26 mx-0 mb-3 px-4" v-if="!$vuetify.breakpoint.mdAndUp">
                <h1>{{ $t("Processes.createMessageGroup") }} </h1>
            </v-row>
            <v-row class="mx-0 h2-18 text-primary" :class="{ 'px-4': !$vuetify.breakpoint.mdAndUp }">
                <h2>{{ $t("Processes.messageGroupName") }}</h2>
            </v-row>
            <v-form ref="form">
                <v-row class="mx-0 mt-4 mb-7" :class="{ 'px-4': !$vuetify.breakpoint.mdAndUp }">
                    <v-text-field
                        v-model="nameEditHelper"
                        :placeholder="$t('Processes.messageGroupName')"
                        class="mt-n2"
                        :rules="nameRules"
                        @keydown.enter.prevent
                    >
                    </v-text-field>
                    <v-progress-circular
                        v-if="nameChangeSaving"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-row>
            </v-form>


            <!-- Main dashboard -->
            <v-card class="mt-4" max-width="1700">
                <v-row class="ma-0" justify="center">
                    <v-card
                        class="rounded-lg px-7 py-2 w-100 d-flex col-align"
                        :class="{ 'px-4': !$vuetify.breakpoint.mdAndUp, 'px-7': $vuetify.breakpoint.mdAndUp }"
                    >
                        <v-row
                            class="mx-0 mt-5 mb-2 h2-18 text-primary"
                            :class="{ 'px-2': $vuetify.breakpoint.mdAndUp }"
                        >
                            <h2>{{ $t("Process.openAndModify") }}</h2>
                        </v-row>
                        <v-row class="mx-0 px-2 my-2">
                            <v-btn
                                data-cy="addEmailMessage"
                                @click="addEmailMessage"
                                class="primary-button mb-3 mr-3"
                            >
                                <v-icon left> mdi-email </v-icon>
                                {{ $t("Processes.addEmail") }}
                            </v-btn>
                            <v-btn
                                @click="addTextMessage"
                                class="primary-button mb-3"
                            >
                                <v-icon left> mdi-cellphone-message </v-icon>
                                {{ $t("Processes.addTextmessage") }}
                            </v-btn>
                        </v-row>
                        <v-list subheader two-line>
                            <v-list-item
                                data-cy="modifyMessage"
                                v-for="message in messages"
                                :key="message.id"
                                @click="openOrModifyItem(message)"
                            >
                                <v-list-item-avatar v-if="message.type === 'email'" dark>
                                    <v-icon
                                        class="primary"
                                        dark
                                    >
                                        mdi-email
                                    </v-icon>

                                </v-list-item-avatar>

                                <v-list-item-avatar v-if="message.type === 'sms'">
                                    <v-icon
                                        class="primary"
                                        dark
                                    >
                                        mdi-cellphone-message
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-card class="pa-3">
                                        <v-list-item-title
                                            v-text="message.title"
                                        ></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="'Lähetetään '
                                                + message.sendTime + ' päivää ennen prosessin sulkeutumista.'"
                                        ></v-list-item-subtitle>
                                    </v-card>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn
                                        data-cy="deleteMessage"
                                        icon
                                        @click.stop="deleteAction(message)"
                                    >
                                        <v-icon class="text-primary">mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-row
                            class="pb-4 ma-0"
                            :class="{'pb-5 w-100 px-2': !$vuetify.breakpoint.mdAndUp,
                                     'justify-end px-7': $vuetify.breakpoint.mdAndUp}"
                        >
                            <div
                                :class="{'text-right': $vuetify.breakpoint.mdAndUp,
                                         'col-align w-100': !$vuetify.breakpoint.mdAndUp}"
                            >
                                <v-btn
                                    :class="{'secondary-button w-100 mb-2': !$vuetify.breakpoint.mdAndUp,
                                             'outline-primary mr-3': $vuetify.breakpoint.mdAndUp}"
                                    @click="cancelChanges()"
                                    :disabled="loading"
                                >
                                    {{ $t("Subscription.cancel") }}
                                </v-btn>
                                <v-btn
                                    class="primary-button"
                                    @click="saveMessageGroup()"
                                    :loading="loading"
                                    :class="{ 'primary-button w-100 mb-2 py-3': !$vuetify.breakpoint.mdAndUp }"
                                >
                                    {{ $t("Subscription.save") }}
                                </v-btn>
                            </div>
                        </v-row>
                    </v-card>
                </v-row>
            </v-card>

            <!-- Inspect email -->

            <InspectAutomaticMessage
                :inspectItem="this.inspectItem"
                ref="InspectAutomaticMesage"
            />

            <!-- Add new automatic massage -->

            <CreateAutomaticMessage
                ref="addAutomaticMessage" :modify="modify" :messageGroup="true"
                @AddMessageToGroup="AddMessageToGroup" @SaveModifyMessage="SaveModifyMessage"/>

            <!-- View and add users -->

        </v-container>
        <OkCancelDialog ref="OkCancelDialog" />

        <!-- For inspecting template -->


        <PDFGenerator ref="PDFGenerator" />

        <BaseLoadingDialog
            :sendingData="loading"
            :msg="$t('Baseloading.loadingMessage')"
        />

        <OkExitDialog ref="OkExitDialog" />

    </div>
</template>

<script>
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';
import CreateAutomaticMessage from '../components/Processes/CreateAutomaticMessage.vue';
import PDFGenerator from '../components/FillReport/PDFGenerator.vue';
import OkCancelDialog from '../components/OkCancelDialog.vue';
import OkExitDialog from '../components/OkExitDialog.vue';
import InspectAutomaticMessage from '../components/Processes/InspectAutomaticMessage.vue';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';

export default {
    components: {
        MobileNavigation,
        CreateAutomaticMessage,
        PDFGenerator,
        OkCancelDialog,
        OkExitDialog,
        InspectAutomaticMessage,
        BaseLoadingDialog,
    },
    data() {
        return {
            //NameChanging Helpers Here:,
            nameEditHelper: '',
            nameChangeSaving: false,
            saved: false,
            inspectItem: {},
            // This is for animation
            icon: 'mdi-calendar',
            modify: false,
            loading: false,
            messages: [],
            groupName: '',
            nameRules: [
                (v) => (v && v.length > 0) || this.$t('Processes.messageGroupNameRule'),
                (v) =>
                    (v && v.length < 51) || this.$t('Processes.nameTooLong'),
            ],
        };
    },
    methods: {
        AddMessageToGroup(message) {
            this.messages.push(message);
        },
        SaveModifyMessage(message) {
            let index = this.messages.findIndex(x => x.id === message.id);
            this.messages.splice(index, 1, message);
        },
        async deleteAction(message) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('Processes.deleteMessageHeader'),
                this.$t('Processes.deleteMessageMessage'),
            );
            if (deleteApproved) {
                this.messages = this.messages.filter((x) => x.id != message.id);
            }
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        openOrModifyItem(item) {
            this.modify = true;
            this.$refs.addAutomaticMessage.inspectModifyMessageGroupDialog(item);
        },
        addTextMessage() {
            this.modify = false;

            this.$refs.addAutomaticMessage.openCreateDialog('sms');
        },
        addEmailMessage() {
            this.modify = false;

            this.$refs.addAutomaticMessage.openCreateDialog('email');
        },
        async navigateToProcesses() {
            let deleteApproved = await this.$refs.OkExitDialog.open(
                this.$t('Process.cancel'),
                this.$t('Process.confirmCancel'),
                this.$t('FillAutolinkReport.yes'),
                this.$t('FillAutolinkReport.no')
            );
            if (deleteApproved) {
                this.$router.push({ name: 'processes' });
            }
        },
        async cancelChanges() {
            let deleteApproved = await this.$refs.OkExitDialog.open(
                this.$t('Process.cancel'),
                this.$t('Process.confirmCancel'),
                this.$t('FillAutolinkReport.yes'),
                this.$t('FillAutolinkReport.no')
            );
            if (deleteApproved) {
                this.$router.push({ name: 'processes' });
            }
        },
        saveMessageGroup() {
            if (this.$refs.form.validate()) {
                if(this.$route.params.edit) {
                    let id = this.$route.params.messageGroup.id;
                    let data = {
                        id: id,
                        name: this.nameEditHelper,
                        messages: this.messages
                    };
                    this.$store
                        .dispatch('updateMessageGroup', data)
                        .then(() => {
                            this.loading = false;
                            this.$router.push({ name: 'processes' });
                        });
                } else {
                    this.loading = true;
                    let messageGroup = {};
                    messageGroup.name = this.nameEditHelper;
                    messageGroup.messages = this.messages;
                    this.$store.dispatch('addMessageGroup', messageGroup).then(() => {
                        this.loading = false;
                        this.$router.push({ name: 'processes' });
                    });
                }
            }
        }
    },
    created() {
        if(this.$route.params.edit) {
            this.nameEditHelper = this.$route.params.messageGroup.name;
            this.messages = this.$route.params.messageGroup.messages;
        }
    },
};
</script>

<style>
</style>
