<template>
    <v-dialog
        v-model="addAutomaticMessage"
        max-width="600"
        class="pa-0 ma-0"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            class="border-0 h1-26"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeAndClearInstantMessage">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("CreateInstantMessage.dialogHeader") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5':!$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1>{{ $t("CreateInstantMessage.dialogHeader") }}</h1>
                    </v-col>
                    <v-col cols="2">
                        <v-icon
                            class="close-button"
                            @click="closeAndClearInstantMessage"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-form ref="form" :class="{'mb-150': !$vuetify.breakpoint.mdAndUp}">
                    <!--
                    <v-alert
                        icon="mdi-information"
                        color="info"
                        class="pa-4 rounded-lg mt-5 mb-7"
                        text
                        outlined
                    >
                        <p class="mb-0">
                            {{message.type === 'email' ?
                                $t("CreateAutomaticMessage.messageInfo") :
                                $t("CreateAutomaticMessage.messageInfo") +
                                $t("CreateAutomaticMessage.textMessagePrice")}}
                        </p>
                    </v-alert>
                    -->
                    <v-row class="mx-0 mb-0 h2-18 text-primary">
                        <h2>{{ $t("CreateInstantMessage.giveMessageName") }}</h2>
                    </v-row>
                    <v-row class="mx-0 my-3">
                        <v-text-field
                            data-cy="messageTitleInput"
                            :label="$t('CreateInstantMessage.addMessageName')"
                            v-model="message.title"
                            hide-details
                            outlined
                            class="comment-field-100 rounded-lg"
                            :rules="requireName"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0 mt-5 mb-0 h2-18 text-primary">
                        <h2>{{ $t("CreateInstantMessage.addMessageHeader") }}</h2>
                    </v-row>
                    <v-row class="mx-0 mt-3" v-if="message.type === 'email'">
                        <v-text-field
                            data-cy="emailTitleInput"
                            :label="$t('CreateInstantMessage.header')"
                            v-model="message.emailTitle"
                            required
                            outlined
                            class="comment-field-100 rounded-lg"
                            :rules="requireHeader"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0 mb-3 h2-18 text-primary">
                        <h2>{{ $t("CreateInstantMessage.messageRule") }}</h2>
                    </v-row>
                    <v-row class="mx-0">
                        <v-textarea
                            data-cy="emailContentInput"
                            :label="$t('CreateInstantMessage.addMessage')"
                            v-model="message.msg"
                            counter
                            required
                            outlined
                            class="comment-field-100 rounded-lg textarea-transparent"
                            :rules="requireMessage"
                            style="white-space: pre-line"
                        ></v-textarea>
                    </v-row>
                    <v-row
                        class="mt-3 mb-0"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="closeAndClearInstantMessage"
                            >
                                {{ $t("CreateInstantMessage.discard") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                @click="sendInstantMessage"
                                data-cy="saveMessage"
                                :loading="loading"
                            >
                                {{ $t("CreateInstantMessage.send") }}
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>
            </v-container>
        </v-card>
        <BaseLoadingDialog
            :sendingData="this.loading"
            :msg="$t('Baseloading.sendingMessage')"
        />
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';

export default {
    components: {
        BaseLoadingDialog,
    },
    data() {
        return {
            loading: false,
            addAutomaticMessage: false,
            requireName: [
                (v) => (v && v.length > 0) || this.$t('CreateInstantMessage.nameRule'),
            ],
            requireSelect: [(v) => !!v || this.$t('CreateInstantMessage.selectRule')],
            requireHeader: [
                (v) =>
                    (v && v.length > 0) || this.$t('CreateInstantMessage.headerRule'),
            ],
            requireMessage: [
                (v) =>
                    (v && v.length > 0) || this.$t('CreateInstantMessage.messageRule'),
            ],
            emailTemplate: {
                emailTitle: '',
                title: '',
                msg: '',
                endDate: '',
                process: this.$store.state.currentProcess.id,
                owner: this.$store.state.userProfile.id,
                firebaseDeleted: false,
                reportFillBaseUrl: location.origin + '/fillprocessreport',
                type: 'email',
            },
            message: {},
            receiver: {},
        };
    },
    methods: {
        openCreateDialog(item) {
            this.receiver = item;
            this.addAutomaticMessage = true;
            this.message = _.cloneDeep(this.emailTemplate);
        },
        closeAndClearInstantMessage() {
            this.message = {};
            this.addAutomaticMessage = false;
        },
        sendInstantMessage() {
            if (this.$refs.form.validate()) {
                let process = this.$store.state.currentProcess;

                let i = process.receivers.findIndex((x) => x.id === this.receiver.id);
                if (!process.receivers[i].instanMessages) {
                    process.receivers[i].instanMessages = [];
                }
                process.receivers[i].instanMessages.push(this.message);
                this.message.email = this.receiver.email;
                this.message.processID = this.$store.state.currentProcess.id;
                this.message.receiverID = this.receiver.id;
                this.message.createdAt = Math.round(new Date().getTime() / 1000);
                this.loading = true;

                this.$store
                    .dispatch('sendInstantMessage', this.message)
                    .then(() => {
                        this.$store.dispatch('updateProcess', process).then(() => {
                            this.loading = false;
                            this.addAutomaticMessage = false;
                            this.dateHelper = '';
                            this.message = {};
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>

<style>
</style>
