import {Component} from '../../component';

export default class DefaultStyle extends Component {
    code() {
        return {
            margin: [5, 20, 5, 200],
            fontSize: 12,
        };
    }
}
