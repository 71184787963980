var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mobile-navigation"},[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-img',{staticClass:"ml-2 my-2",attrs:{"src":require("../../../public/logo.svg"),"max-width":"140"}})],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"2","align":"end"}},[_c('v-btn',{attrs:{"color":"white","x-large":"","icon":""},on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1)],1)],1),_c('v-navigation-drawer',{staticClass:"sidebar",attrs:{"fixed":"","color":"primary","right":"","app":"","temporary":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('hr',{staticClass:"light-gray-divider"}),_c('v-list',{staticClass:"my-3"},_vm._l((_vm.controlItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.navigate(item.routeName)}}},[_c('div',{staticClass:"px-3 w-100",class:{ 'light-bg': _vm.$route.name === item.routeName,
                                  'dark-bg': _vm.$route.name !== item.routeName }},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'light-bg': _vm.$route.name === item.routeName,
                                          'dark-bg': _vm.$route.name !== item.routeName }},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',{attrs:{"data-cy":"controlItems"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Navigation.items." + item.routeName)))])],1)],1)])}),1)]},proxy:true}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',_vm._l((_vm.navItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.navigate(item.routeName)}}},[_c('div',{staticClass:"dark-bg w-100 px-3",class:{'light-bg':_vm.$route.name === item.routeName ||
                        (_vm.$route.name.includes('templategenerator') && item.routeName === 'templates') ||
                        (_vm.$route.name.includes('fillreport') && item.routeName === 'dashboard') }},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"dark-bg",class:{'light-bg':_vm.$route.name === item.routeName ||
                                (_vm.$route.name.includes('templategenerator') && item.routeName === 'templates') ||
                                (_vm.$route.name.includes('fillreport') && item.routeName === 'dashboard') }},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',{attrs:{"data-cy":"navItems"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Navigation.items." + item.routeName)))])],1)],1)])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }