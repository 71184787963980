<template>
    <v-container data-cy="report" class="pa-0 ma-0 mb-3">
        <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".jpeg, .jpg, .png"
            @change="takePhoto($event)"
            :disabled="selectFieldMode"
            multiple
        />
        <v-row
            class="mx-0 px-3"
            v-if="
                field.label !== '' && field.label !== null && field.label !== undefined
            "
        >
            <h3 v-if="field.type === 'textinput'" class="text-primary">
            </h3>
        </v-row>
        <v-row
            v-if="
                isFillable &&
                    field.question !== '' &&
                    field.question !== null &&
                    field.question !== undefined
            "
            class="mx-0 px-3 my-4"
        >
            <h3 class="text-primary">{{ field.question }}</h3>
        </v-row>

        <v-row
            :class="{
                'px-7 pt-0 mb-4 mt-0': $vuetify.breakpoint.mdAndUp,
                'px-2 pt-0 mt-2 mb-4 mx-0': !$vuetify.breakpoint.mdAndUp,
            }"
            v-if="field.type === 'textfield'"
        >
            <v-card class="pa-0 mx-0 mt-0 w-100">
                <v-textarea
                    data-cy="textField"
                    v-model="field.data"
                    @blur="handleAutoSave"
                    @input="emitChange"
                    hide-details
                    :disabled="selectFieldMode"
                    clearable
                    flat
                    solo
                    class="text-field-100"
                    :class="{'overlay': selectFieldMode}"
                    :label="$t('Fillfield.fillTextField')"
                >
                </v-textarea>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    class="text-field-100 h1-26"
                    :class="{'h1-18': !$vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </v-row>

        <div v-else-if="field.type === 'addimages'" class="mb-5">
            <div
                class="ml-2 mt-2 mr-2"
                :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                v-if="field.data.length !== 0 || this.skeletonLoaderHelper.length !== 0"
            >
                <v-row class="mx-0 py-4">
                    <div
                        class="image-container-disabled pa-3 mb-4"
                        :class="{
                            'mr-4': $vuetify.breakpoint.width > 480,
                            'mr-0': $vuetify.breakpoint.width <= 480,
                        }"
                        v-for="(image, indexpic) in field.data"
                        :key="indexpic"
                    >
                        <v-row class="mt-0 mb-3 mx-2">
                            <v-icon color="primary" class="mr-2"> mdi-file-image</v-icon>
                            <p class="text-primary mb-0 mr-10">
                                <strong
                                >{{ $t("Fillfield.picture") }} {{ indexpic + 1 }}</strong
                                >
                            </p>
                            <v-icon
                                class="close-button-small"
                                @click="deleteImage(field.data, indexpic)"
                                v-if="$vuetify.breakpoint.width > 480"
                            >mdi-close</v-icon
                            >
                            <div v-else align="end" class="image-control-row">
                                <v-icon
                                    class="close-button-small"
                                    @click="deleteImage(field.data, indexpic)"
                                >mdi-close</v-icon
                                >
                            </div>
                        </v-row>
                        <v-img
                            :src="image.url"
                            width="160"
                            height="160"
                            class="rounded-lg hover"
                            @click="openImgDataDialog(image)"
                            v-if="$vuetify.breakpoint.width > 480"
                        >
                        </v-img>
                        <v-img
                            :src="image.url"
                            contain
                            class="rounded-lg hover"
                            @click="openImgDataDialog(image)"
                            v-else
                        >
                        </v-img>
                    </div>
                    <div
                        class="image-container-disabled pa-3 mb-4"
                        :class="{
                            'mr-4': $vuetify.breakpoint.width > 480,
                            'mr-0': $vuetify.breakpoint.width <= 480,
                        }"
                        v-for="(image) in skeletonLoaderHelper"
                        :key="image.tempId"
                    >
                        <v-row class="mt-0 mb-3 mx-2">
                            <v-icon color="primary" class="mr-2"> mdi-file-image</v-icon>
                            <p class="text-primary mb-0">
                                <strong
                                >{{ $t("Fillfield.loadingPicture") }}</strong
                                >
                            </p>
                        </v-row>
                        <v-skeleton-loader
                            width="160"
                            height="160"
                            type="image"
                            v-if="image.url === 'loading' && $vuetify.breakpoint.width > 480"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                            contain
                            type="image"
                            v-else-if="image.url === 'loading'"
                        ></v-skeleton-loader>
                    </div>
                </v-row>
            </div>
            <v-card
                class="hover-card rounded-lg py-12"
                :class="{
                    'ml-4 mr-8 mt-2':
                        $vuetify.breakpoint.mdAndUp,
                    'mt-2 ml-2 mr-2':
                        !$vuetify.breakpoint.mdAndUp,
                }"
                @click="takePictureClicked(field)"
                color="rgb(246, 246, 246)"
            >
                <v-row align="center" justify="center" class="mx-0" :class="{'overlay': selectFieldMode}">
                    <v-icon size="40" color="primary">mdi-camera </v-icon>
                </v-row>
                <v-row align="center" justify="center" class="text-primary mx-0" :class="{'overlay': selectFieldMode}">
                    <p class="text-500 mb-0">{{ $t("Fillfield.clickToTakePhoto") }}</p>
                </v-row>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    class="h1-26"
                    :class="{'h1-18': !$vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </div>
        <div v-else-if="field.type === 'comment'" class="mb-7">
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <div
                    class="ml-2 mt-2 mr-2 w-90 row"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-container
                        v-for="(data, i) in field.data"
                        :key="i"
                        class="pa-0 mb-3 blink"
                        :class="{'overlay': selectFieldMode}"
                    >
                        <v-card color="gray" class="pa-5 rounded-lg">
                            <v-row class="ma-0">
                                <!-- DISABLED FOR NOW
                <div class="image-avatar-file mr-4">
                  <p class="avatar-text-table">JE</p>
                </div>
                -->
                                <v-col cols="7" class="pa-0">
                                    <div v-if="data.sender !== 'undefined undefined'">
                                        <v-row
                                            class="ma-0 text-500 text-primary"
                                            v-if="data.sender !== 'undefined undefined'"
                                        >
                                            {{ data.sender }}
                                        </v-row>
                                        <v-row class="ma-0 text-gray small">
                                            {{ formatTime(data.timestamp) }}
                                        </v-row>
                                    </div>
                                    <div v-else>
                                        <v-row class="ma-0 text-500 text-primary">
                                            {{ formatTime(data.timestamp) }}
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-col class="pr-2 pt-2" align="end">
                                    <v-icon color="primary" @click="deleteMessage(field, i)">
                                        mdi-delete
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row data-cy="commentContent" class="ma-0 w-90 break-word">
                                <!--<v-col class="avatar-width"></v-col>-->
                                <v-col class="pa-0">
                                    <p class="mb-0">{{ data.message }}</p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-container>

                    <v-text-field
                        data-cy="commentField"
                        v-model="field.commentHelper"
                        :label="$t('Fillfield.writeComment')"
                        single-line
                        @keydown.enter.prevent="addComment(field)"
                        filled
                        flat
                        hide-details
                        solo
                        class="comment-field-100 mb-4"
                        :class="{'overlay': selectFieldMode}"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon class="mx-3 my-6 icon-plus"
                            >mdi-comment-text-outline</v-icon
                            >
                        </template>
                        <template v-slot:append>
                            <v-icon
                                data-cy="addComment"
                                class="mx-3 icon-plus"
                                color="primary"
                                @click="addComment(field)"
                            >mdi-send</v-icon
                            >
                        </template>
                    </v-text-field>
                    <!--
                        Specs for selectFieldMode, this is done for naming the document dynamically
                    -->

                    <v-overlay
                        :value="true"
                        absolute
                        opacity="0.3"
                        class="ml-2 mr-2 w-90 h1-26 mb-4"
                        :class="{ 'ml-4 mr-8': $vuetify.breakpoint.mdAndUp, 'h1-18': !$vuetify.breakpoint.mdAndUp }"
                        v-if="selectFieldMode"
                    >
                        <h1 class="white--text">
                            {{ $t("Fillfield.unselectableField") }}
                        </h1>
                    </v-overlay>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'choice'" class="mb-4">
            <v-card elevation="0" class="pa-0 ma-0">
                <div
                    class="ml-2 mt-2 mr-2 w-90"
                    :class="{ 'ml-4 mr-8 mt-3 w-90': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-container class="pa-0" :class="{'overlay': selectFieldMode}" v-if="field.options.length <= 10">
                        <div v-for="choice in field.options" :key="choice">
                            <v-btn
                                v-if="choice == field.data"
                                class="ma-1 rounded-lg font-weight-regular"
                                color="primary"
                                dark
                                @click="changeChoiceData('')"
                                block
                                height="50"
                            >
                                <div>
                                    {{ choice }}
                                </div>

                            </v-btn>
                            <v-btn
                                v-else
                                class="ma-1 rounded-lg font-weight-regular"
                                @click="changeChoiceData(choice)"
                                color="white"
                                block
                                height="50"
                            >
                                <div>
                                    {{ choice }}
                                </div>
                            </v-btn>
                        </div>
                    </v-container>
                    <v-container v-else class="pa-0" :class="{'overlay': selectFieldMode}">
                        <v-autocomplete
                            clearable
                            :items="field.options"
                            v-model="field.data"
                            :placeholder="$t('Fillfield.chooseOption')"
                        >
                        </v-autocomplete>
                    </v-container>
                    <v-overlay
                        :value="true"
                        absolute
                        opacity="0.3"
                        class="h1-26"
                        :class="{'h1-18 ml-3 mr-1': !$vuetify.breakpoint.mdAndUp,
                                 'ml-5 mr-7': $vuetify.breakpoint.mdAndUp}"
                        v-if="selectFieldMode"
                    >
                        <h1 class="white--text">
                            {{ $t("Fillfield.unselectableField") }}
                        </h1>
                    </v-overlay>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'table'" class="mb-4">
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <div
                    class="ml-2 mt-2 mr-2 hover"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp,
                              'overlay': selectFieldMode
                    }"
                    v-for="(item, index) in field.data"
                    :key="index"
                    @click="inspectTableItem(index)"
                >
                    <v-row
                        data-cy="tableItemTitle"
                        class="mx-0 my-2 px-4 file-container-disabled py-2"
                    >
                        <v-col cols="10" class="pt-3">
                            <v-icon color="primary" class="mr-5">mdi-table</v-icon>
                            {{ item.data[0].value || "Error" }}
                        </v-col>
                        <v-col cols="2" align="end">
                            <v-icon
                                color="primary"
                                @click.stop="deleteTableItem(field.data, index)"
                            >
                                mdi-delete
                            </v-icon>
                        </v-col>
                    </v-row>
                </div>
                <v-btn
                    data-cy="openTableDataDialog"
                    @click="openAddRowField(field)"
                    class="primary-button ml-4 mt-0"
                    :class="{
                        'mt-5': field.data.length !== 0,
                        'mt-3': !$vuetify.breakpoint.mdAndUp,
                        'overlay': selectFieldMode
                    }"
                >
                    {{ $t("Fillfield.addFinding") }}
                </v-btn>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    class="h1-26 ml-4"
                    :class="{'h1-18 mr-2 mt-3': !$vuetify.breakpoint.mdAndUp, 'mr-7': $vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </div>


        <v-row
            :class="{
                'px-7 pt-4 mb-0': $vuetify.breakpoint.mdAndUp,
                'px-2 pt-2 mt-2 mb-0 mx-0': !$vuetify.breakpoint.mdAndUp,
            }"
            v-else-if="field.type === 'textinput'"
        >
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <v-text-field
                    data-cy="inputField"
                    v-model="field.data"
                    counter="100"
                    :label="field.label ? field.label : field.question"
                    @blur="handleAutoSave"
                    @input="emitChange"
                    clearable
                    flat
                    class="text-field-100"
                    :class="{'overlay': selectFieldMode}"
                ></v-text-field>
                <!--
        Specs for selectFieldMode, this is done for naming the document dynamically
      -->

                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    class="text-field-100"
                    v-if="selectFieldMode"
                >
                    <v-btn @click="addNamingField" class="primary-button">
                        {{ $t("Fillfield.selectThisField") }}
                    </v-btn>
                </v-overlay>
            </v-card>
        </v-row>

        <div v-else-if="field.type === 'file'">
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <div
                    class="ml-2 mt-2 mr-2"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-row
                        class="mx-0 my-2 px-4 file-container-disabled py-2"
                        v-for="(data, i) in field.data"
                        :class="{'overlay': selectFieldMode}"
                        :key="i"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '7' : '10'"
                            class="mt-1"
                            :class="{ 'mt-2': $vuetify.breakpoint.mdAndUp }"
                        >
                            <v-row class="ma-0">
                                <v-icon
                                    color="primary"
                                    class="mr-3"
                                    :class="{ 'mr-5': $vuetify.breakpoint.mdAndUp }"
                                >
                                    mdi-file-document
                                </v-icon>
                                <a :href="data.url" target="_blank">
                                    {{ $vuetify.breakpoint.mdAndUp ? getFileName(data.url)
                                        : getFileNameMobile(data.url) }}
                                </a>
                            </v-row>
                        </v-col>
                        <!--DISABLED FOR NOW
            <div
              class="image-avatar-file mt-3"
              v-if="$vuetify.breakpoint.mdAndUp && false"
            >
              <v-avatar :color="getParticipantColors()" size="40" class="mr-2 mb-2">
                <span
                  class="font-weight-regular"
                  :style="'color: ' + participant.avatarColor.text"
                  >{{
                    participant.firstname[0].toUpperCase() +
                    participant.surname[0].toUpperCase()
                  }}</span
                >
              </v-avatar>
            </div>
            -->
                        <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
                            <div v-if="data.sender !== 'undefined undefined'">
                                <v-row
                                    class="ma-0 text-500 text-primary"
                                    v-if="data.sender !== 'undefined undefined'"
                                >
                                    {{ data.uploader }}
                                </v-row>
                                <v-row class="ma-0 text-gray small">
                                    {{ formatTime(data.timestamp) }}
                                </v-row>
                            </div>
                            <div v-else>
                                <v-row class="ma-0 text-500 text-primary">
                                    {{ formatTime(data.timestamp) }}
                                </v-row>
                            </div>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '' : '2'"
                            class="pr-3"
                            :class="{ 'mt-2': $vuetify.breakpoint.mdAndUp }"
                            align="end"
                        >
                            <v-icon color="primary" @click="deleteDocument(field.data, i)">
                                mdi-delete
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row
                        class="mx-0 my-2 px-4 file-container-disabled py-2"
                        v-for="(data, i) in skeletonFileLoaderHelper"
                        :class="{'overlay': selectFieldMode}"
                        :key="i"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '7' : '10'"
                            class="mt-1"
                            :class="{ 'mt-2': $vuetify.breakpoint.mdAndUp }"
                        >
                            <v-row class="ma-0">
                                <v-icon
                                    color="primary"
                                    class="mr-3"
                                    :class="{ 'mr-5': $vuetify.breakpoint.mdAndUp }"
                                >
                                    mdi-file-document
                                </v-icon>
                                <v-skeleton-loader
                                    type="text"
                                    :width="300"
                                    class="mx-2 mt-1"
                                ></v-skeleton-loader>
                            </v-row>
                        </v-col>
                        <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
                        </v-col>
                    </v-row>
                    <v-file-input
                        :label="$t('Fillfield.attachmentFile')"
                        @change="setFileHelper($event, field)"
                        :value="addFileHelper"
                        :rules="fileInputRules"
                        class="mr-2"
                        :class="{'overlay': selectFieldMode}"
                        accept=".pdf,.doc,.docx,.txt,.mp4,.3gp,.avi,.mkv,.mov"
                    ></v-file-input>
                    <v-overlay
                        :value="true"
                        absolute
                        opacity="0.3"
                        class="h1-26"
                        :class="{'h1-18 ml-2 mr-2': !$vuetify.breakpoint.mdAndUp,
                                 'ml-5 mr-7': $vuetify.breakpoint.mdAndUp}"
                        v-if="selectFieldMode"
                    >
                        <h1 class="white--text">
                            {{ $t("Fillfield.unselectableField") }}
                        </h1>
                    </v-overlay>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'staticfile'" class="mb-5">
            <v-card class="pa-0 ma-0 w-100">
                <div class="ml-2 mt-2 mr-2" :class="{'ml-4 mr-8 mt-3':$vuetify.breakpoint.mdAndUp,
                                                     'overlay': selectFieldMode}"
                     v-for="(data, i) in field.data"
                     :key="i">
                    <v-row class="mx-0 my-2 px-4 file-container-disabled py-5">
                        <v-icon color="primary" class="ml-3 mr-5">mdi-file-document</v-icon>
                        <a :href="data.url" target="_blank">
                            {{ $vuetify.breakpoint.mdAndUp ? getFileName(data.url) : getFileNameMobile(data.url) }}
                        </a>
                    </v-row>
                </div>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    class="h1-26"
                    :class="{'h1-18 mx-2': !$vuetify.breakpoint.mdAndUp, 'ml-4 mr-8': $vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </div>

        <div v-else-if="field.type === 'slider'" class="mb-5">
            <v-card elevation="0" class="ml-6 mt-12 mr-6" :class="{'ml-8 mr-12':$vuetify.breakpoint.mdAndUp}" >
                <v-slider
                    v-model="sliderValue"
                    dense
                    step="1"
                    :min="field.minValue"
                    :max="field.maxValue"
                    tick-size="4"
                    thumb-label="always"
                    ticks
                    hide-details
                    :disabled="selectFieldMode"
                    @change="handleAutoSave"
                    :class="{'overlay': selectFieldMode}"
                >
                    <template v-slot:prepend>
                        <p class="mb-0 mr-2 mt-1 text-primary"><strong> {{ field.minValue }} </strong></p>
                    </template>
                    <template v-slot:append>
                        <p class="mb-0 ml-2 mt-1 text-primary"><strong> {{ field.maxValue }} </strong></p>
                    </template>
                </v-slider>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    class="h1-26"
                    :class="{'h1-18 ml-n4 mr-n4': !$vuetify.breakpoint.mdAndUp,
                             'ml-n4 mr-n4': $vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </div>

        <div v-else-if="field.type === 'signature'" class="mb-4">
            <v-card
                v-if="field.data.length === 0"
                class="rounded-lg hover-card my-2 py-12"
                :class="{
                    'ml-4 mr-8':
                        $vuetify.breakpoint.mdAndUp,
                    'ml-2 mr-2':
                        !$vuetify.breakpoint.mdAndUp,
                }"
                @click="openSignaturePopup(field)"
                color="rgb(246,246,246)"
            >
                <v-row align="center" justify="center" :class="{'overlay': selectFieldMode}">
                    <v-icon size="40" color="primary">mdi-draw </v-icon>
                </v-row>
                <v-row align="center" justify="center" class="text-primary" :class="{'overlay': selectFieldMode}">
                    <p class="text-500 mb-0">{{ $t("Fillfield.clickToOpenSigning") }}</p>
                </v-row>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    v-if="selectFieldMode"
                    class="h1-26"
                    :class="{'h1-18': !$vuetify.breakpoint.mdAndUp}"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
            <v-card
                v-for="(data, i) in field.data"
                v-else
                :key="i"
                elevation="0"
                class="mx-3"
            >
                <v-row class="mx-0 my-4">
                    <v-col cols="6">
                        <v-img :src="data.url" width="400"> </v-img>
                        <v-row class="mx-0 mt-5">
                            <h1
                                class="text-caption"
                                v-if="data.sender !== 'undefined undefined'"
                            >
                                {{ data.printName }},
                                {{ formatTime(data.timestamp) }}
                            </h1>
                            <h1 class="text-caption" v-else>
                                {{ formatTime(data.timestamp) }}
                            </h1>
                        </v-row>
                    </v-col>
                    <v-col cols="6" align="right" class="pr-6" :class="{'pr-12':$vuetify.breakpoint.mdAndUp}">
                        <v-icon @click="deleteSignature(field.data, i)" color="primary">
                            mdi-delete
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <div v-else-if="field.type === 'text'" class="mb-5">
            <v-card elevation="0" class="pa-0 ma-0">
                <div
                    class="ml-2 mt-2 mr-2 w-90"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp,
                              'overlay': selectFieldMode
                    }"
                >
                    <v-row
                        class="ma-0"
                        :class="{ 'px-2': !$vuetify.breakpoint.mdAndUp }"
                    >
                        <v-col cols="12">
                            <span
                                v-html="replaceURLWithHTMLLinks(field.data)"
                                class="text-body-1 py-2 break-word"
                                style="white-space: pre-line">
                            </span>
                        </v-col>
                    </v-row>
                </div>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    @click="fieldClicked"
                    class="h1-26"
                    :class="{'h1-18 mx-2': !$vuetify.breakpoint.mdAndUp,
                             'mr-8 ml-4': $vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </div>

        <div v-else-if="field.type === 'infotext'">
            <v-card
                class="ml-2 mt-2 mr-2"
                :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
            >
                <v-alert
                    icon="mdi-information-outline"
                    type="info"
                    text
                    class="rounded-lg my-2 py-5 break-word"
                    :class="{'overlay': selectFieldMode}"
                >
                    {{ field.data }}
                </v-alert>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    @click="fieldClicked"
                    class="h1-26"
                    :class="{'h1-18': !$vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </div>

        <div v-else-if="field.type === 'image'">
            <v-card class="pa-0 ma-0 w-100">
                <v-row
                    :class="{
                        'px-7 pt-4 mb-0': $vuetify.breakpoint.mdAndUp,
                        'px-2 pt-2 mt-2 mb-4 mx-0': !$vuetify.breakpoint.mdAndUp,
                        'overlay': selectFieldMode
                    }"
                >
                    <v-img
                        v-for="(imgsrc, index) in field.data"
                        :key="imgsrc"
                        :src="imgsrc"
                        class="rounded hover mb-4 mr-4"
                        max-width="300"
                        contain
                        @click="viewImage(index)"
                    >
                        <ViewImage :src="imgsrc" ref="imgDialog" />
                    </v-img>
                </v-row>
                <v-overlay
                    :value="true"
                    absolute
                    opacity="0.3"
                    @click="fieldClicked"
                    class="h1-26"
                    :class="{'h1-18 mx-2': !$vuetify.breakpoint.mdAndUp, 'ml-4 mr-8': $vuetify.breakpoint.mdAndUp}"
                    v-if="selectFieldMode"
                >
                    <h1 class="white--text">
                        {{ $t("Fillfield.unselectableField") }}
                    </h1>
                </v-overlay>
            </v-card>
        </div>

        <div v-else-if="field.type === 'date'">
            <v-card class="pa-0 mx-0 mt-0 mb-3 w-100 row">
                <div
                    class="ml-2 mt-2 mr-2 w-90 col"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                >
                    <template>
                        <v-row class="mx-0" :class="{'overlay': selectFieldMode}">
                            <v-dialog
                                @click:outside="modal = !modal"
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="field.data"
                                persistent
                                width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        data-cy="dateField"
                                        :value="formatDate(field.data)"
                                        :label="$t('Fillfield.chooseDate')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    data-cy="datePicker"
                                    locale="fi"
                                    v-model="field.data"
                                    scrollable
                                    color="primary"
                                    class="rounded-lg"
                                    :full-width="true"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="secondary-button mb-2"
                                        @click="closeDatePicker()"
                                    >
                                        {{ $t("Fillfield.cancelDate") }}
                                    </v-btn>
                                    <v-btn
                                        data-cy="confirmDate"
                                        class="primary-button mb-2"
                                        @click="
                                            $refs.dialog.save(field.data);
                                            handleAutoSave();
                                        "
                                    >
                                        {{ $t("Fillfield.confirmDate") }}
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-row>
                        <v-row class="mx-0" :class="{'overlay': selectFieldMode}">
                            <v-text-field
                                v-if="field.hasClock"
                                v-model="field.time"
                                :label="$t('Fillfield.insertTime')"
                                prepend-icon="mdi-clock-time-four-outline"
                                type="time"
                                color="primary"
                                @change="handleAutoSave"
                            >
                            </v-text-field>
                        </v-row>
                        <v-overlay
                            :value="true"
                            absolute
                            opacity="0.3"
                            @click="fieldClicked"
                            class="h1-26"
                            :class="{'h1-18 mx-2': !$vuetify.breakpoint.mdAndUp,
                                     'ml-4 mr-8': $vuetify.breakpoint.mdAndUp}"
                            v-if="selectFieldMode"
                        >
                            <h1 class="white--text">
                                {{ $t("Fillfield.unselectableField") }}
                            </h1>
                        </v-overlay>
                    </template>
                </div>
            </v-card>
        </div>

        <div class="ml-4 mr-8 mb-7">
            <p
                v-if="field.required"
                :class="isFilledField() ? 'hidden' : 'red--text'"
            >
                {{ $t("Fillfield.requiredField") }}
            </p>
        </div>

        <AddImgData ref="ImgDataDialog" @saveImageData="handleAutoSave" />

        <AddSignature
            ref="AddSignature"
            @signatureAdded="addSignatureToField($event, field)"
        />

        <okcanceldialog ref="okCancelDialog"> </okcanceldialog>

        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="this.$t('Fillfield.loadingMessage')"
        />

        <AddTableDataDialog
            ref="AddTableDataDialog"
            :field="field"
            @dataSaved="handleAutoSave"
        />
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('Fillfield.tooBigFile') + '100MB'" />
    </v-container>
</template>

<script>
import okcanceldialog from '../OkCancelDialog.vue';
import AddImgData from './AddImgData.vue';
import AddSignature from './AddSignature.vue';
import ViewImage from './ViewImage.vue';
import { fbStorage } from '../../fbInitialize';
import { sendImageObj, sendFileObj } from '../../fbImageUploader';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import AddTableDataDialog from './AddTableDataDialog.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    props: ['field', 'selectFieldMode', 'type', 'autoSave', 'multiMode', 'field2', 'field3'],
    components: {
        okcanceldialog,
        AddImgData,
        BaseLoadingDialog,
        AddSignature,
        AddTableDataDialog,
        ViewImage,
        GeneralSnackbar,
    },
    data() {
        return {
            menu: false,
            modal: false,
            imageFullScreen: false,
            requireTime: [
                (v) => (v && v.length > 0) || this.$t('Fillfield.chooseTime'),
            ],
            addFileHelper: null,
            sendingData: false,
            skeletonLoaderHelper: [],
            skeletonFileLoaderHelper: [],
            absolute: true,
            overlay: true,
            disabled1: false,
            radios: null,
            option: {
                penColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgb(255,255,255)',
            },
            snackbarText: '',
            // TODO: Implementation of corrent values from template
            min: 0,
            value: 0,
            max: 5,
            hovered: false,
            fileInputRules: [
                (file) => !file || !(file.size > 100*1024*1024) || this.$t('Fillfield.fileRule'),
            ],
        };
    },
    methods: {
        viewImage(index) {
            this.$refs.imgDialog[index].openDialog();
        },

        isFilledField() {
            if (
                this.field.required &&
                this.field.type === 'date' &&
                this.field.data
            ) {
                if (this.field.hasClock && !this.field.time) {
                    return false;
                }
                return true;
            }
            if (
                this.field.required &&
                (this.field.type === 'textfield' ||
                    this.field.type === 'choice' ||
                    this.field.type === 'textinput') &&
                this.field.data
            ) {
                return true;
            }
            if (
                this.field.required &&
                (this.field.type === 'comment' ||
                    this.field.type === 'addimages' ||
                    this.field.type === 'file' ||
                    this.field.type === 'table' ||
                    this.field.type === 'signature') &&
                this.field.data.length
            ) {
                return true;
            }
        },
        emitChange() {
            this.$emit('inputChanged');
        },
        handleAutoSave() {
            if (this.autoSave) {
                this.$emit('saveField');
            } else {
                console.log('Autosave off');
            }
        },
        openAddRowField() {
            this.$refs.AddTableDataDialog.openAddNewDialog();
        },
        async deleteTableItem(data, index) {
            let deleteApproved = await this.$refs.okCancelDialog.open(
                this.$t('Fillfield.confirmationHeader'),
                this.$t('Fillfield.confirmationMessage'),
                this.$t('Fillfield.deleteImage'),
                this.$t('Fillfield.cancel')
            );
            if (deleteApproved === true) {
                data = data.splice(index, 1);
                this.handleAutoSave();
            }
        },
        inspectTableItem(index) {
            this.$refs.AddTableDataDialog.openInspectDialog(index);
        },
        openSignaturePopup(field) {
            this.$refs.AddSignature.openDialog(field);
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
        closeDatePicker() {
            this.modal = false;
        },
        addNamingField() {
            // Add id of this field to current
            if (typeof this.field.id === 'undefined') {
                return;
            }
            // This is temporary!!! The structure of this must be redesgined!
            this.$emit('fieldChosenNew', {
                id: this.field.id,
                label: this.field.label,
            });
        },
        fieldClicked() {
            console.log('event');
        },
        openImgDataDialog(image) {
            this.$refs.ImgDataDialog.openDialog(image);
        },
        takePictureClicked(field) {
            this.imageAddHelper = field;
            this.$refs.uploader.click();
        },
        hoverOn() {
            this.hovered = true;
        },
        hoverOff() {
            this.hovered = false;
        },
        takePhoto(event) {
            let files = event.target.files;
            if(files.length > 0) {
                Array.from(files).forEach((file) => {
                    var reader = new FileReader();
                    let epochtime = Math.round(new Date().getTime() / 1000);
                    let tempId = Math.floor((Math.random() * 1000000) + 1);
                    let userName =
                        this.$store.state.userProfile.firstname +
                        ' ' +
                        this.$store.state.userProfile.surname;

                    this.skeletonLoaderHelper.push({
                        url: 'loading',
                        text: '',
                        timetag: epochtime,
                        phototaker: userName,
                        temporaryId: tempId
                    });
                    reader.readAsDataURL(file);
                    reader.onload = async () => {
                        let imgUrl = reader.result;
                        const newImgUrl = await this.loadAndDownscale(imgUrl);
                        let img = newImgUrl;
                        img = await sendImageObj(newImgUrl);

                        if (userName == 'undefined undefined' || userName == undefined) {
                            userName = '';
                        }

                        this.skeletonLoaderHelper.shift();

                        this.imageAddHelper.data.push({
                            url: img,
                            text: '',
                            timetag: epochtime,
                            phototaker: userName,
                        });

                        this.handleAutoSave();
                        this.$forceUpdate();
                    };
                });
            }
            this.$refs.uploader.value = null;
        },
        addSignatureToField(signatureObject, field) {
            field.data.shift();
            field.data.unshift(signatureObject);
            this.handleAutoSave();
        },
        downscaleImage(dataUrl, newWidth) {
            let image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;
            let imageArguments = 0.9;
            let maxCompressedImageSizeKbs = 135;

            // Create a temporary image so that we can compute the height of the downscaled image.
            image = new Image();
            image.src = dataUrl;
            oldWidth = image.width;
            oldHeight = image.height;
            newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

            // Create a temporary canvas to draw the downscaled image on.
            canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the downscaled image on the canvas and return the new data URL.
            ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, newWidth, newHeight);

            // Data url size check, compress until image is small enough
            let head = 'data:image/png;base64,';
            newDataUrl = dataUrl;
            let imgFileSize =
                Math.round(((dataUrl.length - head.length) * 3) / 4) / 1000;
            while (imgFileSize > maxCompressedImageSizeKbs) {
                newDataUrl = canvas.toDataURL('image/jpeg', imageArguments);
                imgFileSize =
                    Math.round(((newDataUrl.length - head.length) * 3) / 4) / 1000;
                imageArguments -= 0.1;
            }
            return newDataUrl;
        },
        loadAndDownscale(imgObj) {
            let base64content = imgObj.split(',');
            //get datatype e.g image/jpeg
            let mimetype = base64content[0].match(
                /[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/
            )[0];
            //get image width for compression
            let img = new Image();
            async function getWidth() {
                return new Promise((resolve) => {
                    img.onload = function () {
                        let originalwidth = img.width;
                        resolve(originalwidth);
                    };
                });
            }
            img.src = imgObj;
            let ref = this;
            return getWidth().then(async function (width) {
                if (width < 1080) {
                    return ref.downscaleImage(imgObj, width, mimetype);
                } else {
                    return ref.downscaleImage(imgObj, 1080, mimetype);
                }
            });
        },
        async deleteImage(obj, index) {
            let deleteApproved = await this.$refs.okCancelDialog.open(
                this.$t('Fillfield.deleteImageHeader'),
                this.$t('Fillfield.deleteImageMessage'),
                this.$t('Fillfield.deleteImage'),
                this.$t('Fillfield.cancel')
            );
            if (deleteApproved) {
                // Delete field
                try {
                    let imgRef = fbStorage.refFromURL(obj[index].url);
                    imgRef
                        .delete()
                        .then(() => {
                            console.log('Deleted object from firebase');
                        })
                        .catch(function (e) {
                            console.log('Image Delete from firebase failed');
                            console.log(e);
                        });
                } catch (e) {
                    console.log(e);
                }
                obj.splice(index, 1);
                this.handleAutoSave();
            }
        },
        async addFile(field) {
            // Upload file to firebase
            if (this.addFileHelper !== null) {
                this.skeletonFileLoaderHelper.push({
                });
                let url = await sendFileObj(this.addFileHelper);
                this.skeletonFileLoaderHelper.shift();
                // returns empty object if fails
                if (url) {
                    let userName = '';
                    if (
                        typeof this.$store.state.userProfile.firstname !== 'undefined' &&
                        typeof this.$store.state.userProfile.surname !== 'undefined'
                    ) {
                        userName =
                            this.$store.state.userProfile.firstname +
                            ' ' +
                            this.$store.state.userProfile.surname;
                    }

                    let epochtime = Math.round(new Date().getTime() / 1000);

                    // Set field data
                    field.data.push({
                        url: url,
                        uploader: userName,
                        timestamp: epochtime,
                    });
                    // Update report on database
                    this.$store.dispatch('updateReport');
                    // Delete file from input
                    this.addFileHelper = null;
                }
            }
        },
        getFileName(url) {
            let storageRef = fbStorage.refFromURL(url);
            if (storageRef.name.length < 50) {
                return storageRef.name;
            } else {
                return storageRef.name
                    .slice(0, 42)
                    .concat('...')
                    .concat(storageRef.name.slice(-7));
            }
        },
        getFileNameMobile(url) {
            let storageRef = fbStorage.refFromURL(url);
            if (storageRef.name.length < 22) {
                return storageRef.name;
            } else {
                return storageRef.name
                    .slice(0, 12)
                    .concat('...')
                    .concat(storageRef.name.slice(-7));
            }
        },
        async deleteSignature(obj, index) {
            let deleteApproved = await this.$refs.okCancelDialog.open(
                this.$t('Fillfield.deleteSignatureHeader'),
                this.$t('Fillfield.deleteSignatureMessage'),
                this.$t('Fillfield.deleteFile'),
                this.$t('Fillfield.cancel')
            );
            if (deleteApproved) {
                // Delete field
                try {
                    let imgRef = fbStorage.refFromURL(obj[index].url);
                    imgRef
                        .delete()
                        .then(() => {
                            console.log('Deleted object from firebase');
                        })
                        .catch(function (e) {
                            console.log('Image Delete from firebase failed');
                            console.log(e);
                        });
                } catch (e) {
                    console.log(e);
                }
                obj.splice(index, 1);
                // Update report on database
                this.$store.dispatch('updateReport');
            }
        },
        async deleteDocument(obj, index) {
            let filename = this.getFileName(obj[index].url);
            let deleteApproved = await this.$refs.okCancelDialog.open(
                this.$t('Fillfield.deleteFileHeader') + filename,
                this.$t('Fillfield.deleteFileMessage') + filename,
                this.$t('Fillfield.deleteFile'),
                this.$t('Fillfield.cancel')
            );
            if (deleteApproved) {
                // Delete field
                try {
                    let imgRef = fbStorage.refFromURL(obj[index].url);
                    imgRef
                        .delete()
                        .then(() => {
                            console.log('Deleted object from firebase');
                        })
                        .catch(function (e) {
                            console.log('Image Delete from firebase failed');
                            console.log(e);
                        });
                } catch (e) {
                    console.log(e);
                }
                obj.splice(index, 1);
                // Update report on database
                this.$store.dispatch('updateReport');
            }
        },
        setFileHelper(file, field) {
            if (file.size < 30 * 1024 * 1024) {
                this.addFileHelper = file;
                this.addFile(field);
                this.$forceUpdate();
            } else {
                this.$refs.GeneralSnackbar.snackbar = true;
            }
        },
        addComment(field) {
            if (field.commentHelper !== '' && field.commentHelper !== undefined) {
                let epochtime = Math.round(new Date().getTime() / 1000);
                let userName =
                    this.$store.state.userProfile.firstname +
                    ' ' +
                    this.$store.state.userProfile.surname;
                if (userName !== ' ' && userName !== '' && userName !== 'undefined undefined') {
                    field.data.unshift({
                        message: field.commentHelper,
                        sender: userName,
                        timestamp: epochtime,
                    });
                    field.commentHelper = '';
                    delete field.commentHelper;
                } else {
                    field.data.unshift({
                        message: field.commentHelper,
                        sender: this.$t('Fillfield.undefinedUser'),
                        timestamp: epochtime,
                    });
                    field.commentHelper = '';
                    delete field.commentHelper;
                }
                this.handleAutoSave();
            }
        },
        async deleteMessage(field, i) {
            let deleteApproved = await this.$refs.okCancelDialog.open(
                this.$t('Fillfield.deleteCommentHeader'),
                this.$t('Fillfield.deleteCommentMessage')
            );
            if (deleteApproved) {
                // Delete comment
                field.data.splice(i, 1);
                this.$forceUpdate();
                this.handleAutoSave();
            }
        },

        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);
            return d.toLocaleString();
        },
        changeChoiceData(data) {
            if(this.field.data === data) {
                this.field.data = '';
            } else {
                this.field.data = data;
            }
            this.handleAutoSave();
        },
        replaceURLWithHTMLLinks(text) {
            var exp =
                /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
            return text.replace(exp, '<a href=\'$1\' target=\'_blank\'>$1</a>');
        },
    },
    computed: {
        isFillable() {
            return (
                this.field.type === 'addimages' ||
                this.field.type === 'textfield' ||
                this.field.type === 'comment' ||
                this.field.type === 'choice' ||
                this.field.type === 'signature' ||
                this.field.type === 'table' ||
                this.field.type === 'textinput' ||
                this.field.type === 'file' ||
                this.field.type === 'date' ||
                this.field.type === 'slider'
            );
        },
        sliderValue: {
            get() {
                if(this.field.type === 'slider') {
                    if(this.field.data == null) {
                        return Math.floor((parseInt(this.field.minValue) + parseInt(this.field.maxValue))/2);
                    } else {
                        return this.field.data;
                    }
                } else {
                    return null;
                }
            },
            set(val) {
                this.field.data = val;
            }
        }
    },
    created() {
        if(this.field.type === 'slider' && !this.field.data) {
            this.field.data = this.sliderValue;
            this.handleAutoSave();
        }
    }
};
</script>
