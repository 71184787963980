<template>
    <v-container class="pa-0 py-2 rounded-lg">
        <v-row class="mx-0 my-3 h2-18 text-primary align-center">
            <v-col class="grow px-0">
                <h2>{{$t('AddFillerDialog.modifyFillersOr')}}</h2>
            </v-col>
            <v-col class="shrink">
                <v-btn
                    data-cy="addManually"
                    class="primary-button mt-n3"
                    @click="modifyFillers = true"
                    v-if="getProcessFillersAndResponses.length !== 0"
                >
                    <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                    {{$vuetify.breakpoint.mdAndUp
                        ? $t('AddFillerDialog.addFillers')
                        : $t('AddFillerDialog.addFillersShort') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-0 mt-5 mb-2" v-if="getProcessFillersAndResponses.length === 0">
            <div class="button-add-field rounded-lg">
                <v-row class="mt-3 mb-0 mx-0" justify="center">
                    <p class="text-gray mb-0"> {{$t('AddFillerDialog.addExcel')}}</p>
                </v-row>
                <v-row class="mt-2 mb-0 mx-0" justify="center">
                    <a href="https://firebasestorage.googleapis.com/v0/b/formupprod.appspot.com/o/exampleMaterial%2FFormupTuoExcelEsimerkki.xlsx?alt=media"> {{$t('AddFillerDialog.downloadExampleForm')}} </a>
                </v-row>
                <v-row class="mt-5 mx-0" justify="center">
                    <v-btn
                        @click="handleFileImport"
                        :loading="isSelecting || loadingFile"
                        class="primary-button"
                    >
                        <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                        {{$t('AddFillerDialog.addExcelFile')}}
                    </v-btn>

                    <!-- Hidden uploader for files -->

                    <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        @change="onFileChanged"
                    >

                </v-row>
                <v-row class="mt-9 mb-0 mx-0" justify="center">
                    <p class="text-gray mb-0">
                        <b>- {{$t('AddFillerDialog.alternatively')}} -</b>
                    </p>
                </v-row>
                <v-row justify="center" class="mb-0 mx-0">
                    <v-btn @click="dialog = true" class="secondary-button" data-cy="addManually">
                        <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
                        {{$t('AddFillerDialog.addManually')}}
                    </v-btn>
                </v-row>
            </div>
        </v-row>

        <v-dialog v-model="modifyFillers" width="600" :fullscreen="!$vuetify.breakpoint.mdAndUp">
            <v-card
                class="border-0"
                :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
                width="600"
            >
                <v-row
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    class="modal-header-mobile h1-16 mx-0 items-center"
                >
                    <v-col cols="2">
                        <v-icon color="primary" class="icon-plus" @click="modifyFillers = false">
                            mdi-chevron-left
                        </v-icon>
                    </v-col>
                    <v-col cols="8" align="center">
                        <h1>{{ $t('AddFillerDialog.modifyFillers') }}</h1>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>
                <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>{{ $t('AddFillerDialog.modifyFillers') }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                class="close-button"
                                @click="modifyFillers = false"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row
                        class="mx-0 mt-5"
                        :class="{'mb-150': !$vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.mdAndUp}"
                    >
                        <div class="button-add-field rounded-lg px-3">
                            <v-row class="mt-3 mb-0 mx-0" justify="center">
                                <p class="text-gray mb-0">{{$t('AddFillerDialog.addExcel')}}</p>
                            </v-row>
                            <v-row class="mt-2 mb-0 mx-0" justify="center">
                                <a href="https://firebasestorage.googleapis.com/v0/b/formupprod.appspot.com/o/exampleMaterial%2FFormupTuoExcelEsimerkki.xlsx?alt=media"> {{$t('AddFillerDialog.downloadExampleForm')}} </a>
                            </v-row>
                            <v-row class="mt-5 mx-0" justify="center">
                                <v-btn
                                    @click="handleFileImport"
                                    :loading="isSelecting || loadingFile"
                                    class="primary-button"
                                >
                                    <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                    {{$t('AddFillerDialog.addExcelFile')}}
                                </v-btn>

                                <!-- Hidden uploader for files -->

                                <input
                                    ref="uploader"
                                    class="d-none"
                                    type="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    @change="onFileChanged"
                                >

                            </v-row>
                            <v-row class="mt-9 mb-0 mx-0" justify="center">
                                <p class="text-gray mb-0">
                                    <b>- {{$t('AddFillerDialog.alternatively')}} -</b>
                                </p>
                            </v-row>
                            <v-row justify="center" class="mb-0 mx-0">
                                <v-btn @click="handleChange" class="secondary-button">
                                    <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
                                    {{$t('AddFillerDialog.addManually')}}
                                </v-btn>
                            </v-row>
                        </div>
                    </v-row>
                    <v-row
                        class="mt-3 mb-0 py-3 justify-end d-flex"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            class="primary-button"
                            :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                            @click="modifyFillers = false"
                        >
                            {{ $t("AddFillerDialog.cancel") }}
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" width="600" :fullscreen="!$vuetify.breakpoint.mdAndUp">
            <v-card
                class="border-0"
                :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
                width="600"
            >
                <v-row
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    class="modal-header-mobile h1-16 mx-0 items-center"
                >
                    <v-col cols="2">
                        <v-icon color="primary" class="icon-plus" @click="dialog = false">
                            mdi-chevron-left
                        </v-icon>
                    </v-col>
                    <v-col cols="8" align="center">
                        <h1>{{ $t("AddFillerDialog.addFillers") }}</h1>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>
                <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>{{ $t("AddFillerDialog.addFillers") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                class="close-button"
                                @click="dialog = false"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-form ref="form">
                        <v-row class="mx-0 pt-3 h2-18">
                            <h2 class="text-primary pb-3">{{ $t("AddFillerDialog.addName")}}</h2>
                        </v-row>
                        <v-row class="mx-0">
                            <v-text-field
                                v-model="name"
                                class="rounded"
                                outlined
                                :rules="nameRules"
                                data-cy="addName"
                            ></v-text-field>
                        </v-row>
                        <v-row class="mx-0 h2-18">
                            <h2 class="text-primary pb-3">{{ $t("AddFillerDialog.addEmail") }}</h2>
                        </v-row>
                        <v-row class="mx-0">
                            <v-text-field
                                v-model="email"
                                class="rounded"
                                :rules="emailRules"
                                outlined
                                data-cy="addEmail"
                            ></v-text-field>
                        </v-row>
                        <v-row class="mx-0 h2-18">
                            <h2 class="text-primary pb-3">{{ $t("AddFillerDialog.addPhone") }}</h2>
                        </v-row>
                        <v-row
                            class="mx-0"
                            :class="{'mb-150': !$vuetify.breakpoint.mdAndUp, 'mb-2': $vuetify.breakpoint.mdAndUp}"
                        >
                            <v-text-field
                                v-model="phone"
                                class="rounded"
                                outlined
                                :rules="phoneRules"
                                data-cy="addPhone"
                            ></v-text-field>
                        </v-row>
                        <v-row
                            class="mt-3 mb-0"
                            :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-col
                                :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                                :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                            >
                                <v-btn
                                    class="secondary-button"
                                    :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                    @click="dialog = false"
                                >
                                    {{ $t("AddImgData.cancel") }}
                                </v-btn>
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                                align="end"
                                :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                            >
                                <v-btn
                                    class="primary-button"
                                    :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                    @click="addReciever"
                                    data-cy="addFiller"
                                >
                                    {{ $t('AddFillerDialog.addFillers') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="snackbarText" />
    </v-container>
</template>

<script>
var XLSX = require('xlsx');
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    components: {
        GeneralSnackbar,
    },
    props: ['items'],

    data() {
        return {
            dialog: false,
            manualAdd: false,
            isSelecting: false,
            selectedFile: null,
            modifyFillers: false,
            excelImport: false,
            showModifyButton: false,
            snackbarText: '',
            loadingFile: false,
            file: [],
            name: '',
            email: '',
            phone: '',
            nameRules: [
                (v) =>
                    v.length > 0 ||
                    this.$t('AddUser.writeName'),
            ],
            emailRules: [
                (v) =>
                    !v ||
                    this.validateEmail(v) ||
                    this.$t('AddUser.writeValidEmail'),
            ],
            phoneRules: [
                (v) =>
                    !v ||
                    this.validatePhone(v) ||
                    this.$t('AddUser.addEmailOrPhoneNumber'),
            ],
            fillerTemplate: {
                name: null,
                email: null,
                phone: null,
                processResponseId: '',
                deliveredActions: [],
                status: 'no response', // types: "no response", "opened", "created"
                id: null,
                PDFReceivers: []
            }
        };
    },
    methods: {
        handleChange() {
            this.modifyFillers = false;
            this.dialog = true;
        },

        handleFileImport() {
            this.isSelecting = true;

            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener('focus', () => {
                this.isSelecting = false;
            }, { once: true });

            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },

        onFileChanged(e) {
            this.file = e.target.files[0];
            this.importFromExcel();
            this.$refs.uploader.value = null;
        },

        addReciever() {
            if(!this.$refs.form.validate()) {
                return;
            }
            let process = this.$store.state.currentProcess;
            if (!process.receivers) {
                process['receivers'] = [];
            }
            let receiver = _.cloneDeep(this.fillerTemplate);
            receiver.id =  Math.random().toString(36).substr(2, 22);
            receiver.email = this.email;
            receiver.phone = this.phone;
            receiver.name = this.name;
            process.receivers.push(receiver);
            this.$store.dispatch('updateProcess', process).then(() => {
                this.email = '';
                this.phone = '';
                this.name = '';
                this.dialog = false;
            });
        },

        validateEmail(email) {
            //eslint-disable-next-line
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        validatePhone(phone) {
            //eslint-disable-next-line
            var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return re.test(phone);
        },
        async importFromExcel() {
            if(this.file.length === 0) {
                return;
            }

            this.loadingFile = true;

            const fillers = await new Promise((resolve) => {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: 'binary'
                    });
                    workbook.SheetNames.forEach(function (sheetName) {
                        var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                        resolve(XL_row_object);
                    });

                };
                reader.onerror = function (ex) {
                    console.error(ex);
                };
                reader.readAsBinaryString(this.file);
            });

            // Validate email OR phone
            var fillersToAdd = [];
            try {
                fillers.forEach((filler, index) => {
                    let fillerToAdd = _.cloneDeep(this.fillerTemplate);
                    fillerToAdd.id =  Math.random().toString(36).substr(2, 22);
                    if(!filler.name) {
                        throw new Error(this.$t('AddProcessFiller.noNameAlert') + `${index + 1}.`);
                    }
                    if(filler.email) {
                        if(this.validateEmail(filler.email)) {
                            fillerToAdd.email = filler.email;
                        } else {
                            throw new Error(this.$t('AddUser.writeValidEmail') + ' ' + filler.email);
                        }
                    }
                    if(filler.phone) {
                        // Strip phone numbers
                        const phone = filler.phone.toString().replace(/[^+0-9]/g, '');
                        if(this.validatePhone(phone)) {
                            fillerToAdd.phone = phone;
                        } else {
                            throw new Error(this.$t('AddUser.addEmailOrPhoneNumber') + ': ' + filler.name);
                        }
                    }
                    if(filler.name) {
                        fillerToAdd.name = filler.name;
                    }
                    if(filler.receiver) {
                        if(this.validateEmail(filler.receiver)) {
                            fillerToAdd.PDFReceivers = [filler.receiver];
                        } else {
                            throw new Error(this.$t('AddUser.writeValidEmail') + ': ' + filler.receiver);
                        }
                    }
                    fillersToAdd.push(fillerToAdd);
                    this.modifyFillers = false;
                });
                let process = this.$store.state.currentProcess;
                if (!process.receivers) {
                    process['receivers'] = [];
                }
                process.receivers = process.receivers.concat(fillersToAdd);
                await this.$store.dispatch('updateProcess', process);
            } catch(e) {
                this.snackbarText = e.message;
                this.$refs.GeneralSnackbar.snackbar = true;
            }
            this.loadingFile = false;
            this.file = [];
        }
    },
    computed: {
        ...mapGetters(['getProcessFillersAndResponses']),
    },
};
</script>
