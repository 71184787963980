import {Component} from '../../../component';


class Footer extends Component {
    code() {
        return {
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {},
                        {
                            text: 'Formup',
                            link: 'https://formup.fi/',
                            alignment: 'center',
                            bold: true,
                        },
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            margin: [0, 0, 25, 0]
                        },
                    ],
                };

            }
        };
    }
}

export default Footer;
