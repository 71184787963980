<template>
    <v-dialog
        v-model="inspectEmailDialog"
        max-width="1000"
        class="pa-0 ma-0"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            class="border-0 h1-26"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="1000"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="inspectEmailDialog = false">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("InspectAutomaticMessage.message") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5':!$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1>{{ $t("InspectAutomaticMessage.message") }}</h1>
                    </v-col>
                    <v-col align="end" cols="2">
                        <v-icon
                            class="close-button"
                            @click="inspectEmailDialog = false"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-form ref="form" :class="{'mb-150': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="ma-0 h2-18 text-primary" :class="{'mt-3': $vuetify.breakpoint.mdAndUp}">
                        <h2>{{ $t("InspectAutomaticMessage.messageName") }}</h2>
                    </v-row>
                    <v-row class="mx-0 my-3">
                        <v-text-field
                            data-cy="messageTitleInput"
                            v-model="message.title"
                            hide-details
                            outlined
                            readonly
                            class="comment-field-100 rounded-lg"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0 mt-5 mb-0 h2-18 text-primary" v-if="message.type === 'email'">
                        <h2>{{ $t("InspectAutomaticMessage.header") }}</h2>
                    </v-row>
                    <v-row class="mx-0 mt-3" v-if="message.type === 'email'">
                        <v-text-field
                            data-cy="emailTitleInput"
                            v-model="message.emailTitle"
                            outlined
                            class="comment-field-100 rounded-lg"
                            readonly
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0 mb-3 h2-18 text-primary">
                        <h2>{{ $t("InspectAutomaticMessage.message") }}</h2>
                    </v-row>
                    <v-row class="mx-0">
                        <v-textarea
                            data-cy="emailContentInput"
                            v-model="message.msg"
                            counter
                            outlined
                            readonly
                            class="comment-field-100 rounded-lg textarea-transparent"
                            style="white-space: pre-line"
                        ></v-textarea>
                    </v-row>
                    <v-row class="mx-0 h2-18 text-primary">
                        <h2>{{ $t("InspectAutomaticMessage.timeSent") }}</h2>
                    </v-row>
                    <v-row
                        class="mx-0"
                        :class="{'mb-150': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col cols="6" class="py-0">
                            <!-- This dialog should be refactored to somewhere else... -->
                            <v-dialog
                                @click:outside="modal = !modal"
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="dateHelper"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        data-cy="selectDate"
                                        :value="formatDate(dateHelper)"
                                        :label="$t('InspectAutomaticMessage.date')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        disabled
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="fi"
                                    v-model="dateHelper"
                                    scrollable
                                    color="primary"
                                    class="rounded-lg"
                                    :full-width="true"
                                    :first-day-of-week="1"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="secondary-button mb-2"
                                        @click="closeDatePicker()"
                                    >
                                        {{ $t("InspectAutomaticMessage.cancelDate") }}
                                    </v-btn>
                                    <v-btn
                                        data-cy="confirmDate"
                                        class="primary-button mb-2"
                                        @click="$refs.dialog.save(dateHelper)"
                                    >
                                        {{ $t("InspectAutomaticMessage.confirmDate") }}
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field
                                data-cy="selectTime"
                                v-model="timeHelper"
                                :label="
                                    $t('InspectAutomaticMessage.timeSend')
                                "
                                prepend-icon="mdi-clock-time-four-outline"
                                type="time"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col v-if="formattedStatuses.length > 0" cols="12" class="py-0 pb-6">
                            <v-data-table
                                :headers="headers"
                                :items="formattedStatuses"
                                :items-per-page="5"
                                class="rounded-lg elevation-1"
                            >
                                <template v-slot:item.status="{ item }">
                                    <span>
                                        <v-icon :color="item.status.color">mdi-circle-medium</v-icon>
                                        {{ item.status.code }}
                                    </span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            inspectEmailDialog: false,
            message: {},
            dateHelper: '',
            timeHelper: '',
            modal: false,
        };
    },
    computed: {
        formattedStatuses() {
            const statuses = this.message.statuses ? this.message.statuses : [];
            let formattedData = [];
            formattedData = statuses
                .map(x => ({email: x.contact ||
                    x?.data.email || x?.data.To, status: this.getFormattedStatus(x.status)}));
            return formattedData;
        },
        headers() {
            return [
                {
                    text: this.$t('InspectAutomaticMessage.receiver'),
                    value: 'email'
                },
                {
                    text: this.$t('InspectAutomaticMessage.status'),
                    value: 'status' ,
                    sort: (a,b) => {
                        return b.color.localeCompare(a.color);
                    }
                }
            ];
        }
    },
    methods: {
        open(item) {
            this.inspectEmailDialog = true;
            this.message = item;
            let offset = new Date().getTimezoneOffset() * 60000;
            this.dateHelper = new Date(item.endDate * 1000)
                .toISOString()
                .substr(0, 10);
            this.timeHelper = new Date(item.endDate * 1000 - offset)
                .toISOString()
                .substr(11, 5);

        },
        getFormattedStatus(status) {
            if(this.message.type === 'sms') {
                if(status === 'accepted' || status === 'scheduled' || status === 'queued' ||
                    status === 'sending' || status === 'dispatched') {
                    return ({code: this.$t('InspectAutomaticMessage.waiting'), color: 'yellow'});
                } else if(status === 'sent' || status === 'delivered') {
                    return ({code: this.$t('InspectAutomaticMessage.delivered'), color: 'green'});
                } else if(status === 'failed' || status === 'undelivered' || status === 'aborted' ||
                    status === 'expired' || status === 'rejected' || status === 'deleted' || status === 'unknown') {
                    return ({code: this.$t('InspectAutomaticMessage.deliveryFailed'), color: 'red'});
                }
            } else if(this.message.type === 'email') {
                if(status === 'processed' || status === 'deferred' || status === 'queued') {
                    return ({code: this.$t('InspectAutomaticMessage.deliveryFailed'), color: 'yellow'});
                } else if(status === 'delivered') {
                    return ({code: this.$t('InspectAutomaticMessage.delivered'), color: 'green'});
                } else if(status === 'bounce' || status === 'blocked' || status === 'dropped' || status === 'failed') {
                    return ({code: this.$t('InspectAutomaticMessage.deliveryFailed'), color: 'red'});
                }
            }
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);

            return d.toLocaleString();
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
    },
};
</script>

<style>
</style>
