import {Field} from '../../../field';


class Text extends Field {
    /*
    this.data is structured based on its type.
    here this.data is a string
    */
    code() {
        return {
            text: `${this.data}`,
        };
    }

    get isEmpty() {
        return this.data === '';
    }
}

export default Text;
