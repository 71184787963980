<template>
    <v-container class="pa-0 mt-2">
        <v-dialog
            max-width="1000"
            v-model="dialog"
            :fullscreen="!$vuetify.breakpoint.mdAndUp"
            @click:outside="closeDialog"
        >
            <v-card
                class="border-0"
                :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            >
                <v-row
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    class="modal-header-mobile h1-16 mx-0 items-center"
                >
                    <v-col cols="2">
                        <v-icon color="primary" class="icon-plus" @click="closeDialog" data-cy="closeInspectDialog">
                            mdi-chevron-left
                        </v-icon>
                    </v-col>
                    <v-col cols="8" align="center">
                        <h1>{{ template.templateName }}</h1>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>
                <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>{{ $t("InspectTemplate.inspectTemplate") }} {{ template.templateName }}</h1>
                        </v-col>
                        <v-col cols="2" align="end">
                            <v-icon
                                data-cy="closeInspectDialog"
                                class="close-button"
                                @click="closeDialog"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-container
                        class="pa-0"
                        :class="{'mb-70': !$vuetify.breakpoint.mdAndUp, 'my-2': $vuetify.breakpoint.mdAndUp}"
                    >
                        <v-card
                            class="rounded-lg pa-2 my-2 w-100"
                            v-for="(section, index) in template.sections"
                            :key="index"
                        >
                            <FillSection
                                :section="section"
                                :sectionNumber="index + 1"
                                :subsection="false"
                                :selectFieldMode="true"
                                :inspectMode="true"
                                @sectionChosen="closeDialog"
                                @sectionChosenNew="emitSectionChosen"
                            />
                        </v-card>
                    </v-container>
                    <v-row
                        class="mx-0 mt-2"
                        :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'start'"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}">
                        <v-btn
                            class="primary-button"
                            :class="{
                                'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp,
                                'mb-7': $vuetify.breakpoint.mdAndUp}"
                            @click="closeDialog"
                        >
                            {{ $t("Sulje") }}
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import FillSection from '../FillReport/FillSection.vue';

export default {
    components: {
        FillSection,
    },
    data() {
        return {
            dialog: false,
            template: {}
        };
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.template = {};
        },
        openDialog(template) {
            this.template = template;
            this.dialog = true;
        },
        emitSectionChosen(value) {
            this.$emit('sectionChosen', value);
            this.dialog = false;
        },
    },
};
</script>

<style>
</style>
