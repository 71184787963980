<template>
    <div class="wrapper">
        <v-container
            class="px-7 py-7 container"
            v-if="this.loading"
        >
            <v-skeleton-loader
                type="article, card-heading, table: table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
        </v-container>
        <v-container
            class="px-7 py-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
            v-else
        >
            <v-layout class="ma-0 pa-0">
                <v-flex>
                    <v-row class="mx-0" :class="{ 'h1-26': !$vuetify.breakpoint.mdAndUp }">
                        <h1>{{ $t("Processes.header") }}</h1>
                    </v-row>
                    <v-tabs v-model="selectedTab" class="mt-4" background-color="#F9F9F9">
                        <v-tabs-slider class="v-tabs-slider-wrapper"></v-tabs-slider>
                        <v-tab v-for="item in tabItems" :key="item.text">{{ item.text }}</v-tab>
                    </v-tabs>

                    <hr class="divider" />
                    <div>
                        <v-tabs-items v-model="selectedTab" class="tabs-items">
                            <v-tab-item class="ma-0 pa-0" v-for="item in tabItems" :key="item.text">
                                <ProcessesTable v-if="item.component === 'ProcessesTable'" />
                                <MessageGroupTable v-if="item.component === 'MessageGroupTable'" />
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import ProcessesTable from './ProcessesTable.vue';
import MessageGroupTable from './MessageGroupTable.vue';
import {processesCollection} from '../../fbInitialize';

export default {
    components: {
        ProcessesTable,
        MessageGroupTable,
    },
    data() {
        return {
            selectedTab: null,
            tabItems: [
                {
                    text: this.$t('Processes.processesTab'),
                    component: 'ProcessesTable',
                },
                {
                    text: this.$t('Processes.messageGroupTab'),
                    component: 'MessageGroupTable',
                },
            ],
            loading: false,
            processListener: {}
        };
    },
    async created() {
        this.loading = true;
        const query = processesCollection
            .where('organizationId', '==', this.$store.state.userProfile.organization)
            .where('firebaseDeleted', '==', false);
        this.processListener = query.onSnapshot((querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => {
                let result = doc.data();
                result.id = doc.id;
                return result;
            });
            this.$store.dispatch('setProcesses', data);
            this.loading = false;
        });
    },
    destroyed() {
        this.processListener();
    }
};
</script>

<style scoped>
.row-pointer :deep(tbody tr :hover) {
  cursor: pointer;
}
</style>
