<template>
    <div>
        <div class="subscription-container">
            <div class="col-icon text-center">
                <v-icon
                    class="icon-success"
                    :class="{'icon-style': $vuetify.breakpoint.mdAndUp,
                             'icon-style-mobile': !$vuetify.breakpoint.mdAndUp}"
                >
                    mdi-check-circle-outline
                </v-icon>
            </div>
            <div class="col-rest" :class="{'h2-18':!$vuetify.breakpoint.mdAndUp}">
                <h2 class="pb-2">
                    {{ $t("Subscription.activeSubscroption") }}
                </h2>
                <p class="mb-0">
                    {{ $t("Subscription.cancelSubscriptionMessage")}}
                    <strong>roni@formup.fi</strong>
                </p>
            </div>
        </div>
        <v-card class="my-profile">
            <v-form ref="form">
                <v-row class="px-7 pt-3 pb-2">
                    <h1 class="text-primary">{{ $t("Subscription.myProfile") }}</h1>
                </v-row>
                <v-row class="px-7">
                    <p class="text-gray">
                        {{ $t("Subscription.visibleForOrganisationOnly") }}
                    </p>
                </v-row>
                <v-row class="px-7">
                    <h2 class="text-primary">{{ $t("Subscription.profilePicture") }}</h2>
                </v-row>
                <v-row class="px-7 pt-3">
                    <v-avatar :color="avatarColor.bg" size="60">
                        <span
                            v-if="surname !== '' && surname !== undefined"
                            class="text-h5"
                            :style="'color: ' + avatarColor.text"
                        >{{ firstname[0].toUpperCase() + surname[0].toUpperCase() }}</span
                        >
                        <span
                            v-else
                            class="text-h5"
                            :style="'color: ' + avatarColor.text"
                        >{{ firstname[0].toUpperCase() + firstname[1].toUpperCase() }}</span
                        >
                    </v-avatar>
                </v-row>
                <v-row class="px-7 pt-5">
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        :class="{'pr-12 pl-0 py-0': $vuetify.breakpoint.mdAndUp,
                                 'pa-0': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <h2 class="text-primary pb-3">
                            {{ $t("Subscription.firstName") }}
                        </h2>
                        <v-text-field
                            outlined
                            v-model="firstname"
                            @keydown="addChangesMade"
                            :rules="firstNameRules"
                            :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        :class="{'pr-12 pl-0 py-0': $vuetify.breakpoint.mdAndUp,
                                 'pa-0': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <h2 class="text-primary pb-3">{{ $t("Subscription.lastName") }}</h2>
                        <v-text-field
                            outlined
                            v-model="surname"
                            @keydown="addChangesMade"
                            :rules="surnameRules"
                            :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="px-7">
                    <h2 class="text-primary">{{ $t("Subscription.phoneNumber") }}</h2>
                </v-row>
                <v-row class="px-7 pt-3">
                    <v-text-field
                        outlined
                        v-model="phone"
                        :rules="phoneRules"
                        @keydown="addChangesMade"
                        :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                    ></v-text-field>
                </v-row>
                <v-row class="px-7">
                    <h2 class="text-primary">{{ $t("Subscription.email") }}</h2>
                </v-row>
                <v-row class="px-7 pt-3">
                    <v-text-field
                        outlined
                        v-model="email"
                        @keydown="addChangesMade"
                        :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                    ></v-text-field>
                </v-row>
                <v-row class="px-7">
                    <h2 class="text-primary">{{ $t("Subscription.organisation") }}</h2>
                </v-row>
                <v-row class="px-7 pt-3">
                    <v-text-field
                        outlined
                        disabled
                        v-model="organization"
                        @keydown="addChangesMade"
                        :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                    ></v-text-field>
                </v-row>
                <v-row
                    class="pb-4 ma-0"
                    :class="{'pb-5 w-100 px-4': !$vuetify.breakpoint.mdAndUp,
                             'justify-end px-7': $vuetify.breakpoint.mdAndUp}"
                >
                    <div
                        :class="{'text-right': $vuetify.breakpoint.mdAndUp,
                                 'col-align w-100': !$vuetify.breakpoint.mdAndUp}"
                        v-if="changesMade"
                    >
                        <v-btn
                            :class="{'secondary-button w-100 py-4 mb-2': !$vuetify.breakpoint.mdAndUp,
                                     'outline-primary mr-3': $vuetify.breakpoint.mdAndUp}"
                            @click="cancelChanges()"
                            :disabled="loading"
                        >
                            {{ $t("Subscription.cancel") }}
                        </v-btn>
                        <v-btn
                            class="primary-button"
                            @click="saveChanges()"
                            :loading="loading"
                            :class="{ 'primary-button w-100 py-4 mb-2': !$vuetify.breakpoint.mdAndUp,
                                      'disabled-button w-100 py-4 mb-4': isBtnValid }"
                        >
                            {{ $t("Subscription.save") }}
                        </v-btn>
                    </div>
                </v-row>
            </v-form>
        </v-card>
        <v-card class="my-profile">
            <v-row class="px-7 pt-3 pb-2">
                <h1 class="text-primary">{{ $t("OwnProfile.settings") }}</h1>
            </v-row>
            <v-row class="h2-18 mb-4 mt-4 px-7">
                <h2 class="text-primary">{{ $t("OwnProfile.language") }}</h2>
            </v-row>
            <v-row class="px-7 mb-7">
                <LocaleSwitcher />
            </v-row>
            <v-row class="px-7 mb-7">
                <v-btn
                    class="button-alert-primary"
                    :class="{'w-100 py-3': !$vuetify.breakpoint.mdAndUp}"
                    @click="signOut()"
                    data-cy="signOut"
                >
                    <v-icon class="pr-2"> mdi-logout </v-icon>
                    {{ $t("Accountmanagement.logOut") }}
                </v-btn>
            </v-row>
        </v-card>
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText=snackbarText />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LocaleSwitcher from '../LocaleSwitcher.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: {
        LocaleSwitcher,
        GeneralSnackbar,
    },

    name: 'OwnProfile',
    data() {
        return {
            requireText: [(v) => (v && v.length > 0) || ''],
            phoneRules: [
                (v) =>
                    !v ||
                    /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]{8,14}$/g.test(v) ||
                    v == '' ||
                    this.$t('ProcessParticipants.phoneFormat'),
            ],
            firstNameRules: [
                (v) => (v && v.length > 0) || this.$t('OwnProfile.firstNameRules'),
                (v) =>
                    (v && v.length < 51) || this.$t('CreateFolder.Rules.nameTooLong'),
            ],
            surnameRules: [
                (v) => (v && v.length > 0) || this.$t('OwnProfile.surnameRules'),
                (v) =>
                    (v && v.length < 51) || this.$t('CreateFolder.Rules.nameTooLong'),
            ],
            organization: '',
            firstname: '',
            surname: '',
            phone: '',
            email: '',
            id: '', // UID
            avatarColor: '',
            loading: false,
            text: false,
            snackbarText: '',
            changesMade: false,
        };
    },
    methods: {
        cancelChanges() {
            this.setUserData();
            this.changesMade = false;
        },
        addChangesMade() {
            this.changesMade = true;
        },
        async signOut() {
            this.$store.dispatch('logout');
        },
        saveChanges() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$store
                    .dispatch('updateOwnUser', {
                        phone: this.phone,
                        email: this.email,
                        surname: this.surname,
                        firstname: this.firstname,
                        id: this.id,
                    })
                    .then((result) => {
                        if (result) {
                            this.$refs.GeneralSnackbar.snackbar = true;
                            this.snackbarText =  this.$t('UserDetails.Snackbar.saved');
                        } else {
                            this.$refs.GeneralSnackbar.snackbar = true;
                            this.snackbarText = this.$t('UserDetails.Snackbar.savingFailed');
                        }
                        this.loading = false;
                        this.changesMade = false;
                    });
            }
        },
        setUserData() {
            this.firstname = this.getUser.firstname;
            this.surname = this.getUser.surname;
            this.phone = this.getUser.phone;
            this.avatarColor = this.getUser.avatarColor;
            this.email = this.getUser.email;
            this.id = this.getUser.id;
            this.organization = this.getUserOrganization.name;
        },
    },
    created() {
        this.setUserData();
    },
    computed: {
        ...mapGetters(['getUser']),
        ...mapGetters(['getUserOrganization']),
    },
};
</script>
