<template>
    <v-container data-cy="report" class="pa-0 ma-0 mb-3">
        <v-row
            v-if="
                isFillable &&
                    field.question !== '' &&
                    field.question !== null &&
                    field.question !== undefined &&
                    displayKey === 0
            "
            class="mx-0 px-3 my-4"
        >
            <h3  class="text-primary">{{ field.question }}</h3>
        </v-row>

        <v-row
            v-if="
                displayKey === 0 &&
                    isFillable
            "
            class="mx-0 px-3 my-4"
        >
            <v-avatar color="#FFDEDE" size="40" class="mr-2">
                <span
                    class="font-weight-regular"
                    style="color: #B92D2D"
                >
                    {{ getInitial(fillerData.report.fillerName) }}
                </span>
            </v-avatar>
            <h3  class="text-500 text-primary mt-1">{{ fillerData.report.fillerName  }}</h3>
            <span class="ma-1 ml-4 mt-3 text-gray small"> {{ fillerData.inspectionsRole }} </span>
        </v-row>
        <v-row
            v-if="
                displayKey === 1 &&
                    isFillable
            "
            class="mx-0 px-3 my-4"
        >
            <v-avatar color="#E3FFDE" size="40" class="mr-2">
                <span
                    class="font-weight-regular"
                    style="color: #73B92D"
                >
                    {{ getInitial(fillerData.report.fillerName) }}
                </span>
            </v-avatar>
            <h3  class="text-500 text-primary mt-1">{{ fillerData.report.fillerName  }}</h3>
            <span class="ma-1 ml-4 mt-3 text-gray small"> {{ fillerData.inspectionsRole }} </span>
        </v-row>
        <v-row
            v-if="
                displayKey === 2 &&
                    isFillable
            "
            class="mx-0 px-3 my-4"
        >
            <v-avatar color="#DEEFFF" size="40" class="mr-2">
                <span
                    class="font-weight-regular"
                    style="color: #2D76B9"
                >
                    {{ getInitial(fillerData.report.fillerName) }}
                </span>
            </v-avatar>
            <h3  class="text-500 text-primary mt-1">{{ fillerData.report.fillerName  }}</h3>
            <span class="ma-1 ml-4 mt-3 text-gray small"> {{ fillerData.inspectionsRole }} </span>
        </v-row>

        <v-row
            :class="{
                'px-7 pt-0 mb-4 mt-0': $vuetify.breakpoint.mdAndUp,
                'px-2 pt-0 mt-2 mb-4 mx-0': !$vuetify.breakpoint.mdAndUp,
            }"
            v-if="field.type === 'textfield'"
        >
            <v-card class="pa-0 mx-0 mt-0 w-100">
                <v-textarea
                    data-cy="textField"
                    v-model="field.data"
                    hide-details
                    :disabled="selectFieldMode"
                    flat
                    solo
                    class="text-field-100"
                    :label="$t('Fillfield.fillTextField')"
                >
                </v-textarea>
            </v-card>
        </v-row>

        <div v-else-if="field.type === 'addimages'" class="mb-5">
            <div
                class="ml-2 mt-2 mr-2"
                :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                v-if="field.data.length !== 0 || this.skeletonLoaderHelper.length !== 0"
            >
                <v-row class="mx-0 py-4">
                    <div
                        class="image-container-disabled pa-3 mb-4"
                        :class="{
                            'mr-4': $vuetify.breakpoint.width > 480,
                            'mr-0': $vuetify.breakpoint.width <= 480,
                        }"
                        v-for="(image, indexpic) in field.data"
                        :key="indexpic"
                    >
                        <v-row class="mt-0 mb-3 mx-2">
                            <v-icon color="primary" class="mr-2"> mdi-file-image</v-icon>
                            <p class="text-primary mb-0 mr-10">
                                <strong
                                >{{ $t("Fillfield.picture") }} {{ indexpic + 1 }}</strong
                                >
                            </p>
                        </v-row>
                        <v-img
                            :src="image.url"
                            width="160"
                            height="160"
                            class="rounded-lg hover"
                            @click="viewImage(indexpic)"
                            v-if="$vuetify.breakpoint.width > 480"
                        >
                        </v-img>
                        <v-img
                            :src="image.url"
                            contain
                            class="rounded-lg hover"
                            @click="viewImage(indexpic)"
                            v-else
                        >
                        </v-img>
                        <ViewImage :src="image.url" ref="imgDialog" />

                    </div>
                    <div
                        class="image-container-disabled pa-3 mb-4"
                        :class="{
                            'mr-4': $vuetify.breakpoint.width > 480,
                            'mr-0': $vuetify.breakpoint.width <= 480,
                        }"
                        v-for="(image) in skeletonLoaderHelper"
                        :key="image.tempId"
                    >
                        <v-row class="mt-0 mb-3 mx-2">
                            <v-icon color="primary" class="mr-2"> mdi-file-image</v-icon>
                            <p class="text-primary mb-0">
                                <strong
                                >{{ $t("Fillfield.loadingPicture") }}</strong
                                >
                            </p>
                        </v-row>
                        <v-skeleton-loader
                            width="160"
                            height="160"
                            type="image"
                            v-if="image.url === 'loading' && $vuetify.breakpoint.width > 480"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                            contain
                            type="image"
                            v-else-if="image.url === 'loading'"
                        ></v-skeleton-loader>
                    </div>
                </v-row>
            </div>
        </div>
        <div v-else-if="field.type === 'comment'" class="mb-7">
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <div
                    class="ml-2 mt-2 mr-2 w-90 row"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-container
                        v-for="(data, i) in field.data"
                        :key="i"
                        class="pa-0 mb-3 blink"
                        :class="{'overlay': selectFieldMode}"
                    >
                        <v-card color="gray" class="pa-5 rounded-lg">
                            <v-row class="ma-0">
                                <v-col cols="7" class="pa-0">
                                    <div v-if="data.sender !== 'undefined undefined'">
                                        <v-row
                                            class="ma-0 text-500 text-primary"
                                            v-if="data.sender !== 'undefined undefined'"
                                        >
                                            {{ data.sender }}
                                        </v-row>
                                        <v-row class="ma-0 text-gray small">
                                            {{ formatTime(data.timestamp) }}
                                        </v-row>
                                    </div>
                                    <div v-else>
                                        <v-row class="ma-0 text-500 text-primary">
                                            {{ formatTime(data.timestamp) }}
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row data-cy="commentContent" class="ma-0 w-90">
                                <v-col class="pa-0">
                                    <p class="mb-0">{{ data.message }}</p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-container>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'choice'" class="mb-4">
            <v-card elevation="0" class="pa-0 ma-0">
                <div
                    class="ml-2 mt-2 mr-2 w-90"
                    :class="{ 'ml-4 mr-8 mt-3 w-90': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-container  class="pa-0" :class="{'overlay': selectFieldMode}">
                        <div v-for="choice in field.options" :key="choice">
                            <v-btn
                                v-if="choice == field.data"
                                class="ma-1 rounded-lg font-weight-regular"
                                color="primary"
                                dark
                                block
                                height="50"
                            >
                                <div>
                                    {{ choice }}
                                </div>

                            </v-btn>
                            <v-btn
                                v-else
                                class="ma-1 rounded-lg font-weight-regular"
                                color="white"
                                block
                                height="50"
                            >
                                <div>
                                    {{ choice }}
                                </div>
                            </v-btn>
                        </div>
                    </v-container>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'table'" class="mb-4">
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <div
                    class="ml-2 mt-2 mr-2 hover"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp,
                              'overlay': selectFieldMode
                    }"
                    v-for="(item, index) in field.data"
                    :key="index"
                    @click="inspectTableItem(index)"
                >
                    <v-row
                        data-cy="tableItemTitle"
                        class="mx-0 my-2 px-4 file-container-disabled py-2"
                    >
                        <v-col cols="10" class="pt-3">
                            <v-icon color="primary" class="mr-5">mdi-table</v-icon>
                            {{ item.data[0].value || "Error" }}
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>


        <v-row
            :class="{
                'px-7 pt-4 mb-0': $vuetify.breakpoint.mdAndUp,
                'px-2 pt-2 mt-2 mb-0 mx-0': !$vuetify.breakpoint.mdAndUp,
            }"
            v-else-if="field.type === 'textinput'"
        >
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <v-text-field
                    data-cy="inputField"
                    v-model="field.data"
                    counter="100"
                    :label="field.label ? field.label : field.question"
                    flat
                    class="text-field-100"
                    :class="{'overlay': selectFieldMode}"
                ></v-text-field>
            </v-card>
        </v-row>

        <div v-else-if="field.type === 'file'">
            <v-card elevation="0" class="pa-0 ma-0 w-100">
                <div
                    class="ml-2 mt-2 mr-2"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-row
                        class="mx-0 my-2 px-4 file-container-disabled py-2"
                        v-for="(data, i) in field.data"
                        :class="{'overlay': selectFieldMode}"
                        :key="i"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '7' : '10'"
                            class="mt-1"
                            :class="{ 'mt-2': $vuetify.breakpoint.mdAndUp }"
                        >
                            <v-row class="ma-0">
                                <v-icon
                                    color="primary"
                                    class="mr-3"
                                    :class="{ 'mr-5': $vuetify.breakpoint.mdAndUp }"
                                >
                                    mdi-file-document
                                </v-icon>
                                <a :href="data.url" target="_blank">
                                    {{ $vuetify.breakpoint.mdAndUp ? getFileName(data.url)
                                        : getFileNameMobile(data.url) }}
                                </a>
                            </v-row>
                        </v-col>
                        <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
                            <div v-if="data.sender !== 'undefined undefined'">
                                <v-row
                                    class="ma-0 text-500 text-primary"
                                    v-if="data.sender !== 'undefined undefined'"
                                >
                                    {{ data.uploader }}
                                </v-row>
                                <v-row class="ma-0 text-gray small">
                                    {{ formatTime(data.timestamp) }}
                                </v-row>
                            </div>
                            <div v-else>
                                <v-row class="ma-0 text-500 text-primary">
                                    {{ formatTime(data.timestamp) }}
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'staticfile' && displayKey === 0" class="mb-5">
            <v-card class="pa-0 ma-0 w-100">
                <div class="ml-2 mt-2 mr-2" :class="{'ml-4 mr-8 mt-3':$vuetify.breakpoint.mdAndUp,
                                                     'overlay': selectFieldMode}"
                     v-for="(data, i) in field.data"
                     :key="i">
                    <v-row class="mx-0 my-2 px-4 file-container-disabled py-5">
                        <v-icon color="primary" class="ml-3 mr-5">mdi-file-document</v-icon>
                        <a :href="data.url" target="_blank">
                            {{ $vuetify.breakpoint.mdAndUp ? getFileName(data.url) : getFileNameMobile(data.url) }}
                        </a>
                    </v-row>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'slider'" class="mb-5">
            <v-card elevation="0" class="ml-6 mt-12 mr-6" :class="{'ml-8 mr-12':$vuetify.breakpoint.mdAndUp}" >
                <v-slider
                    v-model="sliderValue"
                    dense
                    step="1"
                    :min="field.minValue"
                    :max="field.maxValue"
                    tick-size="4"
                    thumb-label="always"
                    ticks
                    hide-details
                    :disabled="selectFieldMode"
                    :class="{'overlay': selectFieldMode}"
                >
                    <template v-slot:prepend>
                        <p class="mb-0 mr-2 mt-1 text-primary"><strong> {{ field.minValue }} </strong></p>
                    </template>
                    <template v-slot:append>
                        <p class="mb-0 ml-2 mt-1 text-primary"><strong> {{ field.maxValue }} </strong></p>
                    </template>
                </v-slider>
            </v-card>
        </div>

        <div v-else-if="field.type === 'signature'" class="mb-4">
            <div>
                <v-card
                    v-for="(data, i) in field.data"
                    :key="i"
                    elevation="0"
                    class="mx-3"
                >
                    <v-row class="mx-0 my-4">
                        <v-col cols="6">
                            <v-img :src="data.url" width="400"> </v-img>
                            <v-row class="mx-0 mt-5">
                                <h1
                                    class="text-caption"
                                    v-if="data.sender !== 'undefined undefined'"
                                >
                                    {{ data.printName }},
                                    {{ formatTime(data.timestamp) }}
                                </h1>
                                <h1 class="text-caption" v-else>
                                    {{ formatTime(data.timestamp) }}
                                </h1>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </div>

        <div v-else-if="field.type === 'text' && displayKey === 0" class="mb-5">
            <v-card elevation="0" class="pa-0 ma-0">
                <div
                    class="ml-2 mt-2 mr-2 w-90"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp,
                              'overlay': selectFieldMode
                    }"
                >
                    <v-row
                        class="ma-0"
                        :class="{ 'px-2': !$vuetify.breakpoint.mdAndUp }"
                    >
                        <v-col cols="12">
                            <span
                                v-html="replaceURLWithHTMLLinks(field.data)"
                                class="text-body-1 py-2"
                                style="white-space: pre-line">
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>

        <div v-else-if="field.type === 'infotext' && displayKey === 0">
            <v-card
                class="ml-2 mt-2 mr-2"
                :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
            >
                <v-alert
                    icon="mdi-information-outline"
                    type="info"
                    text
                    class="rounded-lg my-2 py-5"
                    :class="{'overlay': selectFieldMode}"
                >
                    {{ field.data }}
                </v-alert>
            </v-card>
        </div>

        <div v-else-if="field.type === 'image' && displayKey === 0">
            <v-card class="pa-0 ma-0 w-100">
                <v-row
                    :class="{
                        'px-7 pt-4 mb-0': $vuetify.breakpoint.mdAndUp,
                        'px-2 pt-2 mt-2 mb-4 mx-0': !$vuetify.breakpoint.mdAndUp,
                        'overlay': selectFieldMode
                    }"
                >
                    <v-img
                        v-for="(imgsrc, index) in field.data"
                        :key="imgsrc"
                        :src="imgsrc"
                        class="rounded hover mb-4 mr-4"
                        max-width="300"
                        contain
                        @click="viewImage(index)"
                    >
                        <ViewImage :src="imgsrc" ref="imgDialog" />
                    </v-img>
                </v-row>
            </v-card>
        </div>

        <div v-else-if="field.type === 'date'">
            <v-card class="pa-0 mx-0 mt-0 mb-3 w-100 row">
                <div
                    class="ml-2 mt-2 mr-2 w-90 col"
                    :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
                >
                    <template>
                        <v-row class="mx-0">
                            <v-text-field
                                data-cy="dateField"
                                :value="formatDate(field.data)"
                                :label="$t('Fillfield.chooseDate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </v-row>
                        <v-row class="mx-0">
                            <v-text-field
                                v-if="field.hasClock"
                                v-model="field.time"
                                :label="$t('Fillfield.insertTime')"
                                prepend-icon="mdi-clock-time-four-outline"
                                type="time"
                                color="primary"
                                readonly
                            >
                            </v-text-field>
                        </v-row>
                    </template>
                </div>
            </v-card>
        </div>

        <div class="ml-4 mr-8 mb-7">
            <p
                v-if="field.required"
                :class="isFilledField() ? 'hidden' : 'red--text'"
            >
                {{ $t("Fillfield.requiredField") }}
            </p>
        </div>

        <okcanceldialog ref="okCancelDialog"> </okcanceldialog>

        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="this.$t('Fillfield.loadingMessage')"
        />

        <AddTableDataDialog
            ref="AddTableDataDialog"
            :field="field"
        />
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('Fillfield.tooBigFile') + '10MB'" />
    </v-container>
</template>

<script>
import okcanceldialog from '../OkCancelDialog.vue';
import ViewImage from '../FillReport/ViewImage.vue';
import { fbStorage } from '../../fbInitialize';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import AddTableDataDialog from '../FillReport/AddImgData.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    props: [
        'field',
        'selectFieldMode',
        'type',
        'autoSave',
        'multiMode',
        'displayKey',
        'sectionsRole',
        'sections2Role',
        'sections3Role',
        'fillerData'
    ],
    components: {
        okcanceldialog,
        BaseLoadingDialog,
        AddTableDataDialog,
        ViewImage,
        GeneralSnackbar,
    },
    data() {
        return {
            modal: false,
            imageFullScreen: false,
            sendingData: false,
            skeletonLoaderHelper: [],
            absolute: true,
            overlay: true,
            disabled1: false,
            snackbarText: '',
            // TODO: Implementation of corrent values from template
            min: 0,
            value: 0,
            max: 5,
        };
    },
    methods: {
        viewImage(index) {
            this.$refs.imgDialog[index].openDialog();
        },
        isFilledField() {
            if (
                this.field.required &&
                this.field.type === 'date' &&
                this.field.data
            ) {
                if (this.field.hasClock && !this.field.time) {
                    return false;
                }
                return true;
            }
            if (
                this.field.required &&
                (this.field.type === 'textfield' ||
                    this.field.type === 'choice' ||
                    this.field.type === 'textinput') &&
                this.field.data
            ) {
                return true;
            }
            if (
                this.field.required &&
                (this.field.type === 'comment' ||
                    this.field.type === 'addimages' ||
                    this.field.type === 'file' ||
                    this.field.type === 'table' ||
                    this.field.type === 'signature') &&
                this.field.data.length
            ) {
                return true;
            }
        },
        inspectTableItem(index) {
            this.$refs.AddTableDataDialog.openInspectDialog(index);
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
        closeDatePicker() {
            this.modal = false;
        },
        openImgDataDialog(image) {
            this.$refs.ImgDataDialog.openDialog(image);
        },
        getFileName(url) {
            let storageRef = fbStorage.refFromURL(url);
            if (storageRef.name.length < 50) {
                return storageRef.name;
            } else {
                return storageRef.name
                    .slice(0, 42)
                    .concat('...')
                    .concat(storageRef.name.slice(-7));
            }
        },
        getFileNameMobile(url) {
            let storageRef = fbStorage.refFromURL(url);
            if (storageRef.name.length < 22) {
                return storageRef.name;
            } else {
                return storageRef.name
                    .slice(0, 12)
                    .concat('...')
                    .concat(storageRef.name.slice(-7));
            }
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);
            return d.toLocaleString();
        },
        replaceURLWithHTMLLinks(text) {
            var exp =
                /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
            return text.replace(exp, '<a href=\'$1\' target=\'_blank\'>$1</a>');
        },
        getInitial(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

            let initials = [...name.matchAll(rgx)] || [];

            initials = (
                (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;
        }
    },
    computed: {
        isFillable() {
            return (
                this.field.type === 'addimages' ||
                this.field.type === 'textfield' ||
                this.field.type === 'comment' ||
                this.field.type === 'choice' ||
                this.field.type === 'signature' ||
                this.field.type === 'table' ||
                this.field.type === 'textinput' ||
                this.field.type === 'file' ||
                this.field.type === 'date' ||
                this.field.type === 'slider'
            );
        },
        sliderValue: {
            get() {
                if(this.field.type === 'slider') {
                    if(this.field.data == null) {
                        return Math.floor((parseInt(this.field.minValue) + parseInt(this.field.maxValue))/2);
                    } else {
                        return this.field.data;
                    }
                } else {
                    return null;
                }
            },
            set(val) {
                this.field.data = val;
            }
        }
    },
    created() {
        if(this.field.type === 'slider' && !this.field.data) {
            this.field.data = this.sliderValue;
        }
    }
};
</script>
