<template>
    <v-container class="pa-0">

        <AddFillerDialog :items="getProcessFillersAndResponses"/>
        <v-data-table
            :headers="fillerHeaders"
            :items="getProcessFillersAndResponses"
            class="rounded-lg"
            hide-default-footer
            disable-pagination
            :sort-by="['time']"
        >
            <template v-slot:item.reportName="{ item }">
                <div v-if="item.response">
                    <v-btn
                        color="primary"
                        class="px-1"
                        @click="downloadPDF(item.response.report)"
                    >
                        <v-icon class="mr-1"> mdi-file-pdf-box </v-icon>
                        {{ getReportName(item.response.report) }}
                    </v-btn>
                </div>
            </template>

            <template v-slot:item.receiver="{ item }">
                <v-chip
                    v-for="emailReceiver in currentProcess.ReportPDFReceivers"
                    :key="emailReceiver.id" color="primary"
                >
                    {{emailReceiver.email}}
                </v-chip>
                <v-chip
                    v-for="emailReceiver in item.PDFReceivers"
                    :key="emailReceiver.id" close
                    @click:close="deleteEmailReceiver(item, emailReceiver)"
                >
                    {{emailReceiver}}
                </v-chip>
                <v-chip v-if="item.id === addUniqueReceiverItemId" class="pill">
                    <v-text-field
                        :label="$t('Processes.addEmail')"
                        class="pt-4 px-2"
                        v-model="uniqueReceiverToAdd"
                        @blur="saveAddUniqueReceiver(item)"
                        v-on:keyup.native.enter="saveAddUniqueReceiver(item)">

                    </v-text-field>
                </v-chip>
                <v-chip class="pill" @click="addUniqueReceiverItemId = item.id">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-chip>
            </template>

            <template v-slot:item.status="{ item }">
                <span v-if="item.status === 'no response'">
                    <v-icon color="red">mdi-circle-medium</v-icon>
                    {{ $t("ProcessParticipants.notFilled") }}
                </span>
                <span v-if="item.status === 'opened'">
                    <v-icon color="orange">mdi-circle-medium</v-icon>
                    {{ $t("ProcessParticipants.opened") }}
                </span>
                <span v-if="item.status === 'created'">
                    <v-icon color="green">mdi-circle-medium</v-icon>
                    {{ $t("ProcessParticipants.filled") }}
                    {{ formatTime(item.response.created / 1000) }}
                </span>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-menu v-if="item.response">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn data-cy="openReceiverMenu" v-bind="attrs" icon v-on="on">
                            <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                    </template>
                    <v-spacer></v-spacer>
                    <v-list>
                        <v-list-item @click="openInspectReport(item.response.report)">
                            <v-list-item-icon>
                                <v-icon> mdi-eye </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t("ProcessParticipants.viewResponse") }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openEditReport(item.response)">
                            <v-list-item-icon>
                                <v-icon> mdi-pencil </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t("ProcessParticipants.modifyResponse") }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- Options shown if the filler has not filled -->
                <v-menu v-if="item.status !== 'created'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn data-cy="openReceiverMenu" v-bind="attrs" icon v-on="on">
                            <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                    </template>
                    <v-spacer></v-spacer>
                    <v-list>
                        <v-list-item
                            @click="openSendInstantMessage(item)"
                            v-if="item.email && item.email.length !== 0"
                        >
                            <v-list-item-icon>
                                <v-icon> mdi-email-plus </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t("ProcessParticipants.newMessage") }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openMessageHistory(item)">
                            <v-list-item-icon>
                                <v-icon> mdi-archive </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t("ProcessParticipants.messageHistory") }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            data-cy="deleteReceiver"
                            @click="deleteReceiver(item)"
                        >
                            <v-list-item-icon>
                                <v-icon> mdi-delete </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t("ProcessParticipants.deleteRecipient") }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            data-cy="createResponse"
                            @click="createResponse(item)"
                        >
                            <v-list-item-icon>
                                <v-icon> mdi-pencil </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                Luo vastaus
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:item.created="{ item }">
                <span>{{ europeanDate(item.created) }}</span>
            </template>
        </v-data-table>
        <PDFGenerator ref="PDFGenerator" />
        <InspectTemplateOrReport ref="inspectReport"/>
        <OkCancelDialog ref="OkCancelDialog" />

        <CreateInstantMessage ref="CreateInstantMessage" />

        <ShowInstantMessages ref="ShowInstantMessages" />
        <BaseLoadingDialog
            :sendingData="loading"
            :msg="$t('Baseloading.loadingMessage')"
        />
    </v-container>
</template>

<script>
import OkCancelDialog from '../OkCancelDialog.vue';
import { mapGetters,mapState } from 'vuex';
import PDFGenerator from '../FillReport/PDFGenerator.vue';
import InspectTemplateOrReport from '../GeneralizedComponents/InspectTemplateOrReport.vue';
import AddFillerDialog from './AddFillerDialog.vue';
import CreateInstantMessage from './CreateInstantMessage.vue';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import ShowInstantMessages from './ShowInstantMessages.vue';
import { getDynamicName } from '../../componentScripts/dynamicName';

export default {
    components: {
        BaseLoadingDialog,
        PDFGenerator,
        InspectTemplateOrReport,
        AddFillerDialog,
        OkCancelDialog,
        CreateInstantMessage,
        ShowInstantMessages
    },
    data() {
        return {
            loading: false,
            uniqueReceiverToAdd: '',
            addUniqueReceiverItemId: null,

        };
    },
    methods: {
        createResponse(response) {

            this.loading = true;
            let processId = this.$store.state.currentProcess.id;

            this.$store
                .dispatch('createProcessResponse', {
                    reportFillerId: response.id,
                    processId: processId,
                }).then((result) => {
                    this.$router.push({
                        name: 'fillresponse',
                        params: { processId: processId, reportId: result.data.reportId },
                    });
                    this.loading = false;
                });
        },
        openEditReport(response) {
            this.$router.push({
                name: 'fillresponse',
                params: { processId: response.processId, reportId: response.id },
            });
        },
        validateEmail(email) {
            //eslint-disable-next-line
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        openMessageHistory(item) {
            this.$refs.ShowInstantMessages.openCreateDialog(item);
        },
        openSendInstantMessage(item) {
            this.$refs.CreateInstantMessage.openCreateDialog(item);
        },
        saveAddUniqueReceiver(item) {
            this.addUniqueReceiverItemId = null;
            if(this.validateEmail(this.uniqueReceiverToAdd)) {
                let process = this.$store.state.currentProcess;
                process.receivers.map(receiver => {
                    if(receiver.id == item.id) {
                        receiver.PDFReceivers.push(this.uniqueReceiverToAdd);
                    }
                    return receiver;
                });
                this.$store.dispatch('updateProcess', process).then(() => {
                    this.loading = false;
                    this.uniqueReceiverToAdd = '';
                });
            } else {
                // Wrong email or empty
                this.uniqueReceiverToAdd = '';
            }

        },
        async deleteEmailReceiver(item, emailReceiver) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('Process.delete') + ' ' + emailReceiver,
                this.$t('Process.areYouSureDelete')  + emailReceiver + '?'
            );
            if(deleteApproved) {
                this.loading = true;
                let process = this.$store.state.currentProcess;
                process.receivers = process.receivers.map((receiver) => {
                    if(receiver.id == item.id) {
                        receiver.PDFReceivers = receiver.PDFReceivers.filter(email => {
                            return email != emailReceiver;
                        });
                    }
                    return receiver;
                });

                this.$store.dispatch('updateProcess', process).then(() => {
                    this.loading = false;
                });
            }
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        async deleteReceiver(item) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('ProcessParticipants.deleteReceiverTitle') + item.name,
                this.$t('ProcessParticipants.deleteReceiver')
            );
            if (deleteApproved) {
                this.loading = true;
                let process = this.$store.state.currentProcess;
                let filtered = process.receivers.filter((x) => {
                    return x.id != item.id;
                });
                process.receivers = filtered;
                this.$store.dispatch('updateProcess', process).then(() => {
                    this.loading = false;
                });
                this.$forceUpdate();
            }
        },
        getReportName(report) {
            const namingFieldId = this.$store.state.currentProcess.reportNamingFieldId;
            if (report) {
                return getDynamicName(report, namingFieldId);
            } else {
                return '';
            }
        },
        async downloadPDF(report) {
            const namingFieldId =
                this.$store.state.currentProcess.reportNamingFieldId;
            await this.$refs.PDFGenerator.downloadPDF(report, namingFieldId);
        },
        openInspectReport(report) {
            this.$refs.inspectReport.openDialog(report);
        },
        formatTime(epochTime) {
            var d = new Date(epochTime * 1000);
            return d.toLocaleString();
        },
    },
    computed: {
        ...mapGetters(['getProcessFillersAndResponses']),
        ...mapState(['currentProcess']),
        fillerHeaders() {
            if (this.$vuetify.breakpoint.width < 2000) {
                return (
                    [
                        {
                            text: this.$t('Process.name'),
                            value: 'name',
                            sortable: false,
                            width: '250px'
                        },
                        {
                            text: this.$t('Processes.email'),
                            align: 'start',
                            value: 'email',
                            width: '250px'
                        },
                        {
                            text: this.$t('Processes.phoneNumber'),
                            align: 'start',
                            value: 'phone',
                            width: '150px'
                        },
                        {
                            text: this.$t('Process.receiver'),
                            align: 'start',
                            value: 'receiver',
                            width: '250px'
                        },
                        {
                            text: this.$t('Processes.status'),
                            align: 'start',
                            value: 'status',
                            width: '250px'
                        },
                        {
                            text: this.$t('Processes.report'),
                            align: 'start',
                            value: 'reportName',
                            width: '250px'
                        },
                        {
                            text: this.$t('ModifyAutolink.tableActions'),
                            value: 'actions',
                            sortable: false,
                            width: '50px'
                        },
                    ]
                );
            }
            else {
                return (
                    [
                        {
                            text: this.$t('Process.name'),
                            value: 'name',
                            sortable: false,
                            width: '15%'
                        },
                        {
                            text: this.$t('Processes.email'),
                            align: 'start',
                            value: 'email',
                            width: '15%'
                        },
                        {
                            text: this.$t('Processes.phoneNumber'),
                            align: 'start',
                            value: 'phone',
                            width: '15%'
                        },
                        {
                            text: this.$t('Process.receiver'),
                            align: 'start',
                            value: 'receiver',
                            width: '15%'
                        },
                        {
                            text: this.$t('Processes.status'),
                            align: 'start',
                            value: 'status',
                            width: '15%'
                        },
                        {
                            text: this.$t('Processes.report'),
                            align: 'start',
                            value: 'reportName',
                            width: '15%'
                        },
                        {
                            text: this.$t('ModifyAutolink.tableActions'),
                            value: 'actions',
                            sortable: false,
                            width: '10%'
                        },
                    ]
                );
            }
        }
    },
};
</script>
