<template>
    <v-select
        v-model="e1"
        :items="localesComputed"
        item-text="full"
        item-value="code"
        menu-props="auto"
        hide-details
        background-color="transparent"
        elevation="0"
        @change="switchLocale($event)"
        class="ma-0 pa-0 mb-2"
    >
        <v-icon slot="prepend" color="primary">mdi-web</v-icon>
    </v-select>
</template>

<script>
export default {
    name: 'LocaleSwitcher',
    data() {
        return {
            e1: {
                code: this.$i18n.locale || navigator.language,
                full: this.$t('Languages.' + this.$i18n.locale) || this.$t('Languages.' + navigator.languag),
            },
            userTemplate: {
                locale: ''
            },
        };
    },
    methods: {
        // <---------------------------
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
            }
            let template = this.userTemplate;
            template.locale = locale;

            this.$store.dispatch('modifyUserDetails', template);
            this.$store.dispatch('setLocale', locale);
        },
    },
    computed: {
        localesComputed() {
            return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').map(
                (value) => {
                    return {
                        code: value,
                        full: this.$t('Languages.' + value),
                    };
                }
            );
        },
    },
};
</script>

<style>
  .w-fixed {
    max-width: 200px!important
  }
</style>
