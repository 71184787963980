<template>
    <v-container :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}">
        <v-row class="mx-0">
            <div class="fill-report">
                <v-list
                    v-for="(section, index) in sections[0].report.sections"
                    :key="index"
                    class="bg-transparent"
                >
                    <v-card
                        class="fill-report-card my-2 pa-4"
                        :class="{'cursor-not-allowed':$store.state.currentReport.markedReady,
                                 'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-list-item-title
                            :class="{'px-0 py-6':!$vuetify.breakpoint.mdAndUp,
                                     'px-3 py-6 ma-0':$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-row class="mx-0 px-3" :class="{'h1-23':!$vuetify.breakpoint.mdAndUp}">
                                <h1 class="text-primary pre-wrap">
                                    {{ index + 1 + ". &nbsp;" + section.sectionName }}
                                </h1>
                            </v-row>
                        </v-list-item-title>
                        <div
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp,
                                     'pa-3 ma-0':$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-row
                                class="mx-0 px-3 mb-0 mt--1"
                                v-if="section.question !== ''
                                    && section.question !== null
                                    && section.question !== undefined"
                            >
                                <pre class="text-gray mb-7">{{section.question}}</pre>
                            </v-row>
                            <InspectionFillSection
                                :sections="sections.map(x => x.report.sections[index])"
                                :fillerData="sections"
                                :sectionNumber="index + 1"
                                :subsection="false"
                                ref="fillSection"
                                :autoSave="autoSave"
                                @saveField="emitAutoSave"
                                @inputChanged="emitInputChanged"
                            />
                            <v-overlay
                                :value="$store.state.currentReport.markedReady || false"
                                absolute
                                opacity="0.05"
                                class="h2-18 text-gray rounded-lg align-baseline pt-1"
                            >
                                <h2>{{ $t("SectionIterator.markAsReady") }}</h2>
                            </v-overlay>
                        </div>
                    </v-card>
                </v-list>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import InspectionFillSection from './InspectionFillSection.vue';

export default {
    props: [
        'sections',
        'logoUrl',
        'autoSave'
    ],
    data() {
        return {
            panel: [],
        };
    },
    components: {
        InspectionFillSection,
    },
    methods: {
        emitAutoSave() {
            this.$emit('saveField');
        },
        emitInputChanged() {
            this.$emit('inputChanged');
        }
    },
    created() {
        console.log(this.sections.map(x => x.report.sections[0]));
    }
};
</script>

<style scoped>
.v-expansion-panel-content :deep(.v-expansion-panel-content__wrap) {
    padding: 0;
}
.v-expansion-panel-header--active {
    padding: 0;
    margin: 0;
}
</style>
