<template>
    <v-container class="ma-0 pa-0">
        <v-container class="px-0">
            <!-- SELECTION - COMING SOON! -->
            <div v-if="selection.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-row class="mx-0 mb-0">
                    <h2>{{ $t("TemplateTable.selections") }}</h2>
                </v-row>
                <v-row class="mx-0 mb-3">
                    <v-btn class="settings-outline mr-2" @click="clearSelection()">
                        <v-icon class="small-icon mr-1">mdi-minus-box </v-icon>
                        {{ $t("TemplateTable.clearSelections") }}
                    </v-btn>
                    <v-btn class="settings-outline mr-2" @click="deleteSelection(selection)">
                        <v-icon class="small-icon mr-1">mdi-delete </v-icon>
                        {{ $t("TemplateTable.deleteSelected") }}
                    </v-btn>
                </v-row>
            </div>
            <!-- DATATABLE -->
            <v-data-table
                v-model="selection"
                :loading="loading"
                :headers="headers"
                :items="getTemplates || []"
                class="rounded-lg row-pointer"
                :no-data-text="$t('TemplateTable.NoData')"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                item-key="template_id"
                @contextmenu:row="openContextMenuRightClick"
                @click:row="inspectTemplate"
                mobile-breakpoint="0"
            >
                <template v-slot:item.actions="{ item }">
                    <div v-if="item" />
                    <v-btn
                        data-cy="openContextMenu"
                        icon
                        @click.stop="openContextMenuThreeDotClick($event, item)"
                    >
                        <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.createdAt="{ item }">
                    {{ formatTime(item.createdAt) }}
                </template>
                <template v-slot:item.lastModified="{ item }">
                    <span v-if="item.lastModified">
                        {{ formatTime(item.lastModified) }}
                    </span>
                    <span v-else>
                        {{ formatTime(item.createdAt) }}
                    </span>
                </template>
                <template v-slot:item.ownerProfile="{ item }">
                    <div v-if="item.ownerProfile">
                        <v-avatar
                            :color="item.ownerProfile.avatarColor.bg"
                            size="40"
                            class="my-3"
                        >
                            <span
                                v-if="item.ownerProfile.surname !== '' && item.ownerProfile.surname !== undefined"
                                class="font-weight-regular"
                                :style="'color: ' + item.ownerProfile.avatarColor.text"
                            >
                                <!-- eslint-disable max-len -->
                                {{ item.ownerProfile.firstname[0].toUpperCase() + item.ownerProfile.surname[0].toUpperCase() }}
                            </span>
                            <span
                                v-else
                                class="font-weight-regular"
                                :style="'color: ' + item.ownerProfile.avatarColor.text"
                            >
                                <!-- eslint-disable max-len -->
                                {{ item.ownerProfile.firstname[0].toUpperCase() + item.ownerProfile.firstname[1].toUpperCase() }}
                            </span>
                        </v-avatar>
                        <span data-cy="userName" class="pl-2">
                            {{
                                item.ownerProfile.firstname + " " + item.ownerProfile.surname
                            }}
                        </span>
                    </div>
                </template>
                <template v-slot:item.type="{ item }">
                    <!--FOR ERROR TO GO AWAY-->
                    <div v-if="!item"></div>
                    <v-icon color="primary" class="my-4"> mdi-file-outline </v-icon>
                </template>
                <template v-slot:item.templateName="{ item }">
                    <div v-if="!item"></div>
                    {{ getTemplateName(item, $vuetify.breakpoint.width) }}
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination
                    :class="{ 'mb-6 pb-6': !$vuetify.breakpoint.mdAndUp }"
                    v-model="page"
                    :length="pageCount"
                    v-if="pageCount > 1"
                    :total-visible="7"
                >
                </v-pagination>
            </div>
        </v-container>
        <!-- DATA TABLE ENDS -->

        <!-- MODALS BEGIN -->

        <OkCancelDialog ref="OkCancelDialog" />
        <TemplateIsUsedNotifyDialog ref="TemplateIsUsedNotifyDialog"/>
        <v-dialog class="ma-0 pa-0" v-model="alertDialog" width="400">
            <v-card class="rounded-lg pa-4">
                <v-row class="h1-26 ma-0 px-4">
                    <h1>{{ $t("TemplateTable.attention") }}</h1>
                </v-row>
                <v-row class="mx-0 mt-4 px-4">
                    <p>{{ this.alertDialogText }}</p>
                </v-row>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        data-cy="confirmAction"
                        class="primary-button"
                        @click.native="closeAlertdialog"
                    >
                        {{ $t("TemplateTable.confirmOk") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('TemplateTable.deletingMessage')"
        >
        </BaseLoadingDialog>

        <InspectTemplateOrReport ref="inspectTemplate"/>
        <v-menu
            v-model="templateOptionsMenu"
            :position-x="contextMenuX"
            :position-y="contextMenuY"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item data-cy="editTemplate" @click="editItem(item)">
                    <v-list-item-icon>
                        <v-icon class="mr-2">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t("TemplateTable.modify") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item data-cy="deleteTemplate" @click="deleteItem(item)">
                    <v-list-item-icon>
                        <v-icon> mdi-delete </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t("ReportTable.delete") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    data-cy="copyTemplate"
                    @click="copyTemplate()"
                >
                    <v-list-item-icon>
                        <v-icon> mdi-content-copy </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>
                        {{ $t("ReportTable.copy") }}
                    </v-list-item-title>

                </v-list-item>
            </v-list>
        </v-menu>

    <!-- MODALS END -->
    </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import OkCancelDialog from '../OkCancelDialog';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import InspectTemplateOrReport from '../GeneralizedComponents/InspectTemplateOrReport.vue';
import _ from 'lodash';
import TemplateIsUsedNotifyDialog from './TemplateIsUsedNotifyDialog.vue';

export default {
    props: ['search'],
    components: {
        OkCancelDialog,
        BaseLoadingDialog,
        InspectTemplateOrReport,
        TemplateIsUsedNotifyDialog
    },
    data() {
        return {
            loading: false,
            alertDialog: false,
            alertDialogText: null,
            sendingData: false,
            templateOptionsMenu: false,
            contextMenuX: 0,
            contextMenuY: 0,
            item: {},
            selection: [],
            page: 1,
            pageCount: 0,
            itemsPerPage: 50,
        };
    },
    methods: {
        clearSelection() {
            this.selection = [];
        },

        getTemplateName(template, width) {
            if (width < 400) {
                if (template.templateName.length > 20) {
                    return template.templateName.slice(0, 17).concat('...');
                } else {
                    return template.templateName;
                }
            } else if (width < 500) {
                if (template.templateName.length > 25) {
                    return template.templateName.slice(0, 22).concat('...');
                } else {
                    return template.templateName;
                }
            } else if (width < 600) {
                if (template.templateName.length > 35) {
                    return template.templateName.slice(0, 32).concat('...');
                } else {
                    return template.templateName;
                }
            } else if (width < 700) {
                if (template.templateName.length > 45) {
                    return template.templateName.slice(0, 42).concat('...');
                } else {
                    return template.templateName;
                }
            } else {
                return template.templateName;
            }
        },

        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        async deleteItem(item) {
            if (item.owner != this.$store.state.userProfile.id) {
                this.alertDialogText = this.$t(
                    'TemplateTable.youCantDeleteOtherUsersTemplates'
                );
                this.alertDialog = true;
                return;
            }

            let check = this.getCheckIsTemplateUsed(item.template_id);
            if (check.used) {
                this.$refs.TemplateIsUsedNotifyDialog.open(check.processes, check.folders, check.autolinks);
                return;
            }

            let deleteApproved = await this.OkCancelDialog(
                this.$t('TemplateTable.delete') + item.templateName,
                this.$t('TemplateTable.deleteMessage')
            );
            if (!deleteApproved) {
                return;
            }

            this.sendingData = true;
            await this.$store.dispatch('deleteTemplate', item);
            this.sendingData = false;
        },
        async deleteSelection(templates) {
            let deleteMessage1 = this.$t('TemplateTable.thisItem');
            let deleteMessage2 = this.$t('TemplateTable.items');
            let deleteMessage3 = this.$t('TemplateTable.areYouSureDelete');

            if (templates.length > 0) {
                let deleteApproved = await this.OkCancelDialog(
                    this.$t('TemplateTable.deleteSelected'),
                    deleteMessage3 + `${
                        this.selection.length === 1
                            ? deleteMessage1
                            : `${this.selection.length + deleteMessage2}`
                    }`
                );
                if (deleteApproved) {
                    templates.forEach(async (template) => {
                        await this.deleteItem(template);
                    });

                    this.clearSelection();
                }
            }
        },

        async editItem(item) {
            if (item.owner != this.$store.state.userProfile.id) {
                // cant modify others templates
                this.alertDialogText = this.$t(
                    'TemplateTable.youCantModifyOtherUsersTemplates'
                );
                this.alertDialog = true;
            } else {
                this.$store.state.currentTemplate = _.cloneDeep(item);
                this.$store.state.currentTemplate.owner =
                    this.$store.state.userProfile.id;
                this.$router.push({
                    name: 'templategenerator',
                    params: { mode: 'modifytemplate' },
                });
            }
        },
        async copyTemplate() {
            const item = _.cloneDeep(this.item);
            item.createdAt = '';
            item.template_id = '';
            item.templateName = item.templateName + ' - KOPIO';
            item.versionNumber = 1;
            item.createdAt = Math.round(new Date().getTime() / 1000);
            item.owner = '';
            item.organizationId = '';
            this.$store.dispatch('addCurrentTemplate', _.cloneDeep(item));
            this.$router.push({
                name: 'templategenerator'});
        },
        openContextMenuRightClick(e, data) {
            this.item = data.item;
            e.preventDefault();
            this.templateOptionsMenu = false;
            this.contextMenuX = e.clientX;
            this.contextMenuY = e.clientY;
            this.$nextTick(() => {
                this.templateOptionsMenu = true;
            });
        },
        openContextMenuThreeDotClick(e, item) {
            this.item = item;
            e.preventDefault();
            this.templateOptionsMenu = false;
            this.contextMenuX = e.clientX;
            this.contextMenuY = e.clientY;
            this.$nextTick(() => {
                this.templateOptionsMenu = true;
            });
        },
        inspectTemplate(item) {
            this.$refs.inspectTemplate.openDialog(item);
        },
        closeAlertdialog() {
            this.alertDialog = false;
        },

        formatTime(epochTime) {
            var d = new Date(epochTime * 1000);
            return d.toLocaleString();
        },
        getOwnerName(ownerId) {
            let user = this.$store.state.organizationUsers.find(
                (x) => x.id == ownerId
            );
            if (typeof user !== 'undefined') {
                return user.firstname + ' ' + user.surname;
            } else {
                return '';
            }
        },
    },
    computed: {
        ...mapState(['templates']),
        ...mapGetters(['getTemplates']),
        ...mapGetters(['getCheckIsTemplateUsed']),
        headers() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return [
                    {
                        value: 'type',
                        sortable: false,
                        width: '5px',
                    },
                    {
                        text: this.$t('TemplateTable.tableTemplateName'),
                        value: 'templateName',
                        width: '25%',
                    },
                    {
                        text: this.$t('TemplateTable.created'),
                        value: 'createdAt',
                        width: '25%',
                    },
                    {
                        text: this.$t('TemplateTable.lastModified'),
                        value: 'lastModified',
                        width: '25%',
                    },
                    {
                        text: this.$t('TemplateTable.owner'),
                        value: 'ownerProfile',
                        width: '25%',
                        sort: (a,b) => {
                            if (a === undefined) return -1;
                            if (b === undefined) return 1;
                            return b.firstname.localeCompare(a.firstname);
                        }
                    },
                    {
                        text: '',
                        value: 'actions',
                        sortable: false,
                        align: 'end',
                        width: '20%',
                    },
                ];
            } else {
                return [
                    {
                        text: '',
                        value: 'type',
                        sortable: false,
                        width: '5%',
                    },
                    {
                        text: this.$t('TemplateTable.tableTemplateName'),
                        value: 'templateName',
                        width: '75%',
                    },
                    {
                        text: '',
                        value: 'actions',
                        sortable: false,
                        align: 'end',
                        width: '20%',
                    },
                ];
            }
        },
    },
};
</script>

<style scoped>
.row-pointer :deep(tbody tr :hover) {
  cursor: pointer;
}
</style>
