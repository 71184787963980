<template>
    <div>
        <v-container class="pa-0">
            <v-row class="my-0">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                    <v-text-field
                        class="search"
                        v-model="search"
                        :label="$t('UserManagement.searchUsers')"
                        single-line
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        filled
                        flat
                        solo
                        background-color="#E8E8E8"
                    >
                    </v-text-field>
                </v-col>
                <v-col align="end" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn
                        v-if="$store.state.userProfile.role === 'admin'"
                        class="primary-button mt-4"
                        data-cy="openAddUserDialog"
                        @click="openAddUser"
                    >
                        <v-icon class="icon-plus">mdi-plus-circle-outline</v-icon>
                        {{ $t("UserManagement.addUser") }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="px-0">
            <v-data-table
                :headers="headers1"
                :items="orgUsers"
                hide-default-footer
                disable-pagination
                :sort-by="['userRole']"
                @click:row="openEditDialog"
                :search="search"
                mobile-breakpoint="0"
            >
                <template v-slot:item.firstname="{ item }">
                    <v-avatar :color="item.avatarColor.bg" size="40" class="my-3">
                        <span
                            v-if="item.surname !== '' && item.surname !== undefined"
                            class="font-weight-regular"
                            :style="'color: ' + item.avatarColor.text"
                        >
                            {{ item.firstname[0].toUpperCase() + item.surname[0].toUpperCase() }}
                        </span>
                        <span
                            v-else
                            class="font-weight-regular"
                            :style="'color: ' + item.avatarColor.text"
                        >
                            {{ item.firstname[0].toUpperCase() + item.firstname[1].toUpperCase() }}
                        </span>
                    </v-avatar>
                    <span data-cy="userName" class="pl-2">
                        {{ item.firstname + " " + item.surname }}
                    </span>
                </template>

                <template v-slot:item.role="{ item }">
                    <span data-cy="userRole" v-if="item.role === 'admin'">
                        {{ $t("UserManagement.admin") }}
                    </span>
                    <span data-cy="userRole" v-if="item.role === 'default'">
                        {{ $t("UserManagement.defaultUser") }}
                    </span>
                </template>

                <template v-slot:item.created="{ item }">
                    <span>{{ item.phone }}</span>
                </template>
            </v-data-table>
            <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('UserManagement.alreadyInUse')" />
            <EditUser ref="EditUser" />
            <AddUser ref="AddUser" @openSnackbar="openSnackbar()" />
            <OkCancelDialog ref="OkCancelDialog" />
            <BaseLoadingDialog :sendingData="sendingData" :msg="$t('Baseloading.deleting')" />

            <!-- CREATE NEW USER ON MOBILE

      Creates a new user, opens addUser-dialog.
      TODO: Refactor as a new component

      -->

            <v-btn
                class="mobile-add"
                data-cy="createNewItem"
                v-on="on"
                v-bind="attrs"
                v-if="!$vuetify.breakpoint.mdAndUp"
                @click="openAddUser"
            >
                <v-icon class="icon-plus mr-0">mdi-plus</v-icon>
            </v-btn>
        </v-container>
    </div>
</template>

<script>
import AddUser from '../UsersManagement/AddUser.vue';
import { firebaseFunctions } from '../../fbInitialize';
import OkCancelDialog from '../OkCancelDialog.vue';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import EditUser from '../UsersManagement/EditUser.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: { AddUser, OkCancelDialog, BaseLoadingDialog, EditUser,GeneralSnackbar },
    data() {
        return {
            sendingData: false,
            search: '',
        };
    },
    methods: {
        openAddUser() {
            this.$refs.AddUser.openDialog();
        },
        openEditDialog(item) {
            this.$refs.EditUser.openDialog(item);
        },
        navigateBack() {
            this.$router.push({ name: 'accountmanagement' });
        },
        async deleteUser(item) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('UserManagement.deleteUserTitle'),
                this.$t('UserManagement.deleteUser')
            );
            if (deleteApproved) {
                this.sendingData = true;
                let UID = item.id;
                firebaseFunctions
                    .httpsCallable('deleteUser')(UID)
                    .then(() => {
                        this.$store.commit('deleteOrganizationUser', UID);
                        this.sendingData = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.sendingData = false;
                    });
            }
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        openSnackbar() {
            this.$refs.GeneralSnackbar.snackbar = true;
        },
    },
    computed: {
        orgUsers() {
            return this.$store.state.organizationUsers;
        },
        headers1() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return [
                    {
                        text: this.$t('UserManagement.name'),
                        align: 'start',
                        value: 'firstname',
                    },

                    {
                        text: this.$t('UserManagement.role'),
                        value: 'role',
                        sortable: false,
                    },
                    {
                        text: this.$t('UserManagement.email'),
                        align: 'start',
                        value: 'email',
                    },
                    {
                        text: this.$t('UserManagement.phoneNumber'),
                        align: 'start',
                        value: 'phone',
                    },

                    {
                        text: '',
                        value: 'actions',
                        sortable: false,
                    },
                ];
            }
            else {
                return [
                    {
                        text: this.$t('UserManagement.name'),
                        align: 'start',
                        value: 'firstname',
                        width: '75%',
                    },
                    {
                        text: this.$t('UserManagement.role'),
                        value: 'role',
                        sortable: false,
                    },
                ];
            }
        }
    },
    created() {
        this.$store.dispatch('setOrgUsers').catch((error) => {
            console.log(error);
        });
    },
};
</script>
