export class Field {
    constructor(args) {
        this.required = args.required;
        this.static   = args.static;
        this.data     = args.data;
        this.title    = args.title;
        this.type     = args.type;
        this.id       = args.id;
        this.time     = args.time || '';

        this.args = args;
    }

    code() {}  // Called when Field Code is required

    // eslint-disable-next-line no-unused-vars
    after(pdf) {} // Called after Field Code is added in the docDefinition

    get isEmpty() {
        return true;
    }
}
