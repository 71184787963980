<template>
    <div class="wrapper">
        <v-container>
            <v-layout fill-height>
                <v-flex class="xs12 sm9 mx-auto mt-6">
                    <v-row class="px-4 pb-2" align="center" justify="center">
                        <v-col class="pa-0" align="center" justify="center" cols="10">
                            <v-text-field
                                :label="$t('FillAutolinkReport.reportName')"
                                v-model="report.name"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="2" align="right">
                            <v-btn icon large class="my-auto mr-2" @click="updatePage()">
                                <v-icon> mdi-refresh </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept=".jpeg, .jpg, .png"
                        @change="takePhoto($event)"
                    />

                    <SectionIterator
                        :sections="this.$store.state.currentReport.sections"
                        :autolink="true"
                        :logoUrl="this.$store.state.currentReport.logoUrl"
                        ref="sectionIterator"
                        :autoSave="true"
                        @saveField="updateReport"
                    />

                    <SendPDFDialog
                        ref="sendPDFDialog"
                        :report="report"
                        @reportSent="
                            openDoneDialog();
                            addCreatedTimestamp();
                        "
                        @reportSendFailed="openFailedDialog()"
                        v-if="signedIn"
                    />

                    <OkExitDialog ref="OkExitDialog"> </OkExitDialog>
                    <LoginDialog ref="popupLogin" @loginSuccessful="getAutolink" />
                    <BaseLoadingDialog
                        :sendingData="InitialLoad"
                        :msg="$t('FillAutolinkReport.loadingMessage')"
                    />



                    <v-dialog v-model="doneDialog" fullscreen hide-overlay>
                        <v-card color="white" height="100%">
                            <v-card-title primary-title class="justify-center">{{
                                dialogText
                            }}</v-card-title>
                        </v-card>
                    </v-dialog>
                </v-flex>
            </v-layout>
            <v-snackbar
                v-model="snackbar"
                elevation="1"
                color="primary"
                class="rounded-lg"
            >
                <h1 class="title">{{ $t("FillAutolinkReport.fillRequired") }}</h1>

                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="snackbar = false">
                        {{ $t("FillAutolinkReport.close") }}
                    </v-btn>
                </template>
            </v-snackbar>
            <v-snackbar
                v-model="failSnackBar"
                elevation="1"
                color="primary"
                class="rounded-lg"
            >
                <h1 class="title">{{ $t("FillAutolinkReport.somethingWentWrong") }}</h1>

                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="snackbar = false">
                        {{ $t("FillAutolinkReport.close") }}
                    </v-btn>
                </template>
            </v-snackbar>
        </v-container>
    </div>
</template>
<script>
import OkExitDialog from '../components/OkExitDialog.vue';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import SectionIterator from '../components/FillReport/SectionIterator.vue';
import LoginDialog from '../components/LoginDialog.vue';
import firebase from 'firebase/app';
import SendPDFDialog from '../components/FillReport/SendPDFDialog.vue';

export default {
    components: {
        OkExitDialog,
        BaseLoadingDialog,
        SectionIterator,
        LoginDialog,
        SendPDFDialog,
    },
    data() {
        return {
            imageAddHelper: {},
            report: {},
            doneDialog: false,
            sendingData: false,
            InitialLoad: true,
            snackbar: false,
            failSnackBar: false,
            dialogText: '',
        };
    },
    methods: {
        signedIn() {
            return firebase.auth().currentUser;
        },
        openFailedDialog() {
            this.failSnackBar = true;
        },
        takePhoto(event) {
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let imgUrl = reader.result;
                this.imageAddHelper.imgs.push({
                    url: imgUrl,
                    text: '',
                    timetag: '',
                    phototaker: '',
                });
                this.$forceUpdate();
            };
        },
        openDoneDialog() {
            this.dialogText = this.$t('FillAutolinkReport.reportSend');
            this.doneDialog = true;
        },
        addCreatedTimestamp() {
            let timestamp = Math.round(new Date().getTime() / 1000);
            let id = this.$route.params.id;
            this.$store.dispatch('addReportCreatedTimestamp', {
                timestamp,
                id,
            });
        },
        emptyReportAndRefresh() {
            this.$store.commit('clearAutolinkSession');
            location.reload();
        },
        async updatePage() {
            let deleteApproved = await this.$refs.OkExitDialog.open(
                this.$t('FillAutolinkReport.clearDataAndRefresh'),
                this.$t('FillAutolinkReport.areYouSureConfirm'),
                this.$t('FillAutolinkReport.yes'),
                this.$t('FillAutolinkReport.no')
            );
            if (deleteApproved) {
                // Delete field
                this.emptyReportAndRefresh();
            }
        },
        getAutolink() {
            this.$store
                .dispatch('fetchAutolinkReport', this.$route.params['id'])
                .then(() => {
                    this.InitialLoad = false;
                    this.report = this.$store.state.currentReport;
                    this.$i18n.locale = this.report.locale;
                });
        },
        updateReport() {
            this.$store.dispatch('addCurrentReport', this.$store.state.currentReport);
        },
    },

    mounted() {
        // If params has /rederictlogin, check if user is signed in
        if (
            this.$route.params['command'] == 'redirectLogin' &&
            !firebase.auth().currentUser
        ) {
            // Navigate to login
            this.$refs.popupLogin.openDialog();
        } else {
            this.getAutolink();
        }
    },
};
</script>
