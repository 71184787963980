<template>
    <!--Desktop Layout-->
    <div>
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :pageHeader="$t('SharedFolders.header')"
        />
        <ReportTable
            :use="this.use"
            :loadingData="this.loading"
            :getFolder="this.getSharedFolder"
            @openSettings="openSettings"
            @fillAutoReport="fillAutoReport"
            @copyLink="copyLink"
            @createFolder="openCreateFolder"
            @createLinkFolder="openCreateLinkFolder"
            @createReport="openCreateDialog"
            :openCreateDialog="openCreateDialog"
        />
        <CreateReport ref="CreateReport" :shared="this.shared" />
        <CreateLinkReportFolder ref="CreateLinkReportFolder" />
        <TermsAndConditionsDialog ref="TermsAndConditionsDialog" />
        <CreateFolder ref="CreateFolder" />
        <CreateLinkFolder ref="CreateLinkFolder" />
    </div>
</template>

<script>
import CreateFolder from '../components/CreateFolder.vue';
import CreateLinkFolder from '../components/CreateLinkReportFolder.vue';
import ReportTable from '../components/Dashboard/ReportTable';
import CreateReport from '../components/CreateReport.vue';
import TermsAndConditionsDialog from '../components/TermsAndConditionsDialog.vue';
import CreateLinkReportFolder from '../components/CreateLinkReportFolder.vue';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';

import { mapGetters } from 'vuex';

export default {
    components: {
        ReportTable,
        CreateReport,
        TermsAndConditionsDialog,
        CreateLinkReportFolder,
        MobileNavigation,
        CreateFolder,
        CreateLinkFolder,
    },
    data() {
        return {
            use: 'shared',
            shared: false,
            loading: false,
        };
    },
    methods: {
        openCreateDialog() {
            this.$refs.CreateReport.openDialog();
        },
        openCreateFolder() {
            this.$refs.CreateFolder.openDialog();
        },
        openCreateLinkFolder() {
            this.$refs.CreateLinkFolder.openDialog('create');
        },
        fillAutoReport() {
            window.location.href = this.getLink();
        },
        openSettings() {
            this.$refs.CreateLinkReportFolder.openDialog('modify');
        },
        getLink() {
            if (this.getCurrentSharedFolderData.isClosed) {
                return (
                    location.origin +
                    '/fillLinkReport/' +
                    this.getCurrentSharedFolderData.id +
                    '/redirectLogin' || ''
                );
            } else {
                return (
                    location.origin +
                    '/fillLinkReport/' +
                    this.getCurrentSharedFolderData.id || ''
                );
            }
        },
        copyLink() {
            navigator.clipboard.writeText(this.getLink());
        },
    },
    computed: {
        ...mapGetters([
            'getCurrentFolderPath',
            'getCurrentFolderData',
            'getSharedFolder',
            'getCurrentSharedFolderData',
        ]),
        getRole() {
            if (this.getCurrentSharedFolderPath.length > 0) {
                let user = this.getCurrentSharedFolderData.participants.find(
                    (x) => x.UID === this.$store.state.userProfile.id
                );
                if (user) {
                    return user.permission;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
    },
    async created() {
        // Scroll to top
        window.scrollTo(0, 0);
        this.$store.dispatch('setSharedRootsIDsListener');
        if(this.$store.state.fetch.sharedFolders)
            return;
        this.loading = true;
        try {
            await this.$store.dispatch('fetchDispatchCommands', ['setSharedFolderRoots']);
            this.$store.commit('sharedFoldersFetched');
        } finally {
            this.loading = false;
        }
    },
};
</script>
