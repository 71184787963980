<template>
    <v-dialog v-model="sendingData" width="200" persistent>
        <v-card height="180" class="rounded-lg">
            <v-layout align-center justify-center>
                <v-progress-circular
                    class="mt-4"
                    :width="12"
                    :size="120"
                    indeterminate
                    color="primary"
                >
                </v-progress-circular>
            </v-layout>
            <v-layout align-center justify-center>
                <h1 data-cy="loadingDialogText" class="subtitle-1 mt-2">{{ msg }}</h1>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        sendingData: Boolean,
        msg: String,
    },
    data() {
        return {};
    },
};
</script>
