import {Component} from '../../../../component';

class CommentTitleStyle extends Component {
    code() {
        return {
            bold: true,
            margin: [0, 0, 0, 5],
        };
    }
}

export default CommentTitleStyle;
