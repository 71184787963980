<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
        @click:outside="closeDialog()"
    >
        <v-card
            class="border-0 h2-18"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("EditUser.editUser") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{ 'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp }">
                <v-form ref="form" class="hidden-overflow">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10" class="px-0">
                            <h1>{{ $t("EditUser.editUser") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon class="close-button" @click="closeDialog()">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 pt-3">
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                               class="py-0"
                               :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp, 'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <h2 class="text-primary pb-4">{{ $t("EditUser.firstname") }}</h2>
                            <v-text-field
                                v-model="obj.firstname"
                                class="rounded"
                                :rules="requireName"
                                required
                                outlined
                                :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                            ></v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                               class="py-0"
                               :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp, 'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <h2 class="text-primary pb-4">{{ $t("EditUser.lastname") }}</h2>
                            <v-text-field
                                v-model="obj.surname"
                                class="rounded"
                                :rules="requireName"
                                required
                                outlined
                                :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0">
                        <h2 class="text-primary pb-3">{{ $t("EditUser.phoneNumber") }}</h2>
                    </v-row>
                    <v-row class="mx-0">
                        <v-text-field
                            @keydown.space.prevent
                            v-model="obj.phone"
                            class="rounded"
                            :rules="phoneRules"
                            outlined
                            :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0">
                        <h2 class="text-primary pb-4">{{ $t("EditUser.email") }}</h2>
                    </v-row>
                    <v-row class="mx-0">
                        <v-text-field
                            @keydown.space.prevent
                            v-model="obj.email"
                            class="rounded"
                            :rules="emailRules"
                            outlined
                            :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0">
                        <h2 class="text-primary">{{ $t("EditUser.role") }}</h2>
                    </v-row>
                    <v-row class="mx-0" data-cy="selectRole">
                        <v-select
                            :value="obj.role"
                            :items="roles"
                            item-text="name"
                            item-value="role"
                            @change="changeSelectedRole($event)"
                            ref="roleChoice"
                            return-object
                            class="rounded-lg ml-1"
                            :rules="requireSelect"
                            :disabled="$store.state.userProfile.role !== 'admin'
                                || $store.state.userProfile.id === obj.id"
                        >
                        </v-select>
                    </v-row>
                    <v-row class="modal-control-row mb-0 mx-0" :class="{'px-2': !$vuetify.breakpoint.mdAndUp }">
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                               class="px-0"
                               :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <v-btn
                                data-cy="deleteUser"
                                class="button-alert"
                                :class="{ 'w-100 py-4': !$vuetify.breakpoint.mdAndUp }"
                                @click="deleteUser()"
                                :disabled="$store.state.userProfile.role !== 'admin'
                                    || $store.state.userProfile.id === obj.id"
                            >
                                <v-icon class="mr-2">mdi-delete</v-icon>
                                {{ $t("EditUser.deleteUser") }}
                            </v-btn>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'"
                               align="end" class="px-0"
                               :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }">
                            <v-btn
                                class="secondary-button"
                                :class="{ 'w-100 py-4 mb-2':
                                              !$vuetify.breakpoint.mdAndUp,
                                          'mr-3': $vuetify.breakpoint.mdAndUp }"
                                @click="closeDialog()">
                                {{ $t("EditUser.discard") }}
                            </v-btn>
                            <v-btn
                                data-cy="saveChanges"
                                class="primary-button"
                                :class="{ 'w-100 py-4 mb-4': !$vuetify.breakpoint.mdAndUp}"
                                @click="saveChanges()"
                                :disabled="$store.state.userProfile.role !== 'admin'"
                            >
                                {{ $t("EditUser.saveChanges") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
        <OkCancelDialog ref="OkCancelDialog" />
        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('Baseloading.loadingMessage')"
        />
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('EditUser.emailOrPhoneAlreadyAssigned')" />
        <UserManagementErrorDialog :alertDialog="alertDialog" @showAlertDialog="setShowAlertDialog" />
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import { firebaseFunctions } from '../../fbInitialize';
import OkCancelDialog from '../OkCancelDialog.vue';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import UserManagementErrorDialog from './UserManagementErrorDialog.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: { OkCancelDialog, BaseLoadingDialog, UserManagementErrorDialog, GeneralSnackbar },
    data() {
        return {
            sendingData: false,
            alertDialog: false,
            dialog: false,
            roles: [
                { name: this.$t('EditUser.basicUser'), role: 'default' },
                { name: this.$t('EditUser.adminUser'), role: 'admin' },
            ],
            obj: {
                firstname: '',
                surname: '',
                phone: '',
                role: '',
                email: '',
                id: '',
            },
            requireName: [(v) => (v && v.length > 0) || this.$t('EditUser.giveName')],
            requireSelect: [(v) => !!v || this.$t('EditUser.selectRights')],
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.obj.phone.length > 0 ||
                    this.$t('EditUser.writeValidEmail'),
            ],
            phoneRules: [
                (v) =>
                    !v ||
                    /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]{8,14}$/g.test(v) ||
                    this.$t('EditUser.addEmailOrPhoneNumber'),
            ],
            originalEmail: '',
        };
    },
    methods: {
        openDialog(item) {
            this.dialog = true;
            this.obj = _.cloneDeep(item);
            this.originalEmail = _.cloneDeep(item.email);
        },
        closeDialog() {
            this.dialog = false;
            this.clearForm();
        },
        changeSelectedRole(event) {
            this.obj.role = event.role;
        },
        saveChanges() {
            this.sendingData = true;
            let user = this.obj;
            if(!this.$refs.form.validate()) {
                this.sendingData = false;
                return;
            }

            if (this.sameEmailOrPhoneExists(user)) {
                firebaseFunctions
                    .httpsCallable('updateUser')(user)
                    .then((result) => {
                        if(result.data.errorInfo) {
                            this.alertDialog = true;
                            this.sendingData = false;
                            return;
                        }
                        // Refresh organization users
                        this.$store.dispatch('setOrgUsers');
                        this.sendingData = false;
                        this.closeDialog();
                        this.$forceUpdate();
                        if (
                            this.originalEmail !== user.email &&
                            user.id !== this.$store.state.userProfile.id
                        ) {
                            this.$store.dispatch('sendForgotPasswordEmail', user.email);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.sendingData = false;
                        this.closeDialog();
                    });
            } else {
                this.sendingData = false;
                this.$refs.GeneralSnackbar.snackbar = true;
            }
        },
        sameEmailOrPhoneExists(user) {
            // Delete existing user
            let orgUsers = this.$store.state.organizationUsers;
            let duplicates = orgUsers.filter(
                (orgUser) =>
                    orgUser.id !== user.id &&
                    ((orgUser.phone === user.phone && user.phone !== '') ||
                        (orgUser.email === user.email && user.email !== ''))
            );
            if (duplicates.length > 0) {
                return false;
            } else {
                return true;
            }
        },
        clearForm() {
            this.obj = {
                firstname: '',
                surname: '',
                phone: '',
                role: '',
                email: '',
                id: '',
            };
        },
        async deleteUser() {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('EditUser.deleteUser'),
                this.$t('EditUser.confirmDeleteUser')
            );
            if (deleteApproved) {
                this.sendingData = true;
                let UID = this.obj.id;
                firebaseFunctions
                    .httpsCallable('deleteUser')(UID)
                    .then(() => {
                        this.$store.commit('deleteOrganizationUser', UID);
                        this.sendingData = false;
                        this.closeDialog();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.sendingData = false;
                        this.closeDialog();
                    });
            }
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        setShowAlertDialog(show) {
            this.alertDialog = show;
        }
    },
};
</script>

<style>
</style>
