<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
    >
        <v-card
            class="border-0 h1-26"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1 v-if="isCreate">{{ $t("CreateLinkReportFolder.CreateTitle") }}</h1>
                    <h1 v-else>{{ $t("CreateLinkReportFolder.ModifyTitle") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{ 'pb-8 px-5 pt-header': !$vuetify.breakpoint.mdAndUp }">
                <v-form ref="form">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1 v-if="isCreate">{{ $t("CreateLinkReportFolder.CreateTitle") }}</h1>
                            <h1 v-else>{{ $t("CreateLinkReportFolder.ModifyTitle") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                data-cy="closeCreateReportDialog"
                                class="close-button"
                                @click="closeDialog"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0">
                        <v-text-field
                            data-cy="folderNameInput"
                            :label="$t('CreateLinkReportFolder.autofolderName')"
                            v-model="folderName"
                            :rules="nameRules"
                            counter="50"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row data-cy="selectTemplate" class="mx-0" :class="{'mt-5 mb-3':!$vuetify.breakpoint.mdAndUp}">
                        <v-autocomplete
                            v-model="selectedTemplate"
                            :items="getTemplates"
                            :item-text="(item) => item.templateName"
                            return-object
                            :label="$t('CreateLinkReportFolder.chooseTemplate')"
                            :no-data-text="$t('CreateReport.Form.NoData')"
                            :rules="requireSelect"
                            @change="changeSelectedTemplate"
                            prepend-inner-icon="mdi-magnify"
                        >
                        </v-autocomplete>
                    </v-row>

                    <v-row class="mx-0 mt-4 h2-18">
                        <h2>
                            {{ $t("CreateLinkReportFolder.naming") }}
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon class="my-1">
                                        <v-icon color="primary"> mdi-help-circle </v-icon>
                                    </v-btn>
                                </template>
                                <span
                                >{{ $t("CreateLinkReportFolder.namingHelpText1") }}
                                    <br />
                                    {{ $t("CreateLinkReportFolder.namingHelpText2") }}</span
                                >
                            </v-tooltip>
                        </h2>
                    </v-row>
                    <v-row class="mx-0 mt-2 mb-5 text-gray">
                        <p v-if="!getChosenFieldName()" class="mb-0">
                            {{ $t("CreateLinkReportFolder.chooseTemplateFirst") }}
                        </p>
                        <p v-else class="mb-0" :class="{'px-3':!$vuetify.breakpoint.mdAndUp}">
                            {{ $t("CreateLinkReportFolder.reportWillBeNamed") }}
                            <b class="text-primary">
                                {{ getChosenFieldName() }}
                            </b>
                            {{ $t("CreateLinkReportFolder.byThisField") }}
                        </p>
                    </v-row>
                    <v-row
                        class="mx-0 mt-4 mb-7"
                        :class="{ 'hidden': Object.keys(selectedTemplate).length === 0 }"
                    >
                        <v-btn class="primary-button" @click="openNamingDialog">
                            {{ $t("CreateLinkReportFolder.chooseField") }}
                        </v-btn>
                    </v-row>
                    <v-alert
                        type="info"
                        :icon="!isClosed ? 'mdi-earth' : 'mdi-account-group'"
                        :color="!isClosed ? 'success' : 'light-blue'"
                        text
                        outlined
                        dense
                        class="pa-4"
                    >
                        <v-row class="ma-0">
                            <v-menu offset-y class="text-center">
                                <template v-slot:activator="{ on, attrs }">
                                    <p class="mb-0" v-on="on" v-bind="attrs">
                                        <b>
                                            {{ isClosed ? $t("CreateLinkReportFolder.organizationMembers")
                                                : $t("CreateLinkReportFolder.openFolderForAll") }}
                                        </b>
                                        <v-icon
                                            :class="{'btn-light-success': !isClosed,
                                                     'btn-light-blue': isClosed}"
                                        >
                                            mdi-menu-down
                                        </v-icon>
                                    </p>

                                </template>
                                <v-list>
                                    <v-list-item @click="changeFolderType('open')">
                                        <v-list-item-icon color="primary">
                                            <v-icon>
                                                {{ !isClosed ? 'mdi-check' : ''}}
                                            </v-icon>
                                        </v-list-item-icon>
                                        {{ $t("CreateLinkReportFolder.openFolderForAll") }}
                                    </v-list-item>
                                    <v-list-item @click="changeFolderType('close')">
                                        <v-list-item-icon color="primary">
                                            <v-icon> {{ isClosed ? 'mdi-check' : ''}} </v-icon>
                                        </v-list-item-icon>
                                        {{ $t("CreateLinkReportFolder.onlyOrganization") }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                        <v-row class="my-1 mx-0">
                            <p class="mb-0">
                                {{ isClosed ? $t("CreateLinkReportFolder.organizationMembersRule")
                                    : $t("CreateLinkReportFolder.folderOpenForAnyoneWithLink") }}
                            </p>
                        </v-row>
                    </v-alert>
                    <SelectReportNamingField ref="namingDialog" @sectionChosen="getChosenFieldData"/>

                    <v-row
                        class="mx-0 mt-5 pa-0"
                        :class="{'mb-3':$vuetify.breakpoint.mdAndUp,
                                 'mb-150':!$vuetify.breakpoint.mdAndUp && !settings}"
                    >
                        <v-btn
                            class="secondary-button"
                            data-cy="openSettings"
                            @click="openSettings"
                        >
                            <v-icon color="primary" class="mr-2"> mdi-cog-outline </v-icon>
                            <span v-if="!settings">
                                {{ $t("CreateLinkReportFolder.openSettings") }}
                                <v-icon color="primary"> mdi-menu-down </v-icon>
                            </span>
                            <span v-else
                            >{{ $t("CreateLinkReportFolder.closeSettings") }}
                                <v-icon color="primary"> mdi-menu-up </v-icon>
                            </span>
                        </v-btn>
                    </v-row>

                    <div v-if="this.settings">
                        <v-row class="mx-0 h2-18 mt-7 mb-0">
                            <h2>
                                {{ $t("CreateLinkReportFolder.settingsTitle") }}
                            </h2>
                        </v-row>
                        <v-row class="mx-0 text-gray mb-3">
                            <p>{{ $t("CreateLinkReportFolder.settingsSubTitle") }}</p>
                        </v-row>

                        <v-card class="rounded-lg pa-4" outlined>
                            <v-row class="ma-0 px-0 h2-18">
                                <h2>{{ $t("CreateLinkReportFolder.defaultReceivers") }}</h2>
                            </v-row>
                            <v-row class="mx-0 text-gray">
                                <p>{{ $t("CreateLinkReportFolder.defaultReceiverInfo") }}</p>
                            </v-row>
                            <v-list>
                                <v-list-item
                                    v-for="(item, i) in folderData.defaultReceivers"
                                    :key="i"
                                    class="px-0 mx-0"
                                >
                                    <v-list-item-icon class="ma-0">
                                        <div class="image-avatar-table">
                                            <p class="avatar-text-table">
                                                {{ getInitials(getNameFromEmail(item)) }}
                                            </p>
                                        </div>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-row class="ma-0">
                                                <p class="mb-0 text-primary">
                                                    <b> {{ getNameFromEmail(item) }} </b>
                                                </p>
                                            </v-row>
                                            <v-row class="mb-0 mr-0 ml-0 mt-1 text-gray">
                                                {{ item }}
                                            </v-row>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            color="primary"
                                            icon
                                            @click="deleteDefaultReciever(i)"
                                            data-cy="deleteReceiver"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-row class="ma-0">
                                <v-col class="pa-0" :class="{'grow': !$vuetify.breakpoint.mdAndUp}">
                                    <v-form ref="emailInputFormDefault" :lazy-validation="true">
                                        <v-text-field
                                            data-cy="defaultReceiverEmailInput"
                                            class="ma-0 py-2 rounded-lg"
                                            :class="{'text-field-100': !$vuetify.breakpoint.mdAndUp}"
                                            :label="$t('CreateLinkReportFolder.addEmail')"
                                            v-model="defaultEmailToAdd"
                                            :rules="emailRules"
                                            validate-on-blur
                                            outlined
                                            @keydown.enter.prevent="addDefaultReciever()"
                                        >
                                        </v-text-field>
                                    </v-form>
                                </v-col>
                                <v-col
                                    :cols="$vuetify.breakpoint.mdAndUp ? '3' : '' "
                                    class="pa-0 mx-2 mt-3"
                                    :class="{'shrink': !$vuetify.breakpoint.mdAndUp}"
                                >
                                    <v-btn
                                        data-cy="addDefaultReceiver"
                                        @click="addDefaultReciever()"
                                        class="primary-button"
                                    >
                                        <v-icon class="mr-1 icon-plus">mdi-plus-circle-outline</v-icon>
                                        {{ $t("CreateLinkReportFolder.addReceiver") }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 px-0 h2-18">
                                <h2>{{ $t("CreateLinkReportFolder.emailReceivers") }}</h2>
                            </v-row>
                            <v-row class="mx-0 text-gray">
                                <p>{{ $t("CreateLinkReportFolder.emailReceiversInfo") }}</p>
                            </v-row>
                            <v-list>
                                <v-list-item
                                    v-for="(item, i) in folderData.optionalReceivers"
                                    :key="i"
                                    class="px-0 mx-0"
                                >
                                    <v-list-item-icon class="ma-0">
                                        <div class="image-avatar-table">
                                            <p class="avatar-text-table">
                                                {{ getInitials(item.name) }}
                                            </p>
                                        </div>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-row class="ma-0">
                                                <p class="mb-0 text-primary">
                                                    <b> {{ item.name }} </b>
                                                </p>
                                            </v-row>
                                            <v-row class="mb-0 mr-0 ml-0 mt-1 text-gray">
                                                {{ item.email }}
                                            </v-row>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            color="primary"
                                            icon
                                            @click="deleteReceiver(i)"
                                            data-cy="deleteReceiver"
                                        >
                                            <v-icon color="primary">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-row class="mx-0 mt-0 mb-3">
                                <v-form
                                    ref="emailInputFormOptionalName"
                                    :lazy-validation="true"
                                    :class="{'w-100 mr-6':$vuetify.breakpoint.mdAndUp,
                                             'w-100 mr-2':!$vuetify.breakpoint.mdAndUp}"
                                >
                                    <v-text-field
                                        data-cy="receiverNameInput"
                                        class="ma-0 mt-2 rounded-lg comment-field-100"
                                        :label="$t('CreateLinkReportFolder.addNameOrIdentifier')"
                                        v-model="optionalEmailToAddName"
                                        validate-on-blur
                                        outlined
                                        hide-details
                                        @keydown.enter.prevent="addOptionalReciever()"
                                    >
                                    </v-text-field>
                                </v-form>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="pa-0 ma-0" :class="{'grow': !$vuetify.breakpoint.mdAndUp}">
                                    <v-form ref="emailInputFormOptional" :lazy-validation="true">
                                        <v-text-field
                                            data-cy="receiverEmailInput"
                                            class="ma-0 pb-2 rounded-lg"
                                            :class="{'text-field-100': !$vuetify.breakpoint.mdAndUp}"
                                            :label="$t('CreateLinkReportFolder.addEmail')"
                                            v-model="optionalEmailToAdd"
                                            :rules="emailRules"
                                            validate-on-blur
                                            outlined
                                            @keydown.enter.prevent="addOptionalReciever()"
                                        >
                                        </v-text-field>
                                    </v-form>
                                </v-col>
                                <v-col
                                    :cols="$vuetify.breakpoint.mdAndUp ? '3' : '' "
                                    class="pa-0 mx-2 mt-1"
                                    :class="{'shrink': !$vuetify.breakpoint.mdAndUp}"
                                >
                                    <v-btn
                                        @click="addOptionalReciever()"
                                        class="primary-button"
                                        data-cy="addReceiver"
                                    >
                                        <v-icon class="icon-plus">mdi-plus-circle-outline</v-icon>
                                        {{ $t("CreateLinkReportFolder.addReceiver") }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-row class="pa-0 mx-0 my-4 h2-18">
                            <h2>{{ $t("CreateLinkReportFolder.writeEmail") }}</h2>
                        </v-row>
                        <v-card
                            class="pa-4 rounded-lg"
                            outlined
                            :class="{'mb-0':$vuetify.breakpoint.mdAndUp,
                                     'mb-150':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-row class="ma-0 px-0 h2-18">
                                <h2>{{ $t("CreateLinkReportFolder.emailTitle") }}</h2>
                            </v-row>
                            <v-row class="mx-0 text-gray">
                                <p>{{ $t("CreateLinkReportFolder.emailTitleInfo") }}</p>
                            </v-row>
                            <v-row class="ma-0 mb-3">
                                <v-form
                                    :lazy-validation="true"
                                    class="w-100"
                                    :class="{'mr-6':$vuetify.breakpoint.mdAndUp, 'mr-0':!$vuetify.breakpoint.mdAndUp}"
                                >
                                    <v-text-field
                                        data-cy="messageTitleInput"
                                        class="ma-0 mt-4 rounded-lg comment-field-100"
                                        :label="$t('CreateLinkReportFolder.messageTitle')"
                                        v-model="message.emailTitle"
                                        validate-on-blur
                                        outlined
                                        hide-details
                                    >
                                    </v-text-field>
                                </v-form>
                            </v-row>
                            <v-row class="mx-0 mb-0">
                                <v-textarea
                                    data-cy="messageContentInput"
                                    v-if="message"
                                    rows="7"
                                    :label="$t('CreateLinkReportFolder.messageContent')"
                                    v-model="message.msg"
                                    counter
                                    required
                                    outlined
                                    class="rounded-lg comment-field-100 textarea-transparent"
                                    :class="{'mr-6':$vuetify.breakpoint.mdAndUp, 'mr-0':!$vuetify.breakpoint.mdAndUp}"
                                    style="white-space: pre-line"
                                ></v-textarea>
                            </v-row>
                        </v-card>
                    </div>
                    <v-row
                        class="mb-0 mt-7 mx-0"
                        :class="{'mobile-dialog-controls pt-2 pb-5 px-2': !$vuetify.breakpoint.mdAndUp }"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            class="px-0"
                            :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <v-btn
                                class="secondary-button mr-3"
                                :class="{ 'w-100 py-4 mb-2': !$vuetify.breakpoint.mdAndUp,
                                          'mr-3': $vuetify.breakpoint.mdAndUp }"
                                @click="cancel">
                                {{ $t("CreateLinkReportFolder.cancel") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            class="px-0"
                            :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <v-btn
                                data-cy="createAutoFolder"
                                class="primary-button"
                                :class="{ 'w-100 py-4 mb-4': !$vuetify.breakpoint.mdAndUp}"
                                @click="isCreate ? createAndOpenFolder() : saveFolder()"
                            >
                                {{ isCreate ? $t("CreateLinkReportFolder.createFolder")
                                    : $t("CreateLinkReportFolder.saveButton") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('Baseloading.loadingMessage')"
        />
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import BaseLoadingDialog from './GeneralizedComponents/BaseLoadingDialog.vue';
import SelectReportNamingField from './GeneralizedComponents/SelectReportNamingField.vue';
import { folderTemplate } from '../datamodels/datamodels.js';

export default {
    components: {
        BaseLoadingDialog,
        SelectReportNamingField,
    },
    data() {
        return {
            use: '',
            type: 'naming',
            settings: false,
            dialog: false,
            namingDialog: false,
            sendingData: false,
            defaultEmailToAdd: '',
            optionalEmailToAdd: '',
            optionalEmailToAddName: '',
            selectedTemplate: {},
            chosenNamingField: {},
            isClosed: false,
            folderName: '',
            nameRules: [
                (v) =>
                    (v && v.length > 0) ||
                    this.$t('CreateLinkReportFolder.Rules.giveName'),
                (v) =>
                    (v && v.length < 51) ||
                    this.$t('CreateLinkReportFolder.Rules.nameTooLong'),
            ],
            requireSelect: [(v) => !!v || this.$t('CreateAutolink.selectRule')],
            //requireHeader: [(v) => (v && v.length > 0) || "Kirjoita otsikko"],
            //requireMessage: [(v) => (v && v.length > 0) || "Kirjoita viesti"],
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('CreateAutolink.emaiRule'),
            ],
            message: { emailTitle: '', msg: '' },
            headers: [
                {
                    text: this.$t('CreateLinkReportFolder.name'),
                    align: 'start',
                    value: 'name',
                },
                {
                    text: this.$t('CreateLinkReportFolder.email'),
                    align: 'start',
                    value: 'email',
                },
                {
                    text: '',
                    align: 'start',
                    value: 'actions',
                },
            ],
            folderData: {},
        };
    },
    methods: {
        clean(str) {
            let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            let index = 0;
            for (let i = 0; i < str.length; i++) {
                if (numbers.includes(str[i])) {
                    break;
                }
                index += 1;
            }
            return str.slice(0, index);
        },

        getNameFromEmail(email) {
            if (email) {
                let prefix = email.split('@')[0];
                let firstName, lastName;
                if (prefix.includes('.')) {
                    firstName = this.clean(
                        prefix.split('.')[0].charAt(0).toUpperCase() +
                            prefix.split('.')[0].slice(1)
                    );
                    lastName = this.clean(
                        prefix.split('.')[1].charAt(0).toUpperCase() +
                            prefix.split('.')[1].slice(1)
                    );
                    if (prefix.split('.').length > 2) {
                        lastName = this.clean(
                            prefix
                                .split('.')[prefix.split('.').length - 1].charAt(0)
                                .toUpperCase() +
                                prefix.split('.')[prefix.split('.').length - 1].slice(1)
                        );
                    }
                    return `${firstName} ${lastName}`;
                }
                return this.clean(prefix.charAt(0).toUpperCase() + prefix.slice(1));
            }
        },

        getInitials(name) {
            if (name) {
                let names = name.split(' ');
                let initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            }
        },
        changeFolderType(params) {
            this.isClosed = params === 'close';
        },
        openNamingDialog() {
            this.$refs.namingDialog.openDialog(this.selectedTemplate);
        },
        getChosenFieldData(value) {
            this.chosenNamingField = value;
        },
        closeDialog() {
            this.folderData = _.cloneDeep(folderTemplate);
            this.folderName = '';
            this.selectedTemplate = {};
            this.dialog = false;
            this.namingDialog = false;
        },
        getChosenFieldName() {
            return this.chosenNamingField.label;
        },
        async saveFolder() {
            if (this.$refs.form.validate()) {
                let folder = this.currentFolder;
                folder['name'] = this.folderName;
                folder['template_id'] = this.selectedTemplate.template_id;
                folder['msg'] = this.message;
                folder['isClosed'] = this.isClosed;
                folder.optionalReceivers = this.folderData.optionalReceivers;
                folder.defaultReceivers = this.folderData.defaultReceivers;
                if (Object.keys(this.chosenNamingField).length !== 0) {
                    folder['reportNamingFieldId'] = this.chosenNamingField.id;
                    folder['reportNamingFieldLabel'] = this.chosenNamingField.label;
                }
                this.$store.dispatch('updateFolderWithData', folder).then(() => {
                    this.sendingData = false;
                    this.closeDialog();
                });
            }
        },
        async createAndOpenFolder() {
            if (this.$refs.form.validate()) {
                let parentFolder = this.currentFolder;
                this.sendingData = true;
                var newFolder = _.cloneDeep(this.folderData);
                newFolder['use'] = 'AutoFolder';
                newFolder['template_id'] = this.selectedTemplate.template_id;
                newFolder['name'] = this.folderName;
                newFolder['owner'] = parentFolder.owner;
                newFolder['msg'] = this.message;
                newFolder['isClosed'] = this.isClosed;
                if (Object.keys(this.chosenNamingField).length !== 0) {
                    newFolder['reportNamingFieldId'] = this.chosenNamingField.id;
                    newFolder['reportNamingFieldLabel'] = this.chosenNamingField.label;
                }
                newFolder['createdAt'] = Math.round(new Date().getTime() / 1000);
                newFolder['updatedAt'] = Math.round(new Date().getTime() / 1000);
                parentFolder.updatedAt = Math.round(new Date().getTime() / 1000);
                newFolder['participants'] = parentFolder.participants;
                this.$store
                    .dispatch('addFolder', {
                        folderData: newFolder,
                        parentFolderID: parentFolder.id,
                    })
                    .then((folderId) => {
                        this.chosenNamingField = {};
                        this.dialog = false;
                        // navigate to the new folder
                        if (this.$route.name === 'dashboard') {
                            this.$store.state.currentFolderPath.push(folderId);
                        } else if (this.$route.name === 'sharedfolders') {
                            this.$store.state.currentSharedFolderPath.push(folderId);
                        }
                        this.sendingData = false;
                        this.closeDialog();
                        this.$forceUpdate();
                    });
                this.$store.dispatch('updateFolder', parentFolder.id);
                this.$emit('folderCreated');
            }
        },
        openDialog(use) {
            this.use = use;
            if (use === 'modify') {
                let folder = this.currentFolder;
                this.folderName = folder.name;
                this.selectedTemplate = this.getTemplate(folder.template_id);
                this.folderData = this.currentFolder;
                this.message = { ...folder.msg };
                this.isClosed = folder.isClosed;
                this.chosenNamingField.label = folder.reportNamingFieldLabel;
                this.chosenNamingField.id = folder.reportNamingFieldId;
            } else {
                this.folderName = '';
                this.selectedTemplate = '';
                this.folderData = _.cloneDeep(folderTemplate);
                this.isClosed = false;
                this.message = _.cloneDeep(folderTemplate.msg);
                this.chosenNamingField = {};
            }
            this.dialog = true;
        },
        addDefaultReciever() {
            if (
                this.defaultEmailToAdd !== ''  &&
                this.$refs.emailInputFormDefault.validate()
            ) {
                this.folderData.defaultReceivers.push(this.defaultEmailToAdd);
                this.defaultEmailToAdd = '';
            }
        },
        addOptionalReciever() {
            if (
                this.optionalEmailToAdd !== '' &&
                this.$refs.emailInputFormOptional.validate()
            ) {
                let optionalReceiver = {
                    name: this.optionalEmailToAddName,
                    email: this.optionalEmailToAdd,
                };
                this.folderData.optionalReceivers.push(optionalReceiver);
                this.optionalEmailToAdd = '';
                this.optionalEmailToAddName = '';
            }
        },
        deleteDefaultReciever(index) {
            this.folderData.defaultReceivers.splice(index, 1);
        },
        changeSelectedTemplate(obj) {
            this.selectedTemplate = obj;
            this.chosenNamingField = {};
        },
        navigateBack() {
            this.closeDialog();
        },
        deleteReceiver(i) {
            this.folderData.optionalReceivers.splice(i, 1);
            this.optionalEmailToAdd = '';
            this.optionalEmailToAddName = '';
        },
        openSettings() {
            this.settings = !this.settings;
        },
        cancel() {
            this.dialog = false;
        },
    },
    computed: {
        ...mapGetters(['getTemplates', 'getTemplate', 'getCurrentFolderData', 'getCurrentSharedFolderData']),
        isCreate() {
            return this.use === 'create';
        },
        currentFolder() {
            return this.$route.name === 'dashboard'
                ? this.getCurrentFolderData
                : this.getCurrentSharedFolderData;
        },
    },
    created() {
        this.folderData = _.cloneDeep(folderTemplate);
    },
    destroyed() {
        this.folderData;
    },
};
</script>
