<template>
    <div class="wrapper">
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :routeName="'processes'"
            pageHeader="tarkastus"
            :edit="true"
            @enableEdit="enableEdit()"
        />
        <v-container
            class="px-0 pt-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-row class="mx-0 px-4" :class="{'mb-7': $vuetify.breakpoint.mdAndUp }">
                <v-btn
                    x-large
                    circle
                    icon
                    @click="navigateToProcesses"
                    class="rounded-button mr-7"
                    v-if="$vuetify.breakpoint.mdAndUp"
                >
                    <v-icon color="primary">mdi-arrow-left</v-icon>
                </v-btn>
                <h1 v-if="$vuetify.breakpoint.mdAndUp"> {{ inspection.address + " " + inspection.apartment }} </h1>
                <v-btn class="secondary-button pl-0" @click="navigateToProcesses" v-else>
                    <v-icon color="primary" class="icon-plus"> mdi-chevron-left </v-icon>
                    {{ $t("Process.navigateBack") }}
                </v-btn>
            </v-row>

            <v-card class="rounded-lg mt-4" max-width="1700">
                <v-row class="ma-0" justify="center">
                    <v-card class="rounded-lg px-7 py-2 w-100">
                        <v-tabs>
                            <v-tab data-cy="fillerTab">
                                Tarkastukset

                            </v-tab>
                            <v-tab data-cy="messagesTab">
                                Tapahtumat
                                <v-chip
                                    class="ml-5"
                                    color="primary"
                                    text-color="white"
                                >
                                    {{ inspection.events.length }}
                                </v-chip>
                            </v-tab>
                            <v-tab-item name="fillers">
                                <!-- Participants & Responses -->
                                <div class="inspectionStatus-container">
                                    <div class="col-rest" :class="{'h2-18':!$vuetify.breakpoint.mdAndUp}">
                                        <v-row class="ma-0 pa-0">
                                            <v-col cols="7">
                                                <h2 class="pb-2" >
                                                    Asunnon tarkastuksen tilanne
                                                </h2>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-chip
                                                    :color="this.inspection.status === 'Kesken' ?
                                                        '#FAFFDE' : '#E3FFDE'" >
                                                    {{this.inspection.status}}
                                                </v-chip>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-btn class="primary" @click="Toggle">
                                                    <v-icon>mdi-check</v-icon>Hyväksy tarkastus
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                                <b>Havainnot huoneistosta</b>
                                <br>
                                Tarkastele muuttotarkastuksen havaintoja tästä
                                <InspecctionSectionIterator
                                    :sections="this.reports"
                                />
                            </v-tab-item>

                            <v-tab-item name="messages">
                                <v-row class="mx-0 px-2 my-5 h2-18 text-primary">
                                    <h2>  Tapahtumavirta
                                    </h2>
                                </v-row>
                                <v-list subheader two-line>
                                    <v-list-item
                                        data-cy="modifyMessage"
                                        v-for="event in inspection.events"
                                        :key="event.id"
                                        @click="openOrModifyItem(action)"
                                    >
                                        <v-list-item-avatar dark>
                                            <v-icon
                                                class="primary"
                                                dark
                                            >
                                                mdi-profile
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            {{event.message}}
                                            <v-row class="pa-0 ma-0">
                                                <v-col cols="3" class="pa-0 ma-0">
                                                    <v-card class="pa-3">
                                                        <v-list-item-subtitle
                                                            v-text="formatTime(event.timestamp)"
                                                        ></v-list-item-subtitle>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-tab-item>
                        </v-tabs>
                    </v-card>
                </v-row>
            </v-card>
        </v-container>
        <OkCancelDialog ref="OkCancelDialog" />

    </div>
</template>

<script>
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';
import OkCancelDialog from '../components/OkCancelDialog.vue';
import { inspections, inspectionReportsDEMO } from '../fbInitialize';
import InspecctionSectionIterator from '../components/Inspection/InspectionSectionIterator.vue';

export default {
    components: {
        MobileNavigation,
        OkCancelDialog,
        InspecctionSectionIterator
    },
    data() {
        return {
            inspection: {},
            reports: [],
            multiMode: true,
            //Dialog booleans here
            dialog: false,
            //NameChanging Helpers Here:,
            nameEditHelper: '',
            nameChangeSaving: false,
            saved: false,
            //reportNamingFieldID if selected
            reportNamingFieldID: false,
            inspectItem: {},
            listener1: '',
            minDate: '',
            date: '',
            // This is for animation
            icon: 'mdi-calendar',
            modify: false,
            currentTime: Date.now() / 1000,
            loading: false,
        };
    },
    methods: {
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);

            return d.toLocaleString();
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        },
        navigateToProcesses() {
            this.$router.go(-1);
        },
        async Toggle() {
            if(this.inspection.status === 'Kesken') {
                this.inspection.status = 'Hyväksytty';
                const inspectionref = await inspections.doc(this.$route.params.inpectionId);
                inspectionref.update({ status: 'Hyväksytty'});
            } else {
                this.inspection.status = 'Kesken';
                const inspectionref = await inspections.doc(this.$route.params.inpectionId);
                inspectionref.update({ status: 'Kesken'});
            }

        }
    },
    async created() {
        window.scrollTo(0, 0);
        const inspectionFetch = await inspections.doc(this.$route.params.inpectionId).get();
        const inspectionData = inspectionFetch.data();
        this.inspection = inspectionData;

        let idlist = [];
        idlist.push({ id: inspectionData.inMover.report, role: 'Sisäänmuuttaja' });
        idlist.push({ id: inspectionData.inspector.report, role: 'Ammattitarkastaja'});
        idlist.push({ id: inspectionData.outMover.report, role: 'Ulosmuuttaja' });

        for (const idObj of idlist) {
            if(idObj.id !== undefined && idObj.id !== '') {
                const reportFetch = await inspectionReportsDEMO.doc(idObj.id).get();
                const reportData = reportFetch.data();
                if(reportData) {
                    reportData['inspectionsRole'] = idObj.role;
                    this.reports.push(reportData);
                }
            }
        }
    }
};
</script>

<style>
</style>
