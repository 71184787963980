import {Component} from '../../../../component';

class CommentArea extends Component {
    code() {
        return {
            fontSize: 10,
            margin: [0, 5, 0, 5]
        };
    }
}

export default CommentArea;
