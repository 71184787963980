import {Field} from '../../../field';
import {asComponent} from '../components/helpers/asComponent';
import {Line} from './drawings/drawing.line';


class Slider extends Field {
    code() {
        return {
            stack: [
                {
                    columns: [
                        [
                            {
                                text: this.args.question,
                                alignment: 'left',
                                bold: true,
                            },
                        ],
                        [
                            {
                                text: this.data,
                                alignment: 'right',
                            },
                        ],
                    ],
                    unbreakable: true,
                },
                asComponent(Line),
            ],
            unbreakable: true,
        };
    }

    get isEmpty() {
        return this.data === '';
    }
}

export default Slider;
