<template>
    <v-container fill-height :style="{ background:  $vuetify.theme.themes.light.primary }" fluid>
        <v-row align="center" justify="center">
            <div>
                <h2 class="white--text text-center" style="font-size: 40px">Formup</h2>
            </div>
        </v-row>
        <v-row align="center" justify="center" class="mb-0">
            <v-card class="rounded-lg" width="400">
                <v-card-text>
                    <v-container fluid class="pa-0 ma-0">
                        <h1 class="h4 black--text pt-2 pb-4" style="text-align: center">
                            {{ $t("PhoneLogin.header") }}</h1>
                        <form @submit.prevent>
                            <v-flex xs12>
                                <v-text-field
                                    data-cy="phonenumberInput"
                                    name="phone"
                                    type="tel"
                                    v-model="phone"
                                    :label="$t('PhoneLogin.phonenumberLabel')"
                                    required
                                ></v-text-field>
                            </v-flex>
                            <v-card-actions class="pa-0 ma-0">
                                <v-btn
                                    data-cy="login"
                                    type="submit"
                                    @click="login()"
                                    class="rounded-lg mb-4"
                                    block
                                    color="primary"
                                    dark
                                    height="50"
                                    id="431725"
                                >
                                    {{ $t("PhoneLogin.sendLoginCode") }}
                                </v-btn>
                            </v-card-actions>
                            <v-layout row class="pt-6 ma-0" v-show="errMsg != ''">
                                <v-flex xs12>
                                    <v-alert type="error" rounded>
                                        {{ errMsg }}
                                    </v-alert>
                                </v-flex>
                            </v-layout>
                        </form>
                        <LocaleSwitcher class="mt-2" />
                    </v-container>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row align="center" justify="center" >
            <v-card class="rounded-lg" width="300" color="transparent" elevation="0">
            </v-card>
        </v-row>

        <BaseLoadingDialog
            :sendingData="loggingProcessOngoing"
            :msg="$t('PhoneLogin.loadingMessage')"
        >
        </BaseLoadingDialog>
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('PhoneLogin.invalidNumber')" />
    </v-container>
</template>

<script>
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import LocaleSwitcher from '../components/LocaleSwitcher.vue';
import GeneralSnackbar from '../components/GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: {
        BaseLoadingDialog,
        LocaleSwitcher,
        GeneralSnackbar,
    },
    data() {
        return {
            phone: '',
            errMsg: '',
            loggingProcessOngoing: false,
        };
    },
    methods: {
        async login() {
            if (!this.loggingProcessOngoing) {
                this.loggingProcessOngoing = true;
                this.errMsg = '';
                await this.$store
                    .dispatch('onSignInSubmit', this.phone)
                    .catch((error) => {
                        console.log(error);
                        this.loggingProcessOngoing = false;
                        this.$refs.GeneralSnackbar.snackbar = true;
                        this.errMsg = this.$t('PhoneLogin.invalidNumber');
                    });
                this.loggingProcessOngoing = false;
            }
        },
    },

};
</script>
