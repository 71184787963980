import {Component} from '../../../../component';

export class Line extends Component {
    backgroundColor = '#000000';
    paddingY = 8;
    code() {
        return {
            canvas: [
                {
                    lineColor: this.backgroundColor,
                    x1: 0,
                    y1: this.paddingY,
                    x2: 515,
                    y2: this.paddingY,
                    type: 'line',
                    lineWidth: 0.5,
                },
            ],
        };
    }
}
