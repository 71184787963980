<template>
    <v-dialog
        v-model="dialog"
        @click:outside="closeAndErase()"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        class="pa-2"
        width="600"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>
                        {{ modifyState ? $t("AddTableDataDialog.modifyDialogTitle")
                            : $t("AddTableDataDialog.addDialogTitle") }}
                    </h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1>
                            {{ modifyState ? $t("AddTableDataDialog.modifyDialogTitle")
                                : $t("AddTableDataDialog.addDialogTitle") }}
                        </h1>
                    </v-col>
                    <v-col cols="2">
                        <v-icon
                            class="close-button"
                            @click="closeDialog()"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-form
                    ref="dataForm"
                    :class="{ 'mt-5 mb-150':!$vuetify.breakpoint.mdAndUp, 'my-4':$vuetify.breakpoint.mdAndUp }"
                >
                    <v-row
                        v-for="(data, index) in dataObj.data"
                        :key="index"
                        class="mx-0"
                    >
                        <v-text-field
                            @keydown.enter.prevent="saveAndClose()"
                            data-cy="tableDataInput"
                            :label="data.label"
                            class="px-2"
                            :value="data.value"
                            :rules="fieldRule"
                            @change="changeData($event, index)"
                        >
                        </v-text-field>
                    </v-row>
                </v-form>
                <v-row
                    class="mt-3 mb-0"
                    :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                >
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            class="secondary-button"
                            :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                            @click="closeAndErase()"
                        >
                            {{ $t("AddTableDataDialog.cancel") }}
                        </v-btn>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        align="end"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            data-cy="saveTableData"
                            class="primary-button"
                            :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                            @click="saveAndClose()"
                        >
                            {{ $t("AddTableDataDialog.save") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
    props: ['field', 'fieldIndex'],
    data() {
        return {
            dialog: false,
            addMode: false,
            fieldDataIndex: '',
            modifyState: false,
            dataObj: {},
            fieldRule: [
                (v) =>
                    (v && v.length > 0) ||
                    this.$t('AddTableDataDialog.addData'),
            ],
        };
    },
    methods: {
        changeData(event, index) {
            // Event is filled data
            this.dataObj.data[index].value = event;
        },
        closeDialog() {
            this.dialog = false;
        },
        saveAndClose() {
            if (this.$refs.dataForm.validate()) {
                if (this.addMode) {
                    this.field.data.push(_.cloneDeep(this.dataObj));
                } else {
                    //Not reactive because vue doesnt recognise changes
                    this.field.data[this.fieldDataIndex] = _.cloneDeep(
                        this.dataObj
                    );
                    //I will make a change directly to field.data which vuejs will track
                    let copy = _.cloneDeep(this.field.data);
                    this.field.data = copy;
                }
                this.$emit('dataSaved');
                this.dialog = false;
                this.$forceUpdate();
                this.dataObj = {};
            }
        },
        closeAndErase() {
            this.dataObj = {};
            this.dialog = false;
        },
        openAddNewDialog() {
            let fields = _.cloneDeep(this.field.columns);
            this.dataObj['data'] = fields;
            this.addMode = true;
            this.modifyState = false;
            this.dialog = true;
        },
        openInspectDialog(index) {
            this.fieldDataIndex = index;
            this.dataObj = _.cloneDeep(this.field.data[index]);
            this.addMode = false;
            this.dialog = true;
            this.modifyState = true;
            this.$forceUpdate();
        },
    },
};
</script>
