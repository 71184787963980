export default class ThrottledPromiseRunner {
    #results = [];
    #waiting = [];
    #maxConcurrent = 10;
    started = false;

    constructor(maxConcurrent) {
        this.#maxConcurrent = maxConcurrent;
    }

    addAsyncCall(func, ...params) {
        if (this.started) {
            throw new Error('Cannot add an async call after the runner has started');
        }
        this.#waiting.push({ func, params });
    }

    async #runExecutionSlot() {
        do {
            const { func, params } = this.#waiting.shift();
            try {
                const result = await func(...params);
                this.#results.push(result);
            } catch (e) {
                console.error(e);
            }
        } while (this.#waiting.length > 0);
    }

    async run() {
        this.started = true;
        let runners = [];
        while (this.#waiting.length > 0 && runners.length < this.#maxConcurrent) {
            runners.push(this.#runExecutionSlot());
        }
        await Promise.all(runners);
        return this.#results;
    }
}
