<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
        class="pa-0 ma-0"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            class="border-0 h1-26"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="dialog = false">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1 v-if="message.type === 'email'">{{ $t("CreateAutomaticMessage.messageName") }}</h1>
                    <h1 v-else>{{ $t("CreateAutomaticMessage.smsName") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5':!$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1 v-if="message.type === 'email'">{{ $t("CreateAutomaticMessage.messageName") }}</h1>
                        <h1 v-else>{{ $t("CreateAutomaticMessage.smsName") }}</h1>
                    </v-col>
                    <v-col cols="2">
                        <v-icon
                            class="close-button"
                            @click="dialog = false"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-form ref="form" :class="{'mb-150': !$vuetify.breakpoint.mdAndUp}">
                    <v-alert
                        icon="mdi-information"
                        color="info"
                        class="pa-4 rounded-lg mt-5 mb-7"
                        text
                        outlined
                    >
                        <p class="mb-0">
                            {{message.type === 'email' ?
                                $t("CreateAutomaticMessage.messageInfo") :
                                $t("CreateAutomaticMessage.messageInfo") +
                                $t("CreateAutomaticMessage.textMessagePrice")}}
                        </p>
                    </v-alert>
                    <v-row class="mx-0 mb-0 h2-18 text-primary">
                        <h2>{{ $t("CreateAutomaticMessage.giveMessageName") }}</h2>
                    </v-row>
                    <v-row class="mx-0 my-3">
                        <v-text-field
                            data-cy="messageTitleInput"
                            :label="$t('CreateAutomaticMessage.addMessageName')"
                            v-model="message.title"
                            hide-details
                            outlined
                            class="comment-field-100 rounded-lg"
                            :rules="requireName"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0 mt-5 mb-0 h2-18 text-primary" v-if="message.type === 'email'">
                        <h2>{{ $t("CreateAutomaticMessage.addMessageHeader") }}</h2>
                    </v-row>
                    <v-row class="mx-0 mt-3" v-if="message.type === 'email'">
                        <v-text-field
                            data-cy="emailTitleInput"
                            :label="$t('CreateAutomaticMessage.header')"
                            v-model="message.emailTitle"
                            required
                            outlined
                            class="comment-field-100 rounded-lg"
                            :rules="requireHeader"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0 mb-3 h2-18 text-primary">
                        <h2>{{ $t("CreateAutomaticMessage.messageRule") }}</h2>
                    </v-row>
                    <v-row class="mx-0">
                        <v-textarea
                            data-cy="emailContentInput"
                            :label="$t('CreateAutomaticMessage.addMessage')"
                            v-model="message.msg"
                            counter
                            required
                            outlined
                            class="comment-field-100 rounded-lg textarea-transparent"
                            :rules="requireMessage"
                            style="white-space: pre-line"
                        ></v-textarea>
                    </v-row>
                    <v-row class="mx-0 h2-18 text-primary">
                        <h2 v-if="!messageGroup">
                            {{ $t("CreateAutomaticMessage.timeSent") }}
                        </h2>
                        <h2 v-else>
                            {{ $t("CreateAutomaticMessage.groupMessageSendTime") }}
                        </h2>
                    </v-row>
                    <v-row
                        v-if="!messageGroup"
                        class="mx-0"
                        :class="{'mb-150': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col cols="6" class="py-0">
                            <!-- This dialog should be refactored to somewhere else... -->
                            <v-dialog
                                @click:outside="modal = !modal"
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="dateHelper"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        data-cy="selectDate"
                                        :value="formatDate(dateHelper)"
                                        :label="$t('CreateAutomaticMessage.date')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="requireDate"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="fi"
                                    v-model="dateHelper"
                                    scrollable
                                    color="primary"
                                    class="rounded-lg"
                                    :full-width="true"
                                    :first-day-of-week="1"
                                    :min="min"
                                    :max="max"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="secondary-button mb-2"
                                        @click="closeDatePicker()"
                                    >
                                        {{ $t("CreateAutomaticMessage.cancelDate") }}
                                    </v-btn>
                                    <v-btn
                                        data-cy="confirmDate"
                                        class="primary-button mb-2"
                                        @click="$refs.dialog.save(dateHelper)"
                                    >
                                        {{ $t("CreateAutomaticMessage.confirmDate") }}
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field
                                data-cy="selectTime"
                                v-model="timeHelper"
                                :label="
                                    $t('CreateAutomaticMessage.timeInput')
                                "
                                prepend-icon="mdi-clock-time-four-outline"
                                type="time"
                                :rules="requireTime"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row
                        v-else
                        class="mx-0"
                        :class="{'mb-150': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-text-field
                            v-model="sendTime"
                            hide-details
                            data-cy="selectTime"
                            :label="$t('CreateAutomaticMessage.chooseTiming')"
                            prepend-icon="mdi-av-timer"
                            type="number"
                            :rules="requireTimeSent"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row
                        class="mt-3 mb-0"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="closeAndClearAddAutomaticMessage"
                            >
                                {{ $t("CreateAutomaticMessage.discard") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                @click="saveAutomaticMessage"
                                data-cy="saveMessage"
                                :loading="loading"
                            >
                                {{ $t("CreateAutomaticMessage.save") }}
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>
            </v-container>
        </v-card>
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('CreateAutomaticMessage.timeRule')" />
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: {
        GeneralSnackbar,
    },
    props: ['modify', 'itemToModify', 'messageGroup'],
    data() {
        return {
            dialog: false,
            dateHelper: '',
            timeHelper: '',
            sendTime: '',
            modal: false,
            max: '',
            min: '',
            loading: false,
            requireName: [
                (v) =>
                    (v && v.length > 0) ||
                    this.$t('CreateAutomaticMessage.nameRule'),
            ],
            requireSelect: [
                (v) => !!v || this.$t('CreateAutomaticMessage.selectRule'),
            ],
            requireHeader: [
                (v) =>
                    (v && v.length > 0) ||
                    this.$t('CreateAutomaticMessage.headerRule'),
            ],
            requireMessage: [
                (v) =>
                    (v && v.length > 0) ||
                    this.$t('CreateAutomaticMessage.messageRule'),
            ],
            requireDate: [
                (v) =>
                    (v && v.length > 0) ||
                    this.$t('CreateAutomaticMessage.dateRule'),
            ],
            requireTime: [
                (v) =>
                    (v && v.length > 0) ||
                    this.$t('CreateAutomaticMessage.timeRule'),
            ],
            requireTimeSent: [
                (v) =>
                    (v && v > 0 && v.length > 0) ||
                    this.$t('CreateAutomaticMessage.chooseTiming'),
            ],
            emailTemplate: {
                emailTitle: '',
                title: '',
                msg: '',
                endDate: '',
                process: this.$store.state.currentProcess.id,
                owner: this.$store.state.userProfile.id,
                firebaseDeleted: false,
                reportFillBaseUrl: location.origin + '/fillprocessreport',
                type: 'email',
                scheduled: false
            },
            smsTemplate: {
                emailTitle: '',
                title: '',
                msg: '',
                endDate: '',
                process: this.$store.state.currentProcess.id,
                owner: this.$store.state.userProfile.id,
                firebaseDeleted: false,
                reportFillBaseUrl: location.origin + '/fillprocessreport',
                type: 'sms',
                scheduled: false
            },
            message: {},
        };
    },
    methods: {
        openCreateDialog(type) {
            this.dateHelper = '';
            this.timeHelper = '';
            this.sendTime = '';
            this.dialog = true;
            if (type === 'email') {
                this.message = _.cloneDeep(this.emailTemplate);
            } else if (type === 'sms') {
                this.message = _.cloneDeep(this.smsTemplate);
            }
        },
        closeDatePicker() {
            this.modal = false;
        },
        inspectModifyDialog(item) {
            let offset = new Date().getTimezoneOffset() * 60000;
            this.dateHelper = new Date(item.endDate * 1000)
                .toISOString()
                .substr(0, 10);
            this.timeHelper = new Date(item.endDate * 1000 - offset)
                .toISOString()
                .substr(11, 5);
            this.dialog = true;
            this.message = _.cloneDeep(item);
        },
        inspectModifyMessageGroupDialog(item) {
            this.dialog = true;
            this.sendTime = item.sendTime;
            this.message = _.cloneDeep(item);
        },
        closeAndClearAddAutomaticMessage() {
            this.message = {};
            this.dateHelper = '';
            this.dialog = false;
        },
        timeIsAfter2Minutes() {
            var timeIn2Minutes = Date.now() / 1000 + 2 * 60;
            let date = new Date(this.dateHelper).getTime();
            var time =
                Number(this.timeHelper.split(':')[0]) * 3600000 +
                Number(this.timeHelper.split(':')[1]) * 60000;
            let offset = new Date().getTimezoneOffset();
            let d = date / 1000 + time / 1000;
            let endDate = d + offset * 60;
            return endDate > timeIn2Minutes;
        },
        saveAutomaticMessage() {
            if(this.messageGroup) {
                if (this.modify) {
                    this.saveModifyMessageGroup();
                } else {
                    this.saveAsMessageGroup();
                }
            } else if (this.modify) {
                this.modifyAutomaticMessage();
            } else {
                this.scheduleAutomaticMessage();
            }
        },
        saveModifyMessageGroup() {
            if (this.$refs.form.validate()) {
                let messageGroupMessage = {};
                messageGroupMessage.msg = this.message.msg;
                messageGroupMessage.emailTitle = this.message.emailTitle;
                messageGroupMessage.title = this.message.title;
                messageGroupMessage.type = this.message.type;
                messageGroupMessage.sendTime = this.sendTime;
                messageGroupMessage.id = this.message.id;
                this.$emit('SaveModifyMessage', messageGroupMessage);
                this.dialog = false;
                this.dateHelper = '';
                this.message = {};
                this.loading = false;
            }
        },
        saveAsMessageGroup() {
            if (this.$refs.form.validate()) {
                let messageGroupMessage = {};
                messageGroupMessage.msg = this.message.msg;
                messageGroupMessage.emailTitle = this.message.emailTitle;
                messageGroupMessage.title = this.message.title;
                messageGroupMessage.type = this.message.type;
                messageGroupMessage.sendTime = this.sendTime;
                messageGroupMessage.id = Math.floor((Math.random() * 1000000) + 1);
                this.$emit('AddMessageToGroup', messageGroupMessage);
                this.dialog = false;
                this.dateHelper = '';
                this.message = {};
                this.loading = false;
            }
        },
        scheduleAutomaticMessage() {
            if(!this.timeIsAfter2Minutes()) {
                this.$refs.GeneralSnackbar.snackbar = true;
            }
            else if (this.$refs.form.validate()) {
                this.loading = true;
                let date = new Date(this.dateHelper).getTime();
                let offset = new Date().getTimezoneOffset();

                var time =
                    Number(this.timeHelper.split(':')[0]) * 3600000 +
                    Number(this.timeHelper.split(':')[1]) * 60000;

                let d = date / 1000 + time / 1000;

                let endDate = d + offset * 60;

                this.message['endDate'] = endDate;
                this.$store.dispatch('addAction', this.message).then(() => {
                    this.dialog = false;
                    this.dateHelper = '';
                    this.message = {};
                    this.loading = false;
                });
            }
        },
        async modifyAutomaticMessage() {
            if(!this.timeIsAfter2Minutes()) {
                this.$refs.GeneralSnackbar.snackbar = true;
            }
            else if (this.$refs.form.validate()) {
                this.loading = true;
                let date = new Date(this.dateHelper).getTime();
                let offset = new Date().getTimezoneOffset();

                var time =
                    Number(this.timeHelper.split(':')[0]) * 3600000 +
                    Number(this.timeHelper.split(':')[1]) * 60000;

                let d = date / 1000 + time / 1000;

                let endDate = d + offset * 60;

                this.message['endDate'] = endDate;
                const toUpdate = this.message;
                delete toUpdate.scheduled;
                delete toUpdate.expirationTask;
                await this.$store.dispatch('updateAction', toUpdate);
                this.dialog = false;
                this.dateHelper = '';
                this.message = {};
                this.loading = false;
            }
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);

            return d.toLocaleString();
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
    },
    created() {
        this.min = new Date().toISOString().substr(0, 10);
        this.max = new Date(
            new Date().getFullYear() + 2,
            new Date().getMonth(),
            new Date().getDate()
        )
            .toISOString()
            .substr(0, 10);
    },
};
</script>

<style>
</style>
