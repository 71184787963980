<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
        @click:outside="closeDialog"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="navigateBack">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("CreateProcess.newProcess") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-form ref="form">
                <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>{{ $t("CreateProcess.newProcess") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                class="close-button"
                                @click="navigateBack"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0">
                        <v-text-field
                            :label="$t('CreateProcess.processName')"
                            v-model="processName"
                            :rules="requireName"
                            data-cy="processName"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row class="mx-0">
                        <v-autocomplete
                            v-model="template"
                            :items="getTemplates"
                            ref="templatechoice"
                            :item-text="(item) => item.templateName"
                            return-object
                            prepend-icon="mdi-file-outline"
                            :label="$t('CreateProcess.processTemplate')"
                            :no-data-text="$t('CreateReport.Form.NoData')"
                            :rules="requireSelect"
                            class="my-2"
                            @change="changeSelectedTemplate"
                            data-cy="selectTemplate"
                        >
                        </v-autocomplete>
                    </v-row>
                    <v-row class="mx-0">
                        <v-autocomplete
                            v-model="messageGroup"
                            :items="getMessageGroupsSelector"
                            ref="templatechoice"
                            :item-text="(item) => item.name"
                            return-object
                            prepend-icon="mdi-message"
                            :label="$t('CreateProcess.processMessageGroup')"
                            :no-data-text="$t('CreateReport.Form.NoData')"
                            class="my-2"
                            @change="changeSelectedMessageGroup"
                            data-cy="selectMessageGroup"
                        >
                        </v-autocomplete>
                    </v-row>
                    <v-row class="mx-0">

                        <!-- TODO: Use datepicker we already have in fillreport -->

                        <v-dialog
                            @click:outside="modal = !modal"
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    data-cy="selectDate"
                                    :value="formatDate(date)"
                                    :label="$t('CreateProcess.chooseEndDate')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="requireDate"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                locale="fi"
                                v-model="date"
                                scrollable
                                color="primary"
                                class="rounded-lg"
                                :min="min"
                                :full-width="true"
                                :first-day-of-week="1"
                            >
                                <v-spacer></v-spacer>
                                <v-btn class="secondary-button mb-2" @click="closeDatePicker()">
                                    {{ $t("Fillfield.cancelDate") }}
                                </v-btn>
                                <v-btn
                                    data-cy="confirmDate"
                                    class="primary-button mb-2"
                                    @click="$refs.dialog.save(date)"
                                >
                                    {{ $t("Fillfield.confirmDate") }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-row>
                    <v-row
                        class="mt-3 mb-0"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="navigateBack"
                            >
                                {{ $t("AddImgData.cancel") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                data-cy="createProcess"
                                @click="createProcess"
                            >
                                {{ $t("CreateProcess.createProcess") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('CreateProcess.loadingMessage')"
        />
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText=snackbarText />

        <v-dialog v-model="alertDialog" width="400">
            <v-card class="rounded-lg">
                <v-card-text class="pa-4 black--text">
                    {{ $t("CreateProcess.nameAlreadyUsed") }}
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="body-2 font-weight-bold"
                        outlined
                        @click.native="closeAlertdialog"
                        data-cy="closeAlertdialog"
                    >
                        {{ $t("CreateProcess.confirm") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { processesCollection } from '../../fbInitialize';
import { messageGroups, firebaseFunctions } from '../../fbInitialize';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import { processTemplate } from '../../datamodels/datamodels.js';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: {
        BaseLoadingDialog,
        GeneralSnackbar
    },
    data() {
        return {
            snackbarText: '',
            getMessageGroups: [],
            messageGroup: {},
            dialog: false,
            alertDialog: false,
            sendingData: false,
            modal: false,
            date: '',
            min: '',
            processName: '',
            template: {},
            selectedTemplate: {},
            requireName: [
                (v) => (v && v.length > 0) || this.$t('CreateProcess.chooseName'),
            ],
            requireSelect: [
                (v) => (v.template_id && v.template_id.length > 0) ||
                    this.$t('CreateLinkReportFolder.chooseTemplate'),
            ],
            requireDate: [
                (v) => (v && v.length > 0) || this.$t('CreateProcess.chooseEndDate2'),
            ],
        };
    },
    methods: {
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
        closeDatePicker() {
            this.modal = false;
        },
        openDialog() {
            this.selectedTemplate = {};
            this.dialog = true;
        },
        closeDialog() {
            this.date = '';
            this.selectedTemplate = {};
            this.template = {};
            this.processName = '';
            this.dialog = false;
            this.messageGroup = {};
        },
        createProcess() {
            if(Object.keys(this.messageGroup).length === 0 || this.messageGroup.name === 'Ei valintaa'
                || this.messageGroup.name === 'No selection') {
                this.createAndOpenReport();
            } else {
                this.createProcessAPICall();
            }
        },
        async createProcessAPICall() {
            let earliestMessage = Math.max(...this.messageGroup.messages.map(o => o.sendTime));

            let diff = (((new Date(this.date).getTime() / 1000) - (new Date().getTime() / 1000)) / 86400);

            if(diff < earliestMessage) {
                this.snackbarText = this.$t('CreateProcess.chooseLaterSendTime');
                this.$refs.GeneralSnackbar.snackbar = true;
            } else {
                this.sendingData = true;
                let process = {
                    lastDateToFillReport: new Date(this.date).getTime() / 1000,
                    templateId: this.selectedTemplate.template_id,
                    messageGroupId: this.messageGroup.id,
                    processName: this.processName,
                    everyReportReceivers: [],
                    fillers: []
                };

                const processId = await firebaseFunctions.httpsCallable('createProcessCallable')(process);

                const processFetch = await processesCollection.doc(processId.data).get();
                const processData = processFetch.data();

                this.$store
                    .dispatch('addCurrentProcess', processData)
                    .then(() => {
                        this.sendingData = false;
                        this.$router.push({
                            name: 'process',
                            params: { processId: processData.id }
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        createAndOpenReport() {
            if (this.$refs.form.validate()) {
                this.sendingData = true;
                if (this.$refs.form.validate()) {
                    var templateClone = {};
                    templateClone = _.cloneDeep(processTemplate);
                    templateClone['templateId'] = this.selectedTemplate.template_id;
                    templateClone['title'] = this.processName;
                    templateClone['endDate'] =  new Date(this.date).getTime() / 1000; // Epochseconds
                    templateClone['createdAt'] = Math.round(
                        new Date().getTime() / 1000
                    );  // Epochseconds
                    templateClone['organizationId'] = this.$store.state.userProfile.organization;
                    this.$store
                        .dispatch('addProcess', templateClone)
                        .then((result) => {
                            this.sendingData = false;
                            this.$router.push({
                                name: 'process',
                                params: { processId: result }
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }

            }
        },
        changeSelectedTemplate(obj) {
            this.selectedTemplate = obj;
        },
        navigateBack() {
            this.closeDialog();
        },
        closeAlertdialog() {
            this.alertDialog = false;
        },
        changeSelectedMessageGroup(obj) {
            this.messageGroup = obj;
        }
    },
    computed: {
        ...mapGetters(['getTemplates']),
        getMessageGroupsSelector() {
            let noSelection = { name: this.$t('CreateProcess.noSelection')};
            let selection = this.getMessageGroups;
            selection.push(noSelection);
            return selection;
        }
    },
    created() {
        this.min = new Date().toISOString().substr(0, 10);

        messageGroups
            .where('organizationId', '==', this.$store.state.userProfile.organization)
            .where('firebaseDeleted', '==', false)
            .get().then((qs) => {
                const data = qs.docs.map((doc) => {
                    let result = doc.data();
                    result.id = doc.id;
                    return result;
                });
                this.getMessageGroups = data;
            });
    }
};
</script>
