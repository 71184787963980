<template>
    <v-container
        fill-height
        :style="[$vuetify.breakpoint.mdAndUp ?
            { background: $vuetify.theme.themes.light.primary } : { background: '#fff' }]"
        fluid
    >
        <v-row align="center" justify="center">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'" class="px-0">
                <v-row align="center" justify="center" class="bg-white">
                    <v-card class="rounded-lg px-4" width="400">
                        <v-card-text>
                            <v-container fluid class="pa-0 ma-0 h1-26">
                                <v-row class="mx-0 mt-0 mb-12 h2-18">
                                    <v-img
                                        src="../../public/formupWebflow.png"
                                        max-width="160"
                                    >
                                    </v-img>
                                </v-row>
                                <h1 class="text-primary mb-7">{{ $t("Login.header") }}</h1>
                                <form @submit.prevent>
                                    <v-flex xs12>
                                        <v-row class="ma-0 h2-16 pb-3">
                                            <h2 class="text-primary">{{ $t("Login.Form.email") }}</h2>
                                        </v-row>
                                        <v-text-field
                                            data-cy="email"
                                            name="email"
                                            type="email"
                                            id="email"
                                            v-model="email"
                                            :label="$t('Login.fillEmail')"
                                            required
                                            flat
                                            solo
                                            background-color="#E8E8E8"
                                            placeholder=" "
                                            persistent-placeholder
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-row class="ma-0 h2-16 pb-3">
                                            <h2 class="text-primary">{{ $t("Login.Form.password") }}</h2>
                                        </v-row>
                                        <v-text-field
                                            data-cy="password"
                                            name="password"
                                            type="password"
                                            id="password"
                                            v-model="password"
                                            :label="$t('Login.fillPassword')"
                                            required
                                            flat
                                            solo
                                            background-color="#E8E8E8"
                                            placeholder=" "
                                            persistent-placeholder
                                        ></v-text-field>
                                    </v-flex>
                                    <v-card-actions class="pb-3 px-0">
                                        <v-btn
                                            data-cy="login"
                                            type="submit"
                                            @click="login()"
                                            class="primary-button w-100 py-3"
                                        >{{ $t("Login.Form.signIn") }}
                                        </v-btn>
                                    </v-card-actions>
                                    <v-btn
                                        data-cy="forgotPassword"
                                        block
                                        class="secondary-button"
                                        @click="showResetPassword"
                                    >
                                        {{ $t("Login.forgotPassword") }}
                                    </v-btn>
                                    <v-layout row class="pt-6 ma-0" v-show="errMsg != ''">
                                        <v-flex xs12>
                                            <v-alert type="error" rounded>
                                                {{ errMsg }}
                                            </v-alert>
                                        </v-flex>
                                    </v-layout>
                                </form>
                                <v-row justify="center" class="ma-0">
                                    <LocaleSwitcher class="mt-7" />
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
            <v-col cols="6" v-if="$vuetify.breakpoint.mdAndUp">
            </v-col>
        </v-row>
        <BaseLoadingDialog
            :sendingData="loggingProcessOngoing"
            :msg="$t('Login.loadingMessage')"
        >
        </BaseLoadingDialog>
    </v-container>
</template>

<script>
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import router from '../router';
import LocaleSwitcher from '../components/LocaleSwitcher.vue';

export default {
    components: {
        BaseLoadingDialog,
        LocaleSwitcher,
    },
    data() {
        return {
            password: '',
            email: '',
            errMsg: '',

            loggingProcessOngoing: false,
        };
    },
    methods: {
        async login() {
            if (this.loggingProcessOngoing) {
                return;
            }
            this.loggingProcessOngoing = true;
            this.errMsg = '';
            try {
                await this.$store.dispatch('login', {
                    email: this.email,
                    password: this.password,
                });
                if (this.$store.state.userProfile.role === 'admin') {
                    this.$store.dispatch('fetchAll');
                    router.push('/dashboard');
                } else {
                    this.$store.dispatch('fetchAll');
                    router.push('/sharedfolders');
                }
            } catch (e) {
                console.error(e);
                this.loggingProcessOngoing = false;
                this.errMsg = this.$t('Login.errorMessage');
            }
        },
        showResetPassword() {
            router.push('forgotpassword');
        },
    },
    created() {
        let lang = navigator.language.slice(0, 2);
        let langs = [
            process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').map((data) => {
                return data;
            }),
        ];
        const result = langs[0].includes(lang);
        if (result) {
            this.$store.dispatch('setLocale', lang);
        } else {
            this.$store.dispatch(
                'setLocale',
                process.env.VUE_APP_I18N_FALLBACK_LOCALE
            );
        }
    },
};
</script>
