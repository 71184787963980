import Text from './text';
import TextInput from './textInput';
import ImageField from './image';
import FileField from './file';
import Choice from './choice';
import Signature from './signature';
import CommentField from './comment';
import addImages from './addImages';
import DateField from './date';
import TableField from './table';
import Slider from './slider';

export const fields = {
    'text': Text,
    'textfield': Text,
    'textinput': TextInput,
    'image': ImageField,
    'staticfile': FileField,
    'file': FileField,
    'choice': Choice,
    'signature': Signature,
    'date': DateField,
    'comment': CommentField,
    'addimages': addImages,
    'table': TableField,
    'slider': Slider,
};
