import {Component} from '../../../component';

class SectionExplanation extends Component {
    code() {
        return {
            text: `${this.args.explanation}`,
            style: 'SectionExplanationStyle',
        };
    }
}

export default SectionExplanation;
