<template>
    <div class="wrapper">
        <v-container>
            <v-layout fill-height>
                <v-flex class="xs12 sm9 mx-auto mt-8">
                    <SectionIterator
                        :sections="this.$store.state.currentReport.sections"
                        :autoSave="true"
                        ref="sectionIterator"
                        @saveField="autoSaveData"
                        @inputChanged="setNotSavedMode"
                    />

                    <v-btn
                        block
                        class="rounded-lg mt-6 mb-2"
                        height="50"
                        color="primary"
                        :disabled="saved"
                        :loading="autosaving"
                        @click="autoSaveData"
                    >
                        {{ saved ? $t("Fillreport.thisReportIsSavedAutomatically") : $t("Fillreport.save") }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import SectionIterator from '../components/FillReport/SectionIterator.vue';

export default {
    components: {
        SectionIterator,
    },
    data() {
        return {
            saved: true,
            autosaving: false,
            pendingUpdate: false
        };
    },
    methods: {
        NavigateBack() {
            this.$router.go(-1);
        },
        setNotSavedMode() {
            this.saved = false;
        },
        async autoSaveData() {
            this.pendingUpdate = true;
            if(this.autosaving) {
                return;
            }
            try {
                this.autosaving = true;
                while (this.pendingUpdate) {
                    this.pendingUpdate = false;
                    await this.$store.dispatch('updateReport');
                }
                this.saved = true;
            } catch (e) {
                console.log(e);
            }
            finally {
                this.autosaving = false;
            }
        },
    },
    destroyed() {
        // Clear current report
        this.$store.commit('clearCurrentReport');
    },
};
</script>
