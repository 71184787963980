<template>
    <div class="wrapper">
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :pageHeader="$t('Accountmanagement.header')"
        />
        <v-container
            class="px-7 py-7 pb-0 container"
            :class="{ 'pl-10 pb-7 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-row
                class="mx-0"
                :class="{
                    'h1-26 mb-0': !$vuetify.breakpoint.mdAndUp,
                    'mb-3': $vuetify.breakpoint.mdAndUp }"
            >
                <h1>{{ $t("Accountmanagement.header") }}</h1>
            </v-row>
            <v-tabs v-model="selectedTab" class="mt-4" background-color="#F9F9F9">
                <v-tabs-slider class="v-tabs-slider-wrapper"></v-tabs-slider>
                <v-tab v-for="item in tabItems" :key="item.text">{{ item.text }}</v-tab>
            </v-tabs>

            <hr class="divider" />
            <div>
                <v-tabs-items v-model="selectedTab" class="tabs-items">
                    <v-tab-item v-for="item in tabItems" :key="item.text">
                        <OwnProfile v-if="item.component === 'ownProfile'" />
                        <UserManagement v-if="item.component === 'userManagement'" />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-container>
    </div>
</template>

<script>
import OwnProfile from '../components/Profile/OwnProfile.vue';
import UserManagement from '../components/Profile/UserManagement.vue';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';

export default {
    components: {
        OwnProfile,
        MobileNavigation,
        UserManagement,
    },
    data() {
        return {
            selectedTab: null,
            tabItems: [
                {
                    text: this.$t('UserManagement.Tabitems.myInfo'),
                    component: 'ownProfile',
                },
                {
                    text: this.$t('UserManagement.Tabitems.userManagement'),
                    component: 'userManagement',
                },
            ]
        };
    },
};
</script>

