import {Component} from '../../../../component';

class SectionTitleStyle extends Component {
    code() {
        return {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 0],
        };
    }
}

export default SectionTitleStyle;
