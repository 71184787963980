import {Field} from '../../../field';
import ImageUtils from '../utils/imageUtils';
import {imageLoader} from '../utils/imageLoader';
import {asComponent} from '../components/helpers/asComponent';
import {GridLayout} from './layouts/layout.grid';
import ImageStyle from './styles/style.image';


export class AddImages extends Field {
    imgIds = [];

    code() {
        let gridLayout = asComponent(
            GridLayout,
            {
                items: this.#images,
                gridWidth: 2,
            }
        );
        return gridLayout;
    }

    get #images() {
        let images = [];

        for(const imageUrl of this.data) {
            let imageUtil = new ImageUtils(imageUrl);
            images.push(
                {
                    image: imageUtil.imageGarbageCollectionID,
                    ...asComponent(ImageStyle, {height: imageUtil.height, width: imageUtil.width}),
                }
            );
            this.imgIds.push(imageUtil);
        }
        return images;
    }

    after(pdf) {
        for(const imageID of this.imgIds) {
            imageLoader(pdf, imageID);
        }
    }

    get isEmpty() {
        return this.data.length === 0;
    }
}

export default AddImages;
