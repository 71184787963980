<template>
    <v-dialog class="ma-0 pa-0" v-model="showDialog" width="400">
        <v-card class="rounded-lg pa-4">
            <v-row class="h1-26 ma-0 px-4">
                <h1>{{ $t("AddUser.error") }}</h1>
            </v-row>
            <v-row class="mx-0 mt-4 px-4">
                <p> {{ $t("AddUser.addUserFailed") }}</p>
            </v-row>
            <v-row class="mx-0 mb-4 px-4">
                <p>
                    {{ $t("AddUser.checkEmailAndPhone") }}
                </p>
            </v-row>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                    data-cy="confirmAction"
                    class="primary-button"
                    @click.native="closeAlertdialog"
                >
                    {{ $t("AddUser.confirmOk") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props:['alertDialog'],
    computed: {
        showDialog: {
            get() {
                return this.alertDialog;
            },
            set(value) {
                this.$emit('showAlertDialog', value);
            },
        },
    },
    methods: {
        closeAlertdialog() {
            this.showDialog = false;
        }
    },
};
</script>

<style>

</style>
