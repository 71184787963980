<template>
    <v-container class="pa-0">
        <v-dialog v-model="inspectDialog" @click:outside="closeInspectDialog">
            <v-card class="pa-4">
                <v-layout fill-height>
                    <v-flex>
                        <v-row class="ma-4" align="center" justify="center">
                            <v-spacer></v-spacer>
                            <v-btn x-large icon @click="closeInspectDialog" data-cy="closeInspectDialog">
                                <v-icon color="black">mdi-close</v-icon>
                            </v-btn>
                        </v-row>

                        <v-row class="px-4 pb-2" align="center" justify="center">
                            <v-col cols="11" class="pa-0" align="center" justify="center">
                                <v-text-field :label="$t('InspectReport.reportName')" v-model="report.name" disabled>
                                </v-text-field>
                            </v-col>
                            <v-col cols="1" class="pa-0"> </v-col>
                        </v-row>

                        <v-card
                            width="100%"
                            class="rounded-lg pa-2 my-2"
                            v-for="(section, index) in report.sections"
                            :key="index"
                        >
                            <FillSection
                                :section="section"
                                :sectionNumber="index + 1"
                                :subsection="false"
                            />
                            <v-overlay :value="true" absolute opacity="0"></v-overlay>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import FillSection from './FillReport/FillSection.vue';
// THIS COMPONENT IS ONLY USED IN AUTOLINKS, DELETE WHEN AUTOLINKS IS DELETED
// TODO DELETE THIS WHEN AUTOLINK FEATURE IS DELETED

export default {
    props: ['inspectDialog', 'report'],
    components: {
        FillSection,
    },
    methods: {
        closeInspectDialog() {
            this.$emit('closeInspectDialog');
        },
    },
};
</script>

<style>
</style>
