export class PDFDocument {
    docDefinition;
    report;
    metaData;
    pageSize = 'A4';
    pageMargins = [40, 70, 40, 40];
    fields = {};

    constructor(report, metaData) {
        this.report = report;

        this.docDefinition = {
            pageSize: this.pageSize,
            pageMargins: this.pageMargins,
            content: [],
            images: {},
        };

        this.metaData = metaData;
    }

    build() {}

    initialStyle() {}

    addContent(data) {
        this.docDefinition.content.push(data);
    }

    addToDocDefinition(data) {
        this.docDefinition = {...this.docDefinition, ...data};
    }

    getField(fieldName) {
        const isExistingField = fieldName in this.fields;

        if(!isExistingField) {
            console.warn('[PDFTools/pdf.js] Unknown Field:', fieldName);
        }

        return isExistingField ? this.fields[fieldName] : null;
    }
}
