<template>
    <div class="wrapper">
        <v-container
            class="px-7 py-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-layout class="ma-0 pa-0">
                <v-flex>
                    <v-row class="mx-0 mb-4" :class="{ 'h1-26': !$vuetify.breakpoint.mdAndUp }">
                        <h1> Tarkastukset</h1>
                    </v-row>

                    <div>
                        <v-data-table
                            :headers="headers1"
                            :items="inspections"
                            hide-default-footer
                            disable-pagination
                            @click:row="openItem"
                        >
                            <template v-slot:item.address="{ item }">
                                <span data-cy="userName" class="pl-2">
                                    {{ item.address + " " + item.apartment }}
                                </span>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <v-chip
                                    :color="item.status === 'Kesken' ?
                                        '#FAFFDE' : '#E3FFDE'" >
                                    <v-icon v-if="item.status === 'Kesken'" class="mr-2">mdi-clock</v-icon>
                                    <v-icon v-else class="mr-2">mdi-checkbox-marked-circle</v-icon>
                                    {{item.status}}
                                </v-chip>
                            </template>
                            <template v-slot:item.openButton="{ }">
                                <v-chip color="#DEF3FF" >
                                    Avaa raportti
                                    <v-icon class="ml-2">mdi-arrow-right-bold-box</v-icon>
                                </v-chip>
                            </template>
                        </v-data-table>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import { maintenanceReportsDEMO, maintenanceTableDEMO } from '../fbInitialize';

export default {
    components: {
    },
    data() {
        return {
            inspections: []
        };
    },
    methods: {
        navigateBack() {
            this.$router.push({ name: 'dashboard' });
        },
        openItem(item) {
            this.$router.push({
                name: 'FillMaintenaceReport',
                params: { FillMaintenaceReportId: item.id }
            });
        },
    },
    computed: {
        headers1() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return [
                    {
                        text: 'Kiinteistö',
                        align: 'start',
                        value: 'name',
                    },
                    {
                        text: 'Huoltoyhtiö',
                        value: 'maintenanceCompany',
                    },
                    {
                        text: 'Isännöitsijä',
                        value: 'maintenanceManager',
                    },
                    {
                        text: 'Pisteet',
                        value: 'points',
                    },
                    {
                        text: 'Reportin status',
                        value: 'status',
                    },
                    {
                        text: 'Sulkeutuu',
                        value: 'endDate',
                    },
                ];
            }
            else {
                return [
                    {
                        text: 'Kiinteistö',
                        align: 'start',
                        value: 'name',
                    },
                    {
                        text: 'Huoltoyhtiö',
                        value: 'maintenanceCompany',
                    },
                    {
                        text: 'Isännöitsijä',
                        value: 'maintenanceManager',
                    },
                    {
                        text: 'Pisteet',
                        value: 'points',
                    },
                    {
                        text: 'Reportin status',
                        value: 'status',
                    },
                    {
                        text: 'Sulkeutuu',
                        value: 'endDate',
                    },
                    {
                        text: '',
                        value: 'openButton',
                    },
                ];
            }
        }
    },
    async created() {
        window.scrollTo(0, 0);
        const tableFetch = await maintenanceTableDEMO.doc(this.$route.params.MaintenanceReportsId).get();
        const tableData = tableFetch.data();

        for (const id of tableData.reports) {
            const inspectionFetch = await maintenanceReportsDEMO.doc(id).get();
            const inspection = inspectionFetch.data();
            this.inspections.push(inspection);
        }
    }
};
</script>
