<template>
    <div class="wrapper">
        <v-container>
            <v-layout fill-height>
                <v-flex class="xs12 sm9 mx-auto">
                    <v-row class="px-4 pb-2" align="center" justify="center">
                        <v-col class="pa-0" align="center" justify="center" cols="10">
                        </v-col>
                        <v-col cols="2" align="right">
                            <v-btn icon large class="my-auto mr-2" @click="updatePage()">
                                <v-icon> mdi-refresh </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <SectionIterator
                        :sections="this.$store.state.currentReport.sections"
                        :autolink="true"
                        :logoUrl="this.$store.state.currentReport.logoUrl"
                        ref="sectionIterator"
                        :autoSave="true"
                        @saveField="updateReport"
                    />

                    <PDFGenerator ref="PDFGenerator" />
                    <ValidateForm ref="validateForm" />
                    <OkExitDialog ref="OkExitDialog"> </OkExitDialog>
                    <LoginDialog ref="popupLogin" @loginSuccessful="getLinkReport" />
                    <BaseLoadingDialog
                        :sendingData="InitialLoad"
                        :msg="$t('FillLinkReport.loadingMessage')"
                    />
                    <v-snackbar
                        :value="isLimitOfImagesInCurrentReport"
                        width="600px"
                        timeout="-1"
                        color="orange"
                        :top="!$vuetify.breakpoint.mdAndUp"
                    >
                        <v-icon>mdi-alert-outline</v-icon>
                        {{ $t("FillProcessReport.ImageLimitExceedeInfo") }}

                    </v-snackbar>
                    <v-btn
                        data-cy="sendReport"
                        block
                        @click="validateFormAndOpenSendDialog"
                        class="rounded-lg mt-6 mb-2 mx-0"
                        height="50"
                        color="primary"
                        dark
                        :disabled="isLimitOfImagesInCurrentReport"
                    >
                        {{ $t("FillLinkReport.shareReport") }}
                    </v-btn>
                </v-flex>
            </v-layout>



            <v-dialog
                v-model="sendDialog"
                :fullscreen="!$vuetify.breakpoint.mdAndUp"
                hide-on-leave
                class="pa-2"
                width="600"
            >
                <v-card
                    class="border-0"
                    :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
                    width="600"
                >
                    <v-row
                        v-if="!$vuetify.breakpoint.mdAndUp"
                        class="modal-header-mobile h1-16 mx-0 items-center"
                    >
                        <v-col cols="2">
                            <v-icon color="primary" class="icon-plus" @click="sendDialog = false">
                                mdi-chevron-left
                            </v-icon>
                        </v-col>
                        <v-col cols="8" align="center">
                            <h1>{{ $t("FillLinkReport.selectOtherReceiver") }}</h1>
                        </v-col>
                        <v-col cols="2">
                        </v-col>
                    </v-row>
                    <v-container fluid :class="{ 'pb-8 px-5 pt-header': !$vuetify.breakpoint.mdAndUp }">
                        <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                            <v-col cols='10'>
                                <h1>{{ $t("FillLinkReport.selectOtherReceiver") }}</h1>
                            </v-col>
                            <v-col cols="2">
                                <v-icon
                                    class="close-button"
                                    @click="sendDialog = false"
                                >
                                    mdi-close
                                </v-icon>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0"  :class="{ 'mt-3': !$vuetify.breakpoint.mdAndUp }">
                            <v-autocomplete
                                v-model="participants"
                                :items="$store.state.currentReport.optionalReceivers"
                                multiple
                                :label="$t('FillLinkReport.receiver')"
                                class="my-2"
                                ref="optionalRecieverChoice"
                                :item-text="(item) => fullName(item)"
                                :item-value="(item) => item"
                            ></v-autocomplete>
                        </v-row>
                        <v-row
                            class="mb-0"
                            :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp,
                                     'mt-12': $vuetify.breakpoint.mdAndUp}"
                        >
                            <v-col
                                :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                                :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                            >
                                <v-btn
                                    class="secondary-button"
                                    :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                    @click="sendDialog = false"
                                >
                                    {{ $t("CreateFolder.cancel") }}
                                </v-btn>
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                                align="end"
                                :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                            >
                                <v-btn
                                    class="primary-button"
                                    :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                    @click="sendEmail"
                                    :disabled="isSendButtonDisabled"
                                >
                                    {{ $t("FillLinkReport.send") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
            <v-dialog v-model="reportFilledDialog" fullscreen>
                <v-card color="white" height="100%" align="center">
                    <h2 class="mx-auto" style="font-size: 40px">Formup</h2>
                    <h1 class="font-weight-bold title">
                        {{ $t("FillLinkReport.reportSent") }}
                    </h1>
                    <v-btn
                        @click="reloadPage()"
                        height="60"
                        color="primary"
                        class="rounded-lg ma-2 mt-4"
                        dark
                        data-cy="fillNewReport"
                    >
                        {{ $t("FillLinkReport.fillNewReport") }}
                    </v-btn>
                    <v-btn
                        @click="downloadPDF"
                        class="rounded-lg ma-2 mt-4"
                        height="60"
                        color="white"
                    >
                        {{ $t("FillLinkReport.loadReport") }}
                    </v-btn>
                </v-card>
            </v-dialog>

            <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('FillLinkReport.fillRequired')" />

        </v-container>
    </div>
</template>
<script>
import OkExitDialog from '../components/OkExitDialog.vue';
import PDFGenerator from '../components/FillReport/PDFGenerator.vue';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import SectionIterator from '../components/FillReport/SectionIterator.vue';
import LoginDialog from '../components/LoginDialog.vue';
import firebase from 'firebase/app';
import { firebaseFunctions } from '../fbInitialize';
import { mapGetters } from 'vuex';
import ValidateForm from '../components/FillReport/ValidateForm.vue';
import GeneralSnackbar from '../components/GeneralizedComponents/GeneralSnackbar.vue';
import { getDynamicName } from '../componentScripts/dynamicName';

export default {
    components: {
        OkExitDialog,
        PDFGenerator,
        BaseLoadingDialog,
        SectionIterator,
        LoginDialog,
        ValidateForm,
        GeneralSnackbar,
    },
    data() {
        return {
            imageAddHelper: {},
            report: {},
            doneDialog: false,
            sendingData: false,
            InitialLoad: false,
            sendDialog: false,
            selectedReceiver: '',
            reportFilledDialog: false,
            participants: [],
        };
    },
    methods: {
        downloadPDF() {
            if (!this.loading) {
                this.loading = true;
                this.$refs.PDFGenerator.downloadPDF(
                    this.$store.state.currentReport,
                    this.$store.state.currentReport.namingFieldId
                );
                this.loading = false;
            }
        },
        reloadPage() {
            this.emptyReport();
            this.reportFilledDialog = false;
        },
        async sendEmail() {
            this.InitialLoad = true;
            if (
                typeof this.$store.state.currentReport.reportNamingFieldId !==
                'undefined'
            ) {
                // make createdAt timeStamp
                this.$store.state.currentReport.createdAt = Date.now() / 1000;

                // get data from the field
                // Go through the report
                let newName = getDynamicName(
                    this.$store.state.currentReport,
                    this.$store.state.currentReport.reportNamingFieldId
                );
                if (newName != '') {
                    this.$store.state.currentReport.name = newName;
                }
            }
            let pdf = await this.$refs.PDFGenerator.generatePDF(
                this.$store.state.currentReport
            );
            pdf.getBase64(async (file) => {
                let receivers = this.$store.state.currentReport.defaultReceivers;
                if (this.selectedReceiver != '') {
                    receivers.push(this.selectedReceiver);
                }
                for (let participant of this.participants) {
                    receivers.push(participant.email);
                }
                let sharePDF = firebaseFunctions.httpsCallable('sendLinkReport');
                await sharePDF({
                    emailReceivers: receivers,
                    attachment: file,
                    reportName:
                        this.$store.state.currentReport.name +
                        ' ' +
                        new Date().toLocaleString(),
                    report: this.$store.state.currentReport,
                })
                    .then(() => {
                        this.sendDialog = false;
                        this.InitialLoad = false;
                        this.reportFilledDialog = true;
                    })
                    .catch((error) => {
                        // Too big file
                        console.log(error);
                        // Getting the Error details.
                        console.log(error.code);
                        console.log(error.message);
                        this.sendDialog = false;
                        this.InitialLoad = false;
                    });
            });
        },
        validateFormAndOpenSendDialog() {
            if (
                this.$refs.validateForm.isRequiredInputFilled(
                    this.$store.state.currentReport.sections
                )
            ) {
                // Opens dialog if optional receivers exists
                if(this.$store.state.currentReport.optionalReceivers) {
                    if (this.$store.state.currentReport.optionalReceivers.length > 0) {
                        this.sendDialog = true;
                    } else {
                        this.sendEmail();
                    }
                }
                else {
                    this.sendEmail();
                }
            } else {
                this.$refs.GeneralSnackbar.snackbar = true;
            }
        },
        updateReport() {
            this.$store.dispatch('addCurrentReport', this.$store.state.currentReport);
        },
        emptyReport() {
            this.$store.state.currentReport = {};
            this.getLinkReport();
        },
        async updatePage() {
            let deleteApproved = await this.$refs.OkExitDialog.open(
                this.$t('FillAutolinkReport.clearDataAndRefresh'),
                this.$t('FillAutolinkReport.areYouSureConfirm'),
                this.$t('FillAutolinkReport.yes'),
                this.$t('FillAutolinkReport.no')
            );
            if (deleteApproved) {
                // Delete field
                this.emptyReport();
            }
        },
        getLinkReport() {
            if (
                this.$store.state.currentReport === {} ||
                this.$store.state.currentReport.linkFolderId !==
                this.$route.params['folderId']
            ) {
                this.InitialLoad = true;
                this.$store
                    .dispatch('fetchLinkReport', this.$route.params['folderId'])
                    .then(() => {
                        this.$i18n.locale = this.$store.state.currentReport.locale;
                        this.InitialLoad = false;
                    });
            } else {
                this.InitialLoad = false;
            }
        },
        fullName(item) {
            let returnable = '';
            if (item.name) {
                returnable = returnable + item.name + ' ';
            }
            if (item.name) {
                returnable = returnable + item.email;
            }
            return returnable;
        },
    },
    computed: {
        ...mapGetters([
            'isLimitOfImagesInCurrentReport'
        ]),
        isSendButtonDisabled() {
            const isConditionMet = this.$store.state.currentReport.organizationId === 'Kej83rPdJgcyQGXZBKXb';
            const hasParticipantsSelected = this.participants && this.participants.length > 0;

            return isConditionMet && !hasParticipantsSelected;
        },
    },
    destroyed() {
        this.emptyReport();
    },
    mounted() {
        if (
            this.$route.params['command'] == 'redirectLogin' &&
            !firebase.auth().currentUser
        ) {
            this.$refs.popupLogin.openDialog();
        } else {
            this.getLinkReport();
        }
    },
};
</script>
