<template>
    <div class="wrapper">
        <v-container
            class="px-7 py-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
            v-if="loadingData"
        >
            <v-skeleton-loader
                type="article, card-heading, table: table-heading, table-tbody,  table-thead: heading@5"
            ></v-skeleton-loader>
        </v-container>
        <v-container
            class="px-7 py-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
            v-if="!loadingData"
        >

            <!-- TITLE (TODO: Into own component) -->

            <v-row class="mx-0" :class="{ 'h1-26': !$vuetify.breakpoint.mdAndUp }">
                <h1 v-if="this.use === 'regular'">{{ $t("ReportTable.reports") }}</h1>
                <h1 v-if="this.use === 'shared'">
                    {{ $t("ReportTable.sharedFolders") }}
                </h1>
            </v-row>

            <!-- BREADCRUMBS (TODO: Into own component) -->

            <v-container
                class="mt-5 mb-2 px-0"
                v-if="folderPath.length !== 0 && folderPath"
            >
                <v-row class="mx-0">
                    <v-btn
                        @click="navigateToRootFolder"
                        elevation="0"
                        class="py-0 px-1 breadcrumb-button"
                    >
                        <h2 class="breadcrumbs">
                            {{ $t("ReportTable.reports") + " " }}
                        </h2>
                    </v-btn>
                    <div v-for="pathItem in folderPath" :key="pathItem.id">
                        <v-icon class="breadcrumb-gray">mdi-chevron-right</v-icon>
                        <v-btn
                            @click="navigateToFolder(pathItem)"
                            elevation="0"
                            class="py-0 px-1 breadcrumb-button"
                        >
                            <h2
                                :class="
                                    'breadcrumbs ' +
                                        (currentFolder.id === pathItem.id ? 'active' : '')
                                "
                            >
                                {{ pathItem.name }}
                            </h2>
                        </v-btn>
                    </div>
                </v-row>
            </v-container>

            <!-- SEARCH BAR (TODO: Into own component) -->

            <v-row class="mx-0 my-3">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'" class="pa-0">
                    <v-text-field
                        class="search"
                        v-model="search"
                        :label="$t('ReportTable.search')"
                        single-line
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        filled
                        flat
                        solo
                    >
                    </v-text-field>
                </v-col>

                <!-- CREATE NEW -BUTTON (TODO: Into own component) -->

                <v-col
                    align="end"
                    v-if="showCreateButton && $vuetify.breakpoint.mdAndUp"
                >
                    <v-menu
                        offset-y
                        class="text-center"
                        v-if="currentFolder.use !== 'AutoFolder'"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="primary-button mt-2"
                                data-cy="createNewItem"
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-icon class="icon-plus">mdi-plus-circle-outline</v-icon>
                                {{ $t("ReportTable.createNew") }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item data-cy="selectReport" @click="openCreateDialog">
                                <v-list-item-icon>
                                    <v-icon class="mr-2">mdi-file-document</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ $t("ReportTable.report") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item data-cy="selectFolder" @click="createFolder">
                                <v-list-item-icon>
                                    <v-icon> mdi-folder </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ $t("ReportTable.folder") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item data-cy="selectAutoFolder" @click="createLinkFolder">
                                <v-list-item-icon>
                                    <v-icon> mdi-folder-plus </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ $t("ReportTable.autofolder") }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <!-- Checks whether user is currently in autofolder -->

                    <v-btn
                        data-cy="fillAutoReport"
                        v-if="currentFolder.use === 'AutoFolder'"
                        @click="fillAutoReport"
                        class="primary-button mr-2 mt-4"
                    >
                        {{ $t("ReportTable.fillReport") }}
                    </v-btn>
                    <v-btn
                        data-cy="openAutoFolderSettings"
                        v-if="currentFolder.use === 'AutoFolder'"
                        @click="openSettings"
                        class="outline-primary-icon-only mt-4"
                    >
                        <v-icon color="primary">mdi-cog-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <!-- CREATE NEW -BUTTON ON MOBILE DEVICE (TODO: Into own component) -->

            <v-btn
                class="mobile-add"
                data-cy="createNewItem"
                v-on="on"
                v-bind="attrs"
                v-if="!$vuetify.breakpoint.mdAndUp && showCreateButton"
                @click="openMobileAdd"
            >
                <v-icon class="icon-plus mr-0">mdi-plus</v-icon>
            </v-btn>

            <!-- SETTINGS BUTTON ON MOBILE DEVICE (TODO: Into own component) -->

            <v-btn
                class="mobile-add"
                v-on="on"
                v-bind="attrs"
                v-if="
                    !$vuetify.breakpoint.mdAndUp && currentFolder.use === 'AutoFolder'
                "
                @click="openMobileSettingsDialog"
            >
                <v-icon class="icon-plus mr-0">mdi-cog-outline</v-icon>
            </v-btn>

            <!-- PARTICIPANTS (TODO: Into own component) -->

            <v-row
                class="mx-0"
                :class="{ 'mt-5 h2-18': !$vuetify.breakpoint.mdAndUp }"
                v-if="!currentFolder.root"
            >
                <h2>{{ $t("ReportTable.participants") }}</h2>
            </v-row>
            <v-row class="mx-0 py-3 mb-3" v-if="!currentFolder.root">
                <div
                    v-for="participant in currentFolder.participantProfiles"
                    :key="participant.id"
                    @click="openParticipants"
                >
                    <v-avatar :color="participant.avatarColor.bg" size="40" class="mr-2 mb-2 hover">
                        <span
                            v-if="participant.surname !== '' && participant.surname !== undefined"
                            class="font-weight-regular"
                            :style="'color: ' + participant.avatarColor.text"
                        >{{
                            participant.firstname[0].toUpperCase() +
                                participant.surname[0].toUpperCase()
                        }}</span
                        >
                        <span
                            v-else
                            class="font-weight-regular"
                            :style="'color: ' + participant.avatarColor.text"

                        >
                            {{
                                participant.firstname[0].toUpperCase() +
                                    participant.firstname[1].toUpperCase()
                            }}
                        </span>
                    </v-avatar>
                </div>
                <div
                    v-if="$route.name === 'dashboard' || getRole === 'admin'"
                    data-cy="openParticipants"
                    class="avatar-add"
                    @click="openParticipants"
                >
                    <v-icon>mdi-plus</v-icon>
                </div>
            </v-row>

            <!-- SHARE FOLDER LINK IN RECIEVER FOLDER (TODO: Into own component) -->

            <v-row
                v-if="currentFolder.use === 'AutoFolder'"
                class="mx-0 mt-5"
                :class="{ 'h2-18': !$vuetify.breakpoint.mdAndUp }"
            >
                <h2>{{ $t("ReportTable.linkToFillReport") }}</h2>
            </v-row>
            <v-row v-if="currentFolder.use === 'AutoFolder'" class="mx-0 mb-3">
                <v-card
                    @click="copyLink"
                    data-cy="copyAutolink"
                    class="link-copy mt-3"
                    elevation="0"
                >
                    <v-card-text>
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : '8'">
                                {{ getLink }}
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.mdAndUp ? '2' : '4'"
                                :class="{ 'pr-4': !$vuetify.breakpoint.mdAndUp }"
                                :align="$vuetify.breakpoint.mdAndUp ? '' : 'end'"
                            >
                                <v-icon> mdi-content-copy </v-icon>
                                {{ $t("ReportTable.copy") }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-row>

            <!-- SELECTIONS SECTION (TODO: Into own component) -->
            <div v-if="selection.length > 0">
                <v-row class="mx-0 mb-0 mt-3">
                    <h2>{{ $t("ReportTable.selections") }}</h2>
                </v-row>
                <v-row class="mx-0 mb-3">
                    <v-btn class="settings-outline mr-2" @click="clearSelection(selection)"
                    ><v-icon class="small-icon mr-1">mdi-minus-box </v-icon
                    ><span> {{ $t("ReportTable.clearSelections") }} </span></v-btn
                    >
                    <v-btn
                        v-if="showFolderMenu || getRole === 'admin' || getRole === 'modifier'"
                        class="settings-outline mr-2"
                        @click="deleteSelection(selection)"
                    ><v-icon class="small-icon mr-1">mdi-delete </v-icon
                    ><span> {{ $t("ReportTable.deleteSelected") }} </span></v-btn
                    >
                    <v-btn
                        v-if="showFolderMenu || getRole === 'admin' || getRole === 'modifier'"
                        class="settings-outline"
                        @click="openMoveDialog(selection)"
                    ><v-icon class="small-icon mr-1">mdi-arrow-right-bold-box </v-icon
                    ><span> {{ $t("ReportTable.moveSelected") }}</span></v-btn
                    >
                    <!-- Coming soon?
            <v-btn class="settings-outline" @click="moveSelection(selection)"
            ><v-icon class="small-icon mr-1">mdi-download </v-icon
            ><span>Lataa valinnat </span></v-btn
            >
            -->
                </v-row>
            </div>

            <!-- DATA TABLE (TODO: Into own component) -->

            <v-container class="px-0">
                <v-data-table
                    :loading="loading"
                    :search="search"
                    :no-data-text="$t('ReportTable.NoData')"
                    :headers="headers"
                    :items="getFolder"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event"
                    class="rounded-lg row-pointer"
                    hide-default-footer
                    @click:row="openItem"
                    :sort-by.sync="sortBy"
                    :sort-desc="true"
                    mobile-breakpoint="0"
                    :show-select="$vuetify.breakpoint.mdAndUp"
                    v-model="selection"
                    item-key="id"
                >
                    <template v-slot:item.type="{ item }">
                        <div
                            v-if="item.type === 'folder' && item.use !== 'AutoFolder'"
                            small
                            text
                        >
                            <v-icon color="primary" left>mdi-folder</v-icon>
                        </div>

                        <div v-if="item.use == 'AutoFolder'" small text>
                            <v-icon color="primary" left>mdi-folder-plus</v-icon>
                        </div>
                        <div v-else-if="item.type == 'report'" small text>
                            <v-icon color="primary" left>mdi-file-document</v-icon>
                        </div>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div>
                            {{ item.name }}
                        </div>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                        {{ formatTime(item.createdAt) }}
                    </template>
                    <template v-slot:item.markedReady="{ item }">
                        <v-icon v-show="item.markedReady" class="icon-success">
                            mdi-check-circle-outline
                        </v-icon>
                    </template>

                    <template v-slot:item.creator="{ item }">
                        <div v-if="item.creator">
                            <v-avatar
                                v-if="item.creator.avatarColor"
                                :color="item.creator.avatarColor.bg"
                                size="40"
                                class="my-3"
                            >
                                <span
                                    v-if="item.creator.surname !== '' && item.creator.surname !== undefined"
                                    class="font-weight-regular"
                                    :style="
                                        'color: ' + item.creator.avatarColor.text
                                    "
                                >{{
                                    item.creator.firstname[0].toUpperCase() +
                                        item.creator.surname[0].toUpperCase()
                                }}</span
                                >
                                <span v-else
                                      class="font-weight-regular"
                                      :style="
                                          'color: ' + item.creator.avatarColor.text
                                      ">
                                    {{
                                        item.creator.firstname[0].toUpperCase() +
                                            item.creator.firstname[1].toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                            <span
                                v-if="item.creator.firstname !== undefined && item.creator.surname !== undefined"
                                data-cy="userName"
                                class="pl-2"
                            >
                                {{ item.creator.firstname + " " + item.creator.surname }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div v-if="item.type == 'folder'">
                            <v-menu v-if="showFolderMenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        data-cy="openFolderDeleteMenu"
                                        v-bind="attrs"
                                        icon
                                        v-on="on"
                                        class="my-2"
                                    >
                                        <v-icon> mdi-dots-vertical </v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="openRenameDialog(item)">
                                        <v-list-item-icon>
                                            <v-icon> mdi-folder-edit </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("ReportTable.rename") }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        data-cy="deleteFolder"
                                        @click="deleteFolder(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon> mdi-delete </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("ReportTable.delete") }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        data-cy="openFolderMoveDialog"
                                        @click="openMoveDialog([item])"
                                    >
                                        <v-list-item-icon>
                                            <v-icon> mdi-arrow-right-bold </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("ReportTable.move") }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div v-else-if="item.type == 'report'">
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        icon
                                        v-on="on"
                                        data-cy="openReportDeleteMenu"
                                        class="my-2"
                                    >
                                        <v-icon> mdi-dots-vertical </v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-if="getRole !== 'viewer'"
                                        data-cy="deleteReport"
                                        @click="deleteReport(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon> mdi-delete </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("ReportTable.delete") }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="getRole !== 'viewer'"
                                        @click="openMoveDialog([item])"
                                    >
                                        <v-list-item-icon>
                                            <v-icon> mdi-arrow-right-bold </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("ReportTable.move") }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="downloadPDF(item)">
                                        <v-list-item-icon>
                                            <v-icon> mdi-download </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ $t("ReportTable.load") }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        :class="{ 'mb-6': !$vuetify.breakpoint.mdAndUp }"
                        v-model="page"
                        :length="pageCount"
                        v-if="pageCount > 1"
                        :total-visible="7"
                    >
                    </v-pagination>
                </div>
            </v-container>
            <MoveFolderReport ref="MoveFolderReport" :clear="clearSelection" :selection="this.selection" />
            <OkCancelDialog ref="OkCancelDialog" />
            <FolderShare ref="FolderShare" />
        </v-container>


        <!-- RENAME DIALOG (TODO: Refactor as a new component) -->

        <v-dialog
            v-model="renameDialog"
            :retain-focus="false"
            :fullscreen="!$vuetify.breakpoint.mdAndUp"
            hide-on-leave
            class="pa-2"
            width="600"
            @click:outside="closeRenameDialog()"
        >
            <v-card
                class="border-0"
                :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            >
                <v-row
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    class="modal-header-mobile h1-16 mx-0 items-center"
                >
                    <v-col cols="2">
                        <v-icon color="primary" class="icon-plus" @click="closeRenameDialog()">
                            mdi-chevron-left
                        </v-icon>
                    </v-col>
                    <v-col cols="8" align="center">
                        <h1>{{ $t("ReportTable.renameHeader") }}</h1>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>
                <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                    <v-form lazy-validation ref="form">
                        <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                            <v-col cols="10">
                                <h1>{{ $t("ReportTable.renameHeader") }}</h1>
                            </v-col>
                            <v-col cols="2">
                                <v-icon
                                    class="close-button"
                                    @click="closeRenameDialog()"
                                >
                                    mdi-close
                                </v-icon>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0">
                            <v-text-field
                                :label="$t('ReportTable.newName')"
                                v-model="newName"
                                required
                                :rules="nameRules"
                                counter="50"
                                @keydown.enter.prevent="updateFolderName"
                            >
                            </v-text-field>
                        </v-row>
                        <v-row
                            class="mt-12 mb-0"
                            :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-col
                                :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                                :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                            >
                                <v-btn
                                    class="secondary-button"
                                    :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                    @click="closeRenameDialog()"
                                >
                                    {{ $t("ReportTable.close") }}
                                </v-btn>
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                                align="end"
                                :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                            >
                                <v-btn
                                    class="primary-button"
                                    :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                    @click="updateFolderName()"
                                >
                                    {{ $t("EditUser.saveChanges") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>

        <!-- MOBILE CREATE NEW -DIALOG

    User chooses which kind of elements she/he wants to create.
    Options: Report, folder, linkFolder
    TODO: Refactor as a new component

    @author: Jere Niemi

    -->

        <v-dialog
            v-model="mobileAdd"
            :retain-focus="false"
            hide-on-leave
            content-class="mobile-new"
            transition="dialog-bottom-transition"
        >
            <v-card class="pa-5 bg-transparent">
                <v-container fluid>
                    <v-form lazy-validation ref="form">
                        <v-row class="mx-0 h1-26" justify="center">
                            <h1>
                                {{ $t("ReportTable.createNew") }}
                            </h1>
                        </v-row>
                        <v-row class="mx-0 mt-10">
                            <v-btn class="white-button mb-4" @click="openCreateDialog">
                                <v-icon color="primary" class="mr-2">mdi-file-document</v-icon>
                                {{ $t("Reports.Actions.newReport") }}
                            </v-btn>
                            <v-btn class="white-button mb-4" @click="createFolder">
                                <v-icon color="primary" class="mr-2">mdi-folder</v-icon>
                                {{ $t("Reports.Actions.newFolder") }}
                            </v-btn>
                            <v-btn class="white-button mb-4" @click="createLinkFolder">
                                <v-icon color="primary" class="mr-2">mdi-folder-plus</v-icon>
                                {{ $t("Reports.Actions.newAutoFolder") }}
                            </v-btn>
                            <v-btn
                                class="w-100 primary-button py-4 mb-4"
                                @click="closeCreateNewDialog"
                            >
                                {{ $t("ReportTable.cancel") }}
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>

        <!-- MOBILE LINK FOLDER MANAGEMENT -DIALOG

    Link folder management dialog. User can open settings or fill the report linked into this folder.
    Options: Settings, fill report
    TODO: Refactor as a new component

    @author: Jere Niemi

    -->

        <v-dialog
            v-model="mobileSettings"
            :retain-focus="false"
            hide-on-leave
            content-class="mobile-new"
            transition="dialog-bottom-transition"
        >
            <v-card class="pa-5 bg-transparent">
                <v-container fluid>
                    <v-form lazy-validation ref="form">
                        <v-row class="mx-0 h1-26" justify="center">
                            <h1>
                                {{ $t("ReportTable.manageFolder") }}
                            </h1>
                        </v-row>
                        <v-row class="mx-0 mt-10">
                            <v-btn class="white-button mb-4" @click="openSettings">
                                <v-icon color="primary" class="mr-2">mdi-cog-outline</v-icon>
                                {{ $t("ReportTable.folderSettings") }}
                            </v-btn>
                            <v-btn class="white-button mb-4" @click="fillAutoReport">
                                <v-icon color="primary" class="mr-2">mdi-pencil</v-icon>
                                {{ $t("ReportTable.fillReport") }}
                            </v-btn>
                            <v-btn
                                class="primary-button w-100 py-4 mb-4"
                                @click="closeMobileSettingsDialog"
                            >
                                {{ $t("ReportTable.cancel") }}
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>

        <BaseLoadingDialog
            :sendingData="loading"
            :msg="$t('Baseloading.loadingMessage')"
        />
        <InspectTemplateOrReport ref="inspectReport"/>
        <PDFGenerator ref="PDFGenerator" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import OkCancelDialog from '../OkCancelDialog';
import MoveFolderReport from '../MoveFolderReport';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import FolderShare from '../FolderManagement/FolderShare.vue';
import PDFGenerator from '../FillReport/PDFGenerator.vue';
import InspectTemplateOrReport from '../GeneralizedComponents/InspectTemplateOrReport.vue';

export default {
    props: ['getFolder', 'use', 'loadingData'],
    components: {
        OkCancelDialog,
        MoveFolderReport,
        BaseLoadingDialog,
        FolderShare,
        InspectTemplateOrReport,
        PDFGenerator,
    },
    data() {
        return {
            mobileAdd: false,
            mobileSettings: false,
            report: {},
            selection: [],
            renameDialog: false,
            loading: false,
            nameRules: [
                (v) => (v && v.length > 0) || this.$t('ReportTable.Rules.giveName'),
                (v) => (v && v.length < 51) || this.$t('ReportTable.Rules.nameTooLong'),
            ],
            newName: '',
            itemToRename: null,
            tree: [],
            search: '',
            page: 1,
            pageCount: 0,
            itemsPerPage: 50,
            sortBy: 'createdAt',
        };
    },
    methods: {
        closeCreateNewDialog() {
            this.mobileAdd = false;
        },
        openMobileSettingsDialog() {
            this.mobileSettings = true;
        },
        closeMobileSettingsDialog() {
            this.mobileSettings = false;
        },
        openSettings() {
            this.$emit('openSettings');
        },
        createLinkFolder() {
            this.$emit('createLinkFolder');
        },
        copyLink() {
            this.$emit('copyLink');
        },
        fillAutoReport() {
            this.$emit('fillAutoReport');
        },
        clearSelection() {
            this.selection = [];
        },

        //

        //ALL BELOW UNTIL NEXT COMMENT REFACTOR TO FIREBASE FUNCTION

        //

        async deleteSelection(items) {
            let deleteMessage1 = this.$t('TemplateTable.thisItem');
            let deleteMessage2 = this.$t('TemplateTable.items');
            let deleteMessage3 = this.$t('TemplateTable.areYouSureDelete');

            if (items.length > 0) {
                let deleteApproved = await this.OkCancelDialog(
                    this.$t('TemplateTable.deleteSelected'),
                    deleteMessage3 +
                        `${
                            this.selection.length === 1
                                ? deleteMessage1
                                : `${this.selection.length + deleteMessage2}`
                        }`
                );
                if (deleteApproved) {
                    let reports = this.selection.filter(
                        (report) => report.type === 'report'
                    );
                    let folders = this.selection.filter(
                        (folder) => folder.type === 'folder'
                    );
                    await this.deleteMultipleReports(reports);
                    await this.deleteMultipleFolders(folders);
                    this.clearSelection();
                }
            }
        },
        async deleteMultipleReports(items) {
            if (items.length > 0) {
                items.forEach((item) => {
                    let report = this.getReport(item.id);
                    let indexOf = this.reports.indexOf(report);
                    let payload = {
                        report: report,
                        shared: false,
                    };
                    let parentFolder = [];
                    this.$store.state.folders.forEach((folder) => {
                        if (folder.reports.includes(item.id)) {
                            parentFolder.push(folder);
                        }
                    });
                    this.$store.dispatch('updateFolder', parentFolder[0].id).then(() => {
                        this.loading = false;
                    });
                    let parent = parentFolder[0];
                    parent.reports = parent.reports.filter((x) => x.id !== item.id);
                    this.$store.dispatch('updateFolderWithData', parent).then(() => {
                        this.loading = false;
                    });
                    this.$store.dispatch('deleteReport', payload, indexOf);
                });
            }
        },
        async deleteMultipleFolders(items) {
            if (items.length > 0) {
                items.forEach((item) => {
                    this.loading = true;
                    let indexOf = this.folders.findIndex((x) => x.id === item.id);
                    let folder = this.folders[indexOf];
                    this.$store.dispatch('deleteFolder', folder).then(() => {
                        this.loading = false;
                    });
                    let parentFolder = [];
                    this.$store.state.folders.forEach((folder) => {
                        if (folder.subfolders.includes(item.id)) {
                            parentFolder.push(folder);
                        }
                    });
                });
            }
        },

        //

        //SEE NEXT COMMENT ABOVE

        //

        openCreateDialog() {
            this.$emit('createReport');
        },
        createFolder() {
            this.$emit('createFolder');
        },
        openMobileAdd() {
            this.mobileAdd = true;
        },
        formatTime(epochTime) {
            var d = new Date(epochTime * 1000);
            return d.toLocaleString();
        },
        openInspectReport(report) {
            this.$refs.inspectReport.openDialog(report);
        },
        async openItem(item) {
            this.search = '';
            if (this.getRole === 'viewer' && item.type == 'report') {
                const report = await this.$store.dispatch(
                    'getReportFromFirebase',
                    item.id
                );
                this.openInspectReport(report);
            }
            else if (item.type == 'folder') {
                this.clearSelection();
                this.openFolder(item);
            } else if (item.type == 'report') {
                this.editItem(item);
            }
        },
        openFolder(item) {
            if (this.$route.name === 'dashboard') {
                this.$store.state.currentFolderPath.push(item.id);
            } else if (this.$route.name === 'sharedfolders') {
                this.$store.state.currentSharedFolderPath.push(item.id);
            }
            this.configureFolderListener();
        },
        navigateToRootFolder() {
            this.search = '';
            if (this.$route.name === 'dashboard') {
                this.$store.state.currentFolderPath = [];
            } else if (this.$route.name === 'sharedfolders') {
                this.$store.state.currentSharedFolderPath = [];
            }
        },
        configureFolderListener() {
            if (
                this.currentFolder.participants.length > 0 ||
                this.currentFolder.use == 'AutoFolder'
            ) {
                this.$store.dispatch('setCurrentFolderListener');
            }
        },
        navigateToFolder(toFolder) {
            if (this.$route.name === 'dashboard') {
                // Delete everything before wanted id
                for (let index in this.$store.state.currentFolderPath) {
                    let folderId = this.$store.state.currentFolderPath[index];
                    if (folderId == toFolder.id) {
                        let indexNum = parseInt(index) + 1;
                        this.$store.state.currentFolderPath =
                            this.$store.state.currentFolderPath.slice(0, indexNum);
                        this.configureFolderListener();
                        break;
                    }
                }
            } else {
                // Delete everything before wanted id
                for (let index in this.$store.state.currentSharedFolderPath) {
                    let folderId = this.$store.state.currentSharedFolderPath[index];
                    if (folderId == toFolder.id) {
                        let indexNum = parseInt(index) + 1;
                        this.$store.state.currentSharedFolderPath =
                            this.$store.state.currentSharedFolderPath.slice(0, indexNum);
                        this.configureFolderListener();
                        break;
                    }
                }
            }
        },
        navigateToPreviousFolder() {
            if (this.$route.name === 'dashboard') {
                window.history.pushState(null, '', window.location.href);
                this.$store.state.currentFolderPath =
                    this.$store.state.currentFolderPath.slice(0, -1);
                this.configureFolderListener();
            } else {
                window.history.pushState(null, '', window.location.href);
                this.$store.state.currentSharedFolderPath =
                    this.$store.state.currentSharedFolderPath.slice(0, -1);
                this.configureFolderListener();
            }
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        getReport(id) {
            let index = this.reports.findIndex((x) => x.id === id);
            return this.reports[index];
        },
        getFolderName(id) {
            let index = this.folders.findIndex((x) => x.id === id);
            if (index != -1) {
                return this.folders[index].name;
            }
        },
        editItem(item) {
            this.$router.push({
                name: 'fillreport',
                params: { reportId: item.id },
            });
        },

        //

        //ALL BELOW UNTIL NEXT COMMENT REFACTOR TO FIREBASE FUNCTION

        //

        async deleteReport(item) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('ReportTable.deleteReportHeader'),
                this.$t('ReportTable.deleteReportMessage')
            );
            if (deleteApproved) {
                this.loading = true;
                let report = this.getReport(item.id);
                let indexOf = this.reports.indexOf(report);
                let payload = {
                    report: report,
                    shared: false,
                };
                let parentFolder = [];
                this.$store.state.folders.forEach((folder) => {
                    if (folder.reports.includes(item.id)) {
                        parentFolder.push(folder);
                    }
                });
                this.$store.dispatch('updateFolder', parentFolder[0].id).then(() => {
                    this.loading = false;
                });
                let parent = parentFolder[0];
                parent.reports = parent.reports.filter((x) => x.id !== item.id);
                this.$store.dispatch('updateFolderWithData', parent).then(() => {
                    this.loading = false;
                });
                this.$store.dispatch('deleteReport', payload, indexOf);
            }
        },
        async deleteFolder(item) {
            let deleteFolderApproved = await this.OkCancelDialog(
                this.$t('ReportTable.deleteFolderHeader'),
                this.$t('ReportTable.deleteFolderMessage')
            );

            if (deleteFolderApproved) {
                this.loading = true;
                let indexOf = this.folders.findIndex((x) => x.id === item.id);
                let folder = this.folders[indexOf];
                this.$store.dispatch('deleteFolder', folder).then(() => {
                    this.loading = false;
                });
                let parentFolder = [];
                this.$store.state.folders.forEach((folder) => {
                    if (folder.subfolders.includes(item.id)) {
                        parentFolder.push(folder);
                    }
                });
            }
        },

        //

        //SEE NEXT COMMENT ABOVE

        //

        updateFolderName() {
            if (this.$refs.form.validate()) {
                let name = this.newName;
                let folder = this.itemToRename;
                let payload = {
                    name: name,
                    folder: folder,
                };
                this.$store.dispatch('updateFolderName', payload);
                this.closeRenameDialog();
            }
        },
        openRenameDialog(item) {
            this.newName = item.name;
            this.itemToRename = item;
            this.renameDialog = true;
        },
        closeRenameDialog() {
            this.renameDialog = false;
            this.newName = '';
            this.itemToRename = null;
        },
        openMoveDialog(itemList) {
            this.$refs.MoveFolderReport.openDialog(itemList);
        },
        openParticipants() {
            this.$refs.FolderShare.openDialog();
        },
        async downloadPDF(item) {
            if (!this.loading) {
                this.loading = true;
                const report = await this.$store.dispatch(
                    'getReportFromFirebase',
                    item.id
                );
                this.$refs.PDFGenerator.downloadPDF(report);
                this.loading = false;
            }
        }
    },
    computed: {
        folderPath() {
            return this.$route.name === 'dashboard'
                ? this.getCurrentFolderPath
                : this.getCurrentSharedFolderPath;
        },
        currentFolder() {
            return this.$route.name === 'dashboard'
                ? this.getCurrentFolderData
                : this.getCurrentSharedFolderData;
        },
        showCreateButton() {
            return (
                this.use === 'regular' ||
                (this.use === 'shared' &&
                    this.$store.state.currentSharedFolderPath.length > 0 &&
                    (this.getRole === 'admin' || this.getRole === 'modifier'))
            );
        },
        showFolderMenu() {
            if (this.$route.name === 'sharedfolders') {
                if (this.folderPath.length !== 0 && this.getRole !== 'viewer') {
                    return true;
                } else return false;
            } else {
                return true;
            }
        },
        getRole() {
            if (
                this.$store.state.currentFolderPath.length ||
                this.$store.state.currentSharedFolderPath.length
            ) {
                let toReturn = this.currentFolder.participants.find(
                    (x) => x.UID === this.$store.state.userProfile.id
                );
                if (toReturn) {
                    return toReturn.permission;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        getLink() {
            if (this.currentFolder.isClosed) {
                return (
                    location.origin +
                    '/fillLinkReport/' +
                    this.currentFolder.id +
                    '/redirectLogin' || ''
                );
            } else {
                return (
                    location.origin + '/fillLinkReport/' + this.currentFolder.id || ''
                );
            }
        },
        participantsCount() {
            if (!this.currentFolder.participants) {
                return '0';
            }
            if (this.currentFolder.participants.length > 0) {
                return this.currentFolder.participants.length;
            } else {
                return '0';
            }
        },
        headers() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return [
                    {
                        value: 'type',
                        sortable: false,
                        width: '5px',
                    },
                    {
                        text: this.$t('ReportTable.name'),
                        value: 'name',
                        width: '20%',
                    },
                    {
                        text: this.$t('ReportTable.created'),
                        value: 'createdAt',
                        width: '20%',
                    },
                    {
                        text: this.$t('ReportTable.creator'),
                        value: 'creator',
                        width: '30%',
                        sort: (a,b) => {
                            if (a.firstname === undefined) return -1;
                            else if (b.firstname === undefined) return 1;
                            else return b.firstname.localeCompare(a.firstname);
                        }
                    },
                    {
                        text: this.$t('ReportTable.markedReady'),
                        value: 'markedReady',
                        width: '15%',
                    },
                    {
                        text: '',
                        value: 'actions',
                        sortable: false,
                        align: 'end',
                        width: '5%',
                    },
                ];
            } else {
                return [
                    {
                        text: '',
                        value: 'type',
                        sortable: false,
                        width: '5%',
                    },
                    {
                        text: this.$t('ReportTable.name'),
                        value: 'name',
                        width: '75%',
                    },
                    {
                        text: '',
                        value: 'actions',
                        sortable: false,
                        align: 'end',
                        width: '20%',
                    },
                ];
            }
        },
        ...mapGetters([
            'getCurrentFolderPath',
            'getCurrentFolderData',
            'getCurrentSharedFolderPath',
            'getCurrentSharedFolderData',
        ]),
        ...mapState(['reports']),
        ...mapState(['folders']),
    },
    mounted() {
        window.history.pushState(null, '', window.location.href);

        window.addEventListener('popstate', this.navigateToPreviousFolder);
    },
    destroyed() {
        window.removeEventListener('popstate', this.navigateToPreviousFolder);
    },
};
</script>
