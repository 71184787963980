import ImageUtils from '../utils/imageUtils';
import {imageLoader} from '../utils/imageLoader';
import {formatTime} from '../utils/epoch';
import PDFComponent from './PDFComponent';
import store from '../../../../../../store';


class CoverLetter extends PDFComponent {
    code() {
        let coverLetter = this.includeCoverLetter ? this.coverLetter : {};
        return coverLetter;
    }

    get coverLetter() {
        let image = new ImageUtils(this.coverLetterURL);
        imageLoader(this.args.pdf, image);

        return [
            {
                image: image.imageGarbageCollectionID,
                width: 300,
                alignment: 'center',
                margin: [0, 170, 0, 50],
            },
            {
                text:this.args.pdf.metaData.reportName,
                alignment: 'center',
                fontSize: 22,
                margin: [0, 0, 0, 70],
            },
            {
                text: formatTime(this.args.pdf.metaData.timeStamp),
                fontSize: 14,
                alignment: 'center',
                pageBreak: 'after',
            }
        ];
    }

    get coverLetterURL() {
        let url = store.state.userOrganization.logoUrl;
        // url = "https://source.unsplash.com/1920x1080/?logo"; console.warn("CoverLetter is running in Dev Mode!")
        return url;
    }

    get includeCoverLetter() {
        return this.coverLetterURL !== '' && this.coverLetterURL !== undefined;
    }
}

export default CoverLetter;
