<template>
    <div class="wrapper">
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :routeName="'processes'"
            :pageHeader="this.$store.state.currentProcess.title"
            :edit="true"
            @enableEdit="enableEdit()"
        />
        <v-container
            class="px-0 pt-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-row class="mx-0 px-4" :class="{'mb-7': $vuetify.breakpoint.mdAndUp }">
                <v-btn
                    x-large
                    circle
                    icon
                    @click="navigateToProcesses"
                    class="rounded-button mr-7"
                    v-if="$vuetify.breakpoint.mdAndUp"
                >
                    <v-icon color="primary">mdi-arrow-left</v-icon>
                </v-btn>
                <h1 v-if="$vuetify.breakpoint.mdAndUp">{{ $t("Process.modifyProcess") }} </h1>
                <v-btn class="secondary-button pl-0" @click="navigateToProcesses" v-else>
                    <v-icon color="primary" class="icon-plus"> mdi-chevron-left </v-icon>
                    {{ $t("Process.navigateBack") }}
                </v-btn>
            </v-row>
            <v-row class="mx-0 px-4 h1-26" v-if="!$vuetify.breakpoint.mdAndUp">
                <h1 class="mb-4">{{ $t("Process.modifyProcess") }} </h1>
            </v-row>
            <v-row class="mx-0 px-4 h2-18 text-primary">
                <h2>{{ $t("Process.modifyProcessName") }} </h2>
            </v-row>

            <v-row class="mx-0 px-4 mb-7">
                <v-text-field
                    v-model="nameEditHelper"
                    hide-details
                    @blur="saveIfChangesAndClose()"
                    @keydown.enter.prevent="saveIfChangesAndClose()"
                    class="mt-n2"
                >
                </v-text-field>
                <v-progress-circular
                    v-if="nameChangeSaving"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-row>

            <!-- Main dashboard -->
            <v-card :class="{'rounded-lg': $vuetify.breakpoint.mdAndUp}" max-width="1700">
                <v-row class="text-center mx-1 my-3" justify="center">
                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'" class="mt-2">
                        <v-row class="ma-0 py-2 px-4 h2-21">
                            <h2>
                                {{ $t("Processes.actionPanel") }}
                            </h2>
                        </v-row>
                        <v-row class="mx-0 mb-1 px-4">
                            <v-btn class="secondary-button" @click="openTemplateInspect()">
                                <v-icon class="mr-2"> mdi-file-outline </v-icon>
                                {{ $t("Processes.template") }}
                            </v-btn>
                        </v-row>
                        <v-row class="mx-0 mb-1 px-4">
                            <v-btn class="secondary-button" @click="openLogicConfig()" data-cy="openLogicConfig">
                                <v-icon class="mr-2"> mdi-cog </v-icon>
                                {{ $t("Processes.afterFilling") }}
                            </v-btn>
                        </v-row>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        align-self="center"
                        justify="center"
                        class="mt-2"
                    >
                        <v-row justify="center" class="my-2">
                            <v-progress-circular
                                :rotate="90"
                                :size="150"
                                :width="25"
                                :value="amountOfAnswered * (100 / amountOfRecievers)"
                                color="primary"
                                class="justify-center"
                            >
                                <h1 class="title black--text">
                                    {{ this.amountOfAnswered }} /
                                    {{ amountOfRecievers }}
                                </h1>
                            </v-progress-circular>
                        </v-row>
                        <v-row justify="center" class="my-2">
                            <v-card-subtitle class="title black--text">
                                {{ $t("Processes.filledReports") }}
                            </v-card-subtitle>
                        </v-row>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" align-self="start" class="mt-2">
                        <v-row class="mx-0 mt-2 h2-21">
                            <h2 class="px-4">
                                {{ $t("Processes.reportClosing") }}
                            </h2>
                            <v-card-text>
                                <v-dialog
                                    @click:outside="dialog = !dialog"
                                    ref="dialog"
                                    v-model="dialog"
                                    :return-value.sync="date"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            data-cy="changeDate"
                                            class="my-n3"
                                            :value="formatDate(date)"
                                            :prepend-icon="icon"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="fi"
                                        v-model="date"
                                        scrollable
                                        color="primary"
                                        class="rounded-lg"
                                        :first-day-of-week="1"
                                        :full-width="true"
                                        :min="minDate"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn class="secondary-button mb-2" @click="closeDatePicker()">
                                            {{ $t("Fillfield.cancelDate") }}
                                        </v-btn>
                                        <v-btn
                                            data-cy="confirmDate"
                                            class="primary-button mb-2"
                                            @click="saveNewEndDate(date)"
                                        >
                                            {{ $t("Processes.save") }}
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-card-text>
                            <h2 class="px-4">
                                {{ $t("ModifyAutolink.naming") }}
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" icon class="mb-1">
                                            <v-icon> mdi-help-circle </v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="my-n3"
                                    >{{ $t("ModifyAutolink.namingHelpText1") }}
                                        <br />
                                        {{ $t("ModifyAutolink.namingHelpText2") }}</span
                                    >
                                </v-tooltip>
                            </h2>
                            <v-row class="ma-0 px-4 w-100">
                                <p class="mb-0" v-if="!getNamingField">
                                    {{ $t("ModifyAutolink.noChosenField") }}
                                </p>
                                <p class="mb-0" v-else>
                                    {{ $t("ModifyAutolink.ReportWillBeNamed") }}
                                    <b class="text-primary"> {{ getNamingField }} </b>
                                    {{ $t("ModifyAutolink.byThisField") }}
                                </p>
                            </v-row>
                            <v-row class="ma-0 pa-4 w-100">
                                <v-btn
                                    class="primary-button mb-7"
                                    @click="openNamingDialog"
                                >
                                    {{ $t("ModifyAutolink.chooseField") }}
                                </v-btn>
                            </v-row>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="rounded-lg mt-4" max-width="1700">
                <v-row class="ma-0" justify="center">
                    <v-card class="rounded-lg px-7 py-2 w-100">
                        <v-tabs>
                            <v-tab data-cy="fillerTab">
                                {{ $t("Processes.fillers") }}

                            </v-tab>
                            <v-tab data-cy="messagesTab">
                                {{ $t("Processes.messages") }}
                            </v-tab>
                            <v-tab-item name="fillers">
                                <!-- Participants & Responses -->
                                <Fillers />

                                <v-btn
                                    v-if="this.$store.state.currentProcessResponses.length > 0"
                                    class="primary-button ml-2 mb-5"
                                    @click="downloadAll()"
                                >
                                    <v-icon class="mr-2"> mdi-download </v-icon>
                                    {{ $t("ModifyAutolink.downloadAll") }}
                                </v-btn>
                            </v-tab-item>

                            <v-tab-item name="messages">
                                <v-row class="mx-0 px-2 my-5 h2-18 text-primary">
                                    <h2>  {{ $t("Process.openAndModify") }}</h2>
                                </v-row>
                                <v-row class="mx-0 px-2 my-3">
                                    <v-btn
                                        data-cy="addEmailMessage"
                                        @click="addEmailMessage"
                                        class="primary-button mb-5 mr-3"
                                    >
                                        <v-icon left> mdi-email </v-icon>
                                        {{ $t("Processes.addEmail") }}
                                    </v-btn>
                                    <v-btn
                                        @click="addTextMessage"
                                        class="primary-button mb-5"

                                    >
                                        <v-icon left> mdi-cellphone-message </v-icon>
                                        {{ $t("Processes.addTextmessage") }}
                                    </v-btn>
                                </v-row>
                                <v-list subheader two-line>
                                    <v-list-item
                                        data-cy="modifyMessage"
                                        v-for="action in sortedActions"
                                        :key="action.id"
                                        @click="openOrModifyItem(action)"
                                    >
                                        <v-list-item-avatar v-if="action.type === 'email'" dark>
                                            <v-icon
                                                class="primary"
                                                dark
                                                v-if="action.endDate < currentTime"
                                            >
                                                mdi-email
                                            </v-icon>

                                            <v-icon v-else> mdi-email </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-avatar v-if="action.type === 'sms'">
                                            <v-icon
                                                class="primary"
                                                dark
                                                v-if="action.endDate < currentTime"
                                            >
                                                mdi-cellphone-message
                                            </v-icon>
                                            <v-icon v-else> mdi-cellphone-message </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-row class="pa-0 ma-0">
                                                <v-col cols="3" class="pa-0 ma-0">
                                                    <v-card class="pa-3">
                                                        <v-list-item-title
                                                            v-text="action.title"
                                                        ></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            v-text="formatTime(action.endDate)"
                                                        ></v-list-item-subtitle>
                                                    </v-card>
                                                </v-col>
                                                <v-col v-if="action.statuses.length > 0" cols="4">
                                                    <v-chip
                                                        class="ma-2"
                                                        :color="action.statuses.length
                                                            === countDelivered(action.statuses) ? 'success' : 'orange'"
                                                        text-color="white"
                                                    >
                                                        {{ countDelivered(action.statuses) }}
                                                        / {{action.statuses.length}}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>

                                        <v-list-item-action v-if="action.endDate > currentTime">
                                            <v-btn
                                                data-cy="deleteMessage"
                                                icon
                                                @click.stop="deleteAction(action)"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-tab-item>
                        </v-tabs>
                    </v-card>
                </v-row>
            </v-card>

            <!-- Inspect email -->

            <InspectAutomaticMessage
                :inspectItem="this.inspectItem"
                ref="InspectAutomaticMesage"
            />

            <!-- Add new automatic massage -->

            <CreateAutomaticMessage ref="addAutomaticMessage" :modify="modify" />

            <!-- View and add users -->

            <LogicConfig ref="logicConfig" />
        </v-container>
        <OkCancelDialog ref="OkCancelDialog" />

        <!-- For naming -->
        <SelectReportNamingField ref="namingDialog" @sectionChosen="addReportNaming"/>

        <!-- For inspecting template -->

        <InspectTemplateOrReport ref="inspectTemplate"/>

        <PDFGenerator ref="PDFGenerator" />

        <BaseLoadingDialog
            :sendingData="loading"
            :msg="$t('Baseloading.loadingMessage')"
        />
    </div>
</template>

<script>
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';
import CreateAutomaticMessage from '../components/Processes/CreateAutomaticMessage.vue';
import InspectTemplateOrReport from '../components/GeneralizedComponents/InspectTemplateOrReport.vue';
import SelectReportNamingField from '../components/GeneralizedComponents/SelectReportNamingField.vue';
import { processesCollection } from '../fbInitialize';
import { mapGetters } from 'vuex';
import PDFGenerator from '../components/FillReport/PDFGenerator.vue';
import OkCancelDialog from '../components/OkCancelDialog.vue';
import LogicConfig from '../components/Processes/LogicConfig.vue';
import InspectAutomaticMessage from '../components/Processes/InspectAutomaticMessage.vue';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import Fillers from '../components/Processes/Fillers.vue';

export default {
    components: {
        MobileNavigation,
        CreateAutomaticMessage,
        SelectReportNamingField,
        InspectTemplateOrReport,
        PDFGenerator,
        OkCancelDialog,
        InspectAutomaticMessage,
        LogicConfig,
        BaseLoadingDialog,
        Fillers
    },
    data() {
        return {
            //Dialog booleans here
            dialog: false,
            //NameChanging Helpers Here:,
            nameEditHelper: '',
            nameChangeSaving: false,
            saved: false,
            //reportNamingFieldID if selected
            reportNamingFieldID: false,
            inspectItem: {},
            listener1: '',
            minDate: '',
            date: '',
            // This is for animation
            icon: 'mdi-calendar',
            modify: false,
            currentTime: Date.now() / 1000,
            loading: false,
        };
    },
    methods: {
        async deleteAction(action) {
            if (action.messageStatuses && action.messageStatuses.length > 0) {
                // If sent already
                return;
            }
            let deleteApproved = await this.OkCancelDialog(
                this.$t('Processes.deleteMessageHeader'),
                this.$t('Processes.deleteMessageMessage'),
            );
            if (deleteApproved) {
                this.loading = true;
                this.$store.dispatch('deleteAction', action).then(() => {
                    this.loading = false;
                });
            }
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        saveIfChangesAndClose() {
            if (this.nameEditHelper !== this.$store.state.currentProcess.title) {
                this.nameChangeSaving = true;

                let process = this.$store.state.currentProcess;
                process['title'] = this.nameEditHelper;

                this.$store.dispatch('updateProcess', process).then(() => {
                    this.comment = '';

                    this.sleep(1000)
                        .then(() => (this.nameChangeSaving = false))
                        .then(() => (this.saved = true))
                        .then(() => this.sleep(500))
                        .then(() => (this.editLogName = false))
                        .then(() => (this.saved = false));
                });
            } else {
                this.editLogName = false;
            }
        },
        openOrModifyItem(item) {
            if (!(item.endDate < this.currentTime)) {
                this.modify = true;
                this.$refs.addAutomaticMessage.inspectModifyDialog(item);
            } else {
                this.modify = false;
                this.inspectItem = item;
                this.$refs.InspectAutomaticMesage.open(item);
            }
        },
        openTemplateInspect() {
            const item = this.getTemplate(this.$store.state.currentProcess.templateId);
            this.$refs.inspectTemplate.openDialog(item);
        },
        addReportNaming(value) {
            this.$store
                .dispatch('addNamingFieldToProcess', {
                    reportNamingFieldId: value.id,
                    namingFieldLabel: value.label,
                });
        },
        openLogicConfig() {
            this.$refs.logicConfig.openLogicConfigDialog();
        },
        getAnswered(array) {
            var answers = 0;
            for (let receiver of array) {
                if (receiver.status) {
                    if (receiver.status === 'created') {
                        ++answers;
                    }
                } else if (receiver.processResponseId !== '') {
                    ++answers;
                }
            }
            return answers;
        },
        addTextMessage() {
            this.modify = false;

            this.$refs.addAutomaticMessage.openCreateDialog('sms');
        },
        addEmailMessage() {
            this.modify = false;

            this.$refs.addAutomaticMessage.openCreateDialog('email');
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);

            return d.toLocaleString();
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        },
        closeDatePicker() {
            this.dialog = false;
        },
        saveNewEndDate(date) {
            this.$refs.dialog.save(date);
            if (date !== this.$store.state.currentProcess.title) {
                let process = this.$store.state.currentProcess;
                process['endDate'] = new Date(this.date).getTime() / 1000;

                this.$store.dispatch('updateProcess', process).then(() => {
                    this.icon = 'mdi-check';
                    this.sleep(1000)
                        .then(() => this.sleep(500))
                        .then(() => (this.icon = 'mdi-calendar'));
                });
            } else {
                this.dialog = false;
            }
        },
        async downloadPDF(report) {
            await this.$refs.PDFGenerator.downloadPDF(
                report.report,
                this.$store.state.currentProcess.reportNamingFieldId
            );
        },
        async downloadAll() {
            this.loading = true;
            try {
                let answers = this.$store.state.currentProcessResponses.map(x => x.report);
                let id = this.$store.state.currentProcess.reportNamingFieldId;
                let zipName = this.$store.state.currentProcess.title;
                await this.$refs.PDFGenerator.downloadManyZip(answers, id, zipName);
            } finally {
                this.loading = false;
            }
        },
        openNamingDialog() {
            const template = this.getTemplate(this.$store.state.currentProcess.templateId);
            this.$refs.namingDialog.openDialog(template);
        },
        navigateToProcesses() {
            this.$router.push({ name: 'processes' });
        },
        countDelivered(statuses) {
            let count = 0;
            statuses.forEach(x => {
                if(x.status === 'delivered' || x.status === 'sent' ){
                    count++;
                }
            });
            return count;
        }
    },
    computed: {
        ...mapGetters(['getTemplate', 'getProcessReponses']),
        amountOfAnswered() {
            return this.getAnswered(this.receivers);
        },
        amountOfRecievers() {
            return this.receivers.length;
        },
        receivers() {
            return this.$store.state.currentProcess.receivers;
        },
        sortedActions() {
            let sortable = this.$store.state.currentActions;
            let result = sortable.sort(function (x, y) {
                return x.endDate - y.endDate;
            });
            return result;
        },
        getNamingField() {
            return this.$store.state.currentProcess.namingFieldLabel;
        },
    },
    beforeDestroy() {
        // Delete currentprocess
        this.$store.dispatch('addCurrentProcess', {});
        // Delete currentactions
        this.$store.state.currentActions = [];
        this.listener1();
        this.$store.dispatch('detachActionStatusesListeners');
        this.$store.commit('resetProcessSession');
    },
    created() {
        window.scrollTo(0, 0);
        // Timer for updating the frontend
        var self = this;
        setInterval(function () {
            // Seconds
            self.currentTime = Date.now() / 1000;
        }, 1000);

        this.$store.dispatch('getCurrentProcessResponses');

        this.nameEditHelper = this.$store.state.currentProcess.title;
        this.date = new Date(this.$store.state.currentProcess.endDate * 1000)
            .toISOString()
            .substr(0, 10);
        this.minDate = new Date().toISOString().substr(0, 10);
        const docref = processesCollection.doc(this.$store.state.currentProcess.id);
        //Init listener
        this.listener1 = docref.onSnapshot((doc) => {
            let proc = doc.data();
            proc['id'] = doc.id;
            this.$store.dispatch('addCurrentProcess', proc);
            let actionsList = doc.data().actions;
            this.$store.dispatch('getCurrentActions', actionsList);
            this.$store.dispatch('currentActionStatusesListener', actionsList);
        });

        this.reportNamingFieldID =
            this.$store.state.currentProcess.reportNamingFieldId;

    },
};
</script>

<style>
</style>
