import Vue from 'vue';
import App from './App.vue';
const VueSignaturePad = require('vue-signature-pad');
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { auth } from './fbInitialize';
import i18n from './i18n';

Vue.config.productionTip = false;
Vue.use(VueSignaturePad);

let app;
auth.onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            i18n,
            render: h => h(App),
            created() {
                this.$i18n.locale = store.state.locale || navigator.language;
            }
        });
        //@ts-ignore
        if(window.Cypress) {
            window.app = app;
        }
        app.$mount('#app');
    }
});

// For logging user out after 12 hours
auth.onAuthStateChanged((user) => {
    let userSessionTimeout = null;
    if (user === null) {
        clearTimeout(userSessionTimeout);
    } else {
        user.getIdTokenResult().then((idTokenResult) => {
            const authTime = idTokenResult.claims.auth_time * 1000;
            const sessionDurationInMilliseconds = 12 * 60 * 60 * 1000; // 12 h
            const expirationInMilliseconds = sessionDurationInMilliseconds - (Date.now() - authTime);
            console.log('TimeoutSet');
            userSessionTimeout = setTimeout(() => {
                store.dispatch('logout');
            }, expirationInMilliseconds);
        });
    }
});
