<template>
    <v-container class="pa-0 mt-2">
        <v-dialog
            max-width="1000"
            v-model="dialog"
            :fullscreen="!$vuetify.breakpoint.mdAndUp"
            @click:outside="closeDialog"
        >
            <v-card
                class="border-0"
                :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
                v-if="template"
            >
                <v-row
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    class="modal-header-mobile h1-16 mx-0 items-center"
                >
                    <v-col cols="2">
                        <v-icon color="primary" class="icon-plus" @click="closeDialog" data-cy="closeInspectDialog">
                            mdi-chevron-left
                        </v-icon>
                    </v-col>
                    <v-col cols="8" align="center">
                        <h1>{{ template.templateName }}</h1>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>
                <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>{{ $t("InspectTemplate.inspectTemplate") }} {{ template.templateName }}</h1>
                        </v-col>
                        <v-col cols="2" align="end">
                            <v-icon
                                data-cy="closeInspectDialog"
                                class="close-button"
                                @click="closeDialog"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-card
                        class="rounded-lg pa-2 w-100"
                        :class="{'mb-150': !$vuetify.breakpoint.mdAndUp, 'my-2': $vuetify.breakpoint.mdAndUp}"
                    >
                        <SectionIterator :sections="template.sections"/>
                        <v-overlay
                            absolute
                            opacity="0"
                        ></v-overlay>
                    </v-card>
                    <v-row
                        class="mx-0 mt-2"
                        :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'start'"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}">
                        <v-btn
                            class="primary-button"
                            data-cy="closeDialog"
                            :class="{
                                'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp,
                                'mb-7': $vuetify.breakpoint.mdAndUp}"
                            @click="closeDialog"
                        >
                            {{ $t("Sulje") }}
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import SectionIterator from '../FillReport/SectionIterator.vue';

export default {
    components: {
        SectionIterator
    },
    data() {
        return {
            dialog: false,
            template: null
        };
    },
    methods: {
        closeDialog() {
            this.template = null;
            this.dialog = false;
        },
        openDialog(template) {
            this.template = template;
            this.dialog = true;
        }
    },
};
</script>
