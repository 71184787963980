<template>
    <v-snackbar
        v-model="snackbar"
        elevation="1"
        color="primary"
        class="rounded-lg"
    >
        <h1 class="title" data-cy="snackbarTitle">
            {{ snackbarText }}
        </h1>

        <template v-slot:action="{ attrs }">
            <v-btn
                data-cy="closeTemplateSavedSnackbar"
                text
                v-bind="attrs"
                @click="closeSnackbar()"
            >
                {{ $t("Reports.close") }}
            </v-btn>
        </template>

    </v-snackbar>
</template>

<script>
export default {
    props: ['snackbarText'],
    name: 'GeneralSnackbar',
    data() {
        return{
            snackbar: false,
        };
    },
    methods:{
        closeSnackbar() {
            this.snackbar = false;
        },
    }
};
</script>
