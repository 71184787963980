export class RecursiveDepthAnalyzer {
    constructor() {
        this.layers = [];
    }

    addLayer() {
        this.layers.push(0);
    }

    removeLayer() {
        if(this.layers.length < 1) {
            return;
        }
        this.layers.pop();
    }

    addValue() {
        this.layers[this.layers.length-1]++;
    }
}
