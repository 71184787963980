<template>
    <div class="wrapper">
        <v-container
            class="px-0 py-7 container"
            :class="{ 'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-row class="static-full pt-2 pl-2 pr-5" v-if="$vuetify.breakpoint.mdAndUp">
                <v-col cols="2">
                    <v-btn data-cy="backToReports" class="secondary-button mt-1" @click="NavigateBack">
                        <v-icon color="primary" class="mr-2" @click="NavigateBack">
                            mdi-arrow-left
                        </v-icon>
                        {{ $t("Fillreport.backToReports") }}
                    </v-btn>
                </v-col>
                <v-col cols="10" class="px-0" align="end">
                </v-col>
            </v-row>
            <v-row class="mx-0 h1-26 px-4 mb-0" v-if="!$vuetify.breakpoint.mdAndUp">
                <v-btn class="secondary-button pl-1" @click="NavigateBack">
                    <v-icon color="primary" class="icon-plus"> mdi-chevron-left </v-icon>
                    {{ $t("Fillreport.backToReports") }}
                </v-btn>
            </v-row>
            <v-row
                class="mx-0 px-4"
                :class="{'mt-10': $vuetify.breakpoint.mdAndUp, 'ml-3 h1-26': !$vuetify.breakpoint.mdAndUp}"
            >
                <h1>{{ $t("Fillreport.fillReport") }}</h1>
            </v-row>
            <v-row class="ml-4 mt-7">
                <v-col cols="12" class="h2-18 py-0 text-primary" v-if="!$vuetify.breakpoint.mdAndUp">
                    <h2>{{ $t("Fillreport.name") }}</h2>
                </v-col>
            </v-row>
            <v-row class="px-4 my-4 mx-0">
                <v-col cols="8">
                    <v-text-field
                        :label="$t('Fillreport.reportName')"
                        v-model="reportName"
                        @blur="autoSaveData"
                    >
                    </v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                    <v-img
                        :src="this.$store.state.userOrganization.logoUrl"
                        max-height="50"
                        max-width="200"
                        :class="{'mb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                    </v-img>
                </v-col>
            </v-row>
            <SectionIterator
                :sections="this.$store.state.currentReport.sections"
                ref="sectionIterator"
                :autoSave="true"
                @saveField="autoSaveData"
                @inputChanged="setNotSavedMode"
            />

            <PDFGenerator ref="PDFGenerator" />

            <BaseLoadingDialog
                :sendingData="sendingData"
                :msg="$t('Fillreport.loadingMessage')"
            />

            <ValidateForm ref="validateForm" />

        </v-container>
        <v-container
            class="fixed-bottom-full white-bg"
            v-if="$vuetify.breakpoint.mdAndUp"
        >
            <v-row class="px-3">
                <v-col cols="6">
                    <v-btn :class="saved ? 'secondary-button' : 'primary-button'" @click="saved? '' : autoSaveData" >
                        <div v-if="!saved && !autosaving">
                            {{ $t("Fillreport.save") }}
                        </div>
                        <div v-else-if="!autosaving">
                            <v-icon class="mr-2"> mdi-check</v-icon>
                            {{ $t("Fillreport.savedAutomatically") }}
                        </div>
                        <div v-else>
                            <v-progress-circular
                                indeterminate
                                :size="25"
                                color="primary"
                                class="mr-3"
                            ></v-progress-circular>
                            {{ $t("Fillreport.savingChanges") }}
                        </div>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SectionIterator from '../components/FillReport/SectionIterator.vue';
import PDFGenerator from '../components/FillReport/PDFGenerator.vue';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import ValidateForm from '../components/FillReport/ValidateForm.vue';

export default {
    components: {
        PDFGenerator,
        BaseLoadingDialog,
        SectionIterator,
        ValidateForm,
    },
    data() {
        return {
            saved: true,
            autosaving: false,
            sendingData: false,
            dialog: false,
            loading: false,
            mobileSettings: false,
            pendingUpdate: false
        };
    },

    async beforeRouteLeave(to, from, next) {
        this.sendingData = true;
        if (!this.autosaving) {
            this.sendingData = false;
            next();
        } else {
            await this.until(() => this.autosaving == false);
            next();
        }
    },
    methods: {
        setNotSavedMode() {
            this.saved = false;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        until(conditionFunction) {
            const poll = (resolve) => {
                if (conditionFunction()) resolve();
                else setTimeout(() => poll(resolve), 400);
            };

            return new Promise(poll);
        },
        async autoSaveData() {
            this.pendingUpdate = true;
            if(this.autosaving) {
                return;
            }
            try {
                this.autosaving = true;
                while (this.pendingUpdate) {
                    this.pendingUpdate = false;
                    await this.$store.dispatch('updateMaintenanceReport');
                }
                this.saved = true;
            } catch (e) {
                console.log(e);
            }
            finally {
                this.autosaving = false;
            }
        },
        NavigateBack() {
            this.$router.go(-1);
        },
    },
    computed: {
        reportName: {
            get() {
                return this.$store.state.currentReport.name;
            },
            set(value) {
                this.$store.commit('updateCurrentReportName', value);
            },
        },
    },
    mounted() {
    },
    beforeDestroy() {
        this.autoSaveData();
        this.$store.commit('clearCurrentReport');
    },

    beforeMount() {
        this.scrollToTop();
    },
};
</script>

<style scoped>
  * :deep(.mobile-new) {
    position: absolute;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    margin: 0;
    background-color: #f9f9f9;
  }
</style>
