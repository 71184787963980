<template>
    <div :class="{'template-generator mb-4': allowSubSection}">
        <v-container>
            <v-row justify="center">
                <span class="my-handle">
                    <v-icon> mdi-drag-horizontal </v-icon>
                </span>
            </v-row>
            <v-row class="mx-0 mt-5">
                <v-col class="pa-0 grow">
                    <v-row
                        class="ma-0 hover show-icon-onhover-section h1-23 pb-3"
                        @click="setHeaderActive(false)"
                        v-click-outside="onOutsideClick"
                    >
                        <v-col class="shrink pa-0">
                            <h1 class="text-primary mr-5" :class="{'adjustment':!headerActive }">{{sectionNumber}}.</h1>
                        </v-col>
                        <v-col class="pa-0 grow" v-if="!this.headerActive">
                            <v-textarea
                                data-cy="templateName"
                                hide-details
                                v-model="sectionData.sectionName"
                                clearable
                                flat
                                solo
                                auto-grow
                                rows="1"
                                class="text-field-100-content-header light-bg-content-header"
                                ref="sectionHeader"
                            ></v-textarea>
                        </v-col>
                        <v-col class="pa-0 grow" v-else>
                            <h1 class="text-primary" v-if="this.headerActive">
                                {{
                                    sectionData.sectionName !== ""
                                        ? sectionData.sectionName
                                        : $t("Section.sectionName")
                                }}
                                <v-icon
                                    v-if="headerActive && $vuetify.breakpoint.mdAndUp"
                                    class="ml-2 small-icon show-on-hover mt-n1"
                                >
                                    mdi-pencil
                                </v-icon>
                            </h1>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="pa-1 mt-1 shrink min-w-icons" align="end">
                    <v-icon color="primary" class="mr-3" @click.stop="copySection(sectionData, sectionNumber - 1)">
                        mdi-content-copy
                    </v-icon>
                    <v-icon color="primary" @click.stop="deleteSection(sectionData)"> mdi-delete </v-icon>
                </v-col>
            </v-row>
            <v-row
                class="mx-0 hover show-icon-onhover"
                :class="{'mt-1':!headerActive}"
                @click="setSubheaderActive(false)"
                v-click-outside="onSubheaderOutsideClick"
            >
                <pre class="text-gray pre-line mb-4" v-if="this.subheaderActive">
                    {{
                        sectionData.question
                            ? sectionData.question
                            : $t('Section.optionalDescription')
                    }}
                </pre>
                <v-icon
                    v-if="subheaderActive && $vuetify.breakpoint.mdAndUp"
                    class="xs-icon show-on-hover-gray ml-2 mb-4"
                >
                    mdi-pencil
                </v-icon>
                <v-textarea
                    :label="$t('Section.optionalDescription')"
                    hide-details
                    clearable
                    flat
                    solo
                    auto-grow
                    rows="1"
                    class="light-bg-content-subheader text-field-100-content-subheader"
                    :class="{'bold-off':!subheaderActive}"
                    v-model="sectionData.question"
                    ref="sectionSubheader"
                    v-if="!this.subheaderActive"
                >
                </v-textarea>
            </v-row>
        </v-container>

        <draggable
            v-model="sectionData.fields"
            ghost-class="ghost-card"
            handle='.my-handle'
            animation="350"
        >
            <div v-for="(fieldData, fieldIndex) in sectionData.fields" :key="fieldIndex" @click="setInactive()">
                <v-card
                    elevation="0"
                    class="field-background-normal cursor-normal"
                    :ripple="false"
                >
                    <Field
                        :fieldData="sectionData.fields[fieldIndex]"
                        :fieldIndex="fieldIndex"
                        :sectionDataFields="sectionData.fields"
                        :OkCancelDialog="OkCancelDialog"
                        @fieldDeleted="deleteField(sectionData.fields, fieldIndex)"
                    >
                    </Field>
                </v-card>
            </div>
        </draggable>

        <v-container class="py-0 mt-7" :class="{'px-0': !$vuetify.breakpoint.mdAndUp}">
            <v-row class="mx-0 mb-2">
                <v-btn
                    data-cy="addField"
                    @click="addField"
                    class="button-add-field"
                >
                    <v-icon class="icon-plus"> mdi-plus-circle-outline </v-icon>
                    {{ $t('Section.addField') }}
                </v-btn>
            </v-row>
        </v-container>


        <draggable
            v-model="sectionData.sections"
            ghost-class="ghost-card"
            handle='.my-handle'
            animation="350"
        >
            <div
                v-for="(subSectionData, subsectionIndex) in sectionData.sections"
                :key="subsectionIndex"
            >
                <Section
                    :sectionData="sectionData.sections[subsectionIndex]"
                    :OkCancelDialog="OkCancelDialog"
                    :sectionNumber="sectionNumber + '.' + (subsectionIndex + 1)"
                    @sectionDeleted="deleteSubSection(sectionData.sections, subsectionIndex)"
                    @subSectionCopied="copySubsection"
                    :allowSubSection="false"
                />
            </div>
        </draggable>
        <v-container class="my-0" v-if="allowSubSection" :class="{'px-0': !$vuetify.breakpoint.mdAndUp}">
            <v-row class="mx-0">
                <v-btn
                    @click="addSubSection"
                    class="button-add-subsection"
                    v-if="allowSubSection"
                >
                    <v-icon class="icon-plus">
                        mdi-plus-circle-outline
                    </v-icon>
                    {{ $t('Section.addSubSection') }}
                </v-btn>
            </v-row>
        </v-container>
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText=snackbarText />
    </div>
</template>
<script>
import Field from './Field.vue';
import _ from 'lodash';
import draggable from 'vuedraggable';
import {
    sectionTemplate,
    fieldTemplates,
} from '../../datamodels/datamodels.js';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    props: [
        'sectionData',
        'OkCancelDialog',
        'rootKey',
        'sectionIndex',
        'sectionNumber',
        'allowSubSection',
        'report',
    ],
    components: {
        // This file imports itself
        Field,
        Section: () => import('./Section.vue'),
        draggable,
        GeneralSnackbar,
    },
    data() {
        return {
            closeOnContentClick: true,
            snackbarText: '',
            headerActive: true,
            subheaderActive: true,
            headerName: this.$t('Section.sectionName'),
        };
    },
    methods: {
        addField() {
            var templateClone = _.cloneDeep(fieldTemplates[0]);
            templateClone.id = Math.floor(Math.random() * 1000000 + 1);
            this.sectionData.fields.push(templateClone);
        },
        onOutsideClick() {
            this.headerActive = true;
        },
        onSubheaderOutsideClick() {
            this.subheaderActive = true;
        },
        setInactive() {
            this.headerActive = true;
            this.subheaderActive = true;
        },
        setHeaderActive(params) {
            this.headerActive = params;
            if (!params) {
                this.$nextTick(() => this.$refs.sectionHeader.focus());
            }
        },
        setSubheaderActive(params) {
            this.subheaderActive = params;
            if (!params) {
                this.$nextTick(() => this.$refs.sectionSubheader.focus());
            }
        },
        deleteField(fields, index) {
            fields.splice(index, 1);
            this.$forceUpdate();
            this.snackbarText = this.$t('Field.fieldDeleted');
            this.$refs.GeneralSnackbar.snackbar = true;
        },
        async deleteSection() {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('Section.deleteSectionHeader'),
                this.$t('Section.deleteSectionMessage')
            );
            if (deleteApproved) {
                this.$emit('sectionDeleted');
                // Somehow this does not work
                (this.snackbarText = this.$t('Section.sectionDeleted')),
                (this.$refs.GeneralSnackbar.snackbar = true);
            }
        },
        addSubSection() {
            var templateClone = _.cloneDeep(sectionTemplate);
            this.sectionData.sections.push(templateClone);
        },
        copySection(item, index) {
            if(this.allowSubSection) {
                this.$emit('sectionCopied', this.$event, item, index);
            } else {
                this.$emit('subSectionCopied', this.$event, item, index);
            }
            this.snackbarText = this.$t('Section.sectionCopied');
            this.$refs.GeneralSnackbar.snackbar = true;
        },
        copySubsection(event, item) {
            var template = item;
            var templateClone = _.cloneDeep(template);
            templateClone.id = Math.floor(Math.random() * 1000000 + 1);

            this.sectionData.sections.push(templateClone);
        },
        deleteSubSection(list, index) {
            list.splice(index, 1);
        },
    },
};
</script>

<style scoped>
.ghost-card {
  opacity: 0.5;
  color: grey;
}
.my-handle {
  cursor: move;
  cursor: -webkit-move;
}
</style>
