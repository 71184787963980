<template>
    <v-dialog v-model="instantMessages" max-width="500">
        <v-card class="mx-auto rounded-lg" max-width="500" >
            <v-row class="ma-0 pa-0">
                <h2 class="mx-3 pt-2"> {{ $t("ShowInstantMessages.sentMessages") }}</h2>
                <v-spacer></v-spacer>
                <v-icon class="ma-2" @click="close" large>mdi-close</v-icon>
            </v-row>

            <p v-if="this.messages.length === 0" class="ml-3">{{ $t("ShowInstantMessages.noMessages") }}</p>

            <v-list two-line>
                <v-list-item-group multiple>
                    <template v-for="(item, index) in messages">
                        <v-list-item :key="item.title">
                            <template v-slot:default="{}">
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title"></v-list-item-title>

                                    <v-list-item-subtitle
                                        class="text--primary"
                                        v-text="item.emailTitle"
                                    ></v-list-item-subtitle>

                                    <p class="body-2">{{item.msg}}</p>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-list-item-action-text>
                                        {{ formatTime(item.createdAt) }}
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < messages.length - 1"
                            :key="index"
                        ></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            instantMessages: false,
            message: {},
            receiver: {},
            messages: [],
        };
    },
    methods: {
        close() {
            this.instantMessages = false;
        },
        openCreateDialog(item) {

            let messages = this.$store.state.currentProcess.receivers.find(
                (x) => x.id === item.id
            ).instanMessages;
            this.messages = messages ? messages : [];

            this.instantMessages = true;
        },
        closeAndClearAddAutomaticMessage() {
            this.message = {};
            this.addAutomaticMessage = false;
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);
            return d.toLocaleString();
        },
    },
};
</script>

<style>
</style>
