<template>
    <v-dialog
        content-class="img-fullscreen-modal"
        v-model="dialog"
        @click:outside="closeDialog()"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            :class="{'rounded-lg bg-transparent': $vuetify.breakpoint.mdAndUp,
                     'border-0 bg-black': !$vuetify.breakpoint.mdAndUp}"
        >
            <v-row class="ma-0" justify="end">
                <v-icon
                    @click="closeDialog()"
                    size="40"
                    class="square-bg"
                    :class="{'pa-4 mr-3': $vuetify.breakpoint.mdAndUp,
                             'mr-0 pa-4': !$vuetify.breakpoint.mdAndUp}"
                >
                    mdi-close
                </v-icon>
            </v-row>
            <v-row
                :class="{'ma-0': $vuetify.breakpoint.mdAndUp,
                         'ma-0 fullscreen-img': !$vuetify.breakpoint.mdAndUp}"
                justify="center"
            >
                <v-img
                    :src="src"
                    contain
                    max-height="75vh"
                    :max-width="$vuetify.breakpoint.mdAndUp ? '95vw' : '100vw'"
                >
                </v-img>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ViewImage',
    props: ['src'],
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
    },
};
</script>
