<template>
    <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('PDFGenerator.fillRequired')" />
</template>

<script>
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';
export default {
    components: {
        GeneralSnackbar,
    },
    methods: {
        isRequiredInputFilled(sections) {
            for (let section of sections) {
                for (let field of section.fields) {
                    if (!this.requiredFieldCheckHelper(field)) {
                        this.$refs.GeneralSnackbar.snackbar = true;
                        return false;
                    }
                }
                for (let subsection of section.sections) {
                    for (let field of subsection.fields) {
                        if (!this.requiredFieldCheckHelper(field)) {
                            this.$refs.GeneralSnackbar.snackbar = true;
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        requiredFieldCheckHelper(field) {
            //boolean to check field type
            let TextfieldOrChoice =
                field.type === 'textfield' ||
                field.type === 'choice' ||
                field.type === 'textinput' ||
                field.type === 'infotext';
            let addImageOrCommentOrSignature =
                field.type === 'addimages' ||
                field.type === 'comment' ||
                field.type === 'signature' ||
                field.type === 'file';
            let table=field.type==='table';
            let dates=field.type==='date';

            //Conditional exprs to check wheter field is empty or not
            if (TextfieldOrChoice && !field.data && field.required) {
                return false;
            } else if (
                addImageOrCommentOrSignature &&
                field.data.length === 0 &&
                field.required
            ) {
                return false;
            } else if(table && field.required){
                if(field.data.length===0){
                    return false;
                }

            }else if(dates && field.required){
                if(!field.data){
                    return false;
                }
                if(field.hasClock && !field.time ){
                    return false;
                }
            }
            //Required field not empty -> return true
            return true;
        },
    },
};
</script>
