<template>
    <div class="pa-0 ma-0 mb-2">
        <v-container fluid>
            <v-flex class="xs12 sm12 mx-auto">
                <v-row v-if="!$vuetify.breakpoint.mdAndUp">
                    <MobileNavigation
                        :routeName="'autolinks'"
                        :pageHeader="this.autoLinkTemplate.name"
                    />
                </v-row>
                <v-row class="pa-3 mb-0" v-else>
                    <h1 class="title row-pointer" @click="navigateBack">
                        {{
                            $t("ModifyAutolink.header") +
                                " " +
                                "/" +
                                " " +
                                this.autoLinkTemplate.name
                        }}
                    </h1>
                </v-row>

                <v-card class="rounded-lg" max-width="600">
                    <v-card-subtitle class="title black--text">
                        {{ $t("ModifyAutolink.link") }}
                    </v-card-subtitle>

                    <v-card
                        data-cy="copyAutolink"
                        @click="copyLink()"
                        class="d-flex flex-column black--text rounded-lg mx-4"
                        color="grey"
                    >
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col cols="10">
                                    {{ getLink }}
                                </v-col>
                                <v-col cols="2">
                                    <v-icon class="float-right mx-auto">
                                        mdi-content-copy
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card-subtitle class="title black--text mt-2">
                        {{ $t("ModifyAutolink.template") }}
                    </v-card-subtitle>
                    <v-row class="mx-2">
                        <v-select
                            :items="getTemplates"
                            item-text="templateName"
                            ref="templatechoice"
                            :label="getTemplateName(this.autoLinkTemplate.template_id)"
                            return-object
                            @change="changeSelectedTemplate"
                            :rules="requireSelect"
                            class="mx-2 rounded-lg mb-n3"
                            solo
                        >
                        </v-select>
                    </v-row>

                    <v-card-subtitle class="title black--text mb-n3 mt-3">
                        {{ $t("ModifyAutolink.recievers") }}
                    </v-card-subtitle>

                    <v-row class="mx-1 my-1" no-gutters>
                        <v-col
                            v-for="(receiver, index) in autoLinkTemplate.recievers"
                            :key="receiver"
                            cols="12"
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            class="flex-grow-1 flex-shrink-0 my-1"
                        >
                            <span class="grey rounded-lg pa-2 mx-3">
                                <v-icon color="black">mdi-account</v-icon>
                                {{ receiver }}
                                <v-btn
                                    data-cy="deleteReceiver"
                                    icon
                                    @click="deleteReceiver(index)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                        <v-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2">
                            <v-btn
                                data-cy="openAddReceiverDialog"
                                class="rounded-lg mx-3 my-2"
                                color="primary"
                                dark
                                max-width="30"
                                @click="openAddReceiverDialog"
                            >
                                <v-icon>mdi-account-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-subtitle class="title black--text mt-2">
                        {{ $t("ModifyAutolink.naming") }}
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon class="mb-1">
                                    <v-icon> mdi-help-circle </v-icon>
                                </v-btn>
                            </template>
                            <span
                            >{{ $t("ModifyAutolink.namingHelpText1") }} <br />
                                {{ $t("ModifyAutolink.namingHelpText2") }}</span
                            >
                        </v-tooltip>
                    </v-card-subtitle>
                    <v-row>
                        <v-container fluid>
                            <v-row class="px-4">
                                <v-col>
                                    <p v-if="!getNamingField">
                                        {{ $t("ModifyAutolink.noChosenField") }}
                                    </p>
                                    <p v-else>
                                        {{ $t("ModifyAutolink.ReportWillBeNamed") }}
                                        <b> {{ getNamingField }} </b>
                                        {{ $t("ModifyAutolink.byThisField") }}
                                    </p>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        data-cy="openNamingDialog"
                                        class="rounded-lg"
                                        color="primary"
                                        dark
                                        @click="openNamingDialog"
                                    >
                                        {{ $t("ModifyAutolink.chooseField") }}
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <SelectReportNamingField ref="namingDialog" @sectionChosen="addReportNaming"/>
                        </v-container>
                    </v-row>

                    <v-card-subtitle class="title black--text" v-if="false">
                        {{ $t("ModifyAutolink.use") }}
                    </v-card-subtitle>

                    <v-card
                        max-width="400"
                        flat
                        v-show="this.$store.state.currentAutolink.reportCreated.length"
                        v-if="false"
                    >
                        <GChart
                            type="LineChart"
                            :data="chartData"
                            :options="chartOptions"
                        />
                    </v-card>
                    <v-card
                        max-width="400"
                        flat
                        v-show="!this.$store.state.currentAutolink.reportCreated.length"
                        v-if="false"
                    >
                        <GChart
                            type="LineChart"
                            :data="emptyChart"
                            :options="emptychartOptions"
                        />
                    </v-card>
                    <v-btn
                        block
                        @click="saveAndNavigateBack()"
                        class="rounded-lg mt-6 mb-2"
                        height="50"
                        color="white"
                    >
                        {{ $t("ModifyAutolink.saveChanges") }}
                    </v-btn>
                </v-card>

                <h1 class="title mt-8 mb-6">{{ $t("ModifyAutolink.answers") }}</h1>
                <v-data-table
                    :headers="headers"
                    :items="this.$store.state.currentAutolinkSessions"
                    class="elevation-1 rounded-lg"
                    hide-default-footer
                    disable-pagination
                    style="max-width: 600px"
                    :sort-by="['time']"
                >
                    <template v-slot:item.name="{ item }">
                        <span>
                            {{ getDynamicName(item.report, this.$store.state.currentAutolink.reportNamingFieldId) }}
                        </span>
                    </template>

                    <template v-slot:item.type="{ item }">
                        <div v-if="!item"></div>
                        <v-icon> mdi-file-document </v-icon>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-row>
                            <v-btn icon @click="downloadPDF(item.report)">
                                <v-icon> mdi-download </v-icon>
                            </v-btn>
                            <v-btn icon @click="inspectReport(item.report)" class="ml-2">
                                <v-icon> mdi-eye </v-icon>
                            </v-btn>
                        </v-row>
                    </template>

                    <template v-slot:item.created="{ item }">
                        <span>{{ europeanDate(item.created) }}</span>
                    </template>
                </v-data-table>
                <!--
                <v-btn
                    class="rounded-lg mt-5 mb-2"
                    color="primary"
                    height="50"
                    dark
                    style="max-width: 600px"
                    @click="downloadAll()"
                >
                    {{ $t("ModifyAutolink.downloadAll") }}
                </v-btn>
                -->
            </v-flex>
        </v-container>

        <PDFGenerator ref="PDFGenerator" />
        <InspectReport
            :inspectDialog="inspectDialog"
            :report="report"
            @closeInspectDialog="closeInspectDialog"
        />

        <v-dialog
            v-model="addRecieverDialog"
            hide-on-leave
            class="pa-2"
            width="400"
        >
            <v-card class="rounded-lg pa-5" width="400">
                <v-container fluid>
                    <v-form ref="form">
                        <v-row class="pb-2">
                            <v-col class="pa-0">
                                <v-form ref="emailInputForm" :lazy-validation="true">
                                    <v-text-field
                                        data-cy="receiverEmail"
                                        class="ma-0 py-2 rounded-lg"
                                        :label="$t('ModifyAutolink.addRecieverEmail')"
                                        v-model="emailToAdd"
                                        :rules="emailRules"
                                        @keydown.enter.prevent="addRecieverAndCloseDialog()"
                                    >
                                    </v-text-field>
                                </v-form>
                            </v-col>
                            <v-col cols="3" class="pa-0">
                                <v-btn
                                    data-cy="addReceiver"
                                    color="primary"
                                    @click="addRecieverAndCloseDialog()"
                                    block
                                    class="rounded-lg white--text ml-2"
                                >
                                    {{ $t("ModifyAutolink.addReciever") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            elevation="1"
            color="primary"
            class="rounded-lg"
        >
            <h1 class="title">
                {{ snackbarText }}
            </h1>

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="closeSnackbar()">
                    {{ $t("ModifyAutolink.closeSnackbar") }}
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="copysnackbar"
            elevation="1"
            class="rounded-lg pa-0 ma-0"
            timeout="3000"
            color="grey darken-3"
        >
            <v-card
                class="d-flex flex-column black--text rounded-lg pa-0 ma-0"
                color="grey darken-3"
                elevation="0"
            >
                <v-card-text class="pa-0 ma-0">
                    <v-row align="center" justify="center">
                        <v-col cols="10"> {{ $t("ModifyAutolink.linkCopied") }} </v-col>
                        <v-col cols="2">
                            <v-icon class="float-right mx-auto"> mdi-content-copy </v-icon>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { autolinkCollection } from '../fbInitialize';
import SelectReportNamingField from '../components/GeneralizedComponents/SelectReportNamingField.vue';
import InspectReport from '../components/InspectReport.vue';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';
import PDFGenerator from '../components/FillReport/PDFGenerator.vue';
import { getDynamicName } from '../componentScripts/dynamicName';

export default {
    components: {
        SelectReportNamingField,
        PDFGenerator,
        InspectReport,
        MobileNavigation,
    },
    data() {
        return {
            listener1: null,
            addRecieverDialog: false,
            snackbar: false,
            copysnackbar: false,
            snackbarText: '',
            emailToAdd: '',
            selectedTemplate: {},
            name: '',
            inspectDialog: false,
            report: {},
            loading: false,
            requireSelect: [(v) => !!v || this.$t('ModifyAutolink.selectRule')],
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('ModifyAutolink.addRule'),
            ],
            autoLinkTemplate: {
                name: '',
                template_id: '',
                recievers: [],
                reportCreated: [], // This contains Timestamps of times when report is sended with the link
                link: '',
                firebaseDeleted: false,
            },
            //Dummy data for presentatioal reasons
            emptyChart: [
                [
                    this.$t('ModifyAutolink.chartDate'),
                    this.$t('ModifyAutolink.chartUsage'),
                ],
            ],
            chartData: [],
            chartOptions: {
                title: '',
                lineWidth: 4,
                intervals: { style: 'line' },
                legend: 'none',
            },
            emptychartOptions: {
                title: '',
                lineWidth: 4,
                intervals: { style: 'line' },
                legend: 'none',
                vAxis: {
                    viewWindow: {
                        max: 5,
                    },
                },
            },
            headers: [
                {
                    text: '',
                    value: 'type',
                    sortable: false,
                },
                {
                    text: this.$t('ModifyAutolink.tableName'),
                    align: 'start',
                    value: 'name',
                },
                { text: this.$t('ModifyAutolink.tableTime'), value: 'created' },
                {
                    text: this.$t('ModifyAutolink.tableActions'),
                    value: 'actions',
                    sortable: false,
                },
            ],
        };
    },
    methods: {
        copyLink() {
            this.copysnackbar = true;
            navigator.clipboard.writeText(this.getLink);
        },
        changeSelectedTemplate(obj) {
            this.autoLinkTemplate.template = obj;
            this.autoLinkTemplate.template_id = obj.template_id;
            this.$store.dispatch('modifyAutolink', this.autoLinkTemplate);
            this.$forceUpdate();
            // Clear reportnaming
        },
        openNamingDialog() {
            const template = this.getTemplate(this.$store.state.currentAutolink.template_id);
            this.$refs.namingDialog.openDialog(template);
        },
        addReportNaming(value) {
            this.$store
                .dispatch('addNamingFieldToAutolink', {
                    id: value.id,
                    label: value.label,
                });
        },
        closeNamingDialog() {
            this.namingDialog = false;
        },
        addRecieverAndCloseDialog() {
            if (this.emailToAdd != '' && this.$refs.emailInputForm.validate()) {
                this.autoLinkTemplate.recievers.push(this.emailToAdd);
                this.emailToAdd = '';
                this.closeAddReceiverDialog();
            }
        },
        openAddReceiverDialog() {
            this.addRecieverDialog = true;
        },
        closeAddReceiverDialog() {
            this.addRecieverDialog = false;
        },
        closeSnackbar() {
            this.snackbar = false;
        },
        saveAndNavigateBack() {
            if (this.autoLinkTemplate.recievers.length) {
                this.autoLinkTemplate.updatedAt = Math.round(
                    new Date().getTime() / 1000
                );
                this.$store
                    .dispatch('modifyAutolink', this.autoLinkTemplate)
                    .then(() => {
                        this.$router.push({
                            name: 'autolinks',
                            params: { datasent: 'autolink' },
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.snackbarText = this.$t('ModifyAutolink.addAtLeastOne');
                this.snackbar = true;
            }
        },
        navigateBack() {
            this.$router.push({
                name: 'autolinks',
            });
        },
        deleteReceiver(index) {
            if (this.autoLinkTemplate.recievers.length === 1) {
                this.snackbarText = this.$t('ModifyAutolink.cantDelete');
                this.snackbar = true;
            } else {
                this.autoLinkTemplate.recievers.splice(index, 1);
            }
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);
            return d
                .toLocaleString('en-GB', { timeZone: 'Europe/Helsinki' })
                .slice(0, 10);
        },
        europeanDate(epochTime) {
            let date = this.formatTime(epochTime);
            return date.replaceAll('/', '.');
        },
        mapTimestamps(array) {
            array.map((x) => new Date(x));
        },
        async downloadPDF(report) {
            if (!this.loading) {
                this.loading = true;
                console.log(
                    report,
                    this.$store.state.currentAutolink.reportNamingFieldId
                );
                await this.$refs.PDFGenerator.downloadPDF(
                    report,
                    this.$store.state.currentAutolink.reportNamingFieldId
                );
                this.loading = false;
            }
        },
        closeInspectDialog() {
            this.inspectDialog = false;
            this.report = {};
        },
        inspectReport(item) {
            this.report = item;
            this.inspectDialog = true;
        },
        async downloadAll() {
            let answers = this.$store.state.currentAutolinkSessions;
            for (let answer of answers) {
                let report = answer.report;
                await this.$refs.PDFGenerator.downloadPDF(
                    report,
                    this.$store.state.currentAutolink.reportNamingFieldId
                );
            }
        },
        getDynamicName: getDynamicName,
    },
    computed: {
        ...mapGetters(['getTemplates', 'getTemplateName', 'getTemplate']),
        ...mapState(['currentAutolinkSessions']),

        getLink() {
            return (
                location.origin +
                '/fillautolinkreport/' +
                this.$store.state.currentAutolink.autolinkId || ''
            );
        },
        getNamingField() {
            //let template = this.getTemplate(this.$store.state.currentAutolink.template_id)
            return this.$store.state.currentAutolink.namingFieldLabel;
        },

        occurrences() {
            const formatted = this.autoLinkTemplate.reportCreated.map((x) =>
                this.formatTime(new Date(x))
            );
            const data = [...new Set(formatted)].map((x) => [
                x,
                formatted.filter((y) => y === x).length,
            ]);
            data.unshift(['Päivämäärä', 'Käyttökertoja']);
            return data;
        },
    },

    created() {
        this.$store.dispatch('getAutolinkSessions');

        const docref = autolinkCollection.doc(
            this.$store.state.currentAutolink.autolinkId
        );
        this.listener1 = docref.onSnapshot({}, (doc) => {
            this.autoLinkTemplate = doc.data();
            this.chartData = this.occurrences;
            this.emptyChart.push([this.formatTime(new Date() / 1000), 0]);
            this.$store.dispatch('modifyAutolink', doc.data());
            if (this.selectedTemplate == {}) {
                this.selectedTemplate = this.currentAutolink.template;
            }
            this.$forceUpdate();
        });
    },
    beforeDestroy() {
        this.listener1();
        this.$store.commit('resetAutolinkSession');
    },
};
</script>

<style scoped>
.row-pointer :deep(title :hover) {
  cursor: pointer;
}
</style>
