<template>
    <v-container>
        <v-form ref="form">
            <v-text-field
                v-model="obj.firstname"
                :label="$t('AddUser.firstname')"
                :placeholder="$t('AddUser.firstname')"
                required
            ></v-text-field>

            <v-text-field
                v-model="obj.surname"
                :label="$t('AddUser.lastname')"
                :placeholder="$t('AddUser.lastname')"
                required
            ></v-text-field>

            <v-text-field
                v-model="obj.email"
                :label="$t('AddUser.email')"
                :placeholder="$t('AddUser.email')"
                required
            ></v-text-field>

            <v-text-field
                v-if="makeOrganization"
                v-model="obj.organizationName"
                :label="$t('AddUser.organizationName')"
            ></v-text-field>

            <v-text-field
                v-else
                v-model="inputOrganizationId"
                :label="$t('AddUser.organizationID')"
            ></v-text-field>

            <v-checkbox
                v-model="makeOrganization"
                :label="$t('AddUser.createOrganization')"
            ></v-checkbox>

            <v-btn
                color="primary"
                dark
                width="400"
                class="mr-4 rounded-lg"
                @click="addUser"
                :loading="loading"
            >
                {{ $t("AddUser.addUser") }}
            </v-btn>
            <span v-if="orgId !== ''">
                {{ $t("AddUser.organizationID2") }} {{ orgId }}
            </span>
        </v-form>
        <v-card class="mt-4">
            <v-card-title>{{ $t("AddUser.cloneTemplate") }} </v-card-title>
            <v-autocomplete
                :items="getTemplates"
                :item-text="(item) => item.templateName"
                return-object
                :label="$t('CreateReport.Form.ReportTemplate')"
                :no-data-text="$t('CreateReport.Form.NoData')"
                @change="changeSelectedTemplate"
                class="pa-2"
            >
            </v-autocomplete>
            <v-text-field
                :label="$t('AddUser.selectedUID')"
                class="pa-2"
                v-model="selectedUID"
            >
            </v-text-field>
            <v-btn
                color="primary"
                dark
                width="400"
                class="ma-4 rounded-lg"
                @click="cloneTemplate"
                :loading="loading"
            >
                {{ $t("AddUser.closeTemplate") }}
            </v-btn>
        </v-card>
    </v-container>
</template>

<script>
import { firebaseFunctions } from '../fbInitialize';
import { userTemplate } from '../datamodels/datamodels.js';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    data() {
        return {
            obj: {},
            orgId: '',
            inputOrganizationId: '',
            makeOrganization: false,
            selectedTemplate: {},
            selectedUID: '',
            loading: false,
        };
    },
    methods: {
        changeSelectedTemplate(obj) {
            this.selectedTemplate = obj;
        },
        addUser() {
            this.loading = true;
            this.obj['role'] = 'admin';
            this.obj['makeOrganization'] = this.makeOrganization;
            this.obj['organization'] = this.inputOrganizationId;
            firebaseFunctions
                .httpsCallable('addUser')(this.obj)
                .then((data) => {
                    this.loading = false;
                    this.orgId = data.data.id;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        cloneTemplate() {
            this.loading = true;
            firebaseFunctions
                .httpsCallable('cloneFolderToUser')({
                    templateId: this.selectedTemplate.template_id,
                    receiverUID: this.selectedUID,
                })
                .then((payload) => {
                    alert('Success: ' + payload.data.success);
                    this.loading = false;
                })
                .catch((error) => {
                    alert('status:', error);
                    this.loading = false;
                });
        },
    },
    created() {
        this.obj = _.cloneDeep(userTemplate);
        this.obj['organizationName'] = '';
    },
    computed: {
        ...mapGetters(['getTemplates']),
    },
};
</script>
