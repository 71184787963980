export function getDynamicName(report, namingFieldId) {
    if (!Array.isArray(report.sections)) {
        return '';
    }
    for (const section of report.sections) {
        const matchingField = section.fields.find(f => f.id === namingFieldId);
        if (matchingField) {
            return matchingField.data;
        }
        const foundInSubsection = getDynamicName(section, namingFieldId);
        if (foundInSubsection && foundInSubsection !== '') {
            return foundInSubsection;
        }
    }
    return '';
}
