import {isHttp} from './protocolEnsurance';
import {generateId} from './generateId';

export default class ImageUtils {
    constructor(link = '') {
        if (!isHttp(link)) {
            throw new Error(`Invalid protocol in link: "${link}"`);
        }
        this.link = link;
        this.width = 0;
        this.height = 0;
        this.imageGarbageCollectionID = generateId();

        this.loadAttributes();
    }

    loadAttributes() {
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.src = this.link;

        this.width = img.width;
        this.height = img.height;
    }

    get base64() {
        return this.toDataURL();
    }

    toDataURL() {
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.src = this.link;

        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        return canvas.toDataURL('image/png');
    }
}
