<template>
    <!--This component is shown if the app is used with mobile -->
    <v-container class="mobile-navigation">
        <v-row class="pr-4">
            <v-col cols="10">
                <v-img
                    src="../../../public/logo.svg"
                    max-width="140"
                    class="ml-2 my-2"
                >
                </v-img>
            </v-col>
            <v-col cols="2" align="end" class="mt-1">
                <v-btn color="white" x-large icon @click="showMenu = !showMenu">
                    <v-icon color="white">mdi-menu</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-navigation-drawer
            v-model="showMenu"
            fixed
            color="primary"
            right
            app
            temporary
            class="sidebar"
        >
            <v-list>
                <v-list-item
                    v-for="item in navItems"
                    :key="item.title"
                    @click="navigate(item.routeName)"
                    link
                >
                    <div
                        class="dark-bg w-100 px-3"
                        :class="{'light-bg':$route.name === item.routeName ||
                            ($route.name.includes('templategenerator') && item.routeName === 'templates') ||
                            ($route.name.includes('fillreport') && item.routeName === 'dashboard') }"
                    >
                        <v-list-item-icon>
                            <v-icon
                                class="dark-bg"
                                :class="{'light-bg':$route.name === item.routeName ||
                                    ($route.name.includes('templategenerator') && item.routeName === 'templates') ||
                                    ($route.name.includes('fillreport') && item.routeName === 'dashboard') }"
                            >
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content data-cy="navItems">
                            <v-list-item-title>{{
                                $t("Navigation.items." + item.routeName)
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </div>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <hr class="light-gray-divider"/>
                <v-list class="my-3">
                    <v-list-item
                        v-for="item in controlItems"
                        :key="item.title"
                        @click="navigate(item.routeName)"
                        link
                    >
                        <div
                            class="px-3 w-100"
                            :class="{ 'light-bg': $route.name === item.routeName,
                                      'dark-bg': $route.name !== item.routeName }"
                        >
                            <v-list-item-icon>
                                <v-icon
                                    :class="{ 'light-bg': $route.name === item.routeName,
                                              'dark-bg': $route.name !== item.routeName }"
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content data-cy="controlItems">
                                <v-list-item-title>{{
                                    $t("Navigation.items." + item.routeName)
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </div>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import navigationScript from '../../componentScripts/navigationScript';

export default {
    data: () => {
        let baseData = navigationScript.data();
        baseData.showMenu = false;
        return baseData;
    },
    methods: navigationScript.methods,
    computed: navigationScript.computed
};
</script>
