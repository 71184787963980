import {Component} from '../../../../component';

class HeaderStyle extends Component {
    code() {
        return {
            fontSize: 11,
            margin: [20, 20, 20, 20],
        };
    }
}

export default HeaderStyle;
