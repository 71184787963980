const envMode = process.env.NODE_ENV;

let fbConfig;

switch (envMode) {
case 'development':
    fbConfig = {
        apiKey: 'AIzaSyBJKoO11cpye6D9CFM2bsUYQNxbT_PrjBs',
        authDomain: 'renome-staging-2.firebaseapp.com',
        projectId: 'renome-staging-2',
        storageBucket: 'renome-staging-2.appspot.com',
        messagingSenderId: '326287378247',
        appId: '1:326287378247:web:7cceec13b787c70b382e62',
        measurementId: 'G-204WZN8ENJ'
    };
    break;
case 'production':
    fbConfig = {
        apiKey: 'AIzaSyD7YsrqgFpz5vRlRNCZvTDv6bmA_Hn4_es',
        authDomain: 'formupprod.firebaseapp.com',
        projectId: 'formupprod',
        storageBucket: 'formupprod.appspot.com',
        messagingSenderId: '203757015730',
        appId: '1:203757015730:web:365def80b2ec02c9652fab'
    };
    break;
case 'emulator':
    // Same settings with 'development'
    fbConfig = {
        apiKey: 'AIzaSyBJKoO11cpye6D9CFM2bsUYQNxbT_PrjBs',
        authDomain: 'renome-staging-2.firebaseapp.com',
        projectId: 'renome-staging-2',
        storageBucket: 'renome-staging-2.appspot.com',
        messagingSenderId: '326287378247',
        appId: '1:326287378247:web:7cceec13b787c70b382e62',
        measurementId: 'G-204WZN8ENJ'
    };
    break;
case 'staging':
    fbConfig = {
        apiKey: 'AIzaSyCr5Ey8qd5-b8_XVWj0QjMCjvYt7jSpIXI',
        authDomain: 'stagingformup.firebaseapp.com',
        projectId: 'stagingformup',
        storageBucket: 'stagingformup.appspot.com',
        messagingSenderId: '999800170134',
        appId: '1:999800170134:web:341b12aa25795bc64884b7'
    };
    break;
default:
    throw new Error('Unknown environment mode');
}

export { fbConfig };
