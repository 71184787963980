import {Component} from '../../../../component';

class StaticFileStyle extends Component {
    code() {
        return {
            color: '#50ABFF'
        };
    }
}

export default StaticFileStyle;
