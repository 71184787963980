<template>
    <v-container fluid>

        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :pageHeader=" $t('Autolinks.header')"
        />
        <h1 class="title">{{ $t('Autolinks.header') }}</h1>
        <AutolinkTable />
        <v-btn
            data-cy="createAutolink"
            class="rounded-lg"
            @click="openCreateAutolink"
            color="primary"
            dark
        >
            <v-icon class="mr-2">mdi-file-link</v-icon>
            {{ $t('Autolinks.newAutoLink') }}
        </v-btn>
        <CreateAutolink ref="createAutolink" />
        <v-snackbar
            v-model="snackbar"
            elevation="1"
            color="primary"
            class="rounded-lg"
        >
            <h1 class="title">
                {{ snackbarText }}
            </h1>

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="closeSnackbar()">
                    {{ $t('Autolinks.close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import AutolinkTable from '../components/Autolinks/AutolinkTable.vue';
import CreateAutolink from '../components/Autolinks/CreateAutolink.vue';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';

export default {
    data() {
        return {
            snackbar: false,
            snackbarText: '',
        };
    },
    components: {
        AutolinkTable,
        CreateAutolink,
        MobileNavigation
    },
    methods: {
        closeSnackbar() {
            this.snackbar = false;
        },
        openCreateAutolink() {
            this.$refs.createAutolink.openDialog();
        },
        navigateBack() {
            this.$router.push({ name: 'dashboard' });
        },
    },
    created() {
        window.scrollTo(0, 0);
        let datasent = this.$route.params['datasent'];
        if (datasent == 'autolink') {
            this.snackbarText = this.$t('Autolinks.snackbarText');
            this.snackbar = true;
        }
    },
};
</script>
