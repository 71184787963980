<template>
    <v-container
        fill-height
        :style="{ background: $vuetify.theme.themes.light.primary }"
        fluid
    >
        <v-row align="center" justify="center">
            <v-card class="rounded-lg" width="400">
                <v-card-text>
                    <v-container fluid class="pa-0 ma-0">
                        <h1 class="h4 black--text pt-2">
                            {{ $t("Forgotpassword.header") }}
                        </h1>
                        <p class="pt-6 mb-0">{{ $t("Forgotpassword.instructions") }}</p>
                        <form @submit.prevent v-show="!success">
                            <v-flex xs12>
                                <v-form @submit.prevent ref="emailInputForm" :lazy-validation="true">
                                    <v-text-field
                                        data-cy="emailInput"
                                        name="email"
                                        type="email"
                                        id="email"
                                        v-model="email"
                                        :rules="emailRules"
                                        :label="$t('Forgotpassword.Form.email')"
                                        required
                                        class="pb-2"
                                    ></v-text-field>
                                    <v-card-actions class="pa-0 ma-0">
                                        <v-btn
                                            data-cy="sendResetMail"
                                            type="submit"
                                            @click="sendPasswordResetMail()"
                                            class="rounded-lg"
                                            block
                                            color="primary"
                                            @keydown.enter.prevent="sendPasswordResetMail()"
                                        >
                                            {{ $t("Forgotpassword.Form.send") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-flex>
                            <layout row class="pt-6 ma-0" v-show="errMsg != ''">
                                <v-flex xs12>
                                    <v-alert type="error" rounded>
                                        {{ errMsg }}
                                    </v-alert>
                                </v-flex>
                            </layout>
                        </form>
                        <layout row class="pt-6 ma-0" v-show="success">
                            <v-flex xs12>
                                <v-alert data-cy="successMessage" type="success" rounded>
                                    {{ successMsg }}
                                </v-alert>
                            </v-flex>
                        </layout>
                        <v-btn
                            text
                            block
                            @click="navigateToLogin"
                            class="mt-2 rounded-lg font-weight-regular"
                        >
                            {{ $t("Forgotpassword.navigateBackLogIn") }}
                            <v-icon right dark> mdi-login </v-icon>
                        </v-btn>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row align="center" justify="center">
            <v-card class="rounded-lg" width="300" color="transparent" elevation="0">
            </v-card>
        </v-row>
        <BaseLoadingDialog
            :sendingData="loggingProcessOngoing"
            :msg="$t('Forgotpassword.loadingMessage')"
        >
        </BaseLoadingDialog>
    </v-container>
</template>

<script>
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import router from '../router';

export default {
    components: {
        BaseLoadingDialog,
    },
    data() {
        return {
            email: '',
            errMsg: '',
            successMsg: '',
            success: false,
            loggingProcessOngoing: false,
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('Forgotpassword.Form.rule'),
            ],
        };
    },
    methods: {
        navigateToLogin() {
            router.push('/');
        },
        sendPasswordResetMail() {
            if (this.$refs.emailInputForm.validate()) {
                this.loggingProcessOngoing = true;
                this.$store
                    .dispatch('sendForgotPasswordEmail', this.email)
                    .then(() => {
                        this.success = true;
                        this.successMsg = this.$t('Forgotpassword.successMessage');
                        this.loggingProcessOngoing = false;
                        // Show success
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorCode);
                        console.log(errorMessage);
                        // ..
                        this.errMsg = this.$t('Forgotpassword.errorMessage');
                        this.loggingProcessOngoing = false;
                    });
            }
        },
    },
};
</script>
