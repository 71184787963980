import {PDFDocument} from '../../pdf';
import {fields} from './fields';
import Header from './components/header';
import SectionTitle from './components/sectionTitle';
import HeaderStyle from './components/styles/style.header';
import SectionTitleStyle from './components/styles/style.sectionTitle';
import SectionExplanationStyle from './components/styles/style.sectionExplanation';
import DefaultStyle from './style.pdf';
import InfoStyle from './fields/styles/style.info';
import ImageStyle from './fields/styles/style.image';
import StaticFileStyle from './fields/styles/style.FileField';
import CommentArea from './fields/styles/style.commentArea';
import {asComponent} from './components/helpers/asComponent';
import Footer from './components/footer';
import CoverLetter from './components/coverLetter';
import coverLetterStyle from './components/styles/style.coverLetter';
import FieldStyle from './fields/styles/style.field';
import {RecursiveDepthAnalyzer} from './utils/treeTraversal/recursiveDepthAnalyzer';
import FieldExplanation from './components/fieldExplanation';
import SectionExplanation from './components/sectionExplanation';


const unexplainedFields = [
    'choice',
    'textinput',
    'slider',
    'date',
    'table'
];


export default class V1PdfBase extends PDFDocument {
    fields = fields;
    depthAnalyzer = new RecursiveDepthAnalyzer();

    initialStyle() {
        return {
            ...asComponent(Header, {pdf: this}),
            ...asComponent(Footer),
            styles: {
                header: asComponent(HeaderStyle),
                SectionTitleStyle: asComponent(SectionTitleStyle),
                SectionExplanationStyle: asComponent(SectionExplanationStyle),
                InfoStyle: asComponent(InfoStyle),
                ImageStyle: asComponent(ImageStyle),
                StaticFileStyle: asComponent(StaticFileStyle),
                CommentStyle: asComponent(CommentArea),
                coverLetter: asComponent(coverLetterStyle),
                FieldStyle: asComponent(FieldStyle),
            },
            defaultStyle: asComponent(DefaultStyle)
        };
    }

    build() {
        console.log('Using v1 of PDFTools.');
        this.addToDocDefinition(this.initialStyle());
        this.addComponent(CoverLetter, {pdf: this});
        this.renderSectionFields(this.report.sections);
        console.log('------------ BUILT REPORT --------');
        return this.docDefinition;
    }

    renderSectionFields(sections) {
        this.depthAnalyzer.addLayer();
        if(sections.length === 0) {
            return;
        }

        for (const section of sections)  {
            this.depthAnalyzer.addValue();
            this.addComponent(
                SectionTitle,
                {
                    text: section.sectionName,
                    recursiveDepth: this.depthAnalyzer.layers,
                },
            );

            this.addComponent(
                SectionExplanation,
                {explanation: section.question ? section.question : ''}
            );

            for(let field of section.fields) {
                let fieldItem = this.getField(field.type);

                if (!fieldItem) {
                    continue;
                }

                console.log('[PDFTools/pdf.js]: Draw Field:', field.type);

                fieldItem = new fieldItem(field);

                if(fieldItem.isEmpty) {
                    continue;
                }

                if(field.question && !unexplainedFields.includes(field.type)) {
                    let fieldExplanation = asComponent(FieldExplanation, {explanation: field.question});
                    this.addContent(fieldExplanation);
                }

                let content = fieldItem.code();
                content = this.formatContent(content);
                this.addContent(content);

                fieldItem.after(this);
            }

            this.renderSectionFields(section.sections);
            this.depthAnalyzer.removeLayer();
        }
    }

    addComponent(component, args={}) {
        component = asComponent(component, args);
        this.addContent(component);
    }

    formatContent(component) {
        component.margin =
            component.margin ?
                component.margin :
                asComponent(FieldStyle).margin;

        return component;
    }
}
