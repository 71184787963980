<template>
    <v-dialog
        v-model="dialog"
        hide-on-leave
        width="600"
        class="pa-2"
        @click:outside="closeDialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-row
            v-if="!$vuetify.breakpoint.mdAndUp"
            class="modal-header-mobile h1-16 mx-0 items-center"
        >
            <v-col cols="2">
                <v-icon color="primary" class="icon-plus" @click="closeDialog()">
                    mdi-chevron-left
                </v-icon>
            </v-col>
            <v-col cols="8" align="center">
                <h1> {{ $t("MoveFolderReport.heading") }}</h1>
            </v-col>
            <v-col cols="2">
            </v-col>
        </v-row>
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-form ref="form">
                <v-container fluid :class="{ 'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp }">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>
                                {{ $t("MoveFolderReport.heading") }}
                            </h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                class="close-button"
                                @click="closeDialog"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <div :class="{'mb-150': !$vuetify.breakpoint.mdAndUp, 'mt-5': $vuetify.breakpoint.mdAndUp}">
                        <v-treeview
                            :open="initiallyOpen"
                            :items="currentFolderTree"
                            :active.sync="activeItems"
                            activatable
                            return-object
                            item-key="id"
                            open-all
                        >
                            <template v-slot:label="{ item, open }">
                                <v-row align="center">
                                    <v-col cols="1">
                                        <v-icon>
                                            {{ open ? "mdi-folder-open" : "mdi-folder" }}
                                        </v-icon>
                                    </v-col>
                                    <v-col justify="start" class="ml-2">
                                        <span data-cy="moveItemHere" v-if="item.name">{{
                                            item.name
                                        }}</span>
                                        <span data-cy="moveItemHere" v-else>
                                            {{ $t("MoveFolderReport.reports") }}</span
                                        >
                                    </v-col>
                                </v-row>
                            </template>
                        </v-treeview>
                    </div>
                    <v-row
                        class="mb-0 pt-7"
                        :class="{'mobile-dialog-controls ma-0 px-3 pb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="closeDialog"
                            >
                                {{ $t("AddUser.discard") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp, 'disabled-button': isBtnValid}"
                                @click="!isBtnValid && moveItems()"
                                data-cy="moveItem"
                            >
                                {{ $t("MoveFolderReport.moveHere") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <BaseLoadingDialog
                :sendingData="loading"
                :msg="$t('Baseloading.loadingMessage')"
            />
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLoadingDialog from './GeneralizedComponents/BaseLoadingDialog.vue';

export default {
    name: 'MoveFldrRprt',
    components: {
        BaseLoadingDialog,
    },
    props: {
        clear: { type: Function },
        selection: { type: Array }
    },
    data() {
        return {
            dialog: false,
            loading: false,
            initiallyOpen: ['public'],
            activeItems: [],
            currentFolderTree: null
        };
    },
    methods: {
        async moveItems() {
            let activeItem = this.activeItems[0];
            this.loading = true;
            for(const itemToMove of this.itemsToMove) {
                let payload = {
                    itemToMove: itemToMove,
                    folderToMoveFrom: this.getCurrentFolderData,
                    selectedFolder: activeItem,
                };
                await this.$store.dispatch('moveItem', payload);
            }
            this.loading = false;
            this.closeDialog();
            this.clear(this.selection);
        },
        openDialog(itemsToMove) {
            this.itemsToMove = itemsToMove;
            // Notice items contain folderIds and ReportIds
            const itemIds = itemsToMove.map(x => x.id);
            this.currentFolderTree = this.getFolderTree(itemIds);
            this.activeItems = [];
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
    },
    computed: {
        ...mapGetters(['getFolderTree', 'getCurrentFolderData']),
        isBtnValid() {
            return !this.activeItems.length > 0;
        },
    },
};
</script>
