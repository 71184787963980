<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()" data-cy="closeCreateReportDialog">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("CreateReport.header") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pt-header pb-7 px-5': !$vuetify.breakpoint.mdAndUp}">
                <v-form ref="form">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols='10'>
                            <h1>{{ $t("CreateReport.header") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                data-cy="closeCreateReportDialog"
                                class="close-button"
                                @click="closeDialog()"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 pl-1">
                        <v-text-field
                            :label="$t('CreateReport.Form.ReportName')"
                            v-model="reportName"
                            :rules="requireName"
                            data-cy="addReportName"
                            counter="50"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row class="mx-0" data-cy="selectTemplate">
                        <v-autocomplete
                            :items="getTemplates"
                            :item-text="(item) => item.templateName"
                            return-object
                            :label="$t('CreateReport.Form.ReportTemplate')"
                            :no-data-text="$t('CreateReport.Form.NoData')"
                            :rules="requireSelect"
                            class="my-2"
                            @change="changeSelectedTemplate"
                            prepend-inner-icon="mdi-magnify"
                        >
                        </v-autocomplete>
                    </v-row>
                    <v-row
                        class="mt-3 mb-0"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="closeDialog()"
                            >
                                {{ $t("CreateReport.cancel") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                @click="createAndOpenReport"
                                data-cy="createAndOpenReport"
                            >
                                {{ $t("CreateReport.createReport") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
        <BaseLoadingDialog
            :sendingData="loading"
            :msg="$t('Baseloading.loadingMessage')"
        />
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';

export default {
    props: ['shared'],
    components: {
        BaseLoadingDialog,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            reportName: '',
            selectedTemplate: {},
            requireName: [
                (v) => (v && v.length > 0) || this.$t('CreateReport.Rules.giveName'),
            ],
            requireSelect: [
                (v) => !!v || this.$t('CreateReport.Rules.chooseTemplate'),
            ],
            emailToAdd: '',
            emailRules: [
                (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('CreateReport.Rules.validEmail'),
            ],
            initiallyOpen: ['public'],
        };
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        createAndOpenReport() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                var templateClone = _.cloneDeep(this.selectedTemplate);
                templateClone['name'] = this.reportName;
                templateClone.createdAt = Math.round(new Date().getTime() / 1000);
                templateClone.updatedAt = Math.round(new Date().getTime() / 1000);
                templateClone.markedReady = false;
                let parentFolder = this.currentFolder;
                if (parentFolder.participants.length > 0) {
                    templateClone.shared = true;
                }
                parentFolder.updatedAt = Math.round(new Date().getTime() / 1000);
                this.$store
                    .dispatch('addReport', {
                        report: templateClone,
                        folderID: this.currentFolder.id,
                        shared: this.shared,
                    })
                    .then((data) => {
                        this.loading = false;
                        this.$router.push({
                            name: 'fillreport',
                            params: { reportId: data },
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                this.$store.dispatch('updateFolder', parentFolder.id);
            }
        },
        changeSelectedTemplate(obj) {
            this.selectedTemplate = obj;
        },
        navigateBack() {
            this.closeDialog();
        },
    },
    computed: {
        ...mapGetters([
            'getTemplates',
            'getCurrentFolderData',
            'getCurrentSharedFolderData',
        ]),
        currentFolder() {
            return this.$route.name === 'dashboard'
                ? this.getCurrentFolderData
                : this.getCurrentSharedFolderData;
        },
    },
};
</script>

<style scoped>
.pick-hover:hover {
  cursor: pointer;
}
.v-list {
  padding: 19px;
}
</style>
