<template>
    <v-dialog
        v-model="dialog"
        @click:outside="closeAndErase()"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            :width="$vuetify.breakpoint.mdAndUp ? '' : '600'"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeAndErase()">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t('AddSignature.drawSignature') }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1>{{ $t('AddSignature.drawSignature') }}</h1>
                    </v-col>
                    <v-col cols="2" align="end">
                        <v-icon
                            class="close-button"
                            @click="closeAndErase()"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row class="mx-0 my-4 px-3">
                    <v-card class="signature-pad w-100" :height="$vuetify.breakpoint.mdAndUp ? '400' : '200'">
                        <VueSignaturePad
                            id="signature"
                            width="100%"
                            :height="$vuetify.breakpoint.mdAndUp ? '400px' : '200px'"
                            ref="signaturePad"
                            :options="options"
                        />
                    </v-card>
                </v-row>
                <v-row class="mx-0">
                    <v-text-field
                        :placeholder="$t('AddSignature.printName')"
                        v-model="printName"
                        class="mx-3"
                    ></v-text-field>
                </v-row>
                <v-row
                    class="mt-3 mb-0"
                    :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                >
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            class="secondary-button"
                            :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                            @click="closeAndErase()"
                        >
                            {{ $t("AddSignature.clear") }}
                        </v-btn>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        align="end"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            class="primary-button"
                            :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                            @click="saveAndUpload()"
                        >
                            {{ $t('Tallenna') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('Fillfield.addSignatureAndPrintName')" />

        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('Fillfield.loadingMessage')"
        />
    </v-dialog>
</template>

<script>
import { sendImageObj } from '../../fbImageUploader';
import VueSignaturePad from 'vue-signature-pad';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: {
        BaseLoadingDialog,
        VueSignaturePad,
        GeneralSnackbar,
    },
    data() {
        return {
            dialog: false,
            hovered: false,
            defaultSignatureUrl: '',
            options: {
                penColor: 'black',
            },
            printName: '',
            field: {},
            sendingData: false,
        };
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        closeAndErase() {
            this.clear();
            this.dialog = false;
        },
        async saveAndUpload() {
            // Upload
            this.sendingData = true;
            let obj = await this.saveSignature();
            this.sendingData = false;
            if (obj) {
                this.$emit('signatureAdded', obj);
                this.closeAndErase();
            }
        },
        loadAndDownscale(imgObj) {
            let base64content = imgObj.split(',');
            //get datatype e.g image/jpeg
            let mimetype = base64content[0].match(
                /[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/
            )[0];
            //get image width for compression
            let img = new Image();
            async function getWidth() {
                return new Promise((resolve) => {
                    img.onload = function () {
                        let originalwidth = img.width;
                        resolve(originalwidth);
                    };
                });
            }
            img.src = imgObj;
            let ref = this;
            return getWidth().then(async function (width) {
                if (width < 1080) {
                    return ref.downscaleImage(imgObj, width, mimetype);
                } else {
                    return ref.downscaleImage(imgObj, 1080, mimetype);
                }
            });
        },
        downscaleImage(dataUrl, newWidth) {
            let image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;
            let imageArguments = 0.9;
            let maxCompressedImageSizeKbs = 135;

            // Create a temporary image so that we can compute the height of the downscaled image.
            image = new Image();
            image.src = dataUrl;
            oldWidth = image.width;
            oldHeight = image.height;
            newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

            // Create a temporary canvas to draw the downscaled image on.
            canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the downscaled image on the canvas and return the new data URL.
            ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, newWidth, newHeight);

            // Data url size check, compress until image is small enough
            let head = 'data:image/png;base64,';
            newDataUrl = dataUrl;
            let imgFileSize =
                Math.round(((dataUrl.length - head.length) * 3) / 4) / 1000;
            while (imgFileSize > maxCompressedImageSizeKbs) {
                newDataUrl = canvas.toDataURL('image/jpeg', imageArguments);
                imgFileSize =
                    Math.round(((newDataUrl.length - head.length) * 3) / 4) /
                    1000;
                imageArguments -= 0.1;
            }
            return newDataUrl;
        },
        async saveSignature() {
            if (!this.$refs.signaturePad.isEmpty() && this.printName != '') {
                let epochtime = Math.round(new Date().getTime() / 1000);
                let imgData = this.$refs.signaturePad.saveSignature();
                let imgUrl = imgData.data;
                return this.loadAndDownscale(imgUrl).then(async (newImgUrl) => {
                    let img = newImgUrl;
                    img = await sendImageObj(newImgUrl);
                    return {
                        url: img,
                        printName: this.printName,
                        timestamp: epochtime,
                    };
                });
            } else {
                this.$refs.GeneralSnackbar.snackbar = true;
                return undefined;
            }
        },
        openDialog(field) {
            this.field = field;
            this.dialog = true;
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 300);
        },
        clear() {
            window.dispatchEvent(new Event('resize'));
            this.$refs.signaturePad.clearSignature();
            this.printName = '';
        },
        hoverOn() {
            this.hovered = true;
        },
        hoverOff() {
            this.hovered = false;
        },
    },
};
</script>
