import {Component} from '../../../../component';

class FieldExplanationStyle extends Component {
    code() {
        return {
            fontSize: 12,
            bold: true,
            margin: [40, 40, 40, 12],
            color: '#303E59'
        };
    }
}

export default FieldExplanationStyle;
