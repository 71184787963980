import {Field} from '../../../field';
import {formatTime} from '../utils/epoch';
import {asComponent} from '../components/helpers/asComponent';
import CommentTitleStyle from './styles/style.commentTitle';
import CommentFieldStyle from './styles/style.commentField';


export default class CommentField extends Field {
    code() {
        return {
            ...asComponent(CommentFieldStyle),
            stack: [
                {
                    text: this.title,
                    ...asComponent(CommentTitleStyle)
                },
                ...this.#commentFields
            ]
        };
    }

    get #commentFields() {
        let comments = [];

        for(const comment of this.data) {
            comments.push(
                {
                    text: `${comment.message}\n${comment.sender} - ${formatTime(comment.timestamp)}`,
                    style: 'CommentStyle'
                }
            );
        }
        return comments;
    }

    get isEmpty() {
        return this.data.length === 0;
    }
}
