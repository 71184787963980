<template>
    <div class="template-generator-base">
        <draggable
            tag="v-layout"
            v-model="report.sections"
            ghost-class="ghost-card"
            handle='.my-handle'
            animation="350"
        >
            <v-card
                v-for="(sectionData, sectionIndex) in report.sections"
                :key="sectionIndex"
                elevation="0"
                class="border-10"
            >
                <Section
                    :report="report"
                    :sectionData="report.sections[sectionIndex]"
                    :OkCancelDialog="OkCancelDialog"
                    :sectionNumber="sectionIndex + 1"
                    @sectionDeleted="deleteSection(report.sections, sectionIndex)"
                    @sectionCopied="copySection"
                    :allowSubSection="true"
                />
            </v-card>
        </draggable>
        <v-container :class="{'mb-10': $vuetify.breakpoint.mdAndUp, 'px-7 mb-4':!$vuetify.breakpoint.mdAndUp}">
            <v-row>
                <v-btn
                    @click="addSection"
                    class="button-add-section"
                    data-cy="addSection"
                >
                    <v-icon class="icon-plus">
                        mdi-plus-circle-outline
                    </v-icon>
                    {{ $t('ReportCreator.addNewSection') }}
                </v-btn>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import _ from 'lodash';
import Section from './Section.vue';
import draggable from 'vuedraggable';
import { sectionTemplate } from '../../datamodels/datamodels.js';

export default {
    props: ['report', 'OkCancelDialog'],
    components: {
        Section,
        draggable,
    },
    methods: {
        addSection() {
            var templateClone = _.cloneDeep(sectionTemplate);
            this.report.sections.push(templateClone);
        },
        addSectionNumber() {
            this.sectionNumber += 1;
        },
        deleteSection(section, index) {
            section.splice(index, 1);
            this.$forceUpdate();
        },
        copySection(event, item, index) {
            var template = this.report.sections[index];
            var templateClone = _.cloneDeep(sectionTemplate);
            templateClone = _.cloneDeep(template);
            this.report.sections.push(templateClone);
        },
    },
};
</script>

<style scoped>
.ghost-card {
  opacity: 0.5;
  color: grey;
}
.my-handle {
  cursor: move;
  cursor: -webkit-move;
}
</style>

