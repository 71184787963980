<template>
    <div class="wrapper" :class="{ 'mb-12': $vuetify.breakpoint.mdAndUp }">
        <v-form lazy-validation ref="form">
            <MobileNavigation
                v-if="!$vuetify.breakpoint.mdAndUp"
                :pageHeader="$t('Accountmanagement.header')"
            />
            <v-btn
                class="mobile-add"
                v-on="on"
                v-bind="attrs"
                v-if="!$vuetify.breakpoint.mdAndUp"
                @click="setSettingsActive"
            >
                <v-icon class="icon-plus mr-0">mdi-cog-outline</v-icon>
            </v-btn>

            <v-container
                :class="{
                    'px-0 py-7': !$vuetify.breakpoint.mdAndUp,
                    'pl-10 pr-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp}"
            >

                <!-- Header and locale switcher, could be refactored into a new component... -->

                <v-row
                    class="mx-0"
                    :class="{'h1-26 px-4 mb-5': !$vuetify.breakpoint.mdAndUp}"
                    @click="
                        setHeaderIsActive(true);
                    "
                >
                    <v-btn
                        x-large
                        circle
                        icon
                        @click="discardAndExit"
                        class="rounded-button mr-7 mt-3"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        <v-icon color="primary">mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-col cols="5" class="px-0" v-if="$vuetify.breakpoint.mdAndUp">
                        <h1 v-if="!this.modifyMode">{{ $t("TemplateGenerator.createNew") }}</h1>
                        <h1 v-else>{{ $t("TemplateGenerator.modify") }}</h1>
                    </v-col>
                    <v-btn class="secondary-button pl-1" @click="discardAndExit" v-else>
                        <v-icon color="primary" class="icon-plus"> mdi-chevron-left </v-icon>
                        {{ $t("TemplateGenerator.navigateBack") }}
                    </v-btn>
                    <v-col cols="12" v-if="!$vuetify.breakpoint.mdAndUp">
                        <h1 v-if="!this.modifyMode">{{ $t("TemplateGenerator.createNew") }}</h1>
                        <h1 v-else>{{ $t("TemplateGenerator.modify") }}</h1>
                    </v-col>
                    <v-col cols="12" class="h2-18 py-0 text-primary" v-if="!$vuetify.breakpoint.mdAndUp">
                        <h2>{{ $t("TemplateGenerator.chooseLanguage") }}</h2>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '' : '12'"
                        :align="$vuetify.breakpoint.mdAndUp ? 'right' : ''"
                    >
                        <v-select
                            v-model="currentTemplate.locale"
                            :items="localesComputed"
                            item-text="full"
                            item-value="code"
                            menu-props="auto"
                            prepend-icon="mdi-web"
                            return-object
                            @change="switchLocale($event)"
                        >
                        </v-select>
                    </v-col>
                </v-row>

                <!-- Header and locale switcher end -->

                <v-container class="px-0">
                    <v-row class="mx-0">
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'" class="ma-0 pa-0">

                            <!-- Name and short description for the template, always visible -->

                            <div class="template-generator mb-4 py-4">
                                <v-row
                                    class="mx-0 px-3 pt-3 pb-3 hover show-icon-onhover"
                                    @click="setHeaderIsActive(false)"
                                    v-click-outside="onOutsideClick"
                                >
                                    <h1 v-if="headerActive" class="text-primary">
                                        {{
                                            currentTemplate.templateName
                                                ? currentTemplate.templateName
                                                : $t("TemplateGenerator.newTemplate")
                                        }}
                                        <v-icon
                                            v-if="headerActive && $vuetify.breakpoint.mdAndUp"
                                            class="ml-2 small-icon show-on-hover mt-n1"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </h1>
                                    <v-textarea
                                        :label="
                                            $t('TemplateGenerator.templateName')
                                                ? $t('TemplateGenerator.templateName')
                                                : $t('TemplateGenerator.newTemplate')
                                        "
                                        v-model="currentTemplate.templateName"
                                        :rules="requireName"
                                        data-cy="templateName"
                                        hide-details
                                        clearable
                                        flat
                                        solo
                                        auto-grow
                                        rows="1"
                                        class="text-field-100-header light-bg-header"
                                        :class="{
                                            'bold-off':
                                                !currentTemplate.templateName ||
                                                currentTemplate.templateName.length === 0,
                                        }"
                                        ref="templateHeader"
                                        v-else
                                    >
                                    </v-textarea>
                                </v-row>
                            </div>

                            <!-- End of the name and description -->

                            <ReportCreator
                                :report="currentTemplate"
                                :OkCancelDialog="OkCancelDialog"
                            >
                            </ReportCreator>
                        </v-col>
                        <!-- <v-col
                            cols="4"
                            class="template-generator-mobile-preview mb-10"
                            @click="
                                setSubheaderIsActive(true);
                                setHeaderIsActive(true);
                            "
                            v-if="$vuetify.breakpoint.mdAndUp"
                        >
                            <v-row class="mx-0 py-3">
                                <h2 class="text-gray mobile-preview-h2">
                                    {{ $t("TemplateGenerator.mobilePreview") }}
                                </h2>
                                <p class="text-gray my-3">
                                    {{ $t("TemplateGenerator.inactivateHeader") }}
                                </p>
                            </v-row>
                        </v-col> -->
                    </v-row>
                </v-container>

                <v-layout>
                    <v-flex class="xs12 sm9 mx-auto">

                        <BaseLoadingDialog
                            :sendingData="sendingData"
                            :msg="$t('TemplateGenerator.savingMessage')"
                        >
                        </BaseLoadingDialog>
                    </v-flex>
                </v-layout>
                <v-dialog v-model="alertDialog" width="400">
                    <v-card class="rounded-lg">
                        <v-card-text class="pa-4 black--text">
                            {{ $t("TemplateGenerator.nameAlreadyUsed") }}
                        </v-card-text>
                        <v-card-actions class="pt-3">
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                class="body-2 font-weight-bold"
                                outlined
                                @click.native="closeAlertdialog"
                            >{{ $t("TemplateGenerator.confirm") }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
            <OkCancelDialog ref="OkCancelDialog" />
            <v-dialog class="ma-0 pa-0" v-model="alertDialog" width="400">
                <v-card class="rounded-lg pa-4">
                    <v-row class="h1-26 ma-0 px-4">
                        <h1>{{ $t("TemplateTable.attention") }}</h1>
                    </v-row>
                    <v-row class="mx-0 mt-4 px-4">
                        <p>{{ this.alertDialogText }}</p>
                    </v-row>
                    <v-card-actions class="pt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            data-cy="confirmAction"
                            class="primary-button"
                            @click.native="closeAlertdialog"
                        >
                            {{ $t("TemplateTable.confirmOk") }}</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Fixed row with controls -->

            <v-container
                class="fixed-bottom white-bg"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
                <v-row class="px-3">
                    <v-col cols="6">
                        <v-btn class="button-alert" @click="deleteTemplate">
                            <v-icon class="mr-2">mdi-delete</v-icon>
                            {{ $t("TemplateGenerator.deleteTemplate") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6" align="end">
                        <v-btn
                            class="primary-button"
                            data-cy="saveTemplate"
                            @click="saveAndNavigateBack"
                        >
                            {{ $t("TemplateGenerator.saveChanges") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>

        <!-- Controls row ends -->
        <v-dialog
            v-model="settings"
            :retain-focus="false"
            hide-on-leave
            content-class="mobile-new"
            transition="dialog-bottom-transition"
        >
            <v-card class="pa-5 bg-transparent">
                <v-container fluid>
                    <v-row class="mx-0 h1-26" justify="center">
                        <h1>
                            {{ $t("TemplateGenerator.manageTemplate") }}
                        </h1>
                    </v-row>
                    <v-row class="mx-0 mt-10">
                        <v-btn class="white-button mb-4" @click="discardAndExit">
                            <v-icon color="primary" class="mr-2">mdi-undo</v-icon>
                            {{ $t("TemplateGenerator.discardChangesAndLeave") }}
                        </v-btn>
                        <v-btn class="button-alert-primary w-100 py-4 mb-4" @click="deleteTemplate">
                            <v-icon class="mr-2">mdi-delete</v-icon>
                            {{ $t("TemplateGenerator.deleteTemplate") }}
                        </v-btn>
                        <v-btn
                            class="w-100 primary-button py-4 mb-2"
                            @click="saveAndNavigateBack"
                        >
                            {{ $t("TemplateGenerator.saveChangesAndLeave") }}
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <TemplateIsUsedNotifyDialog ref="TemplateIsUsedNotifyDialog"/>
    </div>
</template>
<script>
//import draggable from 'vuedraggable'   FOR DRAGGING FIELDS
import OkCancelDialog from '../components/OkCancelDialog.vue';
import ReportCreator from '../components/TemplateGenerator/ReportCreator.vue';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import { mapState, mapGetters } from 'vuex';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';
import TemplateIsUsedNotifyDialog from '../components/Templates/TemplateIsUsedNotifyDialog.vue';

import _ from 'lodash';
//import MobilePreview from "../components/Templates/MobilePreview.vue"
export default {
    components: {
        OkCancelDialog,
        ReportCreator,
        BaseLoadingDialog,
        MobileNavigation,
        TemplateIsUsedNotifyDialog
    //MobilePreview
    },
    data() {
        return {
            headerActive: true,
            descriptionActive: false,
            alertDialog: false,
            sendingData: false,
            inspectDialog: false,
            modifyMode: false,
            settings: false,
            alertDialogText: '',
            requireName: [
                (v) => (v && v.length > 0) || this.$t('TemplateGenerator.giveName'),
            ],
        };
    },
    methods: {
        async saveModifyTemplate() {
            this.sendingData = true;
            this.$store.state.currentTemplate.versionNumber += 1;
            this.$store.state.currentTemplate.lastModified = Math.round(
                new Date().getTime() / 1000
            );
            await this.$store.dispatch(
                'updateTemplate',
                this.$store.state.currentTemplate
            );
            this.$router.push({
                name: 'templates',
            });
            this.sendingData = false;
        },

        setSettingsActive() {
            this.settings = true;
        },

        closeSettings() {
            this.settings = false;
        },

        onOutsideClick() {
            this.headerActive = true;
        },

        async exitWithoutSaving() {
            let deleteApproved = await this.$refs.OkCancelDialog.open(
                this.$t('TemplateGenerator.discardHeader'),
                this.$t('TemplateGenerator.discardMessage'),
                this.$t('TemplateGenerator.discardMessage')
            );
            if (deleteApproved) {
                this.$store.state.currentTemplate = {};
                this.$router.push({
                    name: 'templates',
                });
            }
        },
        // FOR COMPONENTS
        async OkCancelDialog(header, msg, cnclAction) {
            return await this.$refs.OkCancelDialog.open(header, msg, cnclAction);
        },
        async saveAndNavigateBack() {
            if (this.$refs.form.validate()) {
                if (this.modifyMode) {
                    this.saveModifyTemplate();
                } else {
                    if (this.sendingData) {
                        return;
                    }
                    if (this.getCheckIsTemplateNameTakenTwo(this.currentTemplate)) {
                        this.alertDialog = true;
                        this.alertDialogText = this.$t(
                            'CreateTemplate.nameAlreadyUsed'
                        );
                        return;
                    } else {
                        this.sendingData = true;

                        this.$store
                            .dispatch('addTemplate', this.currentTemplate)
                            .then(() => {
                                this.$store.state.currentTemplate = {};
                                this.sendingData = false;
                                this.$router.push({
                                    name: 'templates',
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    }
                }
            }

        },
        setHeaderIsActive(params) {
            this.headerActive = params;
            if (!params) {
                this.$nextTick(() => this.$refs.templateHeader.focus());
            }
        },

        async deleteTemplate() {
            if(this.currentTemplate.template_id === ''){
                return await this.discardAndExit();
            }

            let check = this.getCheckIsTemplateUsed(this.currentTemplate.template_id);
            if (check.used || this.currentTemplate.owner == 'default')
            {
                return this.$refs.TemplateIsUsedNotifyDialog.open(check.processes, check.folders);
            }

            const deleteApproved = await this.OkCancelDialog(
                this.$t('TemplateTable.delete') + this.currentTemplate.templateName,
                this.$t('TemplateTable.deleteMessage'),
                this.$t('TemplateGenerator.deleteTemplate')
            );
            if (!deleteApproved) {
                return;
            }

            this.sendingData = true;
            await this.$store.dispatch('deleteTemplate', this.currentTemplate);
            this.sendingData = false;
            this.$router.push({ name: 'templates' });
        },
        async discardAndExit() {
            const discardApproved = await this.$refs.OkCancelDialog.open(
                this.$t('TemplateGenerator.discardHeader'),
                this.$t('TemplateGenerator.discardMessage'),
                this.$t('TemplateGenerator.discardChanges')
            );
            if (discardApproved) {
                this.$store.state.currentTemplate = {};
                this.$router.push({ name: 'templates' });
            }
        },
        async changeImagesToUrls() {
            await this.uploadImagesFromFieldRecursive(this.currentTemplate.sections);
        },
        closeAlertdialog() {
            this.alertDialog = false;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        switchLocale(locale) {
            if (locale !== this.locale) {
                this.currentTemplate.locale = locale.code;
            }
        },
    },
    computed: {
        ...mapState(['currentTemplate']),
        ...mapGetters(['getCheckIsTemplateNameTakenTwo']),
        ...mapGetters(['getCheckIsTemplateUsed']),
        localesComputed() {
            return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').map(
                (value) => {
                    return {
                        code: value,
                        full: this.$t('Languages.' + value),
                    };
                }
            );
        },
    },
    created() {
        if (!_.isEmpty(this.currentTemplate)) {
            this.$forceUpdate();
        }

        if (this.$route.params['mode'] === 'modifytemplate') {
            this.modifyMode = true;
        }
    },
    beforeMount() {
        this.scrollToTop();
    },
};
</script>
