var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$vuetify.breakpoint.mdAndUp && _vm.signedIn)?_c('v-navigation-drawer',{attrs:{"dark":"","permanent":"","color":"primary","app":"","touchless":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('hr',{staticClass:"light-gray-divider"}),_c('v-list',{staticClass:"my-3"},_vm._l((_vm.controlItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.navigate(item.routeName)}}},[_c('div',{staticClass:"px-3 w-100",class:{ 'light-bg': _vm.$route.name === item.routeName,
                              'dark-bg': _vm.$route.name !== item.routeName }},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'dark-bg': _vm.$route.name !== item.routeName }},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{attrs:{"data-cy":"controlItems"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Navigation.items." + item.routeName)))])],1)],1)])}),1)]},proxy:true}],null,false,667130313)},[_c('v-layout',{staticClass:"py-12"},[_c('v-img',{staticClass:"ml-7",attrs:{"src":require("../../../public/logo.svg"),"max-height":"70","max-width":"180"}})],1),_c('v-list',_vm._l((_vm.navItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.navigate(item.routeName)}}},[_c('div',{staticClass:"dark-bg w-100 px-3",class:{'light-bg':_vm.$route.name === item.routeName ||
                    (_vm.$route.name.includes('templategenerator') && item.routeName === 'templates') ||
                    (_vm.$route.name.includes('fillreport') && item.routeName === 'dashboard') ||
                    (_vm.$route.name.includes('process') && item.routeName === 'processes') ||
                    (_vm.$route.name.includes('fillresponse') && item.routeName === 'processes')
                }},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"dark-bg",class:{'light-bg':_vm.$route.name === item.routeName ||
                            (_vm.$route.name.includes('templategenerator') && item.routeName === 'templates') ||
                            (_vm.$route.name.includes('fillreport') && item.routeName === 'dashboard') ||
                            (_vm.$route.name.includes('process') && item.routeName === 'processes') ||
                            (_vm.$route.name.includes('fillresponse') && item.routeName === 'processes')
                        }},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',{attrs:{"data-cy":"navItems"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Navigation.items." + item.routeName)))])],1)],1)])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }