import {Component} from '../../../../component';

class CommentFieldStyle extends Component {
    code() {
        return {
            margin: [0, 25, 0, 25]
        };
    }
}

export default CommentFieldStyle;
