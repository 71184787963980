import {Field} from '../../../field';
import ImageUtils from '../utils/imageUtils';
import {formatTime} from '../utils/epoch';
import {imageLoader} from '../utils/imageLoader';


class Signature extends Field {
    $image;

    code() {
        this.$image = new ImageUtils(this.data[0].url);
        let content = [
            {
                image: this.$image.imageGarbageCollectionID,
                alignment: 'left',
                width: 580,
                height: 144,
                fit: [580, 144],
                style: 'FieldStyle',
            },
            {
                text: `${this.data[0].printName}, ${formatTime(this.data[0].timestamp)}`,
                style: 'FieldStyle',
            }

        ];

        return content;
    }

    get image() {
        this.$image = new ImageUtils(this.data[0].url);

        return {
            text: `${this.data.title}`,
            image: this.$image.imageGarbageCollectionID,
        };
    }

    after(pdf) {
        imageLoader(pdf, this.$image);
    }

    get isEmpty() {
        return this.data[0] === undefined;
    }
}

export default Signature;

