<template>
    <v-container class="pa-0 ma-0" data-cy="templateSection">
        <v-row v-if="inspectMode" class="mx-0 px-3 py-3 h1-26" :class="{'h1-23':!$vuetify.breakpoint.mdAndUp}">
            <h1 class="text-primary pre-wrap">
                {{ sectionNumber + ". &nbsp;" + section.sectionName }}
            </h1>
        </v-row>
        <div v-for="(field, index) in sections[0].fields" :key="index">
            <div v-for="(section, index2) in sections" :key="index2">
                <MultiField
                    :field="section.fields[index]"
                    :fillerData="fillerData[index2]"
                    :displayKey="index2"
                    :selectFieldMode="selectFieldMode"
                    @fieldChosen="emitFieldChosen"
                    @fieldChosenNew="emitFieldChosenNew"
                    :type="type"
                    :autoSave="autoSave"
                    @saveField="emitAutoSave"
                    @inputChanged="emitInputChanged"
                />
            </div>
        </div>
        <v-list
            v-for="(section, index) in sections[0].sections"
            :key="index"
            class="pa-0 ma-0"
        >
            <v-list-item-title class="py-6 ma-0 subsection-header">
                <v-row class="mx-0 px-3 text-primary" :class="{'h1-23':!$vuetify.breakpoint.mdAndUp}">
                    <h1 class="pre-wrap">
                        {{
                            sectionNumber + "." + (index + 1) + ". &nbsp;" + section.sectionName
                        }}
                    </h1>
                </v-row>
            </v-list-item-title>
            <div>
                <v-row
                    class="mx-0 px-3 mb-0 mt--1"
                    v-if="section.question"
                >
                    <pre class="text-gray mb-7">{{section.question}}</pre>
                </v-row>
                <InspectionFillSection
                    :sections="sections.map(x => x.sections[index])"
                    :fillerData="fillerData"
                    :sectionNumber="sectionNumber + '.' + (index + 1)"
                    :subsection="true"
                    :selectFieldMode="selectFieldMode"
                    @sectionChosen="emitFieldChosen"
                    @sectionChosenNew="emitFieldChosenNew"
                    @saveField="emitAutoSave"
                    :type="type"
                    :autoSave="autoSave"
                    @inputChanged="emitInputChanged"
                />
            </div>
        </v-list>
    </v-container>
</template>
<script>
import MultiField from './MultiField';
export default {
    props: [
        'sections',
        'sectionNumber',
        'subsection',
        'selectFieldMode',
        'inspectMode',
        'type',
        'autoSave',
        'fillerData'
    ],
    data() {
        return {
        };
    },
    components: {
        MultiField,
        InspectionFillSection: () => import('./InspectionFillSection.vue'), // Importing itself
    },
    methods: {
        emitFieldChosen() {
            this.$emit('sectionChosen');
        },
        emitFieldChosenNew(value) {
            // THIS IS TEMPORARY, HAS TO BE CHANGED
            this.$emit('sectionChosenNew', value);
        },
        emitAutoSave(){
            this.$emit('saveField');
        },
        emitInputChanged() {
            this.$emit('inputChanged');
        },
    },
    created() {
        console.log(this.sections);
    }
};
</script>
<style scoped>
.v-expansion-panel-content :deep(.v-expansion-panel-content__wrap) {
  padding: 0px;
}
.v-expansion-panel-header--active {
  padding: 0px;
  margin: 0px;
}
</style>
