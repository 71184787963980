<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
        @click:outside="closeDialog()">
        <v-card
            class="border-0 h2-18"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{$t('AddUser.addUser')}}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{ 'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp }">
                <v-form ref="form" class="hidden-overflow">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10" class="px-0">
                            <h1>{{$t('AddUser.addUser')}}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon class="close-button" @click="closeDialog()"
                            >mdi-close</v-icon
                            >
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 pt-3">
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                               class="py-0"
                               :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp, 'pa-0': !$vuetify.breakpoint.mdAndUp  }"
                        >
                            <h2 class="text-primary pb-4">{{ $t("AddUser.firstname") }}</h2>
                            <v-text-field
                                data-cy="firstnameInput"
                                v-model="obj.firstname"
                                class="rounded"
                                :rules="requireName"
                                required
                                outlined
                                :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                            ></v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                               class="py-0"
                               :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp, 'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <h2 class="text-primary pb-4">{{ $t("AddUser.lastname") }}</h2>
                            <v-text-field
                                data-cy="surnameInput"
                                v-model="obj.surname"
                                class="rounded"
                                :rules="requireName"
                                required
                                outlined
                                :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0">
                        <h2 class="text-primary pb-3">{{ $t("AddUser.phoneNumber") }}</h2>
                    </v-row>
                    <v-row class="mx-0">
                        <v-text-field
                            @keydown.space.prevent
                            v-model="obj.phone"
                            class="rounded"
                            :rules="phoneRules"
                            outlined
                            :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0">
                        <h2 class="text-primary pb-4">{{ $t("AddUser.email") }}</h2>
                    </v-row>
                    <v-row class="mx-0">
                        <v-text-field
                            data-cy="emailInput"
                            @keydown.space.prevent
                            v-model="obj.email"
                            class="rounded"
                            :rules="emailRules"
                            outlined
                            :class="{ 'text-field-100': !$vuetify.breakpoint.mdAndUp }"
                        ></v-text-field>
                    </v-row>
                    <v-row class="mx-0">
                        <h2 class="text-primary">{{ $t("EditUser.role") }}</h2>
                    </v-row>
                    <v-row class="mx-0" data-cy="selectRole">
                        <v-select
                            :value="obj.role"
                            :items="roles"
                            item-text="name"
                            item-value="role"
                            @change="changeSelectedRole($event)"
                            ref="roleChoice"
                            return-object
                            class="rounded-lg ml-1"
                            :rules="requireSelect"
                            :disabled="$store.state.userProfile.role !== 'admin'
                                || $store.state.userProfile.id === obj.id"
                        >
                        </v-select>
                    </v-row>
                    <v-row class="modal-control-row mb-0 mx-0" :class="{'px-2': !$vuetify.breakpoint.mdAndUp }">
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                               class="px-0"
                               :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <v-btn
                                class="secondary-button mr-3"
                                :class="{ 'w-100 py-4 mb-2': !$vuetify.breakpoint.mdAndUp,
                                          'mr-3': $vuetify.breakpoint.mdAndUp }"
                                @click="closeDialog()"
                            >
                                {{ $t("EditUser.discard") }}
                            </v-btn>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'"
                               align="end"
                               class="px-0"
                               :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <v-btn
                                data-cy="saveChanges"
                                class="primary-button"
                                :class="{ 'w-100 py-4 mb-4': !$vuetify.breakpoint.mdAndUp}"
                                @click="addUser()"
                                :disabled="$store.state.userProfile.role !== 'admin'"
                            >
                                {{ $t("EditUser.saveChanges") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
        <BaseLoadingDialog :sendingData="sendingData" :msg="$t('Baseloading.loadingMessage')" />
        <UserManagementErrorDialog :alertDialog="alertDialog" @showAlertDialog="setShowAlertDialog" />
    </v-dialog>
</template>

<script>
import { firebaseFunctions } from '../../fbInitialize';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import { userTemplate } from '../../datamodels/datamodels.js';
import UserManagementErrorDialog from './UserManagementErrorDialog.vue';
import _ from 'lodash';

export default {
    components: { BaseLoadingDialog, UserManagementErrorDialog },
    data() {
        return {
            alertDialog: false,
            dialog: false,
            roles: [
                { name: this.$t('AddUser.basicUser'), role: 'default' },
                { name: this.$t('AddUser.adminUser'), role: 'admin' },
            ],
            obj: {},
            requireName: [(v) => (v && v.length > 0) || this.$t('AddUser.giveName')],
            requireSelect: [(v) => !!v || this.$t('AddUser.selectRights')],
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.obj.phone.length > 0 ||
                    this.$t('AddUser.writeValidEmail'),
            ],
            phoneRules: [
                (v) =>
                    !v ||
                    /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]{8,14}$/g.test(v) ||
                    this.$t('AddUser.addEmailOrPhoneNumber'),
            ],
            sendingData: false,
        };
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
            this.clearForm();
        },
        changeSelectedRole(event) {
            this.obj.role = event.role;
        },
        addUser() {
            let email = this.obj.email;
            if (this.$refs.form.validate()) {
                this.sendingData = true;
                // Set data before sending
                this.obj['organization'] = this.$store.state.userProfile.organization;
                if (this.userAlreadyExists(this.obj)) {
                    firebaseFunctions
                        .httpsCallable('addUser')(this.obj)
                        .then(async () => {
                            // Refresh organization users
                            this.$store.dispatch('setOrgUsers');
                            this.sendingData = false;
                            this.closeDialog();
                            await this.$store.dispatch('sendForgotPasswordEmail', email);
                        })
                        .catch((error) => {
                            this.alertDialog = true;
                            console.log(error);
                            this.sendingData = false;
                        });
                } else {
                    this.sendingData = false;
                    this.$emit('openSnackbar');
                }
            }
        },
        userAlreadyExists(user) {
            let orgUsers = this.$store.state.organizationUsers;
            let duplicates = orgUsers.filter(
                (orgUser) =>
                    (orgUser.phone === user.phone && user.phone !== '') ||
                    (orgUser.email === user.email && user.email !== '')
            );
            if (duplicates.length > 0) {
                return false;
            } else {
                return true;
            }
        },
        clearForm() {
            this.obj = _.cloneDeep(userTemplate);
        },
        setShowAlertDialog(show) {
            this.alertDialog = show;
        }
    },
    created() {
        this.obj = _.cloneDeep(userTemplate);
    },
};
</script>
