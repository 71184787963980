import {Component} from '../../../../component';

class SectionExplanationStyle extends Component {
    code() {
        return {
            fontSize: 12,
            bold: false,
            margin: [0, 0, 0, 25],
            color: '#303E59'
        };
    }
}

export default SectionExplanationStyle;
