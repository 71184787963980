<template>
    <v-container>
        <PDFGenerator ref="PDFGenerator" />
        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('PDFGenerator.saving')"
        />
    </v-container>
</template>

<script>
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import { firebaseFunctions } from '../../fbInitialize';
import PDFGenerator from './PDFGenerator.vue';
import { getDynamicName } from '../../componentScripts/dynamicName';

export default {
    components: { BaseLoadingDialog, PDFGenerator },
    data() {
        return {
            sendingData: false,
        };
    },
    methods: {
        emitDataSent() {
            this.$emit('reportSent');
        },
        emitSomethingWentWrong() {
            this.$emit('reportSendFailed');
        },
        async sendPDF(report) {
            this.sendingData = true;
            if (report.recievers.length == 0) {
                return;
            } else {
                // Create report name from the field
                if (typeof report.reportNamingFieldId !== 'undefined') {
                    // get data from the field
                    // Go through the report
                    let newName = getDynamicName(report, report.reportNamingFieldId);
                    if (newName != '') {
                        report.name = newName;
                    }
                }
                this.sendingData = true;
                let pdf = await this.$refs.PDFGenerator.generatePDF(report);
                pdf.getBase64((file) => {
                    report.recievers.forEach((recieverEmail) => {
                        let self = this;
                        let sharePDF = firebaseFunctions.httpsCallable('sendSharedLinkPdf');
                        sharePDF({
                            email: recieverEmail,
                            attachment: file,
                            reportName: report.name + ' ' + new Date().toLocaleString(),
                            autolinkSession: this.$store.state.autolinkSession,
                            report: report,
                        })
                            .then(function () {
                                self.emitDataSent();
                                self.$store.commit('addCurrentReport', {});
                                self.$store.commit('clearAutolinkSession', {});
                                self.sendingData = false;
                            })
                            .catch((error) => {
                                // Too big file
                                console.log(error);
                                // Getting the Error details.
                                console.log(error.code);
                                console.log(error.message);
                                self.emitSomethingWentWrong();
                                self.sendingData = false;
                            });
                    });
                });
            }
        },
    },
};
</script>

<style>
</style>
