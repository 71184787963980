<template>
    <v-dialog
        v-model="dialog"
        @click:outside="closeAndErase()"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        width="600"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeAndErase()">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("AddImgData.addCaption") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1>{{ $t("AddImgData.addCaption") }}</h1>
                    </v-col>
                    <v-col cols="2">
                        <v-icon
                            class="close-button"
                            @click="closeAndErase()"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-form>
                    <v-img
                        :src="imageObj.url"
                        class="pa-0 rounded-lg hover my-5"
                        @click="viewImage()"
                        :width="$vuetify.breakpoint.mdAndUp ? '520' : ''"
                        :height="$vuetify.breakpoint.mdAndUp ? '300' : ''"
                        :contain="!$vuetify.breakpoint.mdAndUp"
                    ></v-img>
                    <v-row :class="{'mb-150':!$vuetify.breakpoint.mdAndUp}">
                        <v-col>
                            <v-text-field v-model="imageObj.text" :label="$t('AddImgData.descriptions')">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row
                        class="mt-3 mb-0"
                        :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="closeAndErase()"
                            >
                                {{ $t("AddImgData.cancel") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                @click="closeDialog()"
                            >
                                {{ $t('AddImgData.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
        <ViewImage :src="imageObj.url" ref="imgDialog" />
    </v-dialog>
</template>

<script>
import ViewImage from './ViewImage.vue';
import _ from 'lodash';

export default {
    name: 'AddImgData',
    components: {
        ViewImage,
    },
    data() {
        return {
            dialog: false,
            hovered: false,
            originalText: '',
            imageObj: {
                url: '',
                text: '',
                timetag: Math.round(new Date().getTime() / 1000),
                phototaker: '',
            },
        };
    },
    methods: {
        viewImage() {
            this.$refs.imgDialog.openDialog();
        },
        closeDialog() {
            this.originalText = '';
            this.dialog = false;
            this.$emit('saveImageData');
        },
        closeAndErase() {
            this.imageObj.text = this.originalText;
            this.originalText = '';
            this.dialog = false;
        },
        openDialog(image) {
            this.imageObj = image;
            this.originalText = _.cloneDeep(image.text);
            this.dialog = true;
        },
        hoverOn() {
            this.hovered = true;
        },
        hoverOff() {
            this.hovered = false;
        },
    },
};
</script>
