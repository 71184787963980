import { Field } from '../../../field';


class TableField extends Field {
    code() {
        let field = {
            columns: this.args.columns,
            data: this.data,
        };
        let tableHeaders = field.columns.map(a => a.label);
        let tableContent = [tableHeaders];

        field.data.forEach(obj => {
            let rowToAdd = [];
            obj.data.forEach(innerObj => {
                rowToAdd.push(innerObj.value);
            });
            tableContent.push(rowToAdd);
        });

        return [
            {
                text: this.args.question,
                margin: [0, 0, 0, 12.5]
            },
            {
                table: {
                    widths: this.widths(tableHeaders.length),
                    body: tableContent
                }
            }
        ];
    }

    widths(width) {
        let widths = [];
        let i = 0;

        while (i < width) {
            widths.push(`${Math.ceil((100 - 5) / width).toString()}%`);
            i++;
        }

        return widths;
    }

    get isEmpty() {
        return this.data.length === 0;
    }
}


export default TableField;
