import {Field} from '../../../field';
import {asComponent} from '../components/helpers/asComponent';
import StaticFileStyle from './styles/style.FileField';
import {fbStorage} from '../../../../../../fbInitialize';

class FileField extends Field {
    code() {
        return this.#files;
    }

    get #files() {
        let files = [];

        for(const file of this.data) {
            files.push(
                {
                    text: `${this.cleanUrl(file.url)}`,
                    link: file.url,
                    ...asComponent(StaticFileStyle),
                    style: 'FieldStyle',
                }
            );
        }
        return files;
    }

    cleanUrl(url) {
        let refUrl = fbStorage.refFromURL(url);
        return refUrl.name;
    }

    get isEmpty() {
        return this.data.length === 0;
    }
}

export default FileField;
