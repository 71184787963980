<template>
    <div>
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :pageHeader="$t('Templates.header')"
        />
        <div class="wrapper">
            <v-container class="px-7 py-7 container" :class="{'pl-10 pr-10 pt-10 pb-5':$vuetify.breakpoint.mdAndUp}">
                <v-row class="mx-0" :class="{'h1-26':!$vuetify.breakpoint.mdAndUp}">
                    <h1>{{ $t("Templates.header") }}</h1>
                </v-row>

                <!-- SEARCH BAR + CREATE NEW -BUTTON-->

                <v-row class="mx-0 mb-3 mt-n3" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="8" class="px-0">
                        <v-text-field
                            class="search"
                            v-model="search"
                            :label="$t('Templates.search')"
                            single-line
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            filled
                            flat
                            solo
                            background-color="#E8E8E8"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col align="end">
                        <v-btn class="primary-button mt-4" data-cy="newTemplate" @click="openCreateTemplate()" >
                            <v-icon class="icon-plus">mdi-plus-circle-outline</v-icon>
                            {{ $t("Templates.createNew") }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="mx-0 mb-3" v-else>
                    <v-text-field
                        class="search"
                        v-model="search"
                        :label="$t('Templates.search')"
                        single-line
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        filled
                        flat
                        solo
                        background-color="#E8E8E8"
                    >
                    </v-text-field>
                </v-row>

                <v-btn
                    class="mobile-add"
                    v-on="on"
                    v-bind="attrs"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    data-cy="newTemplate"
                    @click="openCreateTemplate()"
                >
                    <v-icon class="icon-plus mr-0">mdi-plus</v-icon>
                </v-btn>

                <!-- SEARCH BAR END -->

                <!-- CHOSEN SECTION -->

                <!-- CHOSEN SECTION ENDS -->

                <TemplateTable :search="search"/>

                <CreateTemplate ref="createTemplate" />

                <!---foldershare dialog -->
                <FolderShare />

            </v-container>
        </div>
    </div>
</template>

<script>
/* foldershare dialog button */
import FolderShare from '../components/FolderManagement/FolderShare.vue';
import TemplateTable from '../components/Templates/TemplateTable';
import CreateTemplate from '../components/Templates/CreateTemplate.vue';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';

export default {
    components: {
        TemplateTable,
        CreateTemplate,
        MobileNavigation,
        FolderShare,
    },
    data() {
        return {
            search: ''
        };
    },
    methods: {
        openCreateTemplate() {
            this.$refs.createTemplate.openDialog();
        },
        navigateBack() {
            this.$router.push({ name: 'dashboard' });
        },
    },
};
</script>
