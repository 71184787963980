import {Component} from '../../../../component';

class FieldStyle extends Component {
    code() {
        return {
            margin: [0, 0, 0, 25],
            color: '#3E3F4B',
        };
    }
}

export default FieldStyle;
