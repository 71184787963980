import {Component} from '../../../component';
import {asComponent} from './helpers/asComponent';
import FieldExplanationStyle from './styles/style.fieldExplanation';

class FieldExplanation extends Component {
    code() {
        return {
            text: `${this.args.explanation}`,
            style: asComponent(FieldExplanationStyle)
        };
    }
}

export default FieldExplanation;
