import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import fi from 'vuetify/lib/locale/fi';
import en from 'vuetify/lib/locale/en';

export default new Vuetify({
    lang: {
        locales: {
            fi,
            en,
        },
        current: 'fi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        lang: {
            locales: {
                fi,
                en,
            },
            current: 'fi',
        },
        themes: {
            light: {
                primary: '#324874',
                secondary: '#80CACF',
                background: '#E5E5E5',
                warning: '#FC0032',
                white: '#FFFFFF',
                black: '#000000',
                gray: '#F9F9F9'
            }
        }
    }
});
