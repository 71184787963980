<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        class="pa-2"
        width="400"
        persistent
    >
        <v-card class="rounded-lg pa-2" width="400">
            <v-container fluid>
                <v-form ref="form">
                    <v-col>
                        <v-row v-if="$vuetify.breakpoint.mdAndUp">
                            <h1 class="h4">{{ $t('TermsAndConditions.header') }}</h1>
                        </v-row>
                        <v-row>
                            <v-btn class="my-4" @click="openPage1()">
                                {{ $t('TermsAndConditions.readTerms') }}
                            </v-btn>
                        </v-row>
                        <v-row>
                            <v-btn @click="openPage2()">
                                {{ $t('TermsAndConditions.readPrivacypolicy') }}
                            </v-btn>
                        </v-row>
                        <v-row>
                            <v-checkbox
                                v-model="checkbox"
                                :label="$t('TermsAndConditions.confirmRead')"
                                class="black--text"
                            ></v-checkbox>
                        </v-row>
                        <v-row>
                            <v-btn
                                @click="closeDialog()"
                                block
                                height="60"
                                color="primary"
                                class="rounded-lg"
                                :dark="checkbox"
                                :disabled="!checkbox"
                            >
                                {{ $t('TermsAndConditions.startUse') }}
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    data() {
        return {
            dialog: false,
            checkbox: false
        };
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.$store.dispatch('updateTermsAndConditions');
            this.dialog = false;
        },
        openPage1() {
            window.open('https://www.formup.fi/sopimusehdot', '_blank');
        },
        openPage2() {
            window.open('https://www.formup.fi/tietosuojaseloste', '_blank');
        }
    },
};
</script>
