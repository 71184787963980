<template>
    <div>
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :pageHeader="$t('Processes.header')"
        />
        <!-- <ProcessesTable /> -->
        <ProcessAndMessageGroupTabs />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import ProcessAndMessageGroupTabs from '../components/Processes/ProcessAndMessageGroupTabs.vue';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';

export default {
    components: {
        ProcessAndMessageGroupTabs,
        MobileNavigation,
    },
    data() {
        return {};
    },
    methods: {
        navigateBack() {
            this.$router.push({ name: 'dashboard' });
        },
    },
    computed: mapState(['processes']),
};
</script>
