<template>
    <div class="wrapper">
        <v-container>
            <v-layout fill-height>
                <v-flex class="xs12 sm9 mx-auto">

                    <SectionIterator
                        :sections="this.$store.state.currentReport.sections"
                        :autolink="true"
                        :logoUrl="this.$store.state.currentReport.logoUrl"
                        ref="sectionIterator"
                        :autoSave="true"
                        @saveField="updateReport"
                    />
                    <v-snackbar
                        :value="isLimitOfImagesInCurrentReport"
                        width="600px"
                        timeout="-1"
                        color="orange"
                        :top="!$vuetify.breakpoint.mdAndUp"
                    >
                        <v-icon>mdi-alert-outline</v-icon>
                        {{ $t("FillProcessReport.ImageLimitExceedeInfo") }}

                    </v-snackbar>
                    <v-btn
                        block
                        @click="sendReport()"
                        class="rounded-lg mt-6 mb-2 mx-0"
                        height="50"
                        color="primary"
                        dark
                        :disabled="isLimitOfImagesInCurrentReport"
                    >
                        {{ $t("FillProcessReport.shareReport") }}
                    </v-btn>

                    <OkExitDialog ref="OkExitDialog"> </OkExitDialog>

                    <BaseLoadingDialog
                        :sendingData="loading"
                        :msg="$t('FillProcessReport.loadingMessage')"
                    />

                    <ValidateForm ref="validateForm" />

                    <PDFGenerator ref="PDFGenerator" />

                    <v-dialog v-model="doneDialog" fullscreen hide-overlay>
                        <v-card color="white" height="100%" align="center">
                            <h2 class="mx-auto" style="font-size: 40px">Formup</h2>
                            <h1 class="font-weight-bold title">
                                {{ this.dialogText }}
                            </h1>

                            <v-btn
                                @click="downloadPDF"
                                class="rounded-lg mt-6 mb-2"
                                height="50"
                                color="white"
                            >
                                {{ $t("FillProcessReport.loadReport") }}
                            </v-btn>
                        </v-card>
                    </v-dialog>
                </v-flex>
            </v-layout>
            <v-dialog v-model="fullScreenDialog" fullscreen>
                <v-card color="white" height="100%" align="center">
                    <h2 class="mx-auto" style="font-size: 40px">Formup</h2>
                    <h1 class="font-weight-bold title">
                        {{ this.dialogErrorMessage }}
                    </h1>
                </v-card>
            </v-dialog>
            <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('FillProcessReport.somethingWentWrong')" />
        </v-container>
    </div>
</template>
<script>
import OkExitDialog from '../components/OkExitDialog.vue';
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import SectionIterator from '../components/FillReport/SectionIterator.vue';
import ValidateForm from '../components/FillReport/ValidateForm.vue';
import PDFGenerator from '../components/FillReport/PDFGenerator.vue';
import { mapGetters } from 'vuex';
import GeneralSnackbar from '../components/GeneralizedComponents/GeneralSnackbar.vue';
import { getDynamicName } from '../componentScripts/dynamicName';

export default {
    components: {
        OkExitDialog,
        BaseLoadingDialog,
        SectionIterator,
        ValidateForm,
        PDFGenerator,
        GeneralSnackbar,
    },
    data() {
        return {
            autosave: false,
            doneDialog: false,
            sendingData: false,
            loading: true,
            dialogText: '',
            dialogErrorMessage: '',
            sendDialog: false,
            fullScreenDialog: false,
            reportSent: false,
        };
    },
    methods: {
        downloadPDF() {
            if (!this.loading) {
                this.loading = true;
                this.$refs.PDFGenerator.downloadPDF(
                    this.$store.state.currentReport,
                    this.$store.state.currentReport.namingFieldId
                );
                this.loading = false;
            }
        },
        async sendReport() {
            const requiredOk = this.$refs.validateForm.isRequiredInputFilled(this.$store.state.currentReport.sections);
            if (!requiredOk) {
                this.$store.dispatch('reportSendWithEmptyRequired');
                return;
            }

            let report = this.$store.state.currentReport;
            report.name = () => getDynamicName(
                this.$store.state.currentReport,
                this.$store.state.currentProcessInfo.namingFieldId
            );
            report.createdAt = Math.round(new Date().getTime() / 1000);
            this.loading = true;
            let pdf = await this.$refs.PDFGenerator.generatePDF(
                report,
                this.$store.state.currentProcessInfo.namingFieldId
            );
            pdf.getBase64(async (file) => {
                try {
                    await this.$store.dispatch('sendProcessReport', file);
                    this.reportSent = true;
                    this.doneDialog = true;
                    this.dialogText = this.$t('FillProcessReport.reportFinished');
                } catch(error) {
                    this.$refs.GeneralSnackbar.snackbar = true;
                } finally {
                    this.loading = false;
                }
            });
        },
        openSendPdfDialog() {
            this.sendDialog = true;
        },
        openFailedDialog() {
            this.$refs.GeneralSnackbar.snackbar = true;
        },
        openDoneDialog() {
            this.dialogText = this.$t('FillProcessReport.reportSend');
            this.doneDialog = true;
        },
        updateReport() {
            if(this.autosave) {
                this.$store.dispatch('addCurrentReport', this.$store.state.currentReport);
            }
        },
        emptyReportAndRefresh() {
            this.$store.commit('clearAutolinkSession');
            location.reload();
        },
        async updatePage() {
            let deleteApproved = await this.$refs.OkExitDialog.open(
                this.$t('FillProcessReport.clearDataAndRefresh'),
                this.$t('FillProcessReport.areYouSureConfirm'),
                this.$t('FillProcessReport.yes'),
                this.$t('FillProcessReport.no')
            );
            if (deleteApproved) {
                // Delete field
                this.emptyReportAndRefresh();
            }
        },
    },
    destroyed() {
        if (this.reportSent) {
            this.$store.commit('addCurrentReport', {});
        }
    },
    computed: {
        ...mapGetters([
            'isLimitOfImagesInCurrentReport'
        ]),
    },
    async created() {
        let reportFillerId = this.$route.params['reportFillerId'];
        let processId = this.$route.params['processId'];

        const reportFetch = await this.$store
            .dispatch('fetchProcessReport', {
                reportFillerId: reportFillerId,
                processId: processId,
            });

        if (reportFetch.success) {
            this.loading = false;
            this.autosave = true;
        } else {
            this.fullScreenDialog = true;
            this.dialogErrorMessage = reportFetch.message;
        }

    },
};
</script>
