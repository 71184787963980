<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()" data-cy="closeDialog">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("ShareReport.header") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1>{{ $t("ShareReport.header") }}</h1>
                    </v-col>
                    <v-col cols="2">
                        <v-icon
                            data-cy="closeDialog"
                            class="close-button"
                            @click="closeDialog()"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row
                    class="mx-0 h2-18 text-primary"
                    v-if="currentReport.modifiers.length !== 0"
                >
                    <h2>{{ $t("ShareReport.participants") }}</h2>
                </v-row>
                <v-list class="pa-0 my-5">
                    <v-list-item
                        v-for="(item, i) in currentReport.modifiers"
                        :key="i"
                        class="px-0 mx-0"
                    >
                        <v-list-item-icon class="ma-0">
                            <div class="image-avatar-table-visitor">
                                <p class="avatar-text-table-visitor">
                                    {{ getInitials(getNameFromEmail(item)) }}
                                </p>
                            </div>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row class="ma-0">
                                    <p class="mb-0 text-primary">
                                        <b> {{ getNameFromEmail(item) }} </b>
                                    </p>
                                </v-row>
                                <v-row class="mb-0 mx-0 mt-1 text-gray">
                                    {{ item }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                        <!--
            <v-list-item-action>
              <v-btn
                color="primary"
                class="ml-7"
                icon
                @click="deleteParticipant(item)"
              >
                <v-icon color="primary">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
            -->
                    </v-list-item>
                </v-list>
                <v-row
                    class="mx-0 my-3 h2-18 text-primary"
                    v-if="currentReport.shared || this.isShared"
                >
                    <h2>{{ $t("ShareReport.linkToFill") }}</h2>
                </v-row>
                <v-row class="mx-0 mb-3" v-if="currentReport.shared || this.isShared">
                    <v-card @click="copyFilePath()" class="link-copy">
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '9' : '8'">
                                    <span data-cy="shareReportURL">
                                        {{ this.sharedPath }}
                                    </span>
                                </v-col>
                                <v-col
                                    :cols="$vuetify.breakpoint.mdAndUp ? '3' : '4'"
                                    class="text-primary"
                                    :class="{'pr-5': !$vuetify.breakpoint.mdAndUp}"
                                    :align="$vuetify.breakpoint.mdAndUp ? '' : 'end'"
                                >
                                    <v-icon class="mr-2" color="primary">
                                        mdi-content-copy
                                    </v-icon>
                                    <b>{{ $t("ShareReport.copy") }}</b>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-row>
                <v-row class="mx-0 mt-5 h2-18 text-primary">
                    <h2>{{ $t("ShareReport.addModifier") }}</h2>
                </v-row>
                <v-form ref="emailInputForm" :lazy-validation="true">
                    <v-row
                        class="pt-5 mx-0 mt-0"
                        :class="{'mb-0': $vuetify.breakpoint.mdAndUp, 'mb-150': !$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-text-field
                            class="py-2 ml-1 rounded-lg"
                            flat
                            :label="$t('ShareReport.addEmail')"
                            v-model="modifierEmail"
                            :rules="emailRules"
                            @keydown.enter.prevent="addModifier"
                        >
                        </v-text-field>
                    </v-row>
                </v-form>
                <v-row
                    class="mt-3 mb-0"
                    :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                >
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            class="secondary-button"
                            :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                            @click="closeDialog()"
                        >
                            {{ $t("ShareReport.cancel") }}
                        </v-btn>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        align="end"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            class="primary-button"
                            :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                            @click="addModifier()"
                        >
                            {{ $t("FolderShare.addParticipant") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-dialog v-model="sendingData" width="200" persistent>
            <v-card height="180" class="rounded-lg">
                <v-layout align-center justify-center>
                    <v-progress-circular
                        class="mt-4"
                        :width="12"
                        :size="120"
                        indeterminate
                        color="primary"
                    >
                    </v-progress-circular>
                </v-layout>
                <v-layout align-center justify-center>
                    <h1 class="subtitle-1 mt-2">
                        {{ $t("ShareReport.loadingMessage") }}
                    </h1>
                </v-layout>
            </v-card>
        </v-dialog>
        <OkExitDialog ref="OkExitDialog" />
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('ModifyAutolink.linkCopied')" />
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { firebaseFunctions } from '../../fbInitialize';
import OkExitDialog from '../OkExitDialog.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

import _ from 'lodash';

export default {
    components: {
        OkExitDialog,
        GeneralSnackbar,
    },
    data() {
        return {
            modifierEmail: '',
            sendingData: false,
            isShared: false,
            dialog: false,
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('ShareReport.addRule'),
            ],
        };
    },
    methods: {
        clean(str) {
            let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            let index = 0;
            for (let i = 0; i < str.length; i++) {
                if (numbers.includes(str[i])) {
                    break;
                }
                index += 1;
            }
            return str.slice(0, index);
        },

        getNameFromEmail(email) {
            if (email) {
                let prefix = email.split('@')[0];
                let firstName, lastName;
                if (prefix.includes('.')) {
                    firstName = this.clean(
                        prefix.split('.')[0].charAt(0).toUpperCase() +
                            prefix.split('.')[0].slice(1)
                    );
                    lastName = this.clean(
                        prefix.split('.')[1].charAt(0).toUpperCase() +
                            prefix.split('.')[1].slice(1)
                    );
                    if (prefix.split('.').length > 2) {
                        lastName = this.clean(
                            prefix
                                .split('.')[prefix.split('.').length - 1].charAt(0)
                                .toUpperCase() +
                                prefix.split('.')[prefix.split('.').length - 1].slice(1)
                        );
                    }
                    return `${firstName} ${lastName}`;
                }
                return this.clean(prefix.charAt(0).toUpperCase() + prefix.slice(1));
            }
        },

        getInitials(name) {
            if (name) {
                let names = name.split(' ');
                let initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            }
        },
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        copyFilePath() {
            if (!this.currentReport.shared) {
                this.shareReport();
            }
            navigator.clipboard.writeText(this.sharedPath);
            this.$refs.GeneralSnackbar.snackbar = true;
        },
        async shareReport() {
            this.$store.state.currentReport.shared = true;
            await this.$store
                .dispatch('updateReport')
                .then(() => {})
                .catch((err) => {
                    console.log(err);
                });
        },
        async addModifier() {
            if (this.modifierEmail != '' && this.$refs.emailInputForm.validate()) {
                let addModifier = await this.$refs.OkExitDialog.open(
                    this.$t('ShareReport.addModifierHeader'),
                    this.$t('ShareReport.addModifierMessage') + this.modifierEmail,
                    this.$t('ShareReport.addModifierConfirm'),
                    this.$t('ShareReport.addModifierCancel')
                );
                if (addModifier) {
                    this.sendingData = true;
                    if (!this.currentReport.shared) {
                        this.shareReport();
                    }
                    // Share report
                    // Use cloud function to send modify link with email
                    var shareReportEdit =
                        firebaseFunctions.httpsCallable('shareReportEdit');
                    shareReportEdit({
                        email: this.modifierEmail,
                        senderEmail: 'test@example.org',
                        reportName: this.$store.state.currentReport.name,
                        reportLink: this.sharedPath,
                    })
                        .then(() => {
                            // Read result of the Cloud Function.
                            this.$store
                                .dispatch('addReportModifier', this.modifierEmail)
                                .then(
                                    () => {
                                        console.log('added modifier to fb');
                                    },
                                    (error) => console.log(error)
                                );
                            this.modifierEmail = '';
                            this.sendingData = false;
                        })
                        .catch((error) => {
                            console.log(error);
                            // Getting the Error details.
                            console.log(error.code);
                            console.log(error.message);
                            console.log(error.details);
                            // ...
                        });
                }
            }
        },
    },
    beforeMount() {
        this.shareReport();
    },
    computed: {
        sharedPath() {
            return (
                location.origin + '/fillSharedReport/' + this.$route.params.reportId || ''
            );
        },
        sharedTo() {
            if (_.has(this.currentReport, 'modifiers')) {
                return this.currentReport.modifiers;
            } else {
                return [];
            }
        },
        ...mapState(['currentReport']),
    },
};
</script>
