<template>
    <v-dialog class="ma-0 pa-0" v-model="alertDialog" width="400">
        <v-card class="rounded-lg pa-4">
            <v-row class="h1-26 ma-0 px-4">
                <h1>{{ $t("TemplateTable.attention") }}</h1>
            </v-row>
            <v-row class="mx-0 mt-4 px-4">
                <p>{{ this.$t('TemplateTable.cantDeleteTemplateInUse') }}</p>
                <p v-if="usingProcesses.length > 0">
                    {{ this.$t('TemplateTable.byProcesses') }}
                    {{ this.usingProcesses}}
                </p>
                <p v-if="usingLinkFolders.length > 0">
                    {{ this.$t('TemplateTable.byFolders') }}
                    {{ this.usingLinkFolders}}
                </p>
                <p v-if="usingAutolinks.length > 0">{{ this.$t('TemplateTable.byAutolinks') }}
                    {{ this.usingAutolinks}}
                </p>
            </v-row>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                    data-cy="confirmAction"
                    class="primary-button"
                    @click.native="closeAlertdialog"
                >
                    {{ $t("TemplateTable.confirmOk") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            alertDialog: false,
            processesUsing: [],
            foldersUsing: [],
            autolinksUsing: []
        };
    },
    methods: {
        open(processesUsing, foldersUsing, autolinksUsing) {
            this.processesUsing = processesUsing;
            this.foldersUsing = foldersUsing;
            this.autolinksUsing = autolinksUsing;
            this.alertDialog = true;
        },
        closeAlertdialog() {
            this.alertDialog = false;
        },
    },
    computed: {
        usingProcesses() {
            return this.processesUsing.join(', ');
        },
        usingLinkFolders() {
            return this.foldersUsing.join(', ');
        },
        usingAutolinks() {
            return this.autolinksUsing.join(', ');
        },
    }
};
</script>

<style>

</style>
