import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '../fbInitialize';
import store from '../store/index.js';
import Dashboard from '../views/Dashboard.vue';
import Login from '../views/Login.vue';
import Templates from '../views/Templates.vue';
import FillReport from '../views/FillReport.vue';
import FillSharedReport from '../views/FillSharedReport.vue';
import TemplateGenerator from '../views/TemplateGenerator.vue';
import Autolinks from '../views/Autolinks.vue';
import ModifyAutolink from '../views/ModifyAutolink.vue';
import AccountManagement from '../views/AccountManagement.vue';
import FillAutolinkReport from '../views/FillAutolinkReport.vue';
import OopsPage from '../views/OopsPage.vue';
import PageNotFound from '../views/PageNotFound.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import AddUser from '../views/AddUser.vue';
import Processes from '../views/Processes.vue';
import Process from '../views/Process.vue';
import Fillprocessreport from '../views/FillProcessReport.vue';
import PhoneLogin from '../views/PhoneLogin.vue';
import FillLinkReport from '../views/FillLinkReport.vue';
import SharedFolders from '../views/SharedFolders.vue';
import FillResponse from '../views/FillResponse.vue';
import MessageGroup from '../views/MessageGroup.vue';
import Inspection from '../views/Inspection.vue';
import Inspections from '../views/Inspections.vue';
import MaintenanceReports from '../views/MaintenanceReports.vue';
import FillMaintenaceReport from '../views/FillMaintenaceReport.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: ForgotPassword
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/templates',
        name: 'templates',
        component: Templates,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/fillreport/:reportId',
        name: 'fillreport',
        component: FillReport,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch(
                'setCurrentReport',
                to.params.reportId
            ).then(() => {
                next();
            }).catch((e) => {
                console.log(e);
                next('/');
            });
        }
    },
    {
        path: '/fillresponse/:processId/:reportId',
        name: 'fillresponse',
        component: FillResponse,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch(
                'setCurrentProcessResponseReport',
                to.params.reportId
            ).then(() => {
                next();
            }).catch((e) => {
                console.log(e);
                next('/');
            });
        }
    },
    {
        path: '/autolinks',
        name: 'autolinks',
        component: Autolinks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/modifyautolink',
        name: 'modifyautolink',
        component: ModifyAutolink,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/templategenerator/:mode?/',
        name: 'templategenerator',
        component: TemplateGenerator,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/accountmanagement',
        name: 'accountmanagement',
        component: AccountManagement,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/adduser',
        name: 'adduser',
        component: AddUser,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/fillsharedreport/:reportId',
        name: 'fillSharedReport',
        component: FillSharedReport,
        beforeEnter: async (to, from, next) => {
            store.dispatch('fetchSharedReport', to.params.reportId).then(() => {
                next();
            }).catch(() => {
                next('/');
            });
        }
    },
    {
    // :id contains autolink id
        path: '/fillautolinkreport/:id/:command?',
        name: 'fillautolinkreport',
        component: FillAutolinkReport,
    },
    {
        path: '/filllinkreport/:folderId/:command?',
        name: 'filllinkreport',
        component: FillLinkReport,
    },
    {
    // :id contains autolink id
        path: '/fillprocessreport/:processId/:reportFillerId',
        name: 'fillprocessreport',
        component: Fillprocessreport,
    },
    {
        path: '/phonelogin',
        name: 'phonelogin',
        component: PhoneLogin
    },
    {
        path: '/processes',
        name: 'processes',
        component: Processes,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/process/:processId',
        name: 'process',
        component: Process,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/oops',
        name: 'oops',
        component: OopsPage
    },
    {
        path: '*',
        component: PageNotFound
    },
    {
        path: '/sharedfolders',
        name: 'sharedfolders',
        component: SharedFolders
    },
    {
        path: '/messagegroup',
        name: 'messagegroup',
        component: MessageGroup
    },
    {
        path: '/inspection/:inpectionId',
        name: 'inspection',
        component: Inspection
    },
    {
        path: '/inspections/:inspectionsId',
        name: 'inspections',
        component: Inspections
    },
    {
        path: '/MaintenanceReports/:MaintenanceReportsId',
        name: 'MaintenanceReports',
        component: MaintenanceReports
    },
    {
        path: '/FillMaintenaceReport/:FillMaintenaceReportId',
        name: 'FillMaintenaceReport',
        component: FillMaintenaceReport,
        beforeEnter: async (to, from, next) => {
            store.dispatch(
                'setCurrentInspectionReport',
                to.params.FillMaintenaceReportId
            ).then(() => {
                next();
            }).catch((e) => {
                console.log(e);
                next('/');
            });
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !auth.currentUser) {
        next('/');
    } else if (auth.currentUser && to.path == '/') {
        next('/dashboard');
    }
    else {
        next();
    }
});

export default router;
