<template>
    <v-container> </v-container>
</template>
<script>
import {createPDF} from './PDFTools';
import JSZip from 'jszip';
import { getDynamicName } from '../../componentScripts/dynamicName';

export default {
    methods: {
        async generatePDF(report, namingId) {
            if (typeof namingId !== 'undefined') {
                let newName = getDynamicName(report, namingId);
                if (newName !== '') {
                    report.name = newName;
                }
                else {
                    report.name = this.$t('Processes.newReport');
                }
            }

            let pdf = createPDF(report);
            pdf.docDefinition.info = {};
            pdf.docDefinition.info['reportData'] = this.SectionsDataForMetaData(report);
            this.sendingData = false;
            return pdf;
        },
        async downloadPDF(report, namingId) {
            let pdf = await this.generatePDF(report, namingId);
            if(report.name === '') {
                report.name = 'dokumentti';
            }
            pdf.download(report.name + '.pdf');
            return pdf;
        },
        async downloadManyZip(reports, namingId, zipName) {
            const zip = new JSZip();

            let blobGeneratorHelperList = [];
            let reportFileNames = [];

            for (let report of reports) {
                report.name = getDynamicName(report, namingId);
                if(report.name === '') {
                    report.name = 'report';
                }
                let name = report.name;
                let duplicateNameCounter = 1;
                while (reportFileNames.includes(name)) {
                    name = `${report.name}(${duplicateNameCounter++})`;
                }
                reportFileNames.push(name);
                blobGeneratorHelperList.push({report: report, namingId: namingId, name: name});
            }

            async function promiseAllInBatches(task, items, batchSize) {
                let position = 0;
                let results = [];
                while (position < items.length) {
                    const itemsForBatch = items.slice(position, position + batchSize);
                    results = [...results, ...await Promise.all(itemsForBatch.map(
                        item => task(item.report, item.namingId, item.name)))];
                    position += batchSize;
                }
                return results;
            }

            let reportFiles = await promiseAllInBatches(this.getBlob, blobGeneratorHelperList, 10);

            //add files to zip
            reportFiles.forEach((report) => zip.file(report.name + '.pdf', report.blob, {base64: true}));

            //Generate and download zip
            let base64 = await zip.generateAsync({type:'base64'});
            let link = document.createElement('a');
            link.href = 'data:application/zip;base64,' + base64;
            link.download = zipName + '.zip';
            link.click();
        },
        async getBlob(report, namingId, name) {
            let pdf = await this.generatePDF(report, namingId);
            return new Promise((resolve) => {
                pdf.getBlob((blob) => {
                    resolve({
                        blob: blob,
                        name: name
                    });
                });
            });
        },
        SectionsDataForMetaData(report) {
            // return  Object.values(report.sections);
            let data = [];
            const bannedFieldTypes = ['image','addimages','signature','file','staticfile'];
            for (let section of report.sections) {
                for (let field of section.fields) {
                    if(!bannedFieldTypes.includes(field.type)) {
                        data.push({ label: field.label, id: field.id, data: field.data });
                    }
                }
                for (let subsection of section.sections) {
                    for (let field of subsection.fields) {
                        if(!bannedFieldTypes.includes(field.type)) {
                            data.push({ label: field.label, id: field.id, data: field.data });
                        }
                    }
                }
            }
            return JSON.stringify(data);
        }
    },
};
</script>
