<template>
    <v-app>
        <v-main class="overflow-hidden">
            <NavBar v-if="currentRouteName != 'login'"/>
            <router-view>

            </router-view>
        </v-main>
    </v-app>
</template>

<script>
import NavBar from './components/DesktopComponents/NavigationBar';

export default {

    name: 'App',
    components: {
        NavBar
    },
    data: () => ({
    //
    }),
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },
    created() {
        document.title = 'Formup';
    }
};
</script>

<style>
 *{
  text-transform: none !important;
  }

  pre {
    font-family: "Roboto"
  }

  .subscription-container {
    background-color: #f0fef0;
    margin-top: 24px;
    margin-bottom: 24px;
    border-style: solid;
    border-radius: 10px;
    border-color: #337b32;
    border-width: 1px;
    color: #337b32;
    display: flex;
    padding: 15px;
  }

  .inspectionStatus-container {
    background-color: white;
    margin-top: 24px;
    margin-bottom: 24px;
    border-style: solid;
    border-radius: 10px;
    border-color: gray;
    border-width: 1px;
    display: flex;
    padding: 15px;
  }

  @media only screen and (max-width: 450px) {
    .subscription-container {
      padding: 15px 8px
    }
  }
  .m-0 {
    margin: 0 !important;
  }

  .w-80 {
    width: 80%;
  }

  .wrapper {
    background-color: #f9f9f9;
    min-height: 100vh;
  }



  .gray-bg {
    background-color: #f9f9f9
  }

  .icon-success {
    color: #337b32!important;
  }
  .icon-style {
    font-size: 35px!important;
  }

  .icon-style-mobile {
    font-size: 28px!important;
  }


  .v-tabs-slider-wrapper {
    height: 3px !important;
    border-style: none !important;
  }

  .v-tab {
    font-size: 16px !important;
    letter-spacing: normal !important;
    font-weight: 400!important;
    margin-right: 30px !important;
  }

  @media only screen and (max-width: 450px) {
    .v-tab {
      margin-right: 10px!important;
    }
  }

  /* MUST BE BELOW v-tab REDEFINITION! */
  .v-tab--active {
    font-weight: 600!important;
  }

  .my-profile {
    background-color: white;
    border-radius: 10px;
    margin-top: 15px!important;
    padding: 20px;
  }

  @media only screen and (max-width: 950px) {
    .my-profile {
      border-radius: 0;
      padding: 15px;
      max-width: calc(100% + 56px)!important;
      margin-left: -28px!important;
      margin-right: -28px!important
    }
  }

  .template-generator-base {
    margin-right: 15px!important;
    border-radius: 10px!important
  }

  .template-generator {
    background-color: white;
    border-radius: 10px!important;
    margin-top: 15px!important;
    margin-right: 15px!important;
    padding: 20px;
  }

  @media screen and (max-width: 950px) {
    .template-generator {
      margin-right: 0!important;
      width: 100%;
      border-radius: 0!important;
      margin-top: 0!important
    }
    .template-generator-base {
      margin-right: 0!important;
      width: 100%
    }

  }

  .template-generator-mobile-preview {
    background-color: #F0F0F0;
    border-radius: 10px;
    margin-top: 15px!important;
    padding: 20px;
    max-width: calc(33.333333% - 15px)
  }

  .my-profile h1 {
    font-size: 26px;
  }

  .mobile-preview-h2 {
    font-size: 21px
  }

  .h2-21 h2 {
      font-size: 21px
  }

  .border-10 {
    border-radius: 10px!important
  }

   .template-generator h1 {
    font-size: 26px;
  }


  .edit-user h1 {
    font-size: 26px;
  }

  .edit-user h2 {
    font-size: 18px;
  }

  .h2-18 h2 {
    font-size: 18px;
  }


  .h2-16 h2 {
    font-size: 16px;
  }
  .v-treeview-node--active {
    background-color:#F6F9FF;
  }

  .theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
    border-radius: 5px
  }

  .v-treeview-node--active .v-icon {
    color: #324874!important
  }

    .pre-wrap {
        white-space: pre-wrap;
    }

  .btn-light-blue {
    background-color:transparent!important;
    box-shadow: none!important;
    color: #2196F3!important;
    letter-spacing: normal!important;
    font-size: 16px!important
  }

  .btn-light-success {
    background-color:transparent!important;
    box-shadow: none!important;
    color: #4CAF50!important;
    letter-spacing: normal!important;
    font-size: 16px!important
  }


.signature-pad {
  background-color: #f0f0f0!important
}
  .rename h1 {
    font-size: 26px;
  }

  .rename h2 {
    font-size: 18px;
  }

  .my-profile h2 {
    font-size: 18px;
  }

  .phone-field {
    max-width: 400px!important;
    border-style: solid;
    border-width:1px;
    border-color: rgba(0, 0, 0, 0.38);
    border-radius: 5px;
    height: 54px

  }

  .link-copy {
    border-style: solid!important;
    border-width: 1px!important;
    border-color:rgba(0, 0, 0, 0.12)!important;
    width: 700px
  }

  .text-black {
    color: #000!important
  }

  .text-white {
    color: #fff;
  }

  .delete-message > .v-input__control > .v-messages {
    display: none!important;
  }

  .close-button {
    margin-top: 2px;
    font-size: 28px!important;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    transition: background-color 0.1s ease-in;
    margin-left: 40px;
  }

  .close-button-small {
    font-size: 18px!important;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    padding-top:3px;
    transition: background-color 0.1s ease-in;
  }

  .close-button:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }

  .close-button-small:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
  .col-icon {
    width: 70px;
  }

  .col-rest {
    width: auto;
  }

  .text-primary {
    color: #324874 !important;
  }

  .text-gray {
    color: #727272!important;
    white-space: pre-line;
  }

  .image-avatar {
    background-color: rgb(254, 209, 209);
    width: 70px;
    height: 70px;
    border-radius: 35px;
    text-align: center;
  }

  .image-avatar-table {
    background-color: rgb(254, 209, 209);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    margin: 12px 15px 12px 0;
  }

  .image-avatar-table-visitor {
    background-color: rgb(229, 229, 229);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    margin: 12px 15px 12px 0;
  }

  .image-avatar-file {
    background-color: rgb(254, 209, 209);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    margin: 0
  }

  .break-word {
    word-break: break-word
  }

  .avatar-add {
    background-color: rgb(234, 234, 234);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border-style:dashed;
    border-width: 1px;
    border-color: #909090!important;
    text-align: center;
    padding-top: 6px;
    transition: all 0.1s ease-in
  }

  .avatar-add:hover {
    cursor:pointer;
    background-color: rgb(216, 216, 216);
  }

  .image-avatar-table-test {
    background-color: rgb(254, 209, 209);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    margin: 0 5px
  }

  .avatar-text {
    font-size: 30px;
    color: rgb(157, 23, 23);
    padding-top: 10px;
  }

  .avatar-text-table {
    font-size: 18px;
    color: rgb(157, 23, 23);
    padding-top: 6px;
  }

  .avatar-text-table-visitor {
    font-size: 18px;
    color: #324874;
    padding-top: 6px;
  }

  .mx--3 {
    margin-left: -12px!important;
    margin-right: -12px!important;
  }

  .v-text-field {
    max-width: 400px!important;
  }

  .narrow {
    max-width: 140px!important
  }


.v-card {
  box-shadow: none!important
}
.v-card--link:focus {
  color:transparent!important
}

.v-card--link {
  color:transparent!important
}

  .outline-primary {
    padding: 9px 15px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #324874 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: transparent !important;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #324874;
    transition: all 0.1s ease-in!important;
  }

.settings-outline {
    padding: 6px 10px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #324874 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: transparent !important;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #324874;
}

.small-icon {
  font-size: 20px!important
}
.outline-primary-icon-only {
    padding: 7px 15px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #324874 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: transparent !important;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #324874;
    transition: all 0.1s ease-in!important;
  }
  .primary-button {
    padding: 9px 15px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #fff !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #324874 !important;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #324874;
    transition: all 0.1s ease-in!important;
  }


  .disabled-button {
    padding: 9px 15px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #fff !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #15223d !important;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #15223d;
    transition: none!important
  }

  .white-button {
    padding: 15px!important;
    height: auto !important;
    width: 100%;
    text-align: left!important;
    box-shadow: none !important;
    color: #324874 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #fff !important;
    border-style: none;
    border-radius: 5px;
    transition: none!important
  }

  .white-button-primary {
    padding: 15px!important;
    height: auto !important;
    width: 100%;
    text-align: left!important;
    box-shadow: none !important;
    color: #fff !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #324874 !important;
    border-style: none;
    border-radius: 5px;
    transition: none!important
  }

  .white-button-centered {
    padding: 15px!important;
    height: auto !important;
    width: 100%;
    box-shadow: none !important;
    color: #324874 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #fff !important;
    border-style: none;
    border-radius: 5px;
    transition: none!important
  }



  .white-button-alert {
    padding: 15px!important;
    height: auto !important;
    width: 100%;
    text-align: left!important;
    box-shadow: none !important;
    color: #C73636 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #fff !important;
    border-style: none;
    border-radius: 5px;
    transition: none!important
  }


  .white-button > .v-btn__content {
    display: block;
  }
  .white-button-alert > .v-btn__content {
    display: block;
  }

  .white-button-primary > .v-btn__content {
    display: block;
  }
  .disabled-button:hover {
    animation: none;
    background-color: #15223d !important;
  }

  .secondary-button {
    padding: 9px 15px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #324874 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: transparent !important;
    border-style: hidden!important;
  }

  .button-alert {
    padding: 9px 15px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #C73636 !important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: transparent !important;
  }

  .button-alert-primary {
    padding: 9px 15px !important;
    height: auto !important;
    box-shadow: none !important;
    color: #fff!important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #C73636!important;
  }

  .button-add-field {
    width: 100%;
    padding: 25px 15px!important;
    height: auto !important;
    box-shadow: none !important;
    color: #838383!important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #F8F8F8!important;
    text-align: center;
    border-style: dashed;
    border-color:#CAC7C7!important;
    border-width:2px
  }

  .button-add-subsection {
    width: 100%;
    padding: 25px 15px!important;
    height: auto !important;
    box-shadow: none !important;
    color: #324874!important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #F2F7FF!important;
    text-align: center;
    border-style: dashed;
    border-color:#324874!important;
    border-width:2px;
    transition: all 0.2s ease-in!important
  }

  .button-add-subsection:hover {
    background-color: #eaf3ff!important;
  }

  .button-add-section {
    width: 100%;
    padding: 25px 15px!important;
    height: auto !important;
    box-shadow: none !important;
    color: #838383!important;
    font-size: 16px!important;
    letter-spacing: normal!important;
    background-color: #F8F8F8!important;
    text-align: center;
    border-style: dashed;
    border-color:#CAC7C7!important;
    border-width:2px
  }

  .outline-primary:hover {
    background-color: #324874 !important;
    color: #fff !important;
  }

  .outline-primary-icon-only:hover {
    background-color: #324874 !important;
    color: #fff !important;
  }

  .outline-primary-icon-only:hover .v-icon{
    color: #fff !important;
  }

  .primary-button:hover {
    background-color: #212e49 !important;
  }

  .container {
    margin-left: 0!important;
    margin-right: 0!important;
    max-width: none!important;
  }

  .mt--1 {
    margin-top: -4px
  }

  .mt--2 {
    margin-top: -8px
  }

  .logout-button:hover {
    cursor: pointer;
  }

  .tabs-items {
    background-color: #F9F9F9!important
  }

  .text-hover:hover {
    cursor: pointer;
  }

  .search {
    border-radius: 10px;
    max-width: 60%!important;
    margin-top: 16px!important;
  }

  @media only screen  and (max-width: 950px) {
    .search {
      border-radius: 10px;
      max-width: 100%!important;
      margin-top: 16px!important;
    }
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
     background: #f0f0f0!important
  }

  textarea {
    background: #f0f0f0!important;
  }
  .textarea-active textarea {
    background: #E1EDFF!important;
  }

  .textarea-transparent textarea {
    background-color: transparent!important;
  }

  .blue-bg.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    background-color: #E1EDFF!important;
  }
    .light-bg.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    background-color: #ffffff!important;
  }

  .light-bg-header.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    background-color: transparent!important;
  }

.light-bg-content-header.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    background-color: transparent!important;
  }

.light-bg-content-subheader.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    background-color: transparent!important;
  }
.light-bg-subheader.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    background-color: transparent!important;
  }
    .light-bg.theme--light.v-input input{
    color: #324874!important;
  }

  .light-bg-subheader.theme--light.v-input input{
    color: gray!important;
    background-color: transparent!important;
  }

  .light-bg-header.theme--light.v-input textarea {
      color: #324874!important;
      background-color: transparent!important;
      line-height: 39.1px
  }

    .light-bg-content-header.theme--light.v-input textarea {
      color: #324874!important;
      background-color: transparent!important;
      line-height: 36.1px
  }

  .light-bg-content-subheader.theme--light.v-input textarea {
      color: gray!important;
      background-color: transparent!important;
      line-height: 26.1px
  }

    .light-bg-subheader.theme--light.v-input textarea {
      color: gray!important;
      background-color: transparent!important;
      line-height: 26px
  }

  .light-bg-header.v-textarea.v-text-field--solo .v-input__append-inner {
      margin-top: 20px!important
  }

    .light-bg-content-header.v-textarea.v-text-field--solo .v-input__append-inner {
      margin-top: 18px!important
  }

  .xs-icon {
    font-size: 16px!important
  }


  .text-field-100 {
    max-width: calc(100% - 16px)!important
  }

  @media screen and (max-width: 950px) {
    .text-field-100 {
      max-width: 100%!important
    }
  }

  .text-field-100-header {
    max-width: 100%!important;
    font-size: 26px!important;
    font-weight: bold;
    color: #324874!important;
    background-color: transparent!important;
    margin-left: -12px!important;
    margin-top: -10.5px!important;
    margin-bottom: 0.5px!important;
    font-family: 'Mulish', sans-serif!important;
  }

  .text-field-100-subheader {
    max-width: 100%!important;
    font-weight: bold;
    color: gray!important;
    background-color: transparent!important;
    margin-left: -12px!important;
    margin-top: -11px!important;
    margin-bottom: 3px!important
  }

  .text-field-100-content-header {
    max-width: 100%!important;
    font-size: 23px!important;
    font-weight: bold;
    color: #324874!important;
    background-color: transparent!important;
    margin-left: -12px!important;
    margin-top: -10.4px!important;
    margin-bottom: 4.9px!important;
    font-family: 'Mulish', sans-serif!important;
  }

  .text-field-100-content-subheader {
    max-width: 100%!important;
    font-weight: bold;
    color: gray!important;
    background-color: transparent!important;
    margin-left: -12px!important;
    margin-top: -10.8px!important;
    margin-bottom: 2.8px!important;
    line-height: 1rem!important;
  }

    .min-w-240 {
        min-width: 240px!important;
    }
      .min-w-icons {
        min-width: 70px!important
    }

    .min-w-100 {
        min-width: 120px!important
    }
  .bold-off {
    font-weight: normal;
  }
  .comment-field-100 {
    max-width:100%!important
  }

  .button-container {
    min-width: 40%!important
  }
  .icon-plus {
    font-size: 28px!important;
    margin-right: 5px;
  }


  .avatar-width {
    max-width: 70px!important
  }
  .no-margin {
    margin: 0
  }

  .v-data-table tbody tr.v-data-table__selected {
    background: #F6F9FF!important
  }

  .mdi-checkbox-marked {
    color: #324874!important
  }

  .mdi-minus-box {
    color: #324874!important
  }

  .v-data-table-header {
    background: #F4F4F4!important
  }

  .v-data-table {
    border: thin solid rgba(0, 0, 0, 0.12);
  }

  @media screen and (max-width: 950px) {
    .v-data-table {
      min-width: auto;
    }
  }

  .v-data-table >
  .v-data-table__wrapper >
  table > tbody >
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #F6F9FF!important
  }

  .v-data-table-header {
    border-radius: 8px 8px 0 0
  }

  tr:hover {
    cursor: pointer
  }

  .breadcrumbs {
    font-size: 16px;
    letter-spacing: normal;
    color: #909090;
    font-weight: 400;
  }

  .active {
    color: #000;
    font-weight: 600;
  }

  .text-500 {
    font-weight: 500;
  }
  .breadcrumb-gray {
    color: #909090!important;
    font-size: 20px!important;
    margin-top: 2px
  }

  .breadcrumb-button {
    background: transparent!important;
    min-width: auto!important;
  }

  .dark-bg {
    color: #bdc8de!important;
    border-radius: 5px;
  }

  .dark-bg:hover {
    color: #fff!important
  }

  .light-bg {
    background-color: #3f5787;
    border-radius: 5px;
    color:#fff!important
  }

  .w-100 {
    width: 100%;
    display: flex;
  }

  .hidden {
    display: none!important
  }

  .v-list-item--link:before {
    background: transparent!important
  }

  .light-gray-divider {
    border-color: #63769c!important
  }

  .rounded-button {
    background-color:#F2F2F2
  }

  .fixed-bottom {
    position: fixed;
    bottom: 0;
    max-width: calc(100% - 256px)!important;
    box-shadow: 0px -10px 30px -15px rgba(122, 131, 143, 0.1);
  }

  .fixed-bottom-full {
    position: fixed;
    bottom: 0;
    box-shadow: 0px -10px 30px -15px rgba(122, 131, 143, 0.1);
  }

  .mobile-dialog-controls {
    position: fixed;
    bottom: 0;
    width: calc(100% - 40px);
    background-color: #fff;
    z-index: 10
  }

  .mb-150 {
    margin-bottom: 150px!important
  }


  .mb-70 {
    margin-bottom: 70px!important
  }

  .white-bg {
    background-color: #fff
  }

  input.email-login:-internal-autofill-selected {
    background-color: rgb(255, 255, 255) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

  input.password-login:-internal-autofill-selected {
    background-color: rgb(255, 255, 255) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}
.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group input:-webkit-autofill ~ label /* <<<< Add these */
{
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}

  .field-background {
    background-color: #F3F7FE!important;
    border-radius: 0!important;
    margin-left: -20px;
    max-width: calc(100% + 56px)!important;
    border-left-style: solid!important;
    border-left-color: #324874!important;
    border-left-width: 10px!important;
    margin-right: -36px!important;
  }

  @media only screen and (max-width: 950px) {
    .field-background {
      border-left-width: 7px!important;
    }
  }

  .field-header {
    background-color: #F3F7FE!important;

  }
  .field-background-normal {
    background-color: #ffffff!important;
  }

  .field-active {
    background-color: #F3F7FE!important;
    padding: 0 36px 0 10px
  }

  @media only screen and (max-width: 950px) {
    .field-active {
      padding: 0 36px 0 13px
    }
  }


  .field-not-active {
    background-color: #fff;
  }

  .border-solid {
    border-left-style: solid;
    border-left-color: #c2cee4;
    border-left-width: 2px;
  }

  @media screen and (max-width: 950px) {
    .border-solid {
      border-left-style: none;
      border-top: 1px solid #c2cee4
    }
  }

  .ml--3 {
    margin-left: -12px!important
  }

.normal-bg {
  background-color: #ffffff!important;
  border-radius: 0!important;
  border-left-color: #fff;
}

.image-container {
  background-color: rgb(255, 255, 255)!important;
  border-radius: 10px;
}

.image-container-disabled {
  background-color: rgb(246, 246, 246);
  border-radius: 10px;
}

@media screen and (max-width: 480px) {
  .image-container {
    max-width: 100%;
    width: 100%;
  }

  .image-container-disabled {
    max-width: 100%;
    width: 100%
  }
}


.file-container {
  background-color: #fff!important;
  border-radius: 5px
}

.cursor-normal:hover{
  cursor:auto
}

.file-container-disabled {
  background-color: rgb(246, 246, 246);
  border-radius: 5px
}

.choice-container {
  background-color: #fff!important;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.choice-container-disabled {
  background-color: rgb(241, 240, 240);
  border-radius: 5px
}

.choice-container-active {
  background-color: #324874;
  border-radius: 5px
}

.table-row {
  background-color: #fff!important;
  border-radius: 5px
}

.table-row-disabled {
  background-color: rgb(246, 246, 246);
  border-radius: 5px
}

.fill-report {
  width: 100%;
  position: relative;
  z-index: 0;
}

.h1-26 h1 {
  font-size: 26px!important;
}

.fill-report h1 {
  font-size: 26px!important;
}

.border-0 {
  border-radius: 0!important
}

.fill-report-card {
  background-color: #fff;
  border-radius: 10px!important;
}

@media screen and (max-width:950px) {
  .fill-report-card {
    border-radius: 0!important
  }
}

.fill-area {
  width: calc(100% - 36px);
  padding-right: 36px
}

.fill-area--report {
  width: calc(100% - 60px);
}

.bg-transparent {
  background: transparent!important;
}


.bg-white {
  background-color: #fff;
  height: 100vh
}

.subsection-header h1 {
  font-size: 22px!important
}

.hover-card {
  transition: all 0.2s ease-in!important;
}
.hover-card:hover {
  background: #F6F9FF!important
}

.hover {
  cursor:pointer
}

.small {
  font-size: 12px
}

.image-modal-bg {
  background: #000;
}

.font-size-23 {
  font-size: 23px!important
}

.static {
  position: fixed;
  top: 0;
  z-index: 3;
  width: calc(100% - 256px);
  background-color: #fff;
  margin-left: -40px!important;
  box-shadow: 0px -10px 30px 15px rgba(122, 131, 143, 0.1);
}

.static-full {
  position: fixed;
  top: 0;
  z-index: 3;
  width: calc(100%);
  background-color: #fff;
  margin-left: -40px!important;
  box-shadow: 0px -10px 30px 15px rgba(122, 131, 143, 0.1);
}


.cursor-not-allowed {
  cursor: not-allowed;
}
.square-bg {
  color:rgb(220, 220, 220)!important;
  /* background-color:rgb(120, 120, 120); */
}

.square-bg:hover {
  background-color:rgb(110, 110, 110);
  color:#fff!important
}

@media only screen and (max-width: 950px) {
  .square-bg {
    color:rgb(113, 113, 113)!important;
  /* background-color:rgb(120, 120, 120); */
  }

  .square-bg:hover {
    background-color:rgb(110, 110, 110);
    color:#fff!important
  }
}

.no-box-shadow {
  box-shadow: none!important;
}

.show-on-hover {
  color: #fff!important
}

.show-on-hover-gray {
  color: #fff!important
}

.show-icon-onhover {
    display: flex;
    align-items: baseline;
}

.show-icon-onhover:hover > h1 > .show-on-hover {
  color:#324874!important
}

.show-icon-onhover-section:hover > .col > h1 > .show-on-hover {
    color:#324874!important
}

.show-icon-onhover:hover > .show-on-hover-gray {
  color:gray!important
}

.mobile-navigation {
  background-color: #324874;
}

.pre-line {
    white-space: pre-line;
}

.sidebar {
  min-height: 100%;
  height: 100%!important;
}

.mobile-add {
  position: fixed!important;
  bottom: 20px!important;
  right: 20px!important;
  padding: 15px!important;
  background-color: #324874!important;
  color: #fff!important;
  height: auto!important;
  min-width: auto!important;
  border-radius: 50%!important;
  z-index: 6;
}

.mobile-new {
  position: absolute;
  bottom: 0!important;
  border-radius: 10px 10px 0 0!important;
  margin: 0!important;
  background-color: #f9f9f9!important;
}

.img-fullscreen-modal {
  box-shadow: none!important;
  margin-top: -45px!important;
  overflow-x: hidden
}

@media only screen and (max-width: 950px) {
  .img-fullscreen-modal {
    margin-top: 0!important;
  }
}

.fixed-80 {
  min-width: 80px!important
}

.h1-23 h1 {
  font-size: 23px!important;
  white-space: initial;
}


.h1-18 h1 {
  font-size: 18px!important;
}

.h1-16 h1 {
    font-size: 16px!important;
}

.image-control-row {
  width: calc(100% - 121px);
}

.justify-participant-delete {
  margin-top: -50px!important
}

.dashed-line {
  width: 100%;
  height: 64px;
  border-right-style: dashed;
  border-right-color: #324874;
  border-right-width: 2px;
}

.col-align {
  display: flex;
  flex-direction: column;
}

.bg-black {
  background-color: #000!important
}

.v-slide-group__prev {
  display: none!important
}

.fullscreen-img {
  height: calc(100vh - 75px)
}

.v-window {
  overflow: initial!important;
}

.blink {
  animation: blinker 0.5s linear, fading 2s linear;
  border-radius: 5px!important;
  background-color: rgb(246, 246, 246)!important;
}

.mobile-ok {
    position: absolute;
    bottom: 0!important;
    border-radius: 10px 10px 0 0!important;
    margin: 0!important;
    background-color: #f9f9f9!important;
}

.v-icon.v-icon::after {
    background-color: transparent!important;
}

.overlay {
    filter: blur(1px);
    -webkit-filter: blur(1px);
}

.modal-header-mobile {
    position: fixed;
    top: 0;
    border-bottom: solid 1px #e0e0e0;
    width: 100%;
    padding: 16px 0 0 0;
    background: #fff;
    z-index: 9999
}

.pt-header {
    padding-top: 80px
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fading {
  0% {
    background-color: #e6edfa!important;
  }

  100% {
    background-color: rgb(246, 246, 246)!important;
  }
}


</style>
