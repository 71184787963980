import * as firebase from 'firebase/app';

export default {
    data() {
        return {
            controlItems: [
                {
                    title: 'Profiilin hallinta',
                    icon: 'mdi-cog',
                    routeName: 'accountmanagement',
                },
            ],
        };
    },
    methods: {
        navigate(routeName) {
            // If page is already open, do something'
            if (this.$route.name === routeName) {
                if (routeName === 'dashboard') {
                    // Navigate to base folder
                    this.$store.state.currentFolderPath = [];
                } else if (routeName == 'sharedfolders') {
                    this.$store.state.currentSharedFolderPath = [];
                }
            } else {
                this.$router.push({ name: routeName });
            }
        },
    },
    computed: {
        signedIn() {
            var user = firebase.default.auth().currentUser;
            if (user) {
                // User is signed in.
                return true;
            } else {
                return false;
                // No user is signed in.
            }
        },
        navItems() {
            let navItemsToReturn = [];
            if (this.$store.state.userProfile.role === 'default') {
                navItemsToReturn.push({
                    title: 'Jaetut kansiot',
                    icon: 'mdi-folder-account',
                    routeName: 'sharedfolders',
                });
            } else if (this.$store.state.userProfile.role === 'admin') {
                navItemsToReturn.push({
                    title: 'Raportit',
                    icon: 'mdi-file-document',
                    routeName: 'dashboard',
                });
                navItemsToReturn.push({
                    title: 'Pohjat',
                    icon: 'mdi-file-outline',
                    routeName: 'templates',
                });
                navItemsToReturn.push({
                    title: 'Jaetut kansiot',
                    icon: 'mdi-folder-account',
                    routeName: 'sharedfolders',
                });
            }

            if (this.$store.state.userProfile.bundle.includes('process')) {
                navItemsToReturn.push({
                    title: 'Prosessi',
                    icon: 'mdi-chart-timeline-variant',
                    routeName: 'processes',
                });
            }
            if (this.$store.state.autolinks.length !== 0) {
                navItemsToReturn.push({
                    title: 'Automaattiraportit',
                    icon: 'mdi-file-link',
                    routeName: 'autolinks',
                });
            }

            return navItemsToReturn;
        },
    },
};
