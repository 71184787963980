import {Component} from '../../../component';

class SectionTitle extends Component {
    code() {
        return {
            text: `${this.sectionLabel} ${this.args.text}`,
            style: 'SectionTitleStyle',
        };
    }

    get sectionLabel()  {
        let label = '';
        for(const depth of this.args.recursiveDepth) {
            label += `${depth}.`;
        }
        return label;
    }
}

export default SectionTitle;
