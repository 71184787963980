<template>
    <v-dialog
        v-model="dialog"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
        @keydown.enter.prevent="agree"
        hide-on-leave
        :width="$vuetify.breakpoint.mdAndUp ? '400' : ''"
        :content-class="$vuetify.breakpoint.mdAndUp ? '' : 'mobile-ok'"
        transition="dialog-bottom-transition"
    >
        <v-card class="pt-5 px-5 pb-3" :class="{'pa-5 bg-transparent': !$vuetify.breakpoint.mdAndUp}">
            <v-container fluid>
                <v-row class="mx-0 h1-26" :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
                    <h1>{{ title }}</h1>
                </v-row>
                <p
                    class="my-7"
                    v-show="!!message"
                    v-html="message"
                ></p>
                <v-row class="mx-0 mt-4">
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            :class="{'white-button-centered w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp,
                                     'secondary-button': $vuetify.breakpoint.mdAndUp}"
                            @click="cancel"
                            data-cy="cancelAction"
                            v-if="!options.noconfirm"
                        >
                            {{ cancelAction }}
                        </v-btn>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'"
                        align="end"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            class="button-alert-primary"
                            :class="{'py-3 w-100 mb-4':!$vuetify.breakpoint.mdAndUp}"
                            @click="agree"
                            data-cy="confirmAction"
                        >
                            <v-icon class="mr-2">mdi-delete</v-icon>
                            {{ confirmAction }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmDlg',
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            confirmAction: this.$t('OkCancelDialog.confirmAction'),
            cancelAction: this.$t('OkCancelDialog.cancelAction'),
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
        };
    },

    methods: {
        open(title, message, confirmAction, cancelAction, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            if (confirmAction != undefined) {
                this.confirmAction = confirmAction;
            }
            if (cancelAction != undefined) {
                this.cancelAction = cancelAction;
            }

            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>
