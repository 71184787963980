<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="600"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog" data-cy="closeDialog">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("CreateTemplate.newTemplate") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-form ref="form">
                <v-container fluid :class="{'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>{{ $t("CreateTemplate.newTemplate") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                data-cy="closeDialog"
                                class="close-button"
                                @click="closeDialog"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 mt-2 pl-1">
                        <v-text-field
                            :label="$t('CreateTemplate.templateName')"
                            v-model="templateName"
                            :rules="requireName"
                            data-cy="templateName"
                            counter="50"
                            @keydown.enter.prevent="createAndOpenReport"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row
                        class="mb-0"
                        :class="{
                            'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp,
                            'mt-12': $vuetify.breakpoint.mdAndUp}"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="secondary-button"
                                :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                                @click="closeDialog"
                            >
                                {{ $t("AddUser.discard") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                            align="end"
                            :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                        >
                            <v-btn
                                class="primary-button"
                                :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                                data-cy="createTemplate"
                                @click="createAndOpenReport"
                            >
                                {{ $t("CreateTemplate.newTemplate") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('CreateTemplate.loadingMessage')"
        />

        <!-- TODO: Refactor into new component -->

        <v-dialog
            v-model="alertDialog"
            :width="$vuetify.breakpoint.mdAndUp ? '400' : ''"
            hide-on-leave
            :content-class="$vuetify.breakpoint.mdAndUp ? '' : 'mobile-dialog'"
            transition="dialog-bottom-transition"
        >
            <v-container fluid :class="{'pa-0': $vuetify.breakpoint.mdAndUp}">
                <v-card class="rounded-lg pa-4" :class="{'bg-transparent': !$vuetify.breakpoint.mdAndUp}">
                    <v-row class="h1-26 ma-0 px-4" :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
                        <h1>{{ $t("CreateTemplate.attention") }}</h1>
                    </v-row>
                    <v-row class="mx-0 mt-4 mb-2 px-4">
                        <p>{{ $t("CreateTemplate.nameAlreadyUsed") }}</p>
                    </v-row>
                    <v-row class="mx-0 mt-7" :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'start'">
                        <v-btn
                            class="primary-button mb-3 mr-3"
                            :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                            @click="closeAlertdialog"
                            data-cy="closeAlertdialog"
                        >
                            {{ $t("CreateTemplate.ok") }}
                        </v-btn>
                    </v-row>
                </v-card>
            </v-container>
        </v-dialog>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import { templateTemplate } from '../../datamodels/datamodels.js';

export default {
    components: {
        BaseLoadingDialog,
    },
    data() {
        return {
            dialog: false,
            alertDialog: false,
            sendingData: false,
            templateName: '',
            chosenType: 'emptyFile',
            chooseTemplateMode: false,
            locale: {
                code: this.$i18n.locale || navigator.language,
                full: this.$t('Languages.' + this.$i18n.locale || navigator.language),
            },
            selectedTemplate: {},
            requireName: [
                (v) => (v && v.length > 0) || this.$t('CreateTemplate.rule'),
            ],
        };
    },
    methods: {
        setChooseTemplateMode(params) {
            this.chooseTemplateMode = params;
        },

        chooseType(type) {
            this.chosenType = type;
        },

        openDialog() {
            this.selectedTemplate = {};
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        createAndOpenReport() {
            if (this.$refs.form.validate()) {
                if (this.getCheckIsTemplateNameTaken(this.templateName)) {
                    this.alertDialog = true;
                } else {
                    this.sendingData = true;
                    if (this.$refs.form.validate()) {
                        var templateClone = {};
                        templateClone = _.cloneDeep(this.selectedTemplate);
                        if (_.isEmpty(templateClone)) {
                            templateClone = _.cloneDeep(templateTemplate);
                        }
                        templateClone['templateName'] = this.templateName;
                        templateClone['createdAt'] = Math.round(
                            new Date().getTime() / 1000
                        );
                        templateClone['locale'] = this.locale.code;
                        delete templateClone.template_id;
                        this.$store
                            .dispatch('addTemplate', templateClone)
                            .then(() => {
                                this.sendingData = false;
                                this.$router.push({ name: 'templategenerator' });
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
            }
        },
        changeSelectedTemplate(obj) {
            this.selectedTemplate = obj;
        },
        navigateBack() {
            this.closeDialog();
        },

        closeAlertdialog() {
            this.alertDialog = false;
        },
        switchLocale(locale) {
            if (locale !== this.locale) {
                this.locale = locale.code;
            }
        },
    },
    computed: {
        ...mapGetters(['getTemplates']),
        ...mapGetters(['getCheckIsTemplateNameTaken']),
        localesComputed() {
            return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').map(
                (value) => {
                    return {
                        code: value,
                        full: this.$t('Languages.' + value),
                    };
                }
            );
        },
    },
};
</script>

<style scoped>
  * :deep(.mobile-dialog) {
    position: absolute;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    margin: 0;
    background-color: #f9f9f9;
  }
</style>
