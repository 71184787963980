<template>
    <v-card
        class="field-not-active pt-5 pb-7"
    >
        <!-- HELPER FOR SELECTING AN IMAGE -->

        <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".jpeg, .jpg, .png"
            @change="newCamPhotoTaken($event)"
            style="height: 100px"
        />

        <!-- HELPER APPARENTLY ENDS...? -->

        <v-container class="py-0">

            <!-- DRAG AREA  -->

            <v-row justify="center">
                <span class="my-handle">
                    <v-icon> mdi-drag-horizontal </v-icon>
                </span>
            </v-row>

            <!-- DRAG AREA ENDS -->

            <!-- FIELD TYPE CHOOSER -->

            <v-row
                align="center"
                class="mb-3 mt-n4"
                :class="{'col-align':$vuetify.breakpoint.width < 1200 && isFillable}"
            >
                <!-- ICON FOR EVERY TYPE OF THE CHOOSER -->
                <v-col class="ma-0 pr-10 grow">
                    <v-row class="ma-0">
                        <v-col class="pa-0 shrink mt-5">
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'addimages'"
                            >
                                mdi-camera
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'slider'"
                            >
                                mdi-source-commit
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'text'"
                            >
                                mdi-text-box
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'textfield'"
                            >
                                mdi-format-text
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'comment'"
                            >
                                mdi-comment
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'choice'"
                            >
                                mdi-checkbox-multiple-marked
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'image'"
                            >
                                mdi-image-size-select-actual
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'signature'"
                            >
                                mdi-draw
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'textinput'"
                            >
                                mdi-text-short
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'infotext'"
                            >
                                mdi-information
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'file'"
                            >
                                mdi-paperclip
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus"
                                v-if="fieldData.type === 'staticfile'"
                            >
                                mdi-attachment
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'date'"
                            >
                                mdi-calendar-range
                            </v-icon>
                            <v-icon
                                color="primary"
                                class="icon-plus mr-3"
                                v-if="fieldData.type === 'table'"
                            >
                                mdi-table
                            </v-icon>
                        </v-col>

                        <!-- ICON COLUMN ENDS -->

                        <!-- DROPDOWN CHOOSER -->
                        <v-col class="pa-0 grow" data-cy="fieldTypeSelect">
                            <v-select
                                @click.stop
                                :items="getFieldTemplates()"
                                :value="sectionDataFields[fieldIndex]"
                                :item-text="(item) => $t('Field.' + item.type)"
                                return-object
                                @change="changeSelect($event)"
                                hide-details
                            >
                                <template v-slot:item="{ item }">
                                    <v-container>
                                        <v-row v-if="item.type === 'addimages'">
                                            <v-col>
                                                <v-icon> mdi-camera </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'slider'">
                                            <v-col>
                                                <v-icon> mdi-source-commit </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'text'">
                                            <v-col>
                                                <v-icon> mdi-text-box </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'textfield'">
                                            <v-col>
                                                <v-icon> mdi-format-text </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'comment'">
                                            <v-col>
                                                <v-icon> mdi-comment </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'choice'">
                                            <v-col>
                                                <v-icon> mdi-checkbox-multiple-marked </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'image'">
                                            <v-col>
                                                <v-icon> mdi-image-size-select-actual </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'signature'">
                                            <v-col>
                                                <v-icon> mdi-draw </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'table'">
                                            <v-col>
                                                <v-icon> mdi-table </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'textinput'">
                                            <v-col>
                                                <v-icon> mdi-text-short </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'infotext'">
                                            <v-col>
                                                <v-icon> mdi-information </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'file'">
                                            <v-col>
                                                <v-icon> mdi-paperclip </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'staticfile'">
                                            <v-col>
                                                <v-icon> mdi-attachment </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.type === 'date'">
                                            <v-col>
                                                <v-icon> mdi-calendar-range </v-icon>
                                                <span class="ml-2">
                                                    {{ $t("Field." + item.type) }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- DROPDOWN CHOOSER ENDS -->

                <!-- CONTROL PANEL -->

                <v-col
                    class="py-0 pl-0 pr-4 mt-5 shrink min-w-240"
                    :class="{'min-w-100' : $vuetify.breakpoint.width < 1200}"
                    v-if="$vuetify.breakpoint.mdAndUp"
                >
                    <v-row
                        class="ma-0"
                        :justify="$vuetify.breakpoint.width < 1200 && isFillable ? 'start' : 'end'"
                        :class="{'ml-4 mt-n5 mb-3': $vuetify.breakpoint.width < 1200 && isFillable}"
                    >
                        <p class="mt-5 mr-4" v-if="isFillable">
                            {{ $t("Field.required") }}
                        </p>
                        <v-switch
                            data-cy="toggleRequired"
                            v-if="isFillable"
                            v-model="isRequired"
                            @click.stop=""
                        >
                        </v-switch>
                        <v-icon
                            color="primary"
                            class="ml-7 mr-3"
                            @click.stop="copyfield(fieldData)"
                        >
                            mdi-content-copy
                        </v-icon>
                        <v-icon color="primary" @click.stop="deletefield">
                            mdi-delete
                        </v-icon>
                    </v-row>
                </v-col>

                <!-- CONTROL PANEL ENDS -->
            </v-row>

            <!-- CONTROL PANEL MOBILE -->

            <v-row
                class="mx-0 mt-3"
                v-if="!$vuetify.breakpoint.mdAndUp"
                :class="{'mt-5 mb-0': !isFillable}"
            >
                <p class="mt-5 mr-3" v-if="isFillable"> {{ $t("Field.requiredField") }}</p>
                <v-switch
                    data-cy="toggleRequired"
                    v-model="isRequired"
                    v-if="isFillable"
                    @click.stop=""
                >
                </v-switch>
                <v-icon color="primary" class="mr-3" :class="{'ml-7': isFillable}" @click.stop="copyfield(fieldData)">
                    mdi-content-copy
                </v-icon>
                <v-icon color="primary" @click.stop="deletefield"> mdi-delete </v-icon>
            </v-row>

            <!-- FIELD TYPE CHOOSER ENDS -->

        </v-container>

        <!-- FIELD CONTENT AREA  -->

        <!-- QUESTION OR EXPLANATION -->

        <v-row
            @click.stop
            :class="{
                'px-7 pt-4 mb-0': $vuetify.breakpoint.mdAndUp,
                'pl-4 pr-2 pt-4 mt-2 mb-4 mr-1': !$vuetify.breakpoint.mdAndUp,
            }"
            v-if="isFillable"
        >
            <v-text-field
                v-if="isFillable && this.fieldData.type !== 'textinput'"
                :label="$t('Field.writeDescription')"
                hide-details
                clearable
                flat
                class="text-field-100"
                v-model="fieldData.question"
            >
            </v-text-field>
            <v-text-field
                v-if="isFillable && this.fieldData.type === 'textinput'"
                counter
                maxlength="45"
                :label="$t('Field.writeDescription')"
                clearable
                flat
                class="text-field-100"
                v-model="fieldData.label"
            >
            </v-text-field>
        </v-row>

        <!-- QUESTION OR EXPLANATION ENDS -->

        <!-- SLIDER -->

        <div
            :class="{
                'ml-4 mr-4 mt-4': $vuetify.breakpoint.mdAndUp,
                'ml-0 mr-3 mt-4 ml-1': !$vuetify.breakpoint.mdAndUp }"
            v-if="fieldData.type === 'slider'"
        >
            <v-row class="mx-0 mt-1">
                <div class="col-align pl-2 pr-5 mt-3">
                    <div>
                        <p class="mb-1 mt-1 text-primary"><strong>{{$t('Field.minimum')}}</strong></p>
                    </div>
                    <div class="dashed-line ml-n4">
                    </div>
                    <div>
                        <p class="mt-1 text-primary"><strong>{{$t('Field.maximum')}}</strong></p>
                    </div>
                </div>
                <v-col>
                    <v-form ref="sliderForm">
                        <v-row class="mb-2">
                            <v-text-field
                                :label="$t('Field.insertMinimumValue')"
                                single-line
                                clearable
                                filled
                                :rules="sliderRulesMin"
                                flat
                                solo
                                required
                                class="text-field-100"
                                v-model="fieldData.minValue"
                                type="number"
                                validate-on-blur
                            >
                            </v-text-field>
                        </v-row>
                        <v-row class="mb-2">
                            <v-text-field
                                :label="$t('Field.insertMaximumValue')"
                                single-line
                                clearable
                                :rules="sliderRulesMax"
                                filled
                                required
                                flat
                                solo
                                class="text-field-100"
                                v-model="fieldData.maxValue"
                                type="number"
                                validate-on-blur
                            >
                            </v-text-field>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </div>

        <!-- SLIDER ENDS -->

        <!-- TEXTFIELD -->

        <v-row
            :class="{'pl-7 pr-4 mb-0 mt-7':$vuetify.breakpoint.mdAndUp,
                     'pr-2 pl-4 mt-5 mb-2 mr-1':!$vuetify.breakpoint.mdAndUp}"
            v-if="fieldData.type === 'text'"
        >
            <v-textarea
                @click.stop
                :label="$t('Field.textLabel')"
                hide-details
                clearable
                flat
                solo
                class="text-field-100"
                v-model="fieldData.data"
            >
            </v-textarea>
        </v-row>

        <!-- TEXTFIELD ENDS -->

        <!-- INFO -->

        <v-row
            :class="{'pl-7 pr-4 mb-0 mt-7':$vuetify.breakpoint.mdAndUp,
                     'pr-2 pl-4 mt-5 mb-2 mr-1':!$vuetify.breakpoint.mdAndUp}"
            v-if="fieldData.type === 'infotext'"
        >
            <v-textarea
                data-cy="infoInput"
                :label="$t('Field.infoTextLabel')"
                hide-details
                clearable
                flat
                solo
                class="text-field-100"
                v-model="fieldData.data"
            >
            </v-textarea>
        </v-row>

        <!-- INFO ENDS -->

        <!-- DATE -->

        <v-row class="px-7 mb-0" v-if="fieldData.type === 'date'">
            <v-checkbox
                @click.stop="addTime(fieldData)"
                :label="$t('Field.addHoursMinutes')"
            ></v-checkbox>
        </v-row>

        <!-- DATE ENDS -->

        <!-- IMAGE -->

        <div v-if="fieldData.type === 'image'">
            <div
                class="ml-0 mr-2 mt-4 ml-1"
                :class="{ 'ml-4 mr-8 mt-3': $vuetify.breakpoint.mdAndUp }"
            >
                <v-row class="mx-0 py-4">
                    <div
                        @click.stop
                        class="image-container-disabled pa-3 mr-4 mb-4"
                        v-for="(image, indexpic) in fieldData.data"
                        :key="indexpic"
                    >
                        <v-row class="mt-0 mb-3 mx-2">
                            <v-icon color="primary" class="mr-2"> mdi-file-image</v-icon>
                            <p class="text-primary mb-0 mr-10">
                                <strong>Kuva {{ indexpic + 1 }}</strong>
                            </p>

                            <v-icon
                                class="close-button-small"
                                @click="deleteImage(fieldData.data, indexpic)"
                                v-if="$vuetify.breakpoint.width > 480"
                            >mdi-close</v-icon
                            >
                            <div v-else align="end" class="image-control-row">
                                <v-icon
                                    class="close-button-small"
                                    @click="deleteImage(fieldData.data, indexpic)"
                                >mdi-close</v-icon
                                >
                            </div>
                        </v-row>
                        <v-img
                            :src="image"
                            :width="$vuetify.breakpoint.width > 480 ? '160' : ''"
                            :height="$vuetify.breakpoint.width > 480 ? '160' : ''"
                            :contain="$vuetify.breakpoint.width <= 480"
                            class="rounded-lg hover"
                            @click="viewImage(indexpic)"
                        >
                        </v-img>
                        <ViewImage :src="image" ref="imgDialog" />
                    </div>
                    <div
                        class="image-container-disabled pa-3 mb-4"
                        :class="{
                            'mr-4': $vuetify.breakpoint.width > 480,
                            'mr-0': $vuetify.breakpoint.width <= 480,
                        }"
                        v-for="(image) in skeletonLoaderHelper"
                        :key="image.tempId"
                    >
                        <v-row class="mt-0 mb-3 mx-2">
                            <v-icon color="primary" class="mr-2"> mdi-file-image</v-icon>
                            <p class="text-primary mb-0">
                                <strong
                                >{{ $t("Fillfield.loadingPicture") }}</strong
                                >
                            </p>
                        </v-row>
                        <v-skeleton-loader
                            width="160"
                            height="160"
                            type="image"
                            v-if="image.url === 'loading' && $vuetify.breakpoint.width > 480"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                            contain
                            type="image"
                            v-else-if="image.url === 'loading'"
                        ></v-skeleton-loader>
                    </div>
                </v-row>
            </div>
            <v-btn
                :class="{
                    'primary-button ml-4 mb-5': $vuetify.breakpoint.mdAndUp,
                    'primary-button ml-1 mb-5': !$vuetify.breakpoint.mdAndUp,
                }"
                @click.stop="takePicture(fieldData)"
            >
                {{ $t("Field.imageAddTake") }}
            </v-btn>
        </div>

        <!-- IMAGE ENDS -->

        <!-- TABLE -->

        <div v-if="fieldData.type === 'table'">
            <div
                class="ml-0 mr-3 mt-4 ml-1"
                :class="{ 'ml-4 mr-8 mt-4': $vuetify.breakpoint.mdAndUp }"
            >
                <v-row
                    class="mx-0 choice-container"
                    :class="{
                        'mx-0':
                            $vuetify.breakpoint.mdAndUp,
                        'mx-0':
                            !$vuetify.breakpoint.mdAndUp,
                    }"
                    v-for="(column, index) in fieldData.columns"
                    :key="column"
                >
                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? '11' : '10'" class="pl-0">
                        <v-text-field
                            @click.stop
                            :label="
                                'Lisää sarake' +
                                    (index === 0
                                        ? ' (rivi nimetään tämän sarakkeen mukaan mukaan)'
                                        : '')
                            "
                            single-line
                            hide-details
                            clearable
                            filled
                            flat
                            solo
                            :value="fieldData.columns[index].label"
                            @change="
                                setTableFieldColumnValue($event, fieldData.columns[index])
                            "
                            class="text-field-100"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        @click.stop
                        :cols="$vuetify.breakpoint.mdAndUp ? '1' : '2'"
                        :align="$vuetify.breakpoint.mdAndUp ? '' : 'end'"
                    >
                        <v-icon
                            color="primary"
                            @click.stop="deleteColumnValue(fieldData.columns, index)"
                        >mdi-delete
                        </v-icon>
                    </v-col>
                </v-row>
                <v-btn
                    @click.stop="addTableAttribute(fieldData)"
                    class="primary-button mt-7"
                >
                    Lisää sarake
                </v-btn>
            </div>
        </div>

        <!-- TABLE ENDS -->

        <!-- MULTIPLE CHOICE  -->

        <div v-if="fieldData.type === 'choice'">
            <div
                :class="{
                    'ml-4 mr-8 mt-7': $vuetify.breakpoint.mdAndUp,
                    'pr-3 pl-1 mt-0 mt-4': !$vuetify.breakpoint.mdAndUp,
                }"
            >
                <div class="text-primary h2-18 mb-10">
                    <h2 class="mb-3">{{ $t("Field.lotOfOptions") }}</h2>
                    <p class="text-black">{{ $t("Field.optionExplanation") }}</p>
                    <p class="text-black mb-5">{{ $t("Field.excelInfo") }}</p>
                    <v-btn class="primary-button" @click="handleFileImport">
                        <v-icon class="mr-2">
                            mdi-file-excel
                        </v-icon>
                        {{ $t("Field.importExcel") }}
                    </v-btn>
                    <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        @change="onFileChanged"
                    >
                </div>
                <div class="w-100 justify-space-between h2-18 text-primary mb-5 align-center">
                    <h2>{{ $t("Field.options") }}</h2>
                    <v-btn class="button-alert" @click="handleResetOptions">
                        <v-icon class="mr-2">mdi-delete</v-icon>
                        {{ $t("Field.deleteAll") }}
                    </v-btn>
                </div>
                <div
                    v-for="(choiceitem, index4) in fieldData.options"
                    :key="index4"
                >
                    <v-row
                        v-if="index4 < limit"
                        @click.stop
                        class="choice-container"
                        :class="{
                            'mx-0 px-4': $vuetify.breakpoint.smAndUp,
                            'mx-0': $vuetify.breakpoint.xsOnly,
                        }"
                    >
                        <v-col
                            cols="1"
                            :class="{
                                'pl-2': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <v-radio disabled></v-radio>
                        </v-col>
                        <v-col
                            cols="10"
                            :class="{ 'pl-4 pr-0': !$vuetify.breakpoint.mdAndUp }"
                            v-if="!$vuetify.breakpoint.xsOnly"
                        >
                            <v-text-field
                                v-model="fieldData.options[index4]"
                                single-line
                                hide-details
                                clearable
                                filled
                                flat
                                solo
                                class="text-field-100"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="9" class="pl-5 pr-0" v-else>
                            <v-text-field
                                v-model="fieldData.options[index4]"
                                single-line
                                hide-details
                                clearable
                                filled
                                flat
                                solo
                                class="text-field-100"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="1"
                            align="end"
                            class="pr-4"
                            v-if="!$vuetify.breakpoint.xsOnly"
                        >
                            <v-icon
                                data-cy="deleteChoice"
                                color="primary"
                                @click="deleteChoiceItem(fieldData, index4)"
                            >
                                mdi-delete
                            </v-icon>
                        </v-col>
                        <v-col
                            cols="2"
                            align="end"
                            v-else
                        >
                            <v-icon
                                data-cy="deleteChoice"
                                color="primary"
                                @click="deleteChoiceItem(fieldData, index4)"
                            >
                                mdi-delete
                            </v-icon>
                        </v-col>
                    </v-row>
                </div>
                <div class="w-100 justify-center mt-3 align-center" v-if="this.fieldData.options.length > 10">
                    <v-btn class="secondary-button" v-if="limit === 10" @click="handleExpand">
                        <v-icon class="mr-2">mdi-chevron-down</v-icon>
                        <p class="mb-0">{{ $t("Field.showAllOptions") }} ({{fieldData.options.length}})</p>
                    </v-btn>
                    <v-btn class="secondary-button" v-else @click="handleMinify">
                        <v-icon class="mr-2">mdi-chevron-up</v-icon>
                        <p class="mb-0">{{ $t("Field.showLess") }}</p>
                    </v-btn>
                </div>

                <v-text-field
                    data-cy="choiceName"
                    @keydown.enter.prevent="
                        addChoiceItem(fieldData, fieldData.newChoiceHelper)
                    "
                    :placeholder="$t('Field.writeChoice')"
                    class="px-2 mt-4"
                    v-model="fieldData.newChoiceHelper"
                    ref="choiceref"
                    @click.stop
                >
                </v-text-field>
                <v-btn
                    data-cy="addChoice"
                    class="secondary-button mb-5"
                    :class="{ 'ml-2': !$vuetify.breakpoint.mdAndUp }"
                    @click.stop="addChoiceItem(fieldData, fieldData.newChoiceHelper)"
                >
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    {{ $t("Field.addOption") }}
                </v-btn>
            </div>
        </div>

        <!-- MULTIPLE CHOICE ENDS  -->

        <!-- FILE ATTACHMENT -->

        <div v-if="fieldData.type === 'staticfile'">
            <div
                class="ml-0 mr-3 mt-4 ml-1"
                :class="{ 'ml-4 mr-8 mt-4': $vuetify.breakpoint.mdAndUp }"
                v-for="(data, i) in fieldData.data"
                :key="i"
            >
                <v-row
                    class="mx-0 my-2 px-1 file-container-disabled py-2"
                    :class="{
                        'mx-0 my-2 px-4 file-container-disabled py-2': $vuetify.breakpoint.mdAndUp}"
                >
                    <v-col :cols="$vuetify.breakpoint.width > 1200 ? '11' : '10'" class="pt-3">
                        <v-icon color="primary" class="mr-5">mdi-file-document</v-icon>
                        <a :href="data.url" target="_blank">
                            {{ $vuetify.breakpoint.width > 1200 ? getFileName(data.url) : getFileNameMobile(data.url) }}
                        </a>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.width > 1200 ? '1' : '2'"
                        :align="$vuetify.breakpoint.width > 1200 ? '' : 'end'"
                    >
                        <v-icon color="primary" @click="deleteDocument(fieldData.data, i)">
                            mdi-delete
                        </v-icon>
                    </v-col>
                </v-row>
            </div>
            <v-file-input
                :label="$t('Field.addAttachmentFile')"
                @change="setFileHelper($event, fieldData)"
                :value="addFileHelper"
                :rules="fileInputRules"
                class="mr-3 mt-4"
                :class="{ 'ml-5': $vuetify.breakpoint.mdAndUp }"
                accept=".pdf,.doc,.docx,.txt"
            ></v-file-input>
        </div>

        <!-- FILE ATTACHMENT ENDS -->

        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText=snackbarText />

        <!-- DIALOGS -->
        <Okcanceldialog ref="okCancelDialog"> </Okcanceldialog>
        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('Field.loadingMessage')"
        />

    <!-- DIALOGS END -->
    </v-card>
</template>

<script>
var XLSX = require('xlsx');
import _ from 'lodash';
import { sendImageObj, sendFileObj } from '../../fbImageUploader';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import { fbStorage } from '../../fbInitialize';
import Okcanceldialog from '../OkCancelDialog.vue';
import { fieldTemplates } from '../../datamodels/datamodels.js';
import ViewImage from '../FillReport/ViewImage.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';

export default {
    props: [
        'sectionDataFields',
        'fieldData',
        'OkCancelDialog',
        'fieldIndex',
    ],
    components: {
        BaseLoadingDialog,
        Okcanceldialog,
        ViewImage,
        GeneralSnackbar,
    },
    data() {
        return {
            requireTime: [(v) => (v && v.length > 0) || 'Valitse aika'],
            imageAddHelper: {},
            closeOnContentClick: false,
            sendingData: false,
            snackbarText: '',
            addFileHelper: null,
            required: false,
            loadingFile: false,
            limit: 10,
            file: [],
            fileInputRules: [
                (file) => !file || !(file.size > 100*1024*1024) || this.$t('Fillfield.fileRule'),
            ],
            sliderRulesMin: [
                (v) => (v || '').length !== 0 || this.$t('Field.sliderRuleMinimumMissing'),
                (v) => (v <= this.fieldData.maxValue) || this.$t('Field.sliderRuleMinimumLarger'),
            ],
            sliderRulesMax: [
                (v) => ((v || '').length !== 0 ||
                    this.$t('Field.sliderRuleMaximumMissing')),
                (v) => (v >= this.fieldData.minValue) || this.$t('Field.sliderRuleMaximumSmaller'),
            ],
            skeletonLoaderHelper: [],
        };
    },
    methods: {
        getFieldTemplates() {
            return fieldTemplates;
        },
        viewImage(index) {
            this.$refs.imgDialog[index].openDialog();
        },
        addTime(obj) {
            obj.hasClock = true;
        },
        removeTime(obj) {
            obj.hasClock = false;
        },
        addTableAttribute(item) {
            item.columns.push({
                type: 'text',
                label: '',
                value: '',
                rowNamingField: false,
            });
        },
        setTableFieldColumnValue(newLabel, data) {
            data.label = newLabel;
        },
        deleteColumnValue(data, index) {
            data = data.splice(index, 1);
        },
        downscaleImage(dataUrl, newWidth) {
            let image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;
            let imageArguments = 0.9;
            let maxCompressedImageSizeKbs = 135;

            // Create a temporary image so that we can compute the height of the downscaled image.
            image = new Image();
            image.src = dataUrl;
            oldWidth = image.width;
            oldHeight = image.height;
            newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

            // Create a temporary canvas to draw the downscaled image on.
            canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the downscaled image on the canvas and return the new data URL.
            ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, newWidth, newHeight);

            // Data url size check, compress until image is small enough
            let head = 'data:image/png;base64,';
            newDataUrl = dataUrl;
            let imgFileSize =
                Math.round(((dataUrl.length - head.length) * 3) / 4) / 1000;
            while (imgFileSize > maxCompressedImageSizeKbs) {
                newDataUrl = canvas.toDataURL('image/jpeg', imageArguments);
                imgFileSize =
                    Math.round(((newDataUrl.length - head.length) * 3) / 4) / 1000;
                imageArguments -= 0.1;
            }
            return newDataUrl;
        },

        loadAndDownscale(imgObj) {
            let base64content = imgObj.split(',');
            //get datatype e.g image/jpeg
            let mimetype = base64content[0].match(
                /[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/
            )[0];
            //get image width for compression
            let img = new Image();
            async function getWidth() {
                return new Promise((resolve) => {
                    img.onload = function () {
                        let originalwidth = img.width;
                        resolve(originalwidth);
                    };
                });
            }
            img.src = imgObj;
            let ref = this;
            return getWidth().then(async function (width) {
                if (width < 1080) {
                    return ref.downscaleImage(imgObj, width, mimetype);
                } else {
                    return ref.downscaleImage(imgObj, 1080, mimetype);
                }
            });
        },
        changeSelect(event) {
            var copyofevent = _.cloneDeep(event);
            this.sectionDataFields[this.fieldIndex] = {
                ...copyofevent,
            };
            this.fieldData = this.sectionDataFields[this.fieldIndex];
            this.fieldData.id = Math.floor(Math.random() * 1000000 + 1);
            this.fieldData.required = false;
        },
        async deletefield() {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('Field.deleteFieldHeader'),
                this.$t('Field.deleteFieldMessage')
            );
            if (deleteApproved) {
                this.$emit('fieldDeleted');
            }
        },
        copyfield(obj) {
            var copyOfObject = _.cloneDeep(obj);
            copyOfObject.id = Math.floor(Math.random() * 1000000 + 1);
            var index = this.sectionDataFields.findIndex(x => x.id === obj.id);
            this.sectionDataFields.splice(index, 0, copyOfObject);
            this.$refs.GeneralSnackbar.snackbar = true;
            this.snackbarText = this.$t('Field.fieldCopied');
        },
        getFileName(url) {
            let storageRef = fbStorage.refFromURL(url);
            return storageRef.name;
        },
        getFileNameMobile(url) {
            let storageRef = fbStorage.refFromURL(url);
            if (storageRef.name.length < 22) {
                return storageRef.name;
            } else {
                return storageRef.name
                    .slice(0, 12)
                    .concat('...')
                    .concat(storageRef.name.slice(-7));
            }
        },
        formatTime(epochTime) {
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(epochTime);
            return d.toLocaleString();
        },
        async deleteDocument(obj, index) {
            let filename = this.getFileName(obj[index].url);
            let deleteApproved = await this.$refs.okCancelDialog.open(
                this.$t('Fillfield.deleteFileHeader') + filename,
                this.$t('Fillfield.deleteFileMessage') + filename,
                this.$t('Fillfield.deleteFile'),
                this.$t('Fillfield.cancel')
            );
            if (deleteApproved) {
                // Delete field
                try {
                    this.sendingData = true;
                    let imgRef = fbStorage.refFromURL(obj[index].url);
                    imgRef
                        .delete()
                        .then(() => {
                            this.sendingData = false;
                            console.log('Deleted object from firebase');
                        })
                        .catch(function (e) {
                            console.log('Image Delete from firebase failed');
                            console.log(e);
                        });
                } catch (e) {
                    console.log(e);
                }
                obj.splice(index, 1);
                // Update report on database
                this.$store.dispatch('updateReport');
            }
        },
        setFileHelper(file, field) {
            if (file.size < 1e7) {
                this.addFileHelper = file;
                this.addFile(field);
                this.$forceUpdate();
            } else {
                this.$refs.GeneralSnackbar.snackbar = true;
                this.snackbarText = this.$t('Fillfield.tooBigFile') + '10MB';
            }
        },
        async addFile(field) {
            // Upload file to firebase
            if (this.addFileHelper !== null) {
                this.sendingData = true;
                let url = await sendFileObj(this.addFileHelper);
                // returns empty object if fails

                if (url) {
                    let userName = '';
                    if (
                        typeof this.$store.state.userProfile.firstname !== 'undefined' &&
                        typeof this.$store.state.userProfile.surname !== 'undefined'
                    ) {
                        userName =
                            this.$store.state.userProfile.firstname +
                            ' ' +
                            this.$store.state.userProfile.surname;
                    }

                    let epochtime = Math.round(new Date().getTime() / 1000);

                    // Set field data
                    field.data.push({
                        url: url,
                        uploader: userName,
                        timestamp: epochtime,
                    });
                    // Update report on database
                    this.$store.dispatch('updateReport');
                    // Delete file from input
                    this.addFileHelper = null;
                }
                this.sendingData = false;
            }
        },
        handleFileImport() {
            this.isSelecting = true;

            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener('focus', () => {
                this.isSelecting = false;
            }, { once: true });

            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            this.file = e.target.files[0];
            this.importFromExcel();
            this.$refs.uploader.value = null;
        },
        handleResetOptions() {
            this.fieldData.options = [];
        },
        handleExpand() {
            this.limit = this.fieldData.options.length;
        },
        handleMinify() {
            this.limit = 10;
        },
        async importFromExcel() {
            if(this.file.length === 0) {
                return;
            }

            this.loadingFile = true;

            const choices = await new Promise((resolve) => {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: 'binary'
                    });
                    workbook.SheetNames.forEach(function (sheetName) {
                        var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                        resolve(XL_row_object);
                    });

                };
                reader.onerror = function (ex) {
                    console.error(ex);
                };
                reader.readAsBinaryString(this.file);
            });
            var choicesToAdd = [];
            try {
                choices.forEach((choiceElement, index) => {
                    if (!choiceElement.vaihtoehdot || choiceElement.vaihtoehdot === '') {
                        throw new Error(`${this.$t('Field.incorrectData')} ${index}`);
                    }
                    choicesToAdd = choicesToAdd.concat(choiceElement.vaihtoehdot);
                });
                this.fieldData.options = this.fieldData.options.concat(choicesToAdd);
            } catch(e) {
                this.snackbarText = e.message;
                this.$refs.GeneralSnackbar.snackbar = true;
            }
            this.loadingFile = false;
            this.file = [];
        },
        addChoiceItem(objref, value) {
            if (value != '') {
                objref.options.push(value);
                objref.newChoiceHelper = '';
                this.$forceUpdate();
            }
        },
        deleteChoiceItem(choiceitem, index) {
            choiceitem.options.splice(index, 1);
            this.$forceUpdate();
        },
        takePicture(fieldData) {
            this.imageAddHelper = fieldData;
            this.$refs.uploader.click();
        },
        async deleteImage(obj, index) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('Field.deleteImageHeader'),
                this.$t('Field.deleteImageMessage')
            );
            if (deleteApproved) {
                // THIS IS TURNED OFF, because if user deletes image on a tempalte that is copied from other template
                // It creates an empty reference.
                // @TODO THIS SHOULD BE REDONE WHEN TEMPLATE COPYING IS DONE CORRECTLY
                //let url = obj[index]
                /*let imgRef = fbStorage.refFromURL(url);
        imgRef
          .delete()
          .then(() => {
            console.log("Deleted object from firebase");
          })
          .catch(function (e) {
            console.log("Image Delete from firebase failed");
            console.log(e);
          });*/
                // Delete field
                obj.splice(index, 1);
            }
        },
        async newCamPhotoTaken(event) {
            let tempId = Math.floor((Math.random() * 1000000) + 1);
            this.skeletonLoaderHelper.push({
                url: 'loading',
                text: '',
                temporaryId: tempId
            });
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let imgBase64 = reader.result;
                this.loadAndDownscale(imgBase64).then(async (newImgUrl) => {
                    let img = newImgUrl;
                    img = await sendImageObj(newImgUrl);
                    this.imageAddHelper.data.push(img);
                    this.$forceUpdate();
                    this.skeletonLoaderHelper.shift();
                });
            };
            this.$refs.uploader.value = null;
        },
    },
    computed: {
        isFillable() {
            return (
                this.fieldData.type === 'slider' ||
                this.fieldData.type === 'addimages' ||
                this.fieldData.type === 'textfield' ||
                this.fieldData.type === 'comment' ||
                this.fieldData.type === 'choice' ||
                this.fieldData.type === 'signature' ||
                this.fieldData.type === 'table' ||
                this.fieldData.type === 'textinput' ||
                this.fieldData.type === 'file' ||
                this.fieldData.type === 'date'
            );
        },
        isRequired: {
            get() {
                return this.required;
            },
            set(newValue) {
                this.required = newValue;
                this.fieldData.required = newValue;
            }
        }
    },
    created() {
        this.required = this.fieldData.required || false;
    }
};
</script>

<style scoped>
.my-handle {
  cursor: move;
  cursor: -webkit-move;
}
.filler {
  cursor: default;
  opacity: 0;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background-color: #e1edff !important;
}
</style>
