import { storageRef } from './fbInitialize';
import 'firebase/storage';


async function sendImageObj(imgObj) {
    var folderPath = 'images/';
    let base64content = imgObj.split(',');
    //get datatype e.g image/jpeg
    let mimetype = base64content[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
    let imageName = Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15)
        + '.' + mimetype.split('/')[1];
    //get image width for compression
    var img = new Image();
    img.src = imgObj;
    await new Promise((resolve) => {
        img.onload = function() {
            resolve();
        };
    });

    var photourl = imgObj;
    const fetchRes = await fetch(photourl);
    const blob = await fetchRes.blob();
    let imageRef = storageRef.child(folderPath).child(imageName);
    await imageRef.put(blob);
    return await imageRef.getDownloadURL();
}

async function sendFileObj(file) {
    let folderPath = 'files/';
    let idFolder = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '/';
    // Upload file
    return await storageRef.child(folderPath + idFolder + file.name).put(file).then(async snapshot => {
        return await snapshot.ref.getDownloadURL().then((url) => {
            return url;
        });
    }).catch((e) => {
        console.log(e);
        return {};
    });

}

export { sendImageObj,sendFileObj };
