<template>
    <!--Desktop Layout-->
    <div>
        <MobileNavigation
            v-if="!$vuetify.breakpoint.mdAndUp"
            :pageHeader="$t('Reports.header')"
        />
        <ReportTable :use="this.use" :loadingData="this.loading" :getFolder="this.getFolder ? this.getFolder : []"
                     @createLinkFolder="createLinkFolder"
                     @openSettings="openSettings"
                     @fillAutoReport="fillAutoReport"
                     @copyLink="copyLink"
                     @createFolder="openCreateFolder"
                     @createReport="openCreateDialog"
                     :openCreateDialog="openCreateDialog" />

        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="this.snackbarText" />

        <CreateReport ref="CreateReport" :shared="this.shared" />

        <CreateLinkReportFolder ref="CreateLinkReportFolder" />

        <TermsAndConditionsDialog ref="TermsAndConditionsDialog" />

        <CreateFolder ref="CreateFolder" />
    </div>
</template>

<script>
import CreateFolder from '../components/CreateFolder.vue';
import ReportTable from '../components/Dashboard/ReportTable';
import CreateReport from '../components/CreateReport.vue';
import TermsAndConditionsDialog from '../components/TermsAndConditionsDialog.vue';
import CreateLinkReportFolder from '../components/CreateLinkReportFolder.vue';
import MobileNavigation from '../components/GeneralizedComponents/MobileNavigation.vue';
import GeneralSnackbar from '../components/GeneralizedComponents/GeneralSnackbar.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        ReportTable,
        CreateReport,
        TermsAndConditionsDialog,
        CreateLinkReportFolder,
        MobileNavigation,
        CreateFolder,
        GeneralSnackbar,
    },
    data() {
        return {
            use: 'regular',
            shared: false,
            snackbarText: '',
            loading: false
        };
    },
    methods: {
        openCreateDialog() {
            this.$refs.CreateReport.openDialog();
        },
        openCreateFolder(){
            this.$refs.CreateFolder.openDialog();
        },
        fillAutoReport() {
            window.location.href = this.getLink();
        },
        openSettings() {
            this.$refs.CreateLinkReportFolder.openDialog('modify');
        },
        createLinkFolder() {
            this.$refs.CreateLinkReportFolder.openDialog('create');
        },
        getLink() {
            if (this.getCurrentFolderData.isClosed) {
                return (
                    location.origin +
                    '/fillLinkReport/' +
                    this.getCurrentFolderData.id +
                    '/redirectLogin' || ''
                );
            } else {
                return (
                    location.origin + '/fillLinkReport/' + this.getCurrentFolderData.id ||
                    ''
                );
            }
        },
        copyLink() {
            navigator.clipboard.writeText(this.getLink());
            this.snackbarText = this.$t('Dashboard.linkCopied');
            this.$refs.GeneralSnackbar.snackbar = true;
        },
    },
    computed: {
        ...mapGetters([ 'getCurrentFolderData', 'getFolder' ]),

        styleObject() {
            let objectWidth = 0.95 * window.innerWidth + 'px';
            let objectHeight = 0.47 * window.innerHeight + 'px';
            let rightPosition = '-' + window.innerWidth * 0.166 + 'px';
            let topPosition = '-' + window.innerHeight * 0.123 + 'px';
            return {
                position: 'absolute',
                width: objectWidth,
                height: objectHeight,
                right: rightPosition,
                top: topPosition,
                borderRadius: '40px',
                transform: 'rotate(-147.57deg)',
                overflow: 'hidden',
            };
        },
    },

    mounted() {
        if (!this.$store.state.userProfile.userAgreedTermsAndConditions) {
            this.$refs.TermsAndConditionsDialog.openDialog();
        }

        // Scroll to top
        window.scrollTo(0, 0);
    },

    async created() {
        if(this.$store.state.fetch.reports && this.$store.state.fetch.folders)
            return;
        this.loading = true;
        try {
            this.$store.dispatch('fetchDispatchCommands', ['setReports']);
            await this.$store.dispatch('fetchDispatchCommands', ['setFolders']);
            this.$store.commit('reportsFetched');
            this.$store.commit('foldersFetched');
        } finally {
            this.loading = false;
        }
    }

};
</script>
