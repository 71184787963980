import {Component} from '../../../../component';

class InfoStyle extends Component {
    code() {
        return {
            margin: [40, 40, 40, 40],
        };
    }
}

export default InfoStyle;
