<template>
    <v-dialog
        v-model="folderShareDialog"
        width="600"
        class="pa-2"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            class="border-0"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeDialog()" data-cy="closeFolderShareDialog">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("FolderShare.participants") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-form ref="form">
                <v-container fluid :class="{ 'pb-8 pt-header px-5': !$vuetify.breakpoint.mdAndUp }">
                    <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="10">
                            <h1>{{ $t("FolderShare.participants") }}</h1>
                        </v-col>
                        <v-col cols="2">
                            <v-icon
                                class="close-button"
                                @click="closeDialog()"
                                data-cy="closeFolderShareDialog"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-list
                        v-if="currentFolder.participantProfiles.length !== 0"
                        class="pa-0 my-5"
                        :class="{ 'mt-7 mb-5': !$vuetify.breakpoint.mdAndUp }"
                    >
                        <v-list-item
                            v-for="item in currentFolder.participantProfiles"
                            :key="item.email"
                            class="px-0 mx-0"
                        >
                            <v-list-item-icon class="ma-0" v-if="item.UID !== null">
                                <div class="image-avatar-table" v-if="item.UID !== null">
                                    <v-avatar
                                        :color="item.avatarColor.bg"
                                        size="40"
                                        class="mr-2 mb-2"
                                    >
                                        <span
                                            v-if="
                                                item.surname !== '' &&
                                                    item.surname !== undefined
                                            "
                                            class="font-weight-regular"
                                            :style="'color: ' + item.avatarColor.text"
                                        >{{
                                            item.firstname[0].toUpperCase() +
                                                item.surname[0].toUpperCase()
                                        }}</span
                                        >
                                        <span
                                            v-else
                                            class="font-weight-regular"
                                            :style="'color: ' + item.avatarColor.text"
                                        >{{
                                            item.firstname[0].toUpperCase() +
                                                item.firstname[1].toUpperCase()
                                        }}</span
                                        >
                                    </v-avatar>
                                </div>
                                <div class="image-avatar-table-visitor" v-else>
                                    <p class="avatar-text-table-visitor">
                                        {{ getInitials(getNameFromEmail(item.email)) }}
                                    </p>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-row class="ma-0">
                                        <p class="mb-0 text-primary">
                                            <b> {{ item.firstname + " " + item.surname }} </b>
                                        </p>
                                    </v-row>
                                    <v-row class="mb-0 mr-0 ml-0 mt-1 text-gray">
                                        {{ item.email }}
                                    </v-row>
                                </v-list-item-title>
                                <v-select
                                    v-if="!$vuetify.breakpoint.mdAndUp"
                                    class="narrow pt-0 mb-2"
                                    hide-details
                                    :label="getPermission(item.id)"
                                    :items="permissions"
                                    disabled
                                    @change="changepermission()"
                                ></v-select>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row class="mx-0">
                                    <v-select
                                        v-if="$vuetify.breakpoint.mdAndUp"
                                        class="narrow pt-0 mb-2"
                                        hide-details
                                        :label="getPermission(item.id)"
                                        :items="permissions"
                                        disabled
                                        @change="changepermission()"
                                    ></v-select>
                                    <v-btn
                                        color="primary"
                                        class="ml-7"
                                        :class="{ 'mt-n8': !$vuetify.breakpoint.mdAndUp }"
                                        icon
                                        @click="deleteParticipant(item)"
                                        :disabled="item.id === $store.state.userProfile.id"
                                    >
                                        <v-icon color="primary">mdi-delete</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-row>
                        <v-col>
                            <v-card class="rounded-lg">
                                <v-alert
                                    type="info"
                                    :icon="openAccessFolder ? 'mdi-earth' : 'mdi-account-group'"
                                    :color="openAccessFolder ? 'success' : 'light-blue'"
                                    text
                                    outlined
                                    dense
                                >
                                    <v-row align="center">
                                        <v-col class="grow">
                                            {{ openAccessFolder ? $t("FolderShare.openAccessfolder")
                                                : $t("FolderShare.closedFolderInfo1") }}
                                        </v-col>

                                        <!--

                    TODO: Create function for changing folder type.
                    Not included right now, but will be created in the future, so do not delete this section yet.
                          This will allow sharing forder to everyone,
                          even for those who do not belong to a Formup organization.

                    <v-col class="shrink"></v-col>
                      <v-menu offset-y class="text-center">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mr-3"
                            :class="{ 'btn-light-success': openAccessFolder, 'btn-light-blue': !openAccessFolder }"
                            v-on="on"
                            v-bind="attrs"
                          >
                            {{ $t("FolderShare.modify") }}
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="changeFolderType('open')">
                            <v-list-item-icon color="primary">
                              <v-icon>
                                {{ openAccessFolder ? 'mdi-check' : ''}}
                              </v-icon>
                              </v-list-item-icon>
                            {{ $t("FolderShare.openAccessfolder") }}
                          </v-list-item>
                          <v-list-item @click="changeFolderType('close')">
                            <v-list-item-icon color="primary">
                              <v-icon> {{ !openAccessFolder ? 'mdi-check' : ''}} </v-icon>
                            </v-list-item-icon>
                            {{ $t("FolderShare.onlyOrganization") }}
                          </v-list-item>
                        </v-list>
                      </v-menu>

                     -->

                                    </v-row>
                                </v-alert>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 h2-18">
                        <h2>{{ $t("FolderShare.addParticipant") }}</h2>
                    </v-row>
                    <v-row class="mx-0 mb-3">
                        <v-autocomplete
                            data-cy="selectParticipant"
                            v-model="participant"
                            :items="getOrgUsers"
                            :item-text="(item) => fullName(item)"
                            :label="$t('FolderShare.email')"
                            return-object
                            @change="changeParticipantToAdd"
                            :rules="selectRule"
                        ></v-autocomplete>
                    </v-row>
                    <v-row class="mx-0 h2-18">
                        <h2>{{ $t("FolderShare.selectRole") }}</h2>
                    </v-row>
                    <v-row
                        data-cy="selectRole"
                        class="mx-0"
                        :class="{ 'mb-150': !$vuetify.breakpoint.mdAndUp,
                                  'mb-3': $vuetify.breakpoint.mdAndUp }"
                    >
                        <v-select
                            item-text="permission"
                            v-model="permission"
                            :items="permissions"
                        >
                        </v-select>
                    </v-row>
                    <v-row
                        class="mb-0 mx-0"
                        :class="{'mobile-dialog-controls pb-5 px-2': !$vuetify.breakpoint.mdAndUp }"
                    >
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                            class="px-0"
                            :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <v-btn
                                class="secondary-button mr-3"
                                :class="{ 'w-100 py-4 mb-2': !$vuetify.breakpoint.mdAndUp,
                                          'mr-3': $vuetify.breakpoint.mdAndUp }"
                                @click="closeDialog()">
                                {{ $t("FolderShare.cancel") }}
                            </v-btn>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'"
                            align="end"
                            class="px-0"
                            :class="{'pa-0': !$vuetify.breakpoint.mdAndUp }"
                        >
                            <v-btn
                                data-cy="addParticipant"
                                class="primary-button"
                                :class="{ 'w-100 py-4 mb-4': !$vuetify.breakpoint.mdAndUp}"
                                @click="addParticipant"
                                :disabled="$store.state.userProfile.role !== 'admin'"
                            >
                                {{ $t("FolderShare.addParticipant") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <BaseLoadingDialog
            :sendingData="sending"
            :msg="$t('FolderShare.shareLoadingMessage')"
        />
        <BaseLoadingDialog
            :sendingData="deleting"
            :msg="$t('FolderShare.deleteLoadingMessage')"
        />
        <OkCancelDialog ref="OkCancelDialog" />
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import { firebaseFunctions } from '../../fbInitialize';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';
import OkCancelDialog from '../OkCancelDialog.vue';

export default {
    components: {
        BaseLoadingDialog,
        OkCancelDialog,
    },
    data() {
        return {
            changeFolderTypeDialog: false,
            folderShareDialog: false,
            selectRule: [
                (v) =>
                    (!!v && Object.keys(v).length !== 0) ||
                    this.$t('FolderShare.selectRule'),
            ],
            permissions: [
                {
                    permission: this.$t('FolderShare.modifier'),
                    value: 'modifier',
                },
                {
                    permission: this.$t('FolderShare.viewer'),
                    value: 'viewer',
                },
                {
                    permission: this.$t('FolderShare.admin'),
                    value: 'admin',
                },
            ],
            participant: {},
            permission: 'modifier',
            sending: false,
            // TODO: Correct implementation
            openAccessFolder: false,
            deleting: false,
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('FolderShare.emailRule'),
            ],
            openFolderParticipantEmail: '',
        };
    },
    methods: {

        closeDialog() {
            this.folderShareDialog = false;
        },

        clean(str) {
            let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            let index = 0;
            for (let i = 0; i < str.length; i++) {
                if (numbers.includes(str[i])) {
                    break;
                }
                index += 1;
            }
            return str.slice(0, index);
        },

        getNameFromEmail(email) {
            if (email) {
                let prefix = email.split('@')[0];
                let firstName, lastName;
                if (prefix.includes('.')) {
                    firstName = this.clean(
                        prefix.split('.')[0].charAt(0).toUpperCase() +
                            prefix.split('.')[0].slice(1)
                    );
                    lastName = this.clean(
                        prefix.split('.')[1].charAt(0).toUpperCase() +
                            prefix.split('.')[1].slice(1)
                    );
                    if (prefix.split('.').length > 2) {
                        lastName = this.clean(
                            prefix
                                .split('.')[prefix.split('.').length - 1].charAt(0)
                                .toUpperCase() +
                                prefix.split('.')[prefix.split('.').length - 1].slice(1)
                        );
                    }
                    return `${firstName} ${lastName}`;
                }
                return this.clean(prefix.charAt(0).toUpperCase() + prefix.slice(1));
            }
        },

        getInitials(name) {
            if (name) {
                let names = name.split(' ');
                let initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            }
        },
        fullName(item) {
            let returnable = '';
            if (item.firstname && item.surname) {
                returnable = item.firstname + ' ' + item.surname;
            }
            if (item.name) {
                returnable = item.name;
            }
            if (item.email) {
                returnable = returnable + ' (' + item.email + ')';
            }
            if (item.phone) {
                returnable = returnable + ' (' + item.phone + ')';
            }
            return returnable;
        },
        changeParticipantToAdd(newParticipant) {
            this.participant.email = newParticipant.email;
            this.participant.name = newParticipant.name;
        },
        changeFolderType(params) {
            let folder = this.currentFolder;
            folder.isClosed = !folder.isClosed;
            this.changeFolderTypeDialog = false;
            this.openAccessFolder = params !== 'close';
            this.sending = true;
            this.$store.dispatch('updateFolderWithData', folder).then(() => {
                this.sending = false;
            });
        },
        openDialog() {
            this.folderShareDialog = true;
        },
        changeFolderTypePopUp() {
            this.changeFolderTypeDialog = true;
        },
        baseLink() {
            return location.origin;
        },
        addParticipant() {
            // Validate the user
            if (this.$refs.form.validate()) {
                // If the folder is not shared previously, listener should be added!
                if (this.currentFolder.participants.length == 0) {
                    this.$store.dispatch('setCurrentFolderListener');
                }
                let firstname = this.participant.firstname || '';
                let surname = this.participant.surname || '';
                let data = {
                    UID: this.participant.id || undefined,
                    permission: this.permission,
                    name: firstname + ' ' + surname,
                    email: this.participant.email,
                };

                let folderId = this.currentFolder.id;
                this.sending = true;
                firebaseFunctions
                    .httpsCallable('shareFolder')({
                        participantData: data,
                        rootFolderId: folderId,
                        baseLink: this.baseLink(),
                    })
                    .then(() => {
                        this.participant = {};
                        this.sending = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                console.log('Participant not found');
            }
        },
        changepermission() {
            // TODOOO
        },
        getPermission(id) {
            let value = this.currentFolder.participants.find(x => x.UID === id).permission;
            return this.permissions.find(x => x.value === value).permission;
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        async deleteParticipant(item) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('FolderShare.stopSharingTitle') + item.firstname + ' ' + item.surname,
                this.$t('FolderShare.stopSharing')
            );
            if (deleteApproved) {
                let folderId = this.currentFolder.id;
                this.deleting = true;
                try {
                    await firebaseFunctions.httpsCallable('unshareFolder')({
                        folderId: folderId,
                        user: item,
                    });
                } catch (error) {
                    console.log(error);
                } finally {
                    this.deleting = false;
                }
            }
        },
    },

    computed: {
        ...mapGetters(['getCurrentFolderData', 'getCurrentSharedFolderData']),
        currentFolder() {
            return this.$route.name === 'dashboard'
                ? this.getCurrentFolderData
                : this.getCurrentSharedFolderData;
        },

        getOrgUsers() {
            // Gets only users that are not included in the sharing and owner
            if (this.currentFolder.participants) {
                let currentFolderParticipantEmails =
                    this.currentFolder.participants.map((obj) => obj.email);
                let notChosenUsers = this.$store.state.organizationUsers.filter(
                    (e) => !currentFolderParticipantEmails.includes(e.email)
                );
                // Delete user itself
                let notItself = notChosenUsers.filter(
                    (x) => x.id !== this.$store.state.userProfile.id
                );
                //Delete the owner
                return notItself.filter((x) => x.id !== this.currentFolder.owner);
            } else {
                // Delete user itself
                return this.$store.state.organizationUsers.filter(
                    (x) => x.id !== this.$store.state.userProfile.id
                );
            }

            //return this.getCurrentFolderData
        },
    },
};
</script>

<style>
</style>
