<template>
    <v-container class="ma-0 pa-0">
        <v-btn
            data-cy="sendReport"
            block
            @click="validateFormAndOpenSendDialog(report)"
            class="rounded-lg mt-6 mb-2 mx-0"
            height="50"
            color="primary"
            dark
        >
            {{ $t("PDFGenerator.shareReport") }}
        </v-btn>
        <v-dialog
            v-model="sendDialog"
            :fullscreen="!$vuetify.breakpoint.mdAndUp"
            hide-on-leave
            class="pa-2"
            width="400"
        >
            <v-card class="rounded-lg pa-2" width="400">
                <v-container fluid v-if="report.recievers">
                    <v-form>
                        <v-col>
                            <v-row v-if="$vuetify.breakpoint.mdAndUp">
                                <h1 class="h4">{{ $t("PDFGenerator.addYourEmail") }}</h1>
                            </v-row>
                            <v-row v-if="!$vuetify.breakpoint.mdAndUp">
                                <v-btn color="white" x-large icon @click="closeSendPdfDialog">
                                    <v-icon color="black">mdi-arrow-left</v-icon>
                                </v-btn>
                                <h1 class="h4">{{ $t("PDFGenerator.addYourEmail") }}</h1>
                            </v-row>
                            <v-row>
                                <p class="mt-2">
                                    {{ $t("PDFGenerator.ifYouWantCopy") }}
                                </p>
                                <b class="pb-2">
                                    {{ $t("PDFGenerator.leaveBlankifNoCopy") }}
                                </b>
                            </v-row>
                            <v-row class="pb-2">
                                <v-col class="pa-0">
                                    <v-form ref="emailInputForm" :lazy-validation="true">
                                        <v-text-field
                                            class="ma-0 py-2 rounded-lg"
                                            :label="$t('PDFGenerator.writeEmail')"
                                            v-model="emailToAdd"
                                            :rules="emailRules"
                                            validate-on-blur
                                        >
                                        </v-text-field>
                                    </v-form>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                    @click="validateEmailAndSend(report)"
                                    block
                                    height="60"
                                    color="primary"
                                    class="rounded-lg"
                                    dark
                                    v-if="emailToAdd != ''"
                                >
                                    {{ $t("PDFGenerator.sendAndRecieveCopy") }}
                                </v-btn>
                                <v-btn
                                    data-cy="sendCopyToEmail"
                                    @click="sendPDF(report)"
                                    block
                                    height="60"
                                    color="primary"
                                    class="rounded-lg"
                                    dark
                                    v-else
                                >
                                    {{ $t("PDFGenerator.send") }}
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-form>
                </v-container>
                <v-container fluid v-else>
                    <v-col>
                        <v-row v-if="$vuetify.breakpoint.mdAndUp">
                            <h1 class="h4">  {{ $t("PDFGenerator.chooseRecepient") }}</h1>
                        </v-row>
                        <v-row v-if="!$vuetify.breakpoint.mdAndUp">
                            <v-btn color="white" x-large icon @click="closeSendPdfDialog">
                                <v-icon color="black">mdi-arrow-left</v-icon>
                            </v-btn>
                            <h1 class="h4">  {{ $t("PDFGenerator.chooseRecepient") }}</h1>
                        </v-row>
                        <v-row>
                            <v-select
                                :items="report.optionalRecievers"
                                :label="$t('PDFGenerator.receiver')"
                                class="my-2"
                                ref="optionalRecieverChoice"
                            >
                            </v-select>
                        </v-row>
                        <v-row>
                            <v-btn
                                @click="sendEmailWithOptionalRecievers(this.report)"
                                block
                                height="60"
                                color="primary"
                                class="rounded-lg mt-2"
                                dark
                            >
                                {{ $t("PDFGenerator.send") }}
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
        <SendPDF
            ref="sendPDF"
            @reportSent="emitDataSent()"
            @reportSendFailed="emitSomethingWentWrong()"
        />
        <ValidateForm ref="validateForm" />
    </v-container>
</template>

<script>
import SendPDF from './SendPDF.vue';
import ValidateForm from './ValidateForm.vue';

export default {
    props: ['report'],
    components: {
        SendPDF,
        ValidateForm,
    },
    data() {
        return {
            sendDialog: false,
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('PDFGenerator.validEmail'),
            ],
            emailToAdd: '',
            sending: false,
        };
    },
    methods: {
        openSendPdfDialog() {
            this.sendDialog = true;
        },
        closeSendPdfDialog() {
            this.sendDialog = false;
        },
        async sendPDF(report) {
            if (!this.sending) {
                this.sending = true;
                await this.$refs.sendPDF.sendPDF(report);
                this.sending = false;
            }
        },
        sendEmailWithOptionalRecievers(report) {
            report.recievers.push(this.$refs.optionalRecieverChoice);
            this.$refs.SendPDF.sendPDF(report);
        },
        async validateEmailAndSend(report) {
            if (this.$refs.emailInputForm.validate() && !this.sending) {
                this.sending = true;
                report.recievers.push(this.emailToAdd);
                await this.$refs.sendPDF.sendPDF(report);
                this.sending = false;
            }
        },
        validateFormAndOpenSendDialog(report) {
            if (this.$refs.validateForm.isRequiredInputFilled(report.sections)) {
                this.openSendPdfDialog();
            } else {
                this.$store.dispatch('reportSendWithEmptyRequired');
            }
        },
        emitDataSent() {
            this.$emit('reportSent');
        },
        emitSomethingWentWrong() {
            this.$emit('reportSendFailed');
        },
    },
};
</script>
