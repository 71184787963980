import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import {
    auth,
    autolinkCollection,
    reportsCollection,
    usersCollection,
    templatesCollection,
    foldersCollection,
    organizationsCollection,
    autolinkSessionsCollection,
    processesCollection,
    actionsCollection,
    processResponse,
    messageGroups,
    maintenanceReportsDEMO
} from '../fbInitialize';
import router from '../router';
import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firebaseFunctions } from '../fbInitialize';
import i18n from '../i18n';
import ThrottledPromiseRunner from './utils/ThrottledPromiseRunner';

var ls = new SecureLS({ isCompression: false });
Vue.use(Vuex);

const getDefaultState = () => {
    return {
        reports: [],
        templates: [],
        folders: [],
        autolinks: [],
        processes: [],
        userOrganization: [],
        rootFolderId: undefined,
        currentAutolinkSessions: [],
        userProfile: {},
        currentReport: {},
        currentTemplate: {},
        currentAutolink: {},
        currentProcess: {},
        autolinkSessionSubscription: undefined,
        currentFolderPath: [],
        currentSharedFolderPath: [],
        locale: '',
        currentProcessInfo: {},
        currentActions: [],
        organizationUsers: [],
        currentFolderListener: {},
        currentSharedFolderListener: {},
        currentProcessResponses: [],
        currentProcessSubscription: undefined,
        sharedRoots: [],
        sharedOpenRootFolder: '',
        sharedRootsIDsListener: {},
        reportListener: {},
        actionStatusesListeners: [],
        fetch: {
            reports: false,
            folders: false,
            templates: false,
            processes: false,
            sharedFolders: false
        }
    };
};

export default new Vuex.Store({
    state: getDefaultState(),
    mutations: {
        setSharedRootsIDsListener(state, data) {
            state.sharedRootsIDsListener = data;
        },
        addCurrentSharedFolderListener(state, data) {
            state.currentFolderListener = data;
        },
        setLocale(state, data) {
            state.locale = data;
            i18n.locale = data;
        },
        setReports(state, data) {
            state.reports = [...state.reports, ...data];
        },
        setTemplates(state, data) {
            state.templates = data;
        },
        setFolders(state, data) {
            state.folders = data;
        },
        addToFolders(state, data) {
            state.folders = state.folders.concat(data);
        },
        setRootFolder(state, data) {
            state.rootFolderId = data;
        },
        setProcesses(state, data) {
            state.processes = data;
        },
        setCurrentActions(state, data) {
            state.currentActions = data;
        },
        setUserOrganization(state, data) {
            state.userOrganization = data;
        },
        setAutolinkSession(state, data) {
            state.autolinkSession = data;
        },
        setOrgUsers(state, data) {
            state.organizationUsers = data;
        },
        setActionStatusesListener(state, data) {
            state.actionStatusesListeners = data;
        },
        deleteOrganizationUser(state, UIDToBeDeleted) {
            state.organizationUsers = state.organizationUsers.filter(user => user.id != UIDToBeDeleted);
        },
        editOrganizationUser(state, user) {
            Vue.set(
                state.organizationUsers,
                state.organizationUsers.findIndex(item => item.id === user.id),
                user
            );
        },
        addUser(state, user) {
            state.organizationUsers.push(user);
        },
        setAutolinkSessions(state, data) {
            state.currentAutolinkSessions = data;
        },
        autolinkSessionSubscription(state, unsubscribe) {
            state.autolinkSessionSubscription = unsubscribe;
        },
        resetAutolinkSession(state) {
            state.autolinkSessionSubscription();
            state.currentAutolinkSessions = [];
            state.autolinkSessionSubscription = undefined;
        },
        setCurrentProcessResponses(state, data) {
            state.currentProcessResponses = data;
        },
        currentProcessSubscription(state, unsubscribe) {
            state.currentProcessSubscription = unsubscribe;
        },
        resetProcessSession(state) {
            state.currentProcessSubscription();
            state.currentProcessResponses = [];
            state.currentProcessSubscription = undefined;
        },
        addReport(state, data) {
            state.reports.push(data);
        },
        addProcess(state, data) {
            state.processes.push(data);
        },
        addCurrentProcess(state, data) {
            state.currentProcess = data;
        },
        updateProcess(state, data) {
            let index = state.processes.findIndex(item => item.id === data.id);
            state.processes[index] = data;
        },
        deleteReport(state, payload) {
            let report = payload.report;
            state.reports = state.reports.filter((x) => x.id != report.id);
        },
        deleteProcess(state, process) {
            state.processes = state.processes.filter((x) => x.id != process.id);
        },
        deleteAction(state, action) {
            state.currentActions = state.currentActions.filter((x) => x.id != action.id);
        },
        deleteFolder(state, folder) {
            state.folders = state.folders.filter((x) => x.id != folder.id);
        },
        deleteAutolink(state, autolink) {
            state.autolinks = state.autolinks.filter((x) => x.autolinkId != autolink.autolinkId);
        },
        updateReport(state, data) {
            Vue.set(
                state.reports,
                state.reports.findIndex(
                    report => report.id == data.id
                ),
                data
            );
        },
        updateFolder(state, data) {
            Vue.set(
                state.folders,
                state.folders.findIndex(
                    folders => folders.id == data.id
                ),
                data
            );

        },
        updateTemplate(state, data) {
            Vue.set(
                state.templates,
                state.templates.findIndex(
                    template => template.template_id == data.template_id
                ),
                data
            );
        },
        modifyTemplate(state, data) {
            Vue.set(
                state.templates,
                state.templates.findIndex(
                    template => template.template_id == data.template_idK
                ),
                data
            );
        },
        updateAutolink(state, data) {
            Vue.set(
                state.autolinks,
                state.autolinks.findIndex(
                    autolink => autolink.autolinkId == data.autolinkId
                ),
                data
            );
        },
        updateUserDetails(state, data) {
            state.userProfile = Object.assign({}, state.userProfile, {
                firstname: data.firstname || state.userProfile.firstname,
                surname: data.surname || state.userProfile.surname,
            });
        },
        updateCurrentAction(state, data) {
            Vue.set(
                state.currentActions,
                state.currentActions.findIndex(
                    action => action.id == data.id
                ),
                data
            );
        },
        addCurrentReport(state, data) {
            state.currentReport = data;
        },
        addCurrentTemplate(state, data) {
            state.currentTemplate = data;
        },
        addTemplate(state, data) {
            state.templates.push(data);
        },
        addFolder(state, data) {
            state.folders.push(data);
        },
        deleteTemplate(state, template) {
            state.templates = state.templates.filter((x) => x.template_id != template.template_id);
        },
        setUserProfile(state, data) {
            state.userProfile = data;
        },
        setAutolinks(state, data) {
            state.autolinks = data;
        },
        addAutolink(state, data) {
            state.autolinks.push(data);
        },
        addCurrentAutolink(state, data) {
            state.currentAutolink = data;
        },
        addReportTimestampToAutolink(state, { timestamp, id }) {
            let index = state.autolinks.findIndex(item => item.autolinkId === id);
            state.autolinks[index].reportCreated.push(timestamp);
        },
        updateFolderName(state, data) {
            let index = state.folders.findIndex(item => item.id === data.folder.id);
            state.folders[index].name = data.name;
        },
        moveItem(state, data) {
            Vue.set(
                state.folders,
                state.folders.findIndex(folder => folder.id === data.id),
                data
            );
        },
        updateUserAgreedTermsAndConditions(state) {
            state.userProfile.userAgreedTermsAndConditions = true;
        },
        clearAutolinkSession(state) {
            state.autolinkSession.refresh = true;
        },
        addCurrentProcessInfo(state, data) {
            state.currentProcessInfo = data;
        },
        clearCurrentReport(state) {
            state.currentReport = {};
        },
        setSharedRoots(state, data) {
            state.sharedRoots = data;
        },
        addReportToSharedFolder(state, data) {
            state.reports.push(data);
        },
        setSharedOpenRoots(state, data) {
            state.sharedOpenRootFolder = data;
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        addReportListener(state, data) {
            state.reportListener = data;
        },
        updateCurrentReportName(state, reportName) {
            state.currentReport.name = reportName;
        },
        updateUserProfile(state, user) {
            state.userProfile = user;
        },
        reportsFetched(state) {
            state.fetch.reports = true;
        },
        foldersFetched(state) {
            state.fetch.folders = true;
        },
        templatesFetched(state) {
            state.fetch.templates = true;
        },
        processesFetched(state) {
            state.fetch.processes = true;
        },
        sharedFoldersFetched(state) {
            state.fetch.sharedFolders = true;
        }
    },
    actions: {
        async setCurrentReport({ commit }, reportId) {
            await reportsCollection.doc(reportId).get().then(doc => {
                const report = doc.data();
                commit('addCurrentReport', report);
            });
        },
        async setCurrentInspectionReport({ commit }, reportId) {
            await maintenanceReportsDEMO.doc(reportId).get().then(doc => {
                const report = doc.data();
                commit('addCurrentReport', report);
            });
        },
        async setCurrentProcessResponseReport({ commit }, reportId) {
            const reportFetch = await processResponse.doc(reportId).get();
            const reportData = reportFetch.data();
            commit('addCurrentReport', reportData.report);
        },
        // eslint-disable-next-line no-empty-pattern
        async getReportFromFirebase({ }, reportId) {
            return await reportsCollection.doc(reportId).get().then(doc => {
                return doc.data();
            });
        },
        async setReportListener({ commit }, reportId) {
            let reportListener = reportsCollection.doc(reportId).onSnapshot((doc) => {
                const report = doc.data();
                commit('addCurrentReport', report);
            });
            commit('addReportListener', reportListener);
        },
        destroyReportListener({ state, commit }) {
            state.reportListener();
            commit('addReportListener', {});
        },
        async addNamingFieldToProcess({ commit, state }, data) {
            let process = state.currentProcess;
            process.reportNamingFieldId = data.reportNamingFieldId;
            process.namingFieldLabel = data.namingFieldLabel;
            await processesCollection.doc(state.currentProcess.id).update(data);
            commit('updateProcess', process);
            commit('addCurrentProcess', process);
        },
        async addNamingFieldToAutolink({ commit, state }, data) {
            let id = data.id;
            let label = data.label;
            let autolink = state.currentAutolink;
            if (autolink.reportNamingFieldId === undefined) {
                autolink.reportNamingFieldId = '';
            }
            if (autolink.namingFieldLabel === undefined) {
                autolink.reportNamingFieldId = '';
            }
            autolink.reportNamingFieldId = id;
            autolink.namingFieldLabel = label;
            //USE .UPDATE
            await autolinkCollection.doc(state.currentAutolink.autolinkId).set(autolink).then(() => {
                commit('addCurrentAutolink', autolink);
            });
        },
        async setLocale({ commit }, data) {
            commit('setLocale', data);
        },
        async setReports({ commit, state }) {
            const batchSize = 250; // Define the batch size
            let lastVisible = null; // This will hold the last document of each batch

            const fetchBatch = async () => {
                let query = reportsCollection
                    .where('organizationId', '==', state.userProfile.organization)
                    .where('owner', '==', state.userProfile.id)
                    .where('firebaseDeleted', '==', false)
                    .orderBy('createdAt', 'desc')
                    .limit(batchSize);

                if (lastVisible) {
                    query = query.startAfter(lastVisible);
                }

                const querySnapshot = await query.get();
                const reports = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data();
                    const report = {
                        id: doc.id,
                        owner: data.owner,
                        creator: data.creator,
                        name: data.name,
                        organizationId: data.organizationId,
                        createdAt: data.createdAt,
                        markedReady: data.markedReady,
                    };
                    reports.push(report);
                });

                if (reports.length > 0) {
                    lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    commit('setReports', reports);
                    fetchBatch(); // Fetch next batch
                }
            };

            fetchBatch();
        },
        async deleteProcess({ commit }, item) {
            await processesCollection.doc(item.id).update({
                'firebaseDeleted': true
            }).then(() => {
                commit('deleteProcess', item);
            });
        },
        async deleteAction({ state }, action) {
            // delete action from the process
            await processesCollection.doc(state.currentProcess.id).update({
                'actions': firebase.firestore.FieldValue.arrayRemove(action.id)
            });

            // Mark as deleted
            actionsCollection.doc(action.id).update({
                'firebaseDeleted': true
            });
        },
        async setOrgUsers({ commit }) {
            const getUsers = firebaseFunctions.httpsCallable('getOrganizationUsers');
            await getUsers({}).then((users) => {
                commit('setOrgUsers', users.data);
            });
        },
        // eslint-disable-next-line no-empty-pattern
        async sendInstantMessage({ }, message) {
            return await firebaseFunctions.httpsCallable('sendInstantMessage')(message).then(() => {
            });
        },
        async setSharedFolderRoots({ commit, state }) {
            let returnable = [];
            for (const id of state.userProfile.sharedToFoldersIDs) {
                let folder = await foldersCollection.doc(id).get().then((doc) => {
                    const folder = doc.data();
                    folder.id = doc.id;
                    return folder;
                });
                if (!folder.firebaseDeleted) {
                    returnable.push(folder);
                }
            }
            commit('setSharedRoots', returnable);
            commit('addToFolders', returnable);
        },
        async setFolders({ commit, state }) {
            // Get root folder and rootfolder tree
            await foldersCollection
                .where('organizationId', '==', state.userProfile.organization)
                .where('owner', '==', state.userProfile.id)
                .where('firebaseDeleted', '==', false)
                .get().then((qs) => {
                    const folders = qs.docs.map((doc) => {
                        let result = doc.data();
                        result.id = doc.id;
                        // Get users own rootFolder
                        if (result.root && result.owner == state.userProfile.id) {
                            commit('setRootFolder', result.id);
                        }
                        return result;
                    });
                    commit('setFolders', folders);
                });
        },
        async setProcesses({ commit }, processes) {
            commit('setProcesses', processes);
        },
        async getCurrentActions({ commit }, actionList) {
            let currentActions = [];

            await actionList.forEach(async action => {
                const actionDoc = await actionsCollection.doc(action).get();
                const actionData = actionDoc.data();
                if (actionData.firebaseDeleted)
                    return;

                const actionStatusesQuery = await actionsCollection
                    .doc(action).collection('receiverMessageStatuses').get();

                const statuses = actionStatusesQuery.docs.map((doc) => {
                    let statusData = doc.data();
                    statusData.id = doc.id;
                    return statusData;
                });

                actionData.statuses = statuses;
                currentActions.push(actionData);
            });

            commit('setCurrentActions', currentActions);
        },
        async updateCurrentAction({ commit }, action) {
            const actionDoc = await actionsCollection.doc(action).get();
            const actionData = actionDoc.data();
            if (actionData.firebaseDeleted)
                return;

            const actionStatusesQuery = await actionsCollection
                .doc(action).collection('receiverMessageStatuses').get();

            const statuses = actionStatusesQuery.docs.map((doc) => {
                let statusData = doc.data();
                statusData.id = doc.id;
                return statusData;
            });
            actionData.statuses = statuses;

            commit('updateCurrentAction', actionData);
        },
        async currentActionStatusesListener({ dispatch, commit }, actionList) {
            let listenerList = [];
            await actionList.forEach(async action => {
                const listener = actionsCollection.doc(action).collection('receiverMessageStatuses')
                    .onSnapshot(() => {
                        dispatch('updateCurrentAction', action);
                    });
                listenerList.push(listener);
            });
            commit('setActionStatusesListener', listenerList);
        },
        async detachActionStatusesListeners({ commit, state }) {
            state.actionStatusesListeners.forEach(listener => {
                listener();
            });
            commit('setActionStatusesListener', []);
        },
        async setAutolinks({ commit, state }) {
            await autolinkCollection
                .where('owner', '==', state.userProfile.id)
                .where('firebaseDeleted', '==', false)
                .get().then((qs) => {
                    const data = qs.docs.map((doc) => {
                        let result = doc.data();
                        result.autolinkId = doc.id;
                        return result;
                    });
                    commit('setAutolinks', data);
                });
        },
        async setTemplates({ commit, state }) {
            const templatesQuery = await templatesCollection
                .where('organizationId', '==', state.userProfile.organization)
                .where('firebaseDeleted', '==', false).get();
            const templates = templatesQuery.docs.map((doc) => {
                let result = doc.data();
                return result;
            });
            commit('setTemplates', templates);
            commit('templatesFetched');
        },
        async setUserOrganization({ commit, state }) {
            await organizationsCollection.doc(state.userProfile.organization)
                .get().then((doc) => {
                    const result = doc.data();
                    result.organizationId = doc.id;
                    commit('setUserOrganization', result);
                });
        },
        async addReport({ commit, state }, data) {
            let folderID = data.folderID;
            let folder = state.folders.find(element => element.id == folderID);
            let report = data.report;

            report.owner = state.userProfile.id;
            report.creator = state.userProfile.id;
            report.organizationId = state.userProfile.organization;
            let ref = reportsCollection.doc();
            let reportId = ref.id;
            report.id = reportId;
            folder.reports.push(reportId);
            await Promise.all([
                reportsCollection.doc(reportId).set(report),
                foldersCollection.doc(folderID).update({
                    reports: firebase.firestore.FieldValue.arrayUnion(reportId)
                })
            ]);
            commit('updateFolder', folder);
            commit('addCurrentReport', report);
            if (data.shared) {
                commit('addReportToSharedFolder', report);
            } else {
                commit('addReport', report);
            }
            return report.id;
        },
        async updateReport({ commit, state }) {
            await reportsCollection.doc(state.currentReport.id).set(state.currentReport);
            let report = state.currentReport;
            commit('updateReport', report);
        },
        async updateMaintenanceReport({ commit, state }) {
            await maintenanceReportsDEMO.doc(state.currentReport.id).set(state.currentReport);
            let report = state.currentReport;
            commit('updateReport', report);
        },
        async updateProcessResponse({ state }, reportId) {
            await processResponse.doc(reportId).update({ report: state.currentReport });
        },
        async updateFolder({ commit, state }, folderID) {
            let folder = state.folders.find(element => element.id == folderID);
            folder.updatedAt = Math.round(new Date().getTime() / 1000);
            await foldersCollection.doc(folderID).update({updatedAt: folder.updatedAt});
            commit('updateFolder', folder);
        },
        async updateFolderWithData({ commit }, data) {
            let folder = data;
            folder.updatedAt = Math.round(new Date().getTime() / 1000);
            await foldersCollection.doc(data.id).update(data);
            commit('updateFolder', folder);
        },
        async addFolder({ commit, state }, data) {
            if (data.shared) {
                return new Promise((resolve, reject) => {
                    // Upload folder data to firebase
                    // Update parent folder data firebase
                    // Add folder to vuex folders
                    // Update vuex parent folder data
                    let folder = data.folderData;
                    let parentFolderID = data.parentFolderID;
                    let parentFolder = state.folders.find(element => element.id == parentFolderID);
                    // If user not signed in get data from parent
                    folder.organizationId = state.userProfile.organization || parentFolder.organizationId;
                    // If user not signed in get data from parent
                    folder.owner = state.userProfile.id || parentFolder.owner;

                    let ref = foldersCollection.doc();
                    let folderId = ref.id;
                    folder.id = folderId;

                    foldersCollection.doc(folderId).set(folder).then(() => {
                        parentFolder.subfolders.push(folder.id);
                        commit('addFolder', folder);
                        foldersCollection.doc(parentFolderID).update({
                            subfolders: firebase.firestore.FieldValue.arrayUnion(folderId)
                        }).then(() => {
                            commit('updateFolder', parentFolder);
                            resolve(folder.id);
                        });

                    }, error => reject(error));
                });
            } else {

                return new Promise((resolve, reject) => {
                    // Upload folder data to firebase
                    // Update parent folder data firebase
                    // Add folder to vuex folders
                    // Update vuex parent folder data
                    let folder = data.folderData;
                    folder.owner = state.userProfile.id;
                    folder.organizationId = state.userProfile.organization;
                    let parentFolderID = data.parentFolderID;
                    let parentFolder = state.folders.find(element => element.id == parentFolderID);
                    let ref = foldersCollection.doc();
                    let folderId = ref.id;
                    folder.id = folderId;
                    foldersCollection.doc(folderId).set(folder).then(() => {
                        // TODO TO MUTATIONS
                        parentFolder.subfolders.push(folder.id);
                        commit('addFolder', folder);
                        foldersCollection.doc(parentFolderID).update({
                            subfolders: firebase.firestore.FieldValue.arrayUnion(folderId)
                        }).then(() => {
                            commit('updateFolder', parentFolder);
                            resolve(folder.id);
                        });

                    }, error => reject(error));
                });
            }
        },
        async addProcess({ commit, state }, process) {
            return new Promise((resolve, reject) => {
                process.owner = state.userProfile.id;

                processesCollection.add(process).then((doc) => {
                    process.id = doc.id;
                    commit('addCurrentProcess', process);
                    resolve(process.id);
                }, error => reject(error));
            });
        },
        async updateProcess({ commit, }, process) {
            let passable = process;
            await processesCollection.doc(process.id).update(process).then(() => {
                commit('updateProcess', passable);
            });
        },
        async addAction({ state }, action) {
            const ref = await actionsCollection.doc();
            action.id = ref.id;
            await ref.set(action);
            let process = state.currentProcess;
            process.actions.push(action.id);
            this.dispatch('updateProcess', process);
        },
        async updateAction({ state }, action) {
            await actionsCollection.doc(action.id).update(action).then(() => {
                let process = state.currentProcess;
                if (process.actions.indexOf(action.id) === -1) {
                    this.items.push(action.id);
                }
                this.dispatch('updateProcess', process);
                this.dispatch('getCurrentActions', process.actions);
            });
        },
        async addAutolink({ commit, state }, autolink) {
            return new Promise((resolve, reject) => {
                autolink.owner = state.userProfile.id;
                let coverLetter = state.userOrganization.logoUrl != undefined && state.userOrganization.logoUrl != '';
                autolink.organizationLogoUrl = '';
                if (coverLetter) {
                    autolink.organizationLogoUrl = state.userOrganization.logoUrl;
                }
                autolinkCollection.add(autolink).then((doc) => {
                    autolink.autolinkId = doc.id;
                    commit('addAutolink', autolink);
                    commit('addCurrentAutolink', autolink);
                    resolve(doc.id);
                }, error => reject(error));
            });
        },
        async modifyAutolink({ commit, state }, autolink) {
            autolink.owner = state.userProfile.id;
            //USE .UPDATE
            await autolinkCollection.doc(state.currentAutolink.autolinkId).set(autolink).then(() => {
                autolink.autolinkId = state.currentAutolink.autolinkId;
                commit('addCurrentAutolink', autolink);
            });
        },
        async modifyUserDetails({ commit, state }, user) {
            user.organization = state.userOrganization.organizationId;
            await usersCollection.doc(state.userProfile.id).update(user).then(() => {
                commit('updateUserDetails', user);
            });

        },
        async addReportModifier({ commit, state }, modifier) {
            let report = state.currentReport;
            if (report.modifiers === undefined) {
                report.modifiers = [];
            }
            report.modifiers.push(modifier);
            reportsCollection.doc(report.id).update({
                modifiers: firebase.firestore.FieldValue.arrayUnion(modifier)
            }).then(() => {
                commit('updateReport', report);
            });
        },
        async deleteReport({ commit, state, dispatch }, payload) {

            let report = payload.report;
            let parentFolder = [];
            let folders = state.folders;
            folders.forEach((item) => {
                if (item.reports.includes(report.id)) {
                    parentFolder.push(item);
                }
            });
            let parent = parentFolder[0];
            parent.reports = parent.reports.filter((x) => x != report.id);
            parent.updatedAt = Math.round(new Date().getTime() / 1000);

            dispatch('updateFolderWithData', parent).then(() => {
                this.loading = false;
            });
            report.firebaseDeleted = true;
            await reportsCollection.doc(report.id).update({
                firebaseDeleted: true
            }).then(() => {
                commit('deleteReport', payload);
            });
        },
        async updateFolderName({ commit }, payload) {
            await foldersCollection.doc(payload.folder.id).update({ name: payload.name }).then(() => {
                commit('updateFolderName', payload);
            });
        },
        async setSharedRootsIDsListener({ commit, dispatch, state }) {

            let setSharedRootsIDsListener = usersCollection.doc(state.userProfile.id).onSnapshot((doc) => {

                let difference = doc.data().sharedToFoldersIDs
                    .filter(x => !state.userProfile.sharedToFoldersIDs.includes(x));

                if (difference[0] !== undefined) {
                    dispatch('addSharedFolderWithId', difference[0]).then(() => {
                        dispatch('updateUserProfile').then(() => {
                            dispatch('setNewSharedFoldersByListener', difference);
                            dispatch('setSharedFolderRoots');

                        });
                    });
                }
            });
            commit('setSharedRootsIDsListener', setSharedRootsIDsListener);
        },
        async setNewSharedFoldersByListener({ commit }, ids) {
            async function getSharedFolders(idArray) {
                let returnable = [];

                for (const id of idArray) {
                    let folder = await foldersCollection.doc(id).get().then((doc) => {
                        let obj = doc.data();
                        obj.id = doc.id;
                        return obj;

                    });
                    commit('addFolder', folder);

                }
                return returnable;
            }

            async function getSharedReports(idArray) {
                let returnable = [];

                for (const id of idArray) {
                    let report = await reportsCollection.doc(id).get().then((doc) => {
                        let data = doc.data();
                        const report = {};
                        report.id = doc.id;
                        report.owner = data.owner;
                        report.creator = data.creator;
                        report.name = data.name;
                        report.organizationId = data.organizationId;
                        report.createdAt = data.createdAt;
                        return report;
                    });

                    commit('addReport', report);
                }
                return returnable;
            }


            async function fetchFolders(ids) {
                await getSharedFolders(ids).then((result) => {

                    result.forEach((item) => {
                        getSharedReports(item.reports);
                        fetchFolders(item.subfolders);
                    });
                });

            }
            fetchFolders(ids);
        },
        async addSharedFolderWithId({ commit }, id) {
            await foldersCollection.doc(id).get().then((doc) => {
                let obj = doc.data();
                obj.id = doc.id;
                commit('addFolder', obj);
            });
        },
        setCurrentFolderListener({ commit, state, getters }) {
            let folderPath = router.history.current.name === 'dashboard'
                ? getters.getCurrentFolderPath
                : getters.getCurrentSharedFolderPath;
            async function getSharedFolders(idArray) {
                let returnable = [];

                for (const id of idArray) {
                    let folder = {};
                    await foldersCollection.doc(id).get().then((doc) => {
                        folder = doc.data();
                        folder.id = doc.id;
                    });
                    if (!folder.firebaseDeleted) {
                        returnable.push(folder);
                    }
                }
                return returnable;
            }
            async function getSharedReports(idArray) {

                const promiseRunner = new ThrottledPromiseRunner(5);
                let returnable = [];

                while (idArray.length) {
                    // firestore limits batches to 10
                    const batch = idArray.splice(0, 10);

                    // add the batch request to to a queue
                    promiseRunner.addAsyncCall(
                        async () => {
                            const all = await reportsCollection
                                .where(
                                    firebase.firestore.FieldPath.documentId(),
                                    'in',
                                    [...batch]
                                )
                                .get();
                            all.docs.forEach(doc => {
                                const data = doc.data();
                                const report = {
                                    id: doc.id,
                                    owner: data.owner,
                                    creator: data.creator,
                                    name: data.name,
                                    organizationId: data.organizationId,
                                    createdAt: data.createdAt,
                                };
                                commit('addReport', report);
                            });
                        }
                    );
                }
                await promiseRunner.run();
                return returnable;
            }
            // Updates all the reports of the folder
            let currentFolderId = state.rootFolderId;
            if (folderPath.length > 0) {
                currentFolderId = folderPath.at(-1).id;
            }

            let folderReportListener = foldersCollection.doc(currentFolderId).onSnapshot(async doc => {

                let folder = doc.data();
                folder.id = doc.id;

                let sharedFoldersIDs = state.folders.map(x => x.id);
                let sharedReportsIDs = state.reports.map(x => x.id);

                let newFoldersIDs = folder.subfolders.filter(x => !sharedFoldersIDs.includes(x));
                let newReportsIDs = folder.reports.filter(x => !sharedReportsIDs.includes(x));

                let newFolders = await getSharedFolders(newFoldersIDs);

                let newReports = await getSharedReports(newReportsIDs);

                newFolders.forEach((item) => commit('addFolder', item));
                newReports.forEach((item) => commit('addReport', item));
                commit('updateFolder', folder);
            });
            commit('addCurrentSharedFolderListener', folderReportListener);
        },

        async moveItem({ commit }, payload) {
            let updated = await firebaseFunctions.httpsCallable('moveItem')(payload);
            commit('moveItem', updated.data.folderToMoveFrom);
            commit('moveItem', updated.data.folderToMoveTo);
        },
        async deleteFolder({ commit, dispatch, state }, folder) {
            folder.firebaseDeleted = true;
            await foldersCollection.doc(folder.id).update(folder).then(() => {
                commit('deleteFolder', folder);
            });
            let parentFolder = [];
            let folders = state.folders;
            folders.forEach((item) => {
                if (item.subfolders.includes(folder.id)) {
                    parentFolder.push(item);
                }
            });
            let parent = parentFolder[0];
            parent.subfolders = parent.subfolders.filter((x) => x != folder.id);
            parent.updatedAt = Math.round(new Date().getTime() / 1000);

            dispatch('updateFolderWithData', parent).then(() => {
                this.loading = false;
            });
            if (folder.subfolders.length != 0) {
                folder.subfolders.forEach((folderID) => {
                    let indexOf = this.state.folders.findIndex((x) => x.id === folderID);
                    let folder = this.state.folders[indexOf];
                    let payload = {
                        folder: folder,
                        shared: false
                    };
                    dispatch('deleteFolder', payload);
                });
            }
            if (folder.reports.length != 0) {
                folder.reports.forEach((reportID) => {
                    let indexOf = this.state.reports.findIndex((x) => x.id === reportID);
                    let report = this.state.reports[indexOf];
                    let payload = {
                        report: report,
                        shared: false
                    };
                    dispatch('deleteReport', payload, indexOf);
                });
            }


        },
        async fetchSharedReport({ commit }, id) {
            return new Promise((resolve, reject) => {
                reportsCollection.doc(id).onSnapshot((doc) => {
                    if (doc.exists) {
                        let report = doc.data();
                        report.id = doc.id;
                        if (report.shared) {
                            commit('addCurrentReport', report);
                            resolve();
                        }
                    }
                    reject();
                }, error => reject(error));
            });
        },
        async fetchAutolinkReport({ commit, state }, id) {
            // TODO CLEAR VUEX AFTER 14 DAYS THIS IS NOT IMPLEMENTED RIGHT NOW
            //let time14DaysAgo = Math.round(new Date().getTime() / 1000) - 1209600;
            let session = state.autolinkSession;
            if (state.currentReport.autolinkId === id
                && session != {}
                && (session.refresh == undefined || session.refresh === false)) {
                console.log('report already loaded');
            }
            else {
                // CALL CLOUD FUNCTION AND GIVE NAME TO THE
                await firebaseFunctions.httpsCallable('getAutolink')({ autolinkId: id }).then((doc) => {
                    let data = doc.data;
                    let session = data.data;
                    let report = session.report;
                    let created = session.created;
                    let sessionId = session.id;
                    if (data.success) {
                        commit('setAutolinkSession', { created: created, id: sessionId, refresh: false });
                        commit('addCurrentReport', report);
                    }
                    else {
                        commit('addCurrentReport', {});
                        // PUSH OOPS PAGE
                        router.push('/oops');
                    }
                });
            }

        },
        async fetchLinkReport({ commit, state }, folderId) {
            try {
                const doc = await firebaseFunctions.httpsCallable('getLinkReport')(folderId);
                const report = doc.data;
                if (report) {
                    report.creator = state.userProfile.id;
                    commit('addCurrentReport', report);
                }
                else {
                    commit('addCurrentReport', {});
                    router.push('/oops');
                }
            } catch (e) {
                console.error(e);
                router.push('/oops');
            }
        },
        // eslint-disable-next-line no-empty-pattern
        async createProcessResponse({ }, params) {
            const dataForFilling = await firebaseFunctions.httpsCallable('getProcessReportAdminCreate')(params);
            let data = dataForFilling.data.data;

            let reportData = {
                reportFillerId: data.reportFillerId,
                processId: data.processId,
                report: data.report,
                created: Date.now(),
                namingFieldId: data.namingFieldId,
            };

            const processResponseId = await firebaseFunctions.httpsCallable('fillProcessReportAdminCreate')(reportData);
            return processResponseId;
        },

        async fetchProcessReport({ commit, state }, params) {
            let processDataFetch = {};
            try {
                processDataFetch = await firebaseFunctions.httpsCallable('getProcessReport')(params);
            } catch (e) {
                return ({
                    success: false,
                    message: i18n.t('FillProcessReport.unexpectedProblem'),
                });
            }
            const fetchData = processDataFetch.data;

            const processReportData = fetchData.data;
            const report = processReportData.report;

            if (fetchData.success) {
                if(Object.keys(state.currentReport).length === 0
                || state.currentReport.template_id !== report.template_id
                || state.currentProcessInfo.reportFillerId !== params.reportFillerId) {
                    commit('addCurrentReport', report);
                }

                if (state.currentProcessInfo.processId === params.processId
                    && state.currentProcessInfo.reportFillerId === params.reportFillerId) {
                    return ({
                        success: true,
                        message: 'report already loaded',
                    });
                }

                let processInfo = {
                    processId: processReportData.processId,
                    reportFillerId: processReportData.reportFillerId,
                    namingFieldId: processReportData.namingFieldId
                };

                commit('addCurrentProcessInfo', processInfo);

                return ({
                    success: true,
                    message: 'Fetch success',
                });
            } else {
                if (fetchData.message === 'Report closed') {
                    return ({
                        success: false,
                        message: i18n.t('FillProcessReport.reportClosed'),
                    });
                } else if (fetchData.message === 'Already filled or no reciever found') {
                    return ({
                        success: false,
                        message: i18n.t('FillProcessReport.reportFilled'),
                    });
                }
                return ({
                    success: false,
                    message: i18n.t('FillProcessReport.reportFilled'),
                });
            }

        },
        async sendProcessReport({ commit, state }, file) {
            let params = {
                reportFillerId: state.currentProcessInfo.reportFillerId,
                processId: state.currentProcessInfo.processId,
                report: state.currentReport,
                created: Date.now(),
                namingFieldId: state.currentProcessInfo.namingFieldId,
                attachment: file
            };
            await firebaseFunctions.httpsCallable('fillProcessReport')(params);
            commit('addCurrentProcessInfo', {});
        },
        async addCurrentReport({ commit }, report) {
            commit('addCurrentReport', report);
        },
        async addCurrentProcess({ commit }, process) {
            commit('addCurrentProcess', process);
        },
        async addCurrentAutolink({ commit }, autolink) {
            commit('addCurrentAutolink', autolink);
        },
        async updateTemplate({ commit }, template) {
            await templatesCollection.doc(template.template_id).set(template).then(() => {
                commit('updateTemplate', template);
            });
        },
        async addTemplate({ commit, state }, template) {
            return new Promise((resolve, reject) => {
                template.owner = state.userProfile.id;
                template.organizationId = state.userProfile.organization;
                if (template.template_id) {
                    templatesCollection.doc(template.template_id).set(template).then(() => {
                        commit('updateTemplate', template);
                        resolve();
                    }, error => reject(error));
                } else {
                    let ref = templatesCollection.doc();
                    let template_id = ref.id;
                    template.template_id = template_id;
                    templatesCollection.doc(template_id).set(template).then(() => {
                        commit('addTemplate', template);
                        commit('addCurrentTemplate', template);
                        resolve();
                    }, error => reject(error));
                }
            });
        },
        addCurrentTemplate({ commit }, template) {
            commit('addCurrentTemplate', template);
        },
        async deleteTemplate({ commit }, template) {
            template.firebaseDeleted = true;
            await templatesCollection.doc(template.template_id).update({
                firebaseDeleted: true
            }).then(() => {
                commit('deleteTemplate', template);
            });
        },
        async deleteAutolink({ commit }, autolink) {
            let id = autolink.autolinkId;
            autolink.firebaseDeleted = true;
            delete autolink.autolinkId;
            //USE .UPDATE
            await autolinkCollection.doc(id).set(autolink).then(() => {
                commit('deleteAutolink', autolink);
            });
        },
        async login({ dispatch }, form) {
            const { user } = await auth.signInWithEmailAndPassword(form.email, form.password);
            await dispatch('fetchUserProfile', user);
        },
        // eslint-disable-next-line no-empty-pattern
        async onSignInSubmit({ }, phone) {
            const num = phone.replace(/\s/g, '');

            let isValid = await firebaseFunctions.httpsCallable('checkPhoneNumber')(phone);
            if (isValid.data != false) {
                const vert = new firebase.auth.RecaptchaVerifier('431725', {
                    'size': 'invisible',
                    'callback': () => { console.log('callback initialized'); },
                });
                return await firebase.auth().signInWithPhoneNumber(num, vert).then(async (confirmationResult) => {
                    return confirmationResult;
                }).catch((e) => {
                    console.log(e);
                    throw new Error(e);
                });
            } else {
                throw new Error('invalid phone number');
            }

        },
        // eslint-disable-next-line no-empty-pattern
        async sendForgotPasswordEmail({ }, email) {
            if (email !== '') {
                return auth.sendPasswordResetEmail(email);
            }
        },
        async fetchUserProfile({ commit, dispatch }, user) {
            // if logged in
            let fbUser = firebase.auth().currentUser;
            if (!fbUser) {
                return;
            }

            const userProfile = await usersCollection.doc(user.uid).get();
            const data = userProfile.data();
            data.id = userProfile.id;

            commit('setUserProfile', data);
            await dispatch('setLocale', data.locale);
        },
        async fetchAll({ dispatch }) {
            const dispatchCommands = [
                'setOrgUsers',
                'setUserOrganization',
                'setTemplates',
                'setAutolinks'
            ];
            const promises = dispatchCommands.map(async (command) => { await dispatch(command); });
            await Promise.all(promises);
        },
        async fetchDispatchCommands({ dispatch }, dispatchCommands) {
            const promises = dispatchCommands.map(command => dispatch(command) );
            await Promise.all(promises);
        },
        async updateOwnUser({ dispatch }, user) {
            return new Promise((res) => {
                firebaseFunctions
                    .httpsCallable('updateUser')(user)
                    .then(() => {
                        dispatch('fetchUserProfile', user);
                        res(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        res(false);
                    });
            });
        },
        async updateUserProfile({ commit }) {
            const userProfile = await usersCollection.doc(firebase.auth().currentUser.uid).get();
            const UserData = userProfile.data();
            UserData.id = userProfile.id;
            commit('setUserProfile', UserData);
        },
        async getAutolinkSessions({ commit }) {
            const collectionRef =
                autolinkSessionsCollection.where('report.autolinkId', '==', this.state.currentAutolink.autolinkId);

            const unsubscribe = collectionRef.onSnapshot((qs) => {
                const data = qs.docs.map((doc) => {
                    let result = doc.data();
                    result.id = doc.id;
                    return result;
                });
                commit('setAutolinkSessions', data);
            });
            commit('autolinkSessionSubscription', unsubscribe);
        },
        async getCurrentProcessResponses({ commit }) {
            const collectionRef = processResponse.where('processId', '==', this.state.currentProcess.id);

            const unsubscribe = collectionRef.onSnapshot((qs) => {
                const data = qs.docs.map((doc) => {
                    let result = doc.data();
                    result.id = doc.id;
                    return result;
                });
                let res = data.filter((doc) => doc.created);
                commit('setCurrentProcessResponses', res);
            });
            commit('currentProcessSubscription', unsubscribe);
        },
        async logout({ commit }) {
            await auth.signOut();
            location.reload();
            commit('resetState');
            window.localStorage.removeItem('vuex');
            router.push({ name: 'login' });
        },
        async reportSendWithEmptyRequired({ commit }) {
            commit('sendEmptyRequired');
        },
        async updateTermsAndConditions({ commit, state }) {
            await usersCollection.doc(state.userProfile.id).update({
                userAgreedTermsAndConditions: true
            }).then(() => {
                commit('updateUserAgreedTermsAndConditions', true);
            });
        },
        async addReportCreatedTimestamp({ commit }, { timestamp, id }) {
            autolinkCollection.doc(id).update({
                reportCreated: firebase.firestore.FieldValue.arrayUnion(timestamp)
            });
            commit('addReportTimestampToAutolink', { timestamp, id });
        },
        async deleteFollowUp({ commit }, process) {
            delete process['followUp'];
            await processesCollection.doc(process.id).update({
                followUp: firebase.firestore.FieldValue.delete()
            });
            commit('updateProcess', process);
        },
        async addMessageGroup({ state }, messageGroup) {
            messageGroup.owner = state.userProfile.id;
            messageGroup.organizationId = state.userProfile.organization;
            messageGroup.firebaseDeleted = false;
            messageGroup.createdAt = Math.round(new Date().getTime() / 1000);
            const ref = messageGroups.doc();
            messageGroup.id = ref.id;
            ref.set(messageGroup);
        },
        // eslint-disable-next-line no-empty-pattern
        async deleteMessageGroup({  }, messageGroup) {
            await messageGroups.doc(messageGroup.id).update({
                'firebaseDeleted': true
            });
        },
        // eslint-disable-next-line no-empty-pattern
        async updateMessageGroup({ }, {id, name, messages}) {
            await messageGroups.doc(id).update({
                'messages': messages,
                'name': name
            });
        },
    },
    getters: {
        getProcesses: state => {
            return state.processes.filter(process => !process.firebaseDeleted);
        },
        getTemplates: state => {
            function getOwnerProfile(UID) {
                let user = state.organizationUsers.find(
                    (x) => x.id == UID
                );
                if (typeof user !== 'undefined') {
                    return { firstname: user.firstname, surname: user.surname, avatarColor: user.avatarColor };
                } else {
                    return undefined;
                }
            }
            let data = state.templates.filter(template => !template.firebaseDeleted);
            let clonedData = _.cloneDeep(data);
            clonedData = clonedData.map(x => ({ ...x, ownerProfile: getOwnerProfile(x.owner) }));
            let sortedData = clonedData.sort(function (a, b) {
                return new Date(b.createdAt * 1000) - new Date(a.createdAt * 1000);
            });
            return sortedData;
        },
        getTemplate: state => templateId => {
            let templates = state.templates.filter(template => template.template_id == templateId);
            if (templates != []) {
                return templates[0];
            }
            else {
                return {};
            }
        },
        getUser: state => {
            return state.userProfile;
        },
        getAutolinks: (state) => {
            return state.autolinks;
        },
        getTemplateName: (state) => (templateId) => {
            try {
                let templateName = state.templates.find(template => template.template_id == templateId).templateName;
                if (templateName) {
                    return templateName;
                }
                else {
                    return 'NOT FOUND';
                }
            }
            catch (e) {
                console.log(e);
                return '';
            }
        },
        getUserOrganization: (state) => {
            return state.userOrganization;
        },
        getFolder: (state) => {
            function getOwnerName(ownerId) {
                let user = state.organizationUsers.find(
                    (x) => x.id == ownerId
                );
                if (typeof user !== 'undefined') {
                    return { firstname: user.firstname, surname: user.surname, avatarColor: user.avatarColor };
                } else {
                    return null;
                }
            }

            // If folder list is empty, gets root folder
            let currentFolderId = state.rootFolderId;
            if (state.currentFolderPath.length != 0) {
                currentFolderId = state.currentFolderPath.at(-1);
            }
            let folder = state.folders.find(x => x.id == currentFolderId);
            // If doesn't load folders, return empty list

            if (!folder) {
                return [];
            }
            let formattedFolder = [];
            // Go trough all of the folders in the folder and get id & name pair

            for (let subfolderId of folder.subfolders) {
                let subfolder = state.folders.find(x => x.id == subfolderId);
                if (subfolder && !formattedFolder.find(x => x.id == subfolderId)) {
                    formattedFolder.push({
                        id: subfolderId,
                        name: subfolder.name,
                        type: 'folder',
                        use: subfolder.use,
                        creator: getOwnerName(subfolder.owner),
                        createdAt: subfolder.createdAt
                    });
                }
            }
            // Go trough all of the reports in the folder and get id & name pair
            for (let reportId of folder.reports) {
                let report = state.reports.find(x => x.id == reportId);
                if (report && !formattedFolder.find(x => x.id == reportId)) {
                    formattedFolder.push({
                        id: reportId,
                        name: report.name,
                        type: 'report',
                        createdAt: report.createdAt,
                        owner: report.owner,
                        creator: getOwnerName(report.creator),
                        markedReady: report.markedReady ? report.markedReady : false
                    });
                }
            }
            return formattedFolder;
        },
        getSharedFolder: (state) => {
            function getOwnerName(ownerId) {
                let user = state.organizationUsers.find(
                    (x) => x.id == ownerId
                );
                if (typeof user !== 'undefined') {
                    return { firstname: user.firstname, surname: user.surname, avatarColor: user.avatarColor };
                } else {
                    return {};
                }
            }
            let folder = {};
            // If folder list is empty, gets root folder
            let currentFolderId = '';
            if (state.currentSharedFolderPath.length > 0) {
                currentFolderId = state.currentSharedFolderPath.at(-1);
                folder = state.folders.find(x => x.id == currentFolderId);
            } else {
                folder.subfolders = state.sharedRoots.map(x => x.id);
                folder.reports = [];
            }
            // If doesn't load folders, return empty list
            if (!folder) {
                return [];
            }
            let formattedFolder = [];
            // Go trough all of the folders in the folder and get id & name pair

            for (let subfolderId of folder.subfolders) {
                let subfolder = state.folders.find(x => x.id == subfolderId);
                if (subfolder && !formattedFolder.find(x => x.id == subfolderId)) {
                    formattedFolder.push({
                        id: subfolderId,
                        name: subfolder.name,
                        type: 'folder',
                        use: subfolder.use,
                        creator: getOwnerName(subfolder.owner),
                        createdAt: subfolder.createdAt
                    });
                }
            }
            // Go trough all of the reports in the folder and get id & name pair
            for (let reportId of folder.reports) {
                let report = state.reports.find(x => x.id == reportId);
                if (report && !formattedFolder.find(x => x.id == reportId)) {
                    formattedFolder.push({
                        id: reportId,
                        name: report.name,
                        type: 'report',
                        createdAt: report.createdAt,
                        owner: report.owner,
                        creator: getOwnerName(report.creator),
                        markedReady: report.markedReady ? report.markedReady : false
                    });
                }
            }
            return formattedFolder;
        },
        getCurrentFolderData: (state) => {
            function getParticipantData(UID) {
                let user = state.organizationUsers.find(
                    (x) => x.id == UID
                );
                if (typeof user !== 'undefined') {
                    return {
                        id: user.id,
                        firstname: user.firstname,
                        surname: user.surname,
                        avatarColor: user.avatarColor,
                        email: user.email
                    };
                } else {
                    return null;
                }
            }
            let currentFolderId = state.rootFolderId;
            if (state.currentFolderPath.length > 0) {
                currentFolderId = state.currentFolderPath.at(-1);
            }
            let folders = state.folders;
            let folder = folders.find(x => x.id == currentFolderId);
            // maps participant profiles and filter out the null values
            if(folder === undefined) {
                return folder;
            }
            let returnableFolder = _.cloneDeep(folder);
            returnableFolder.participantProfiles =
                folder.participants
                    .map(x => getParticipantData(x.UID))
                    .filter(participant => participant);
            return returnableFolder;
        },
        getCurrentSharedFolderData: (state) => {
            function getParticipantData(UID) {
                let user = state.organizationUsers.find(
                    (x) => x.id == UID
                );
                if (typeof user !== 'undefined') {
                    return {
                        id: user.id,
                        firstname: user.firstname,
                        surname: user.surname,
                        avatarColor: user.avatarColor,
                        email: user.email
                    };
                } else {
                    return null;
                }
            }
            let currentFolderId = state.rootFolderId;
            if (state.currentSharedFolderPath.length > 0) {
                currentFolderId = state.currentSharedFolderPath.at(-1);
            }
            let folders = state.folders;
            let folder = folders.find(x => x.id == currentFolderId);
            // maps participant profiles and filter out the null values
            if(folder === undefined) {
                return folder;
            }
            let returnableFolder = _.cloneDeep(folder);
            returnableFolder.participantProfiles = folder.participants.map(x => getParticipantData(x.UID));
            return returnableFolder;
        },

        getCurrentFolderPath: (state) => {
            let folderArrayToReturn = [];
            // Get each folder and make a name & id pair out of it
            for (let folderId of state.currentFolderPath) {
                let folders = state.folders;
                let folder = folders.find(x => x.id == folderId);
                if (folder) {
                    folderArrayToReturn.push({
                        id: folderId,
                        name: folder.name
                    });
                }
            }

            return folderArrayToReturn;
        },
        getCurrentSharedFolderPath: (state) => {
            let folderArrayToReturn = [];
            // Get each folder and make a name & id pair out of it
            for (let folderId of state.currentSharedFolderPath) {
                let folders = state.folders;
                let folder = folders.find(x => x.id == folderId);
                if (folder) {
                    folderArrayToReturn.push({
                        id: folderId,
                        name: folder.name
                    });
                }
            }

            return folderArrayToReturn;
        },
        getFolderTree: (state) => (itemsToMoveIds) => {
            function sortFolderTree(folder, itemsToMoveIds) {
                folder.children = [];
                folder.subfolders.forEach((subfolderId) => {
                    // If folder is not in not in movingItems and exists
                    if (!itemsToMoveIds.includes(subfolderId)) {
                        let subfolder = state.folders.find(x => x.id == subfolderId);
                        if (subfolder) {
                            let cloneSubFolder = _.cloneDeep(subfolder);
                            cloneSubFolder.type = 'folder';
                            folder.children.push(cloneSubFolder);
                            sortFolderTree(cloneSubFolder, itemsToMoveIds);
                        }
                    }
                });
                return [folder];
            }
            const rootFolder = _.cloneDeep(state.folders.find(x => x.id === state.rootFolderId));

            rootFolder.type = 'folder';
            let items = sortFolderTree(rootFolder, itemsToMoveIds);
            return items;
        },
        getCheckIsTemplateUsed: (state) => (template_id) => {
            let processes = [];
            let folders = [];
            let autolinks = [];
            for (let autolink of state.autolinks) {
                if (autolink.template_id == template_id && !autolink.firebaseDeleted) {
                    autolinks.push(autolink.name);
                }
            }
            for (let process of state.processes) {
                if (process.templateId == template_id && !process.firebaseDeleted) {
                    processes.push(process.title);
                }
            }
            for (let folder of state.folders) {
                if (folder.template_id == template_id && !folder.firebaseDeleted) {
                    folders.push(folder.name);
                }
            }
            if (processes.length > 0 || folders.length > 0 || autolinks.length > 0) {
                return { used: true, processes: processes, folders: folders, autolinks: autolinks };
            }
            return false;
        },
        getCheckIsTemplateNameTaken: (state) => (templateName) => {
            // Used to check if templateName is already used
            for (var i = 0; i < state.templates.length; i++) {
                if (state.templates[i].templateName === templateName && !state.templates[i].firebaseDeleted) {
                    return true;
                }
            }
            return false;
        },
        getCheckIsTemplateNameTakenTwo: (state) => (template) => {
            // const value = state.templates.filter(x => x.id !== template.id).find(template.name)
            for (var i = 0; i < state.templates.length; i++) {
                if (state.templates[i].templateName === template.templateName && !state.templates[i].firebaseDeleted) {
                    if (state.templates[i].template_id == template.template_id) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
            return false;
        },
        getSharedFolders: () => async (idArray) => {
            return new Promise((res) => {
                // don't run if there aren't any ids or a path for the collection
                if (!idArray || !idArray.length) return res([]);

                const collectionPath = foldersCollection;
                let batches = [];

                while (idArray.length) {
                    // firestore limits batches to 10
                    const batch = idArray.splice(0, 10);

                    // add the batch request to to a queue
                    batches.push(
                        new Promise(response => {
                            collectionPath
                                .where(
                                    firebase.firestore.FieldPath.documentId(),
                                    'in',
                                    [...batch]
                                )
                                .get()
                                .then(results => response(results.docs.map(result => ({ ...result.data() }))));
                        })
                    );
                }

                // after all of the data is fetched, return it
                Promise.all(batches).then(content => {
                    res(content.flat());
                });

            });
        },
        getProcessReponses: () => async (idArray) => {
            return new Promise((res) => {
                // don't run if there aren't any ids or a path for the collection
                if (!idArray || !idArray.length) return res([]);

                const collectionPath = processResponse;
                let batches = [];

                while (idArray.length) {
                    // firestore limits batches to 10
                    const batch = idArray.splice(0, 10);

                    // add the batch request to to a queue
                    batches.push(
                        new Promise(response => {
                            collectionPath
                                .where(
                                    firebase.firestore.FieldPath.documentId(),
                                    'in',
                                    [...batch]
                                )
                                .get()
                                .then(results => response(results.docs.map(result => ({ ...result.data() }))));
                        })
                    );
                }

                // after all of the data is fetched, return it
                Promise.all(batches).then(content => {
                    res(content.flat());
                });

            });
        },
        getProcessFillersAndResponses: (state) => {
            let process = state.currentProcess;
            let receivers = _.cloneDeep(process.receivers);
            const responses = state.currentProcessResponses;
            receivers.forEach(receiver => {
                receiver['response'] = responses.find(response => response.reportFillerId == receiver.id) || null;
            });
            return receivers;
        },
        getGlobalSearchData: (state) => {
            // If folder list is empty, gets root folder
            let currentFolderId = state.rootFolderId;
            if (state.currentFolderPath.length != 0) {
                currentFolderId = state.currentFolderPath.at(-1);
            }
            let folder = state.folders.find(x => x.id == currentFolderId);
            // If doesn't load folders, return empty list

            if (!folder) {
                return [];
            }
            let formattedFolder = [];
            function formatTime(epochTime) {
                var d = new Date(epochTime * 1000);
                return d.toLocaleString();
            }
            function getOwnerName(ownerId) {
                let user = state.organizationUsers.find(
                    (x) => x.id == ownerId
                );
                if (typeof user !== 'undefined') {
                    return { firstname: user.firstname, surname: user.surname, avatarColor: user.avatarColor };
                } else {
                    return {};
                }
            }

            function recursiveHelper(folder) {
                for (let subfolderId of folder.subfolders) {
                    let subfolder = state.folders.find(x => x.id == subfolderId);
                    if (subfolder && !formattedFolder.find(x => x.id == subfolderId)) {
                        formattedFolder.push({
                            id: subfolderId,
                            name: subfolder.name,
                            type: 'folder',
                            use: subfolder.use,
                            creator: getOwnerName(subfolder.owner),
                            createdAt: formatTime(subfolder.createdAt)
                        });
                    }
                }
            }
            recursiveHelper(folder);
            return;

        },
        isLimitOfImagesInCurrentReport: (state) => {
            let report = state.currentReport;
            if (!report || Object.keys(report).length === 0) {
                return false;
            }

            let sections = report.sections;
            let imgCount = 0;
            for (let section of sections) {
                for (let field of section.fields) {
                    if (field.type === 'addimages' || field.type === 'image') {
                        imgCount = imgCount + field.data.length;
                    }
                }
                for (let subsection of section.sections) {
                    for (let field of subsection.fields) {
                        if (field.type === 'addimages' || field.type === 'image') {
                            imgCount = imgCount + field.data.length;
                        }
                    }
                }
            }
            return imgCount > 50;
        },
    },

    modules: {
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        })
    ]
});
