<template>
    <v-container class="pa-0 ma-0 mb-2">
        <v-layout>
            <v-flex class="xs12 sm12 md10">
                <v-sheet>
                    <v-data-table
                        :loading="loading"
                        :headers="headers"
                        :items="getAutolinks || []"
                        class="elevation-1 rounded-lg row-pointer"
                        hide-default-footer
                        disable-pagination
                        @click:row="navigateTo"
                        :no-data-text="$t('AutolinkTable.NoData')"
                        item-key="autolinkId"
                    >
                        <template v-slot:item.templateNames="{ item }">
                            <span class="">{{ getTemplateName(item.template_id) }}</span>
                        </template>

                        <template v-slot:item.name="{ item }">
                            <div class="">{{ item.name }}</div>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn icon>
                                <v-icon @click.stop="deleteItem(item)" data-cy="deleteAutolink">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.link="{ item }">
                            <!--FOR ERROR TO GO AWAY-->
                            <div v-if="!item"></div>
                            <v-btn icon>
                                <v-icon @click.stop="copyLink(item)"> mdi-content-copy </v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.type="{ item }">
                            <!--FOR ERROR TO GO AWAY-->
                            <div v-if="!item"></div>

                            <v-icon> mdi-file-link </v-icon>
                            <v-snackbar
                                v-model="copysnackbar"
                                elevation="1"
                                class="rounded-lg pa-0 ma-0"
                                timeout="3000"
                                color="grey darken-3"
                            >
                                <v-card
                                    class="d-flex flex-column black--text rounded-lg pa-0 ma-0"
                                    color="grey darken-3"
                                    elevation="0"
                                >
                                    <v-card-text class="pa-0 ma-0">
                                        <v-row align="center" justify="center">
                                            <v-col cols="10">
                                                {{ $t("ModifyAutolink.linkCopied") }}
                                            </v-col>
                                            <v-col cols="2">
                                                <v-icon class="float-right mx-auto">
                                                    mdi-content-copy
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-snackbar>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-flex>
        </v-layout>
        <OkCancelDialog ref="OkCancelDialog" />
        <v-dialog v-model="alertDialog" width="400">
            <v-card class="rounded-lg">
                <v-card-text class="pa-4 black--text">
                    {{ $t("AutolinkTable.cantDeleteTemplate") }}

                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="body-2 font-weight-bold"
                        outlined
                        @click.native="alertDialog = false"
                        data-cy="confirmDialog"
                    >
                        {{ $t("AutolinkTable.confirmOk") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <BaseLoadingDialog
            :sendingData="loading"
            :msg="$t('Baseloading.loadingMessage')"
        />
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import OkCancelDialog from '../OkCancelDialog';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';

export default {
    components: {
        OkCancelDialog,
        BaseLoadingDialog,
    },
    data() {
        return {
            copysnackbar: false,
            loading: false,
            alertDialog: false,
            headers: [
                {
                    text: '',
                    value: 'type',
                    sortable: false,
                },
                {
                    text: this.$t('AutolinkTable.name'),
                    align: 'start',
                    value: 'name',
                },
                {
                    text: this.$t('AutolinkTable.templateNames'),
                    value: 'templateNames',
                    sortable: true
                },
                { text: this.$t('AutolinkTable.link'), value: 'link', sortable: false },
                {
                    text: this.$t('AutolinkTable.actions'),
                    value: 'actions',
                    sortable: false,
                },
            ],
        };
    },
    methods: {
        copyLink(item) {
            this.copysnackbar = true;
            let content = this.getLink(item);
            navigator.clipboard.writeText(content);
        },
        getLink(item) {
            return location.origin + '/fillautolinkreport/' + item.autolinkId || '';
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        navigateTo(item) {
            this.$store.dispatch('addCurrentAutolink', item).then(() => {
                this.$router.push({ name: 'modifyautolink' });
            });
        },
        async deleteItem(item) {
            let deleteApproved = await this.OkCancelDialog(
                this.$t('AutolinkTable.deleteItemHeader') + item.name,
                this.$t('AutolinkTable.deleteItemMessage')
            );
            if (deleteApproved) {
                this.loading = true;

                this.$store.dispatch('deleteAutolink', item).then(() => {
                    this.loading = false;
                });
            }
        },
    },
    computed: {
        ...mapGetters(['getAutolinks']),
        ...mapGetters(['getTemplateName']),
    },
};
</script>

<style scoped>
.truncate {
  display: inline-block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-pointer :deep(tbody tr :hover) {
  cursor: pointer;
}
</style>
