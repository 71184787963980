<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        hide-on-leave
        class="pa-2"
        width="400"
    >
        <v-card class="rounded-lg pa-2" width="400">
            <v-form ref="form">
                <v-container fluid class="pa-6">
                    <v-row v-if="!$vuetify.breakpoint.mdAndUp">
                        <v-col cols="1">
                            <v-btn
                                color="white"
                                x-large
                                icon
                                @click="navigateBack"
                                data-cy="navigateBack"
                            >
                                <v-icon color="black">mdi-arrow-left</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col>
                            <h1 class="text-center">
                                {{ $t("CreateAutolink.header") }}
                            </h1>
                        </v-col>
                        <v-col cols="1"> </v-col>
                    </v-row>
                    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="py-0">
                        <v-col cols="10" class="py-0 pa-0">
                            <h1 class="h4">{{ $t("CreateAutolink.header") }}</h1>
                        </v-col>
                        <v-col cols="2" class="py-0">
                            <v-btn
                                color="white"
                                x-large
                                icon
                                @click="closeDialog"
                                data-cy="closeCreateAutolinkDialog"
                            >
                                <v-icon color="black">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-text-field
                            data-cy="autolinkName"
                            :label="$t('CreateAutolink.name')"
                            v-model="name"
                            :rules="requireName"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row data-cy="selectTemplate">
                        <v-autocomplete
                            :items="getTemplates"
                            ref="templatechoice"
                            :item-text="(item) => item.templateName"
                            return-object
                            prepend-icon="mdi-file-outline"
                            :label="$t('CreateAutolink.template')"
                            :no-data-text="$t('CreateReport.Form.NoData')"
                            :rules="requireSelect"
                            class="my-2"
                            @change="changeSelectedTemplate"
                        >
                        </v-autocomplete>
                    </v-row>
                    <v-row>
                        <v-col align="left" class="pa-0">
                            <v-list class="pa-0">
                                <v-list-item
                                    v-for="(item, i) in autoLinkTemplate.recievers"
                                    :key="i"
                                    class="px-0"
                                >
                                    <v-list-item-icon>
                                        <v-icon large>mdi-account</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            icon
                                            @click="deleteReceiver(i)"
                                            data-cy="deleteReceiver"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row class="pb-2">
                        <v-col class="pa-0">
                            <v-form ref="emailInputForm" :lazy-validation="true">
                                <v-text-field
                                    data-cy="addReceiverEmail"
                                    class="ma-0 py-2 rounded-lg"
                                    :label="$t('CreateAutolink.email')"
                                    v-model="emailToAdd"
                                    :rules="emailRules"
                                    validate-on-blur
                                    @keydown.enter.prevent="addReciever()"
                                >
                                </v-text-field>
                            </v-form>
                        </v-col>
                        <v-col cols="3" class="pa-0">
                            <v-btn
                                color="primary"
                                @click="addReciever()"
                                block
                                class="rounded-lg white--text"
                                data-cy="addReceiver"
                            >
                                {{ $t("CreateAutolink.addReciever") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn
                            @click="createAndOpenAutolink"
                            block
                            height="60"
                            color="primary"
                            class="rounded-lg"
                            dark
                            data-cy="createAndOpenAutolink"
                        >
                            {{ $t("CreateAutolink.create") }}
                        </v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <v-snackbar
            v-model="snackbar"
            elevation="1"
            color="primary"
            class="rounded-lg"
        >
            <h1 class="title">
                {{ snackbarText }}
            </h1>

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="closeSnackbar()">
                    {{ $t("CreateAutolink.close") }}
                </v-btn>
            </template>
        </v-snackbar>
        <BaseLoadingDialog
            :sendingData="sendingData"
            :msg="$t('Baseloading.loadingMessage')"
        />
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import BaseLoadingDialog from '../GeneralizedComponents/BaseLoadingDialog.vue';

export default {
    components: {
        BaseLoadingDialog,
    },
    data() {
        return {
            dialog: false,
            sendingData: false,
            snackbar: false,
            snackbarText: '',
            emailToAdd: '',
            selectedTemplate: {},
            name: '',
            requireName: [
                (v) => (v && v.length > 0) || this.$t('CreateAutolink.nameRule'),
            ],
            requireSelect: [(v) => !!v || this.$t('CreateAutolink.selectRule')],
            emailRules: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('CreateAutolink.emaiRule'),
            ],
            autoLinkTemplate: {
                name: '',
                template_id: '',
                recievers: [],
                reportCreated: [], // This contains Timestamps of times when report is sended with the link
                link: '',
                firebaseDeleted: false,
            },
        };
    },
    methods: {
        closeSnackbar() {
            this.snackbar = false;
        },
        openDialog() {
            this.selectedTemplate = {};
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        createAndOpenAutolink() {
            if (this.$refs.form.validate()) {
                if (this.autoLinkTemplate.recievers.length) {
                    this.sendingData = true;
                    var templateClone = {};
                    templateClone = _.cloneDeep(this.autoLinkTemplate);
                    templateClone['template_id'] = this.selectedTemplate.template_id;
                    templateClone['name'] = this.name;
                    templateClone['createdAt'] = Math.round(new Date().getTime() / 1000);
                    templateClone['updatedAt'] = Math.round(new Date().getTime() / 1000);
                    this.$store
                        .dispatch('addAutolink', templateClone)
                        .then(() => {
                            this.sendingData = false;
                            this.$router.push({ name: 'modifyautolink' });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    this.snackbarText = this.$t('CreateAutolink.snackBar');
                    this.snackbar = true;
                }
            }
        },
        addReciever() {
            if (this.emailToAdd != '' && this.$refs.emailInputForm.validate()) {
                this.autoLinkTemplate.recievers.push(this.emailToAdd);
                this.emailToAdd = '';
            }
        },
        deleteReceiver(index) {
            this.autoLinkTemplate.recievers.splice(index, 1);
        },
        changeSelectedTemplate(obj) {
            this.selectedTemplate = obj;
        },
        navigateBack() {
            this.closeDialog();
        },
    },
    computed: {
        ...mapGetters(['getTemplates', 'getTemplate']),
    },
};
</script>
