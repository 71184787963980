import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import { fbConfig } from './fbConfig.js';

const firebaseApp = firebase.initializeApp(fbConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

// This is set for reset password email
auth.languageCode = 'fi';

const firebaseFunctions = firebase.app().functions('europe-west1');
const autolinkCollection = db.collection('autolinks');
const processesCollection = db.collection('processes');
const actionsCollection = db.collection('actions');
const reportsCollection = db.collection('reports');
const usersCollection = db.collection('users');
const templatesCollection = db.collection('templates');
const foldersCollection = db.collection('folders');
const organizationsCollection = db.collection('organizations');
const autolinkSessionsCollection = db.collection('autolinkSessions');
const processResponse = db.collection('processResponse');
const messageGroups = db.collection('messageGroups');
const inspections = db.collection('inspections');
const inspectionsTableDEMO = db.collection('inspectionTableDEMO');
const inspectionReportsDEMO = db.collection('inspectionReportsDEMO');
const maintenanceTableDEMO = db.collection('maintenanceTableDEMO');
const maintenanceReportsDEMO = db.collection('maintenanceReportsDEMO');
const storageRef = firebase.storage().ref();
const fbStorage = firebase.storage();

// Emulator settings
const emulator = process.env.VUE_APP_EMULATOR;
if (emulator) {
    db.useEmulator('localhost', 8081);
    if (window.Cypress) {
        db.settings({
            experimentalForceLongPolling: true,
            merge: true
        });
    }
    auth.useEmulator('http://localhost:9099');
    fbStorage.useEmulator('localhost', 9199);
    firebaseFunctions.useEmulator('localhost', 5001);
}

export {
    db,
    auth,
    firebaseFunctions,
    autolinkCollection,
    processesCollection,
    actionsCollection,
    usersCollection,
    reportsCollection,
    templatesCollection,
    foldersCollection,
    organizationsCollection,
    storageRef,
    fbStorage,
    autolinkSessionsCollection,
    processResponse,
    messageGroups,
    inspections,
    inspectionsTableDEMO,
    inspectionReportsDEMO,
    maintenanceTableDEMO,
    maintenanceReportsDEMO
};
