<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
        @click:outside="closeAndClear"
        class="pa-0 ma-0"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            class="border-0 h1-26"
            :class="{ 'rounded-lg pt-3 px-8': $vuetify.breakpoint.mdAndUp }"
            width="600"
        >
            <v-row
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="modal-header-mobile h1-16 mx-0 items-center"
            >
                <v-col cols="2">
                    <v-icon color="primary" class="icon-plus" @click="closeAndClear">
                        mdi-chevron-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center">
                    <h1>{{ $t("LogicConfig.afterFillReport") }}</h1>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-container fluid :class="{'pb-8 pt-header px-5':!$vuetify.breakpoint.mdAndUp}">
                <v-row class="h1-26" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="10">
                        <h1>{{ $t("LogicConfig.afterFillReport") }}</h1>
                    </v-col>
                    <v-col cols="2">
                        <v-icon
                            data-cy="closeLogicConfig"
                            class="close-button"
                            @click="closeAndClear"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row class="mx-0">
                    <p>{{ $t("LogicConfig.actionsAfterFillReport") }}</p>
                </v-row>
                <v-row class="mx-0 mb-0 h2-18">
                    <h2>{{ $t("LogicConfig.sendFinishedReport") }}</h2>
                </v-row>
                <v-list v-if="this.$store.state.currentProcess.ReportPDFReceivers.length > 0">
                    <v-list-item
                        v-for="(item, i) in this.$store.state.currentProcess.ReportPDFReceivers"
                        :key="i"
                        class="px-0 mx-0"
                    >
                        <v-list-item-icon class="ma-0">
                            <div class="image-avatar-table-visitor">
                                <p class="avatar-text-table-visitor">
                                    {{ getInitials(getNameFromEmail(item)) }}
                                </p>
                            </div>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row class="ma-0">
                                    <p class="mb-0 text-primary">
                                        <b> {{ getNameFromEmail(item) }} </b>
                                    </p>
                                </v-row>
                                <v-row class="mb-0 mr-0 ml-0 mt-1 text-gray">
                                    {{ item.email }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                icon
                                color="primary"
                                @click.stop="deleteReceiver(item)"
                                data-cy="receiverDelete"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-row class="mx-0 mt-2">
                    <v-col class="grow pa-0">
                        <v-form ref="emailInputFormDefault">
                            <v-text-field
                                data-cy="emailInput"
                                class="ma-0 py-2 rounded-lg"
                                v-model="addRecieverHelper.email"
                                :label="$t('LogicConfig.recepientEmail')"
                                :rules="emailRules"
                                validate-on-blur
                                outlined
                                @keydown.enter.prevent="saveEmail()"
                            >
                            </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col class="shrink pa-0">
                        <v-btn
                            data-cy="saveEmail"
                            @click="saveEmail()"
                            class="primary-button mt-3 ml-3"
                        >
                            <v-icon class="mr-1 icon-plus">mdi-plus-circle-outline</v-icon>
                            {{ $t("CreateLinkReportFolder.addReceiver") }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 mb-0 h2-18">
                    <h2>{{ $t("LogicConfig.sendReponseToFiller") }}</h2>
                </v-row>
                <v-row class="mx-0">
                    <v-form ref="form" class="w-100">
                        <v-text-field
                            data-cy="addTitle"
                            :label="$t('LogicConfig.title')"
                            v-model="message.header"
                            required
                            outlined
                            :rules="requireHeader"
                            class="rounded-lg comment-field-100"
                        ></v-text-field>
                    </v-form>
                </v-row>
                <v-row class="mx-0" :class="{'mb-150': !$vuetify.breakpoint.mdAndUp}">
                    <v-form ref="form" class="w-100">
                        <v-textarea
                            data-cy="addMessage"
                            rows="4"
                            :label="$t('LogicConfig.addMessage')"
                            v-model="message.msg"
                            counter
                            required
                            outlined
                            class="rounded-lg comment-field-100 textarea-transparent"
                            style="white-space: pre-line"
                            :rules="requireMsg"
                        ></v-textarea>
                    </v-form>
                </v-row>
                <v-row
                    class="mt-3 mb-0"
                    :class="{'mobile-dialog-controls ma-0 px-3 pt-7 pb-5': !$vuetify.breakpoint.mdAndUp}"
                >
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            data-cy="followupDelete"
                            class="secondary-button"
                            :class="{'w-100 py-4 mb-2':!$vuetify.breakpoint.mdAndUp}"
                            @click="deleteFollowUp()"
                        >
                            {{ $t("LogicConfig.deleteMessage") }}
                        </v-btn>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                        align="end"
                        :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}"
                    >
                        <v-btn
                            data-cy="saveMessage"
                            class="primary-button"
                            :class="{'w-100 py-4 mb-4':!$vuetify.breakpoint.mdAndUp}"
                            @click="saveFollowUp()"
                        >
                            {{ $t("LogicConfig.saveMessage") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <OkCancelDialog ref="OkCancelDialog" />
        <GeneralSnackbar ref="GeneralSnackbar" :snackbarText="$t('LogicConfig.saved')" />

    </v-dialog>
</template>

<script>
import OkCancelDialog from '../OkCancelDialog.vue';
import GeneralSnackbar from '../GeneralizedComponents/GeneralSnackbar.vue';
import _ from 'lodash';

export default {
    components: { OkCancelDialog, GeneralSnackbar },
    data() {
        return {
            dialog: false,
            addRecieverHelper: { email: '', phone: '' },
            message: { header: '', msg: '' },
            addReceiver: false,
            addMessage: false,
            emailRules: [
                (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    this.$t('LogicConfig.emailRule'),
            ],
            requireHeader: [
                (v) => (v && v.length > 0) || this.$t('LogicConfig.writeTitle'),
            ],
            requireMsg: [
                (v) => (v && v.length > 0) || this.$t('LogicConfig.writeMessage'),
            ],
            receivers: [],
        };
    },
    methods: {
        openLogicConfigDialog() {
            let header = Object.is(
                this.$store.state.currentProcess.followUp,
                undefined
            )
                ? ''
                : this.$store.state.currentProcess.followUp.header;

            let msg = Object.is(this.$store.state.currentProcess.followUp, undefined)
                ? ''
                : this.$store.state.currentProcess.followUp.msg;
            this.message.header = _.cloneDeep(header);
            this.message.msg = _.cloneDeep(msg);

            if (this.$store.state.currentProcess.followUp) {
                this.addMessage = true;
            }

            this.dialog = true;
        },
        async OkCancelDialog(header, msg) {
            return await this.$refs.OkCancelDialog.open(header, msg);
        },
        saveEmail() {
            if (this.addRecieverHelper.email === '') {
                return;
            }
            if (!this.$refs.emailInputFormDefault.validate()) {
                return;
            }

            let process = this.$store.state.currentProcess;
            if (!process.receivers) {
                process['ReportPDFReceicers'] = [];
            }
            let receiver = {};
            receiver['email'] = this.addRecieverHelper.email;
            process.ReportPDFReceivers.push(receiver);
            this.$store.dispatch('updateProcess', process).then(() => {
                this.addRecieverHelper = { email: '' };
            });
        },
        closeAndClear() {
            this.addReceiver = false;
            this.addMessage = false;
            this.dialog = false;
            this.message = { header: '', msg: '' };
        },
        openAddReceiver() {
            this.addReceiver = true;
        },
        openAddEmailMessage() {
            this.addMessage = true;
        },
        async deleteReceiver(item) {
            let process = this.$store.state.currentProcess;
            let filtered = process.ReportPDFReceivers.filter((x) => {
                return x.email != item.email;
            });
            process.ReportPDFReceivers = filtered;
            this.$store.dispatch('updateProcess', process).then(() => {
                this.addRecieverHelper = { email: '', phone: '' };
            });
        },
        saveFollowUp() {
            if (this.$refs.form.validate()) {
                let process = this.$store.state.currentProcess;
                process['followUp'] = this.message;
                this.$store.dispatch('updateProcess', process).then(() => {
                    let followUp = this.$store.state.currentProcess.followUp;
                    this.message = { header: followUp.header, msg: followUp.msg };
                    this.$refs.GeneralSnackbar.snackbar = true;
                });
                this.dialog = false;
            }
        },
        deleteFollowUp() {
            let process = this.$store.state.currentProcess;
            this.$store.dispatch('deleteFollowUp', process).then(() => {
                this.message = { header: '', msg: '' };
                this.addMessage = false;
            });
            this.dialog = false;
        },
        takeStrUntilNumbers(str) {
            let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            let index = 0;
            for (let i = 0; i < str.length; i++) {
                if (numbers.includes(str[i])) {
                    break;
                }
                index += 1;
            }
            return str.slice(0, index);
        },

        getNameFromEmail(item) {
            if (item.email) {
                let prefix = item.email.split('@')[0];
                let firstName, lastName;
                if (prefix.includes('.')) {
                    firstName = this.takeStrUntilNumbers(
                        prefix.split('.')[0].charAt(0).toUpperCase() +
                            prefix.split('.')[0].slice(1)
                    );
                    lastName = this.takeStrUntilNumbers(
                        prefix.split('.')[1].charAt(0).toUpperCase() +
                            prefix.split('.')[1].slice(1)
                    );
                    if (prefix.split('.').length > 2) {
                        lastName = this.takeStrUntilNumbers(
                            prefix
                                .split('.')[prefix.split('.').length - 1].charAt(0)
                                .toUpperCase() +
                                prefix.split('.')[prefix.split('.').length - 1].slice(1)
                        );
                    }
                    return `${firstName} ${lastName}`;
                }
                return this.takeStrUntilNumbers(prefix.charAt(0).toUpperCase() + prefix.slice(1));
            }
        },

        getInitials(name) {
            if (name) {
                let names = name.split(' ');
                let initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            }
        },
    },
};
</script>

<style>
</style>
