// Template
const templateTemplate = {
    name: '',
    templateName: '',
    sections: [],
    modifiers: [],
    shared: false,
    firebaseDeleted: false,
    markedReady: false, // For report
    versionNumber: 1,
    locale: ''
};


// User
const userTemplate = {
    exists: true,
    firstname: '',
    surname: '',
    organization: '',
    sharedToFoldersIDs: [],
    rootFolderId: '',
    email: '',
    phone: '',
    userAgreedTermsAndConditions: false,
    role: '', // 'admin', 'default'
    bundle: [], // 'process'
    locale: '', // 'fi', 'en'
};

// Folder creation
const folderTemplate = {
    firebaseDeleted: false,
    name: '',
    root: false,
    reports: [],
    subfolders: [],
    participants: [],
    isClosed: false,
    createdAt: '',
    updatedAt: '',
    owner: '',
    organizationId: '',
    // ForAutoFolder
    use: '', // use:'AutoFolder'
    reportNamingFieldId: '',
    reportNamingFieldLabel: '',
    template_id: '',
    defaultReceivers: [],
    optionalReceivers: [],
    msg: {
        emailTitle: '',
        msg: ''
    }
};

// TemplateGenerator sections
const sectionTemplate = {
    sectionName: '',
    fields: [],
    sections: [],
    question: ''
};

// TemplateGenerator fields
const fieldTemplates = [
    {
        type: 'textfield',
        data: '',
        required: false,
        static: false,
        question: '',
    },
    {
        type: 'textinput',
        label: '',
        data: '',
        required: false,
        static: false,
        question: '',
    },
    {
        type: 'choice',
        newChoiceHelper: '',
        options: ['Hyvä', 'Tyydyttävä', 'Huono'],
        data: '',
        required: false,
        question: '',
        static: false,
    },
    {
        type: 'addimages',
        data: [], // Format { phototaker: String, timetag: Number, text: String, url: String }
        required: false,
        static: false,
        question: '',
    },
    {
        type: 'comment',
        data: [],
        required: false,
        static: false,
        question: '',
    },
    {
        type: 'signature',
        data: [],
        required: false,
        static: false,
        question: '',
    },
    {
        type: 'file',
        data: [
            /*{ url:"...", uploader: "firstname surname", timestamp: "..."} */
        ],
        required: false,
        static: false,
        question: '',
    },
    {
        type: 'date',
        data: '',
        required: false,
        static: false,
        hasClock:false,
        time:'',
        question: '',
    },
    {
        type: 'table',
        data: [],
        columns: [{ type: 'text', label: '', value: '', rowNamingField: true }],
        name: '',
        required: false,
        static: false,
        question: '',
    },

    {
        type: 'slider',
        data: null,
        required: false,
        static: false,
        question: '',
        maxValue: 10,
        minValue: 1
    },
    {
        type: 'text',
        data: '',
        static: true,
        question: '',
    },
    {
        type: 'image',
        data: [],
        static: true,
        question: '',
    },
    {
        type: 'infotext',
        data: '',
        static: true,
        question: '',
    },
    {
        type: 'staticfile',
        data: [
            /*{ url:"...", uploader: "firstname surname", timestamp: "..."} */
        ],
        static: true,
        question: '',
    },

];

//Process
const processTemplate = {
    title: '',
    firebaseDeleted: false,
    endDate: '',
    templateId: '',
    ReportPDFReceivers: [],
    actions: [],
    createdAt: '',
    owner: '',
    organizationId: '',
    receivers: [],
    slugName: null
};

export {
    folderTemplate,
    sectionTemplate,
    fieldTemplates,
    userTemplate,
    templateTemplate,
    processTemplate
};
