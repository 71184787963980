import {Component} from '../../../../component';

class coverLetterStyle extends Component {
    code() {
        return {
            alignment: 'center',
        };
    }
}

export default coverLetterStyle;
