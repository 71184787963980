import {Field} from '../../../field';
import ImageUtils from '../utils/imageUtils';
import {imageLoader} from '../utils/imageLoader';
import {asComponent} from '../components/helpers/asComponent';
import {GridLayout} from './layouts/layout.grid';
import {formatTime} from '../utils/epoch';
import ImageStyle from './styles/style.image';
// import {formatTime} from "../utils/epoch";


export class AddImages extends Field {
    imgIds = [];

    code() {
        let gridLayout = asComponent(
            GridLayout,
            {
                items: this.#images,
                gridWidth: 2,
            }
        );
        return gridLayout;
    }

    photoTaker(imageData) {
        return imageData.phototaker || '';
    }

    caption(imageData) {
        return imageData.text || '';
    }

    get #images() {
        let images = [];

        for(const imageData of this.data) {
            let imageUtil = new ImageUtils(imageData.url);
            images.push(
                [
                    {
                        image: imageUtil.imageGarbageCollectionID,
                        ...asComponent(ImageStyle, {height: imageUtil.height, width: imageUtil.width}),
                    },
                    {
                        text: `${this.caption(imageData)}`,
                        alignment: 'left',
                    },
                    {
                        text: `${this.photoTaker(imageData)}${formatTime(imageData.timetag)}`,
                        alignment: 'left',
                        style: 'FieldStyle',
                    },
                ]
            );
            this.imgIds.push(imageUtil);
        }
        return images;
    }

    after(pdf) {
        for(const imageID of this.imgIds) {
            imageLoader(pdf, imageID, false);
        }
    }

    get isEmpty() {
        return this.data.length === 0;
    }
}

export default AddImages;
