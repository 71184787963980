import {Component} from '../../../../component';

export class GridLayout extends Component {
    code() {
        let grid = {
            stack: this.columns
        };

        return grid;
    }

    get columns() {
        let columns = [];

        for(const items of this.partitionedItems) {
            columns.push(
                {
                    columns: items
                }
            );
        }

        return columns;
    }

    get partitionedItems() {
        let items = [];
        let i = 0;

        while(i <= this.args.items.length - 1) {
            let item = this.args.items.slice(i, i + this.args.gridWidth);
            i += this.args.gridWidth;
            items.push(item);
        }

        return items;
    }
}
