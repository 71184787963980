<template>
    <v-dialog fullscreen v-model="dialog">
        <v-container fill-height :style="{ background:  $vuetify.theme.themes.light.primary }" fluid>
            <v-row align="center" justify="center">
                <div>
                    <h2 class="white--text text-center" style="font-size: 40px">
                        Formup
                    </h2>
                </div>
            </v-row>
            <v-row align="center" justify="center" class="mb-0">
                <v-card class="rounded-lg" width="400">
                    <v-card-text>
                        <v-container fluid class="pa-0 ma-0" v-if="enterPhoneNumber">
                            <h1 class="h4 black--text pt-2 pb-4" style="text-align: center">
                                {{ $t("LoginDialog.title") }}
                            </h1>
                            <form @submit.prevent>
                                <v-flex xs12>
                                    <p class="pt-2 pb-0">{{ $t("LoginDialog.areaCode") }}</p>
                                    <v-text-field
                                        name="phone"
                                        type="tel"
                                        v-model="phone"
                                        :label="$t('LoginDialog.phoneNumberLabel')"
                                        required
                                    ></v-text-field>
                                </v-flex>
                                <v-card-actions class="pa-0 ma-0">
                                    <v-btn
                                        type="submit"
                                        @click="login()"
                                        class="rounded-lg"
                                        block
                                        color="primary"
                                        dark
                                        height="50"
                                        id="431725"
                                    >
                                        {{ $t("LoginDialog.sendCode") }}
                                    </v-btn>
                                </v-card-actions>
                                <v-layout row class="pt-6 ma-0" v-show="errMsg != ''">
                                    <v-flex xs12>
                                        <v-alert type="error" rounded>
                                            {{ errMsg }}
                                        </v-alert>
                                    </v-flex>
                                </v-layout>
                            </form>
                            <LocaleSwitcher class="mt-2" />
                        </v-container>
                        <v-container v-else>
                            <h1 class="h4 black--text pt-2 pb-4" style="text-align: center">
                                {{ $t("LoginDialog.enterCode") }}
                            </h1>
                            <v-text-field v-model="confirmationCode"> </v-text-field>
                            <v-btn
                                type="submit"
                                @click="confirmCode()"
                                class="rounded-lg"
                                block
                                color="primary"
                                dark
                                height="50"
                                id="431725"
                            >
                                {{ $t("LoginDialog.confirmCode") }}
                            </v-btn>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-row>
            <v-row align="center" justify="center">
                <v-card
                    class="rounded-lg"
                    width="300"
                    color="transparent"
                    elevation="0"
                >
                </v-card>
            </v-row>
            <BaseLoadingDialog
                :sendingData="loggingProcessOngoing"
                :msg="$t('Login.loadingMessage')"
            />
            <GeneralSnackbar ref="GeneralSnackbar" :snackbarText=snackbarText />
        </v-container>
    </v-dialog>
</template>

<script>
import BaseLoadingDialog from '../components/GeneralizedComponents/BaseLoadingDialog.vue';
import GeneralSnackbar from './GeneralizedComponents/GeneralSnackbar.vue';

export default {
    components: {
        BaseLoadingDialog,
        GeneralSnackbar,
    },
    data() {
        return {
            phone: '',
            errMsg: '',
            loggingProcessOngoing: false,
            dialog: false,
            enterPhoneNumber: true,
            confirmation: {},
            confirmationCode: '',
            snackbarText: '',
        };
    },
    methods: {
        async login() {
            if (!this.loggingProcessOngoing) {
                this.loggingProcessOngoing = true;
                this.errMsg = '';
                await this.$store
                    .dispatch('onSignInSubmit', this.phone)
                    .then(async (confirmation) => {
                        this.enterPhoneNumber = false;
                        this.confirmation = confirmation;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loggingProcessOngoing = false;
                        this.$refs.GeneralSnackbar.snackbar = true;
                        this.snackbarText=this.$t('LoginDialog.invalidNumber') + this.$t('LoginDialog.areaCode');
                        this.errMsg = this.$t('LoginDialog.phoneNumberRejected');
                    });
                this.loggingProcessOngoing = false;
            }
        },
        async confirmCode() {
            this.loggingProcessOngoing = true;
            // Open confim code
            await this.confirmation
                .confirm(this.confirmationCode)
                .then(async (result) => {
                    await this.$store.dispatch('fetchUserProfile', result.user);
                    this.loggingProcessOngoing = false;
                    this.$emit('loginSuccessful');
                    this.dialog = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.enterPhoneNumber = true;
                    this.confirmationCode = '';
                    this.errMsg = this.$t('LoginDialog.wrongCode');
                    this.loggingProcessOngoing = false;
                });
        },
        openDialog() {
            this.dialog = true;
        },
    },
};
</script>
