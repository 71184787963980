import {formatTime} from '../utils/epoch';
import PDFComponent from './PDFComponent';

class Header extends PDFComponent {
    code() {
        return {
            header: [
                {
                    style: 'header',
                    columns: [
                        {
                            alignment: 'left',
                            text: `${this.args.pdf.metaData.reportName}`
                        },
                        {
                            alignment: 'right',
                            text: `${formatTime(this.args.pdf.metaData.timeStamp)}`
                        }
                    ]
                },
            ],
        };
    }
}

export default Header;
